const planInfoKeys = {
  SmartDetectionAlert: 'Smart Detection Alerts',
  PriorityTechSupport: 'Priority Tech Support',
  CustomizedActivityZones: 'Customized Activity Zones',
  AccessToLiveFeed24X7: '24/7 Access to Live Feed',
  ExtendedProductWarranty: 'Extended Product Warranty',
  TwoWayTalk: '2-Way Talk',
  RichNotification: 'Rich Notifications',
  UpTo20PercentOff: 'Up to 20 Percent Off',
  ReviewShareSaveVideo: 'Review, Share, Save Video',
  EmergencySiren: 'Emergency Siren',
  MotionCapture: 'Motion Capture',
  DaysOfStorage: 'Days of Storage',
  DesktopStreaming: 'Desktop Streaming',
  PersonDetection: 'Person Detection',
};

export default planInfoKeys;
