import React from 'react';
import classes from './subscription.module.css';

const ViewPlans = props => {
  const { handleViewPlan } = props;

  return (
    <div className={classes.noDevicesWrap}>
      <div>No devices to show</div>
      <div
        onClick={() => handleViewPlan(2)}
        role="presentation"
        className={classes.viewPlans}
      >
        Click here to view Plans
      </div>
    </div>
  );
};

export default ViewPlans;
