import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import InputElem from '../../ui/Input/Input';
import * as actionCreator from '../store/action-creator';
import { addOrUpdateDeviceToken } from '../../Home/store/action-creator';
import ButtonElem from '../../ui/Button/Button';
import { updateObject, checkValidity } from '../../common/utils';
import msgConstant from '../../common/textConstants';
import CustomDialog from '../../ui/CustomDialog/CustomDialog';
import LoadingSpinner from '../../ui/LoadingSpinner/LoadingSpinner';
import classes from '../Profile.module.css';
import { AuthContext } from '../../../Providers/AuthProvider';

const ChangePassword = () => {
  const { signOut, updatePassword, user } = React.useContext(AuthContext);
  const dispatch = useDispatch();

  const email = user.userEmail;
  const deviceToken = useSelector((state) => state.homePageReducer.deviceToken);
  const [spinner, setSpinner] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [newPwdForm, setNewPwdForm] = useState({
    currentpassword: {
      elemType: 'input',
      isdisabled: false,
      elemConfig: {
        type: 'password',
        name: 'currentpassword',
        placeholder: 'Current password',
        required: false,
      },
      value: '',
      validation: {
        required: true,
        minLength: 8,
        maxLength: 99,
        isPassword: true,
      },
      valid: false,
      touched: false,
    },
    newpassword: {
      elemType: 'input',
      isdisabled: false,
      elemConfig: {
        type: 'password',
        name: 'newpassword',
        placeholder: 'New password',
        required: false,
        info: 'true',
      },
      value: '',
      validation: {
        required: true,
        minLength: 8,
        maxLength: 99,
        isPassword: true,
      },
      valid: false,
      touched: false,
    },
    confirmpassword: {
      elemType: 'input',
      isdisabled: false,
      elemConfig: {
        type: 'password',
        name: 'confirmpassword',
        placeholder: 'Confirm new password',
        required: false,
      },
      value: '',
      validation: {
        required: true,
        minLength: 8,
        maxLength: 99,
        isPassword: true,
      },
      valid: false,
      touched: false,
    },
  });

  useEffect(() => {
    setTimeout(() => setSpinner(false), 2000);
  }, []);

  const checkSpace = (e) => {
    if (e.keyCode === 32) {
      e.preventDefault();
    }
  };

  const matching = newPwdForm.newpassword.value !== newPwdForm.confirmpassword.value;

  const onSetChangeHandler = async () => {
    setSpinner(true);
    await updatePassword();
    await signOut();

    const deviceTokenData = {
      action: 'update_token',
      data: {
        email,
        device_type: 'web',
        new_device_token: '',
        old_device_token: deviceToken,
      },
    };
    await addOrUpdateDeviceToken(deviceTokenData);
    setSpinner(false);
  };

  const spinnerConfig = {
    showSpinner: spinner,
    left: '35%',
  };

  const responseHandler = (hasAction) => {
    if (hasAction) {
      onSetChangeHandler();
    } else setPasswordModal(!passwordModal);
  };

  const handleMenuItem = (menu) => dispatch(actionCreator.setProfileMenu(menu));

  const modalConfig = {
    open: passwordModal,
    btnClicked: responseHandler,
    btnText: 'Change',
    title: 'Change Password',
    disabled: true,
    type: 'action',
    cancelBtnWidth: '130px',
    doneBtnWidth: '130px',
  };

  const inputChangeHandler = (event, controlName) => {
    const updatedControls = updateObject(newPwdForm, {
      [controlName]: updateObject(newPwdForm[controlName], {
        value: event.target.value,
        valid: checkValidity(event.target.value, newPwdForm[controlName].validation),
        touched: true,
      }),
    });

    let formValid = true;
    // eslint-disable-next-line no-restricted-syntax
    for (const inputIdentifier in updatedControls) {
      if (Object.prototype.hasOwnProperty.call(updatedControls, inputIdentifier)) {
        formValid = updatedControls[inputIdentifier].valid && formValid;
      }
    }
    setNewPwdForm(updatedControls);
    setFormIsValid(formValid);
  };

  const formElemts = Object.keys(newPwdForm).map((keys) => (
    <div className={classes.borderBottom} key={keys}>
      <Grid container direction="row">
        <Grid item sm={3} className={classes.greyLabel}>
          {newPwdForm[keys].elemConfig.placeholder}
        </Grid>
        <Grid item sm={4}>
          <InputElem
            value={newPwdForm[keys].value}
            elemType={newPwdForm[keys].elemType}
            elemConfig={newPwdForm[keys].elemConfig}
            changed={(evt) => inputChangeHandler(evt, keys)}
            isdisabled={newPwdForm[keys].isdisabled}
            keyEvent={(e) => checkSpace(e)}
            classRequired="CustomInput ProfileInput"
          />
        </Grid>
      </Grid>
    </div>
  ));

  return (
    <>
      <LoadingSpinner spinnerConfig={spinnerConfig} />
      {formElemts}
      <p style={{ color: 'Red', visibility: matching ? 'visible' : 'hidden' }}>
        {msgConstant.PASSWORD_MATCH}
      </p>
      <div>
        <ButtonElem
          btnColor="YellowButton"
          value="Change Password"
          btnType="submit"
          style={{ width: 172, margin: '20px 20px 0px 0px' }}
          clicked={() => setPasswordModal(!passwordModal)}
          isbtndisabled={!formIsValid || matching}
        />
        <ButtonElem
          btnColor="GreyButton"
          value="Cancel"
          btnType="submit"
          style={{ width: 172, marginTop: '20px' }}
          clicked={() => handleMenuItem(1)}
        />
      </div>
      {passwordModal && (
        <CustomDialog dialogConfig={modalConfig}>
          <div>{msgConstant.CHANGE_PWD_CHECK}</div>
        </CustomDialog>
      )}
    </>
  );
};

export default withRouter(ChangePassword);
