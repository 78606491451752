import * as iconElem from '../ui/Icons/Icons';

const actionMenus = [
  {
    id: 1,
    menu: 'Account Overview',
    icon: iconElem.userIcon,
    iconStyle: {
      width: 23
    }
  },
  {
    id: 2,
    menu: 'Smart Life Plans',
    icon: iconElem.subscriptionIcon,
    iconStyle: {
      width: 23,
      height: 17
    }
  },
  {
    id: 3,
    menu: 'Edit Profile',
    icon: iconElem.editIcon,
    iconStyle: {
      width: 22,
      height: 20
    }
  },
  {
    id: 4,
    menu: 'Manage Password',
    icon: iconElem.passwordIcon,
    iconStyle: {
      width: 21,
      height: 26
    }
  },
  {
    id: 5,
    menu: 'Privacy Policy',
    icon: iconElem.privacyIcon,
    iconStyle: {
      width: 22,
      height: 25
    }
  },
  {
    id: 6,
    menu: 'Terms of Service',
    icon: iconElem.termsIcon,
    iconStyle: {
      width: 21,
      height: 26
    }
  },
  {
    id: 7,
    menu: 'Log Out',
    icon: iconElem.logOutIcon,
    iconStyle: {
      width: 21,
      height: 26
    }
  }
];

export default actionMenus;
