import React, { useState, useEffect } from 'react';
import { PubSub } from 'aws-amplify';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import OffImg from '../../../../assets/device/Light_OFF.png';
import OnImg from '../../../../assets/device/Light_ON.png';
import * as actionCreator from '../../store/action-creator';
import Notification from '../../../ui/Notification/Notification';
import { sirenActivatedIcon, panicOffIcon } from '../../../ui/Icons/Icons';
import msgConstant from '../../../common/textConstants';
import { objectHasKey } from '../../../common/utils';
import classes from './SRU.module.css';

const SruActions = (props) => {
  const {
    deviceInfo,
    MQTTData,
    FMRadioStatus,
    onSetFMRadioStaus,
    isMQTTUpdated,
    onSetMQTTUpdated,
  } = props;
  const panicClassOn = [classes.customButton, classes.panicButtonOn].join(' ');
  const panicClassOff = [classes.customButton, classes.panicButtonOff].join(' ');
  const [light, setLight] = useState(0);
  const [fm, setFm] = useState(0);
  const [panic, setPanic] = useState(0);
  const [open, setOpen] = useState(false);
  const [isLoaded, setLoaded] = useState(true);
  const [cloudState, setCloudState] = useState(false);

  const renderSRUActions = () => {
    if (objectHasKey(MQTTData, 'reported')) {
      if (objectHasKey(MQTTData.reported, 'cloudConnectedState')) {
        setCloudState(MQTTData.reported.cloudConnectedState);
      }
    }

    if (objectHasKey(MQTTData, 'desired')) {
      if (objectHasKey(MQTTData.desired, 'nightLight')) {
        setLight(MQTTData.desired.nightLight);
      }
      if (objectHasKey(MQTTData.desired, 'fmStatus')) {
        setFm(MQTTData.desired.fmStatus);
      }
      if (objectHasKey(MQTTData.desired, 'panicState')) {
        setPanic(MQTTData.desired.panicState);
      }
    }
  };

  useEffect(() => {
    if (MQTTData && isLoaded) {
      setLoaded(false);
      renderSRUActions();
    }
  }, [MQTTData]);

  useEffect(() => {
    if (isMQTTUpdated) {
      onSetMQTTUpdated();
      setTimeout(() => {
        renderSRUActions();
      });
    }
  }, [isMQTTUpdated]);

  const updateStatus = (newStatus) => {
    if (deviceInfo) {
      PubSub.publish(`$aws/things/${deviceInfo.DeviceId}/shadow/update`, {
        state: { desired: newStatus },
      });
    }
  };

  const onToggle = (type, status) => {
    switch (type) {
      case 'fm': {
        if (cloudState) {
          const fmstate = status ? 0 : 1;
          setFm(fmstate);
          updateStatus({ fmStatus: fmstate });
        } else setOpen(true);
        break;
      }
      case 'light': {
        if (cloudState) {
          const lightStatus = status ? 0 : 1;
          setLight(lightStatus);
          updateStatus({ nightLight: lightStatus });
        } else setOpen(true);
        break;
      }
      case 'panic': {
        if (cloudState) {
          const panicStatus = status === 0 ? 2 : 0;
          setPanic(panicStatus);
          updateStatus({ panicState: panicStatus });
        } else setOpen(true);
        break;
      }
      default:
        break;
    }
  };

  const updateFMRadioStatus = () => onSetFMRadioStaus(2);

  const RenderFMIcon = () => {
    let fmIcon = (
      <img
        role="presentation"
        src={OffImg}
        alt="FM"
        className={classes.image}
        onClick={() => onToggle('fm', fm)}
      />
    );
    if (fm) {
      if (FMRadioStatus === 1) {
        fmIcon = (
          <img
            role="presentation"
            src={OnImg}
            alt="FM"
            className={classes.image}
            onClick={updateFMRadioStatus}
          />
        );
      } else {
        fmIcon = <img src={OnImg} alt="FM" className={classes.fmOFF} />;
      }
    }
    return fmIcon;
  };

  const config = {
    position: 'bottomRight',
    open,
    onClose: () => setOpen(false),
  };

  return (
    <>
      <Grid container direction="column">
        <Grid item container direction="row" className={classes.buttonwrapper}>
          <Grid item className={classes.alignButtons}>
            <RenderFMIcon />
            <p className={classes.actionButtonName}>FM Radio</p>
          </Grid>
          <Grid item className={classes.alignButtons}>
            <img
              role="presentation"
              src={light ? OnImg : OffImg}
              alt="Light"
              className={classes.image}
              onClick={() => onToggle('light', light)}
            />
            <p className={classes.actionButtonName}>Night Light</p>
          </Grid>
        </Grid>
        {/* #84554 Panic button design in UI is different from design */}
        <Grid item style={{ paddingTop: '4em' }} className="sruButton">
          <Button
            type="submit"
            variant={panic ? 'contained' : 'outlined'}
            className={panic ? panicClassOn : panicClassOff}
            onClick={() => onToggle('panic', panic)}
          >
            <img
              src={panic ? sirenActivatedIcon : panicOffIcon}
              alt="Alarm"
              style={{
                width: panic ? '22px' : '27px',
                paddingRight: panic ? '14px' : '7.2px',
              }}
            />
            <span className={classes.panicButton}>{panic ? 'SIREN ACTIVATED' : 'Siren'}</span>
            {panic ? (
              <img src={sirenActivatedIcon} alt="" style={{ paddingLeft: '14px', width: '22px' }} />
            ) : (
              ''
            )}
          </Button>
          <div
            className={classes.pushToSilence}
            style={{ visibility: panic ? 'visible' : 'hidden' }}
          >
            Push to Silence
          </div>
        </Grid>
      </Grid>
      {open && <Notification config={config}>{msgConstant.DEVICE_DISCONNECTED}</Notification>}
    </>
  );
};

const mapStateToProps = (state) => ({
  MQTTData: state.deviceReducer.MQTTInfo,
  deviceInfo: state.deviceReducer.deviceInfo,
  FMRadioStatus: state.deviceReducer.FMRadioStatus,
  isMQTTUpdated: state.deviceReducer.MQTTUpdated,
});

const mapDispatchToProps = (dispatch) => ({
  onSetFMRadioStaus: (status) => dispatch(actionCreator.setFMRadioStatus(status)),
  onSetMQTTUpdated: () => dispatch(actionCreator.setMQTTUpdated(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SruActions);
