import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classes from './ManageHomes.module.css';
import * as actionCreator from './store/action-creator';
import { EDIT_HOME } from '../../constants/routes';
import CustomDialog from '../ui/CustomDialog/CustomDialog';
import ErrorCodes from '../common/ErrorCodes';
import msgConstant from '../common/textConstants';
import LoadingSpinner from '../ui/LoadingSpinner/LoadingSpinner';
import { AuthContext } from '../../Providers/AuthProvider';

const Homecard = (props) => {
  const { user } = React.useContext(AuthContext);
  const emailId = user.userEmail;
  const { name, data } = props;
  const [memCount, setMemCount] = useState('');
  const [address, setAddress] = useState('');
  const [commonError, setCommonError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    setMemCount(Object.keys(props.data.members).length);
    const add = props.data.home_address;
    const changedAddress = add.split('&?');
    setAddress(changedAddress);
  }, []);

  const commonErrorBtnHandler = () => setCommonError(!commonError);

  const commonErrorDialogConfig = {
    open: commonError,
    textAlign: 'left',
    btnClicked: commonErrorBtnHandler,
  };

  const onEditHandler = () => {
    setSpinner(true);
    const result = {
      deviceData: null,
    };
    props
      .onGetHomeData(emailId, data.home_id)
      .then((resp) => {
        setSpinner(false);
        if (resp.data.ErrorCode === 'err_0') {
          props.onSelectedHome(props.data);
          result.deviceData = resp.data.ResponseData.DeviceData;
          if (result.deviceData.DeviceList.length) {
            result.noDeviceList = false;
          }
          props.setSelectedHomeData(result);
          props.history.push({
            pathname: EDIT_HOME,
            state: { prevPath: props.location.pathname },
          });
        } else {
          setCommonError(!commonError);
          setErrorMessage(ErrorCodes[resp.data.ErrorCode]);
        }
      })
      .catch(() => {
        setSpinner(false);
        props.setSelectedHomeData(result);
        setCommonError(!commonError);
        setErrorMessage(`${msgConstant.COMMON_ERROR}`);
      });
  };

  return (
    <>
      <LoadingSpinner spinnerConfig={{ showSpinner: spinner }} />
      <div className={classes.homeCard}>
        <div className={classes.homeCardName}>{name}</div>
        <div>
          {address[0]}
          {address[1] && <span className={classes.addressLineTwo}>{address[1]}</span>}
        </div>
        <div>
          {/* BUG-[#84392] Comma is showing in manage home page
        if user has created the home without address */}
          {address[2]}
          {address[3] && (
            <>
              <span>,</span>
              <span className={classes.addressLineTwo}>{address[3]}</span>
            </>
          )}
        </div>
        <div className={['GreyColor', 'Font12'].join(' ')} style={{ padding: '10px 0' }}>
          <span>{`${memCount} users`}</span>
          <span className={classes.addressLineTwo}>{`${data.device_count} devices`}</span>
        </div>
        <div className={classes.editHome}>
          <span
            role="presentation"
            onClick={onEditHandler}
            className="CursorPointer"
            style={{ fontFamily: 'bookItalicFont' }}
          >
            Edit
          </span>
        </div>
        {commonError && (
          <CustomDialog dialogConfig={commonErrorDialogConfig}>
            <div>{errorMessage}</div>
          </CustomDialog>
        )}
      </div>
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onSelectedHome: (data) => dispatch(actionCreator.selectedHome(data)),
  setSelectedHomeData: (data) => dispatch(actionCreator.selectedHomeData(data)),
  onGetHomeData: (emailId, homeId) => actionCreator.getHomeDevices(emailId, homeId),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Homecard));
