import React, { Component } from 'react';
import {
  CardComponent,
  CardNumber,
  CardExpiry,
  CardCVV,
} from '@chargebee/chargebee-js-react-wrapper';
import { Dialog, Tabs, Tab } from '@material-ui/core';
import { connect } from 'react-redux';
import * as actionCreator from '../store/action-creator';
import { closeWhiteIcon } from '../../ui/Icons/Icons';
import ErrorCodes from '../../common/ErrorCodes';
import CardDetails from './CardDetails';
import LoadingSpinner from '../../ui/LoadingSpinner/LoadingSpinner';
import './PaymentWidget.css';
import cardClass from './Payment.module.css';
import states from './states.json';

class WidgetWrapper extends Component {
  constructor(props) {
    super(props);
    // Create ref to assign card-component
    window.Chargebee.init({
      site: process.env.REACT_APP_CHARGEBEE_SITE,
      publishableKey: process.env.REACT_APP_CHARGEBEE_PUBLISHABLEKEY,
    });
    this.cardRef = React.createRef();
    const chargebeeInstance = window.Chargebee.getInstance();
    chargebeeInstance.load3DSHandler().then((threeDSHandler) => {
      console.log('inside 3DS handler', threeDSHandler);
    });
    this.state = {
      // token: '',
      error: '',
      loading: false,
      additionalData: {
        firstName: '',
        lastName: '',
        billingAddr1: '',
        billingCity: '',
        billingStateCode: '',
        billingState: '',
        billingZip: '',
        billingCountry: 'US',
      },
      tabValue: 0,
      isLoader: true,
      options: {
        // Custom classes - applied on container elements based on field's state
        classes: {
          focus: 'focus',
          invalid: 'invalid',
          empty: 'empty',
          complete: 'complete',
        },

        style: {
          // Styles for default field state
          base: {
            color: '#333',
            fontWeight: '500',
            fontFamily: 'Roboto, Segoe UI, Helvetica Neue, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',

            ':focus': {
              color: '#424770',
            },

            '::placeholder': {
              color: 'transparent',
            },

            ':focus::placeholder': {
              color: '#7b808c',
            },
          },

          // Styles for invalid field state
          invalid: {
            color: '#e41029',

            ':focus': {
              color: '#e44d5f',
            },
            '::placeholder': {
              color: '#FFCCA5',
            },
          },
        },

        // locale
        locale: 'en',

        // Custom placeholders
        placeholder: {
          number: '4111 1111 1111 1111',
          expiry: 'MM / YY',
          cvv: 'CVV',
        },

        // Custom fonts
        fonts: ['https://fonts.googleapis.com/css?family=Roboto:300,500,600'],
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.tokenize = this.tokenize.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isLoader: false });
    }, 3000);
  }

  handleSubmitToken = (data) => {
    const { customerId, submitCbToken, handleCommonError } = this.props;
    const promise = new Promise((resolve, reject) => {
      submitCbToken({
        customer_id: customerId,
        token_id: data.vaultToken,
      })
        .then((resp) => {
          if (resp.ErrorCode !== 'err_0') {
            handleCommonError(true, ErrorCodes(resp.ErrorCode));
          }
          resolve(true);
        })
        .catch((err) => {
          const addressValidationError =
            err?.response?.data?.body?.includes('Address Verification');

          if (addressValidationError) {
            handleCommonError(true, 'Address Verification failed for payment method');
          } else if (err) {
            handleCommonError(true, 'Something went wrong!');
          }
          reject(err);
        });
    });
    return promise;
  };

  handleModalClose = () => {
    const { onClose } = this.props;
    this.setState({ firstName: '' });
    this.setState({ error: '' });
    onClose();
  };

  handleOk = () => {
    const {
      manageCard,
      updateSubscription,
      user,
      customerId,
      metadata,
      planChanged,
      onSubmitSuccess,
    } = this.props;

    if (manageCard === true) {
      onSubmitSuccess();
    } else {
      onSubmitSuccess();
      updateSubscription({
        email: user.userEmail,
        customerId,
        metadata,
        planChanged,
      });
    }
  };

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  // eslint-disable-next-line react/sort-comp
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ additionalData: { ...this.state.additionalData, [name]: value } });
  }

  additionalDataValidator = (additionalData) => {
    const additionalDataKey = {
      firstName: 'First Name',
      lastName: 'Last Name',
      billingAddr1: 'Address',
      billingCity: 'City',
      billingStateCode: 'State',
      billingZip: 'Zip',
      billingCountry: 'US',
    };
    const result = { valid: true, invalidField: '' };
    Object.keys(additionalData).forEach((key) => {
      if (additionalData[key] === '') {
        result.valid = false;
        result.invalidField = additionalDataKey[key];
        return result;
      } else if (key === 'billingZip') {
        const value = additionalData[key];
        if (value.length != 5 || !Number(value)) {
          result.valid = false;
          result.invalidField = additionalDataKey[key];
          return result;
        }
      }
    });
    return result;
  };

  calculateBillingState = (billingStateCode) => {
    return states.filter(({ code }) => code === billingStateCode)[0].name;
  };

  tokenize = () => {
    const {
      customerId,
      user,
      metadata,
      planChanged,
      onSubmitSuccess,
      manageCard,
      updateSubscription,
    } = this.props;
    const { additionalData } = this.state;
    additionalData.billingState = this.calculateBillingState(additionalData.billingStateCode);
    const result = this.additionalDataValidator(additionalData);
    if (!result.valid) {
      alert(`${result.invalidField} can not be blank.`);
      return;
    }
    this.setState({ loading: true });

    if (manageCard === true) {
      this.cardRef.current
        .tokenize(additionalData)
        .then((data) => {
          this.handleSubmitToken(data);
          onSubmitSuccess();
        })
        .catch((e) => {
          console.log('error:', e);
          this.setState({ error: 'We are having trouble adding your card.' });
        });
    } else if (manageCard === false) {
      this.cardRef.current
        .tokenize(additionalData)
        .then((data) => {
          this.handleSubmitToken(data);
          updateSubscription({
            email: user.userEmail,
            customerId,
            metadata,
            planChanged,
          });
          onSubmitSuccess();
        })
        .catch((e) => {
          console.log('error:', e);
          this.setState({ error: 'We are having trouble adding your card.' });
        });
    }
  };

  onFocus = () => {
    const { error } = this.state;
    if (error.length > 1) {
      this.setState({ error: '' });
    }
  };

  render() {
    const { options, loading, additionalData, error, tabValue, isLoader } = this.state;
    const { firstName, lastName, billingAddr1, billingCity, billingStateCode, billingZip } =
      additionalData;
    const { style, classes, locale, placeholder, fonts } = options;
    const { openVal, cardDetails, cardError, cardErrorMsg } = this.props;
    const spinnerConfig = {
      showSpinner: isLoader,
      top: '45%',
      left: '45%',
    };
    return (
      <Dialog aria-labelledby="customized-dialog-title" open={openVal}>
        <div style={{ clear: 'both' }}>
          <img
            src={closeWhiteIcon}
            alt="X"
            className={cardClass.closeIcon}
            onClick={this.handleModalClose}
            role="presentation"
          />
        </div>
        <div className="ex1 container">
          {isLoader && <LoadingSpinner spinnerConfig={spinnerConfig} />}
          <div className="ex1-wrap">
            <Tabs
              value={tabValue}
              onChange={this.handleTabChange}
              aria-label="simple tabs example"
              TabIndicatorProps={{ style: { background: '#FFD200' } }}
            >
              <Tab label="View Card" />
              <Tab label="Add Card" />
            </Tabs>
            {tabValue === 0 && (
              <>
                {cardError && cardErrorMsg ? (
                  <div className={cardClass.cardError}>{cardErrorMsg}</div>
                ) : cardDetails ? (
                  <CardDetails cardDetails={cardDetails} />
                ) : (
                  ''
                )}
                <button
                  type="submit"
                  disabled={error}
                  className={loading ? 'submit ex1-button' : 'ex1-button'}
                  onClick={this.handleOk}
                >
                  Ok
                </button>
              </>
            )}
            <>
              {tabValue === 1 && (
                <>
                  <div className="ex1-fieldset">
                    <div className="ex1-field">
                      <input
                        className={firstName ? 'ex1-input val' : 'ex1-input'}
                        type="text"
                        placeholder="First Name"
                        value={firstName}
                        name="firstName"
                        onChange={this.handleChange}
                      />
                      <label className="ex1-label">First Name</label>
                      <i className="ex1-bar" />
                    </div>
                    <div className="ex1-field">
                      <input
                        className={lastName ? 'ex1-input val' : 'ex1-input'}
                        type="text"
                        placeholder="Last Name"
                        value={lastName}
                        name="lastName"
                        onChange={this.handleChange}
                      />
                      <label className="ex1-label">Last Name</label>
                      <i className="ex1-bar" />
                    </div>
                    <CardComponent
                      ref={this.cardRef}
                      className="fieldset field"
                      styles={style}
                      classes={classes}
                      locale={locale}
                      placeholder={placeholder}
                      fonts={fonts}
                    >
                      <div className="ex1-field">
                        {/* Card number component */}
                        <CardNumber className="ex1-input" onFocus={this.onFocus} />
                        <label className="ex1-label">Card Number</label>
                        <i className="ex1-bar" />
                      </div>

                      <div className="ex1-fields">
                        <div className="ex1-field">
                          {/* Card expiry component */}
                          <CardExpiry className="ex1-input" onFocus={this.onFocus} />
                          <label className="ex1-label">Expiry</label>
                          <i className="ex1-bar" />
                        </div>
                        <div className="ex1-field">
                          {/* Card cvv component */}
                          <CardCVV className="ex1-input" onFocus={this.onFocus} />
                          <label className="ex1-label">CVC</label>
                          <i className="ex1-bar" />
                        </div>
                      </div>
                    </CardComponent>
                    <div className="ex1-field">
                      <input
                        className={billingAddr1 ? 'ex1-input val' : 'ex1-input'}
                        type="text"
                        placeholder="Address"
                        value={billingAddr1}
                        name="billingAddr1"
                        onChange={this.handleChange}
                      />
                      <label className="ex1-label">Address</label>
                      <i className="ex1-bar" />
                    </div>
                    <div className="ex1-field">
                      <input
                        className={billingCity ? 'ex1-input val' : 'ex1-input'}
                        type="text"
                        placeholder="City"
                        value={billingCity}
                        name="billingCity"
                        onChange={this.handleChange}
                      />
                      <label className="ex1-label">City</label>
                      <i className="ex1-bar" />
                    </div>
                    <div className="ex1-field">
                      <input
                        className={billingZip ? 'ex1-input val' : 'ex1-input'}
                        type="text"
                        placeholder="Zip Code"
                        value={billingZip}
                        name="billingZip"
                        onChange={this.handleChange}
                      />
                      <label className="ex1-label">Zip Code</label>
                      <i className="ex1-bar" />
                    </div>
                    <div className="ex1-select">
                      <select
                        className={'select'}
                        required
                        value={billingStateCode}
                        name="billingStateCode"
                        onChange={this.handleChange}
                      >
                        <option value="" disabled selected>
                          Select State
                        </option>
                        {states.map((state) => (
                          <option key={state.code} value={state.code}>
                            {state.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <button
                    type="submit"
                    disabled={error}
                    className={loading ? 'submit ex1-button' : 'ex1-button'}
                    onClick={(e) => this.tokenize(e)}
                  >
                    Submit
                  </button>
                  {error && (
                    <div className="error" role="alert" style={{ width: '90%' }}>
                      {error}
                    </div>
                  )}
                  {/* {token && <div className="token">{token}</div>} */}
                </>
              )}
            </>
          </div>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  customerId: state.subscriptionReducer.userSubscription.CustomerID,
  userSubscription: state.subscriptionReducer.userSubscription,
  cardDetails: state.subscriptionReducer.cardDetails,
});

const mapDispatchToProps = (dispatch) => ({
  updateSubscription: (data) => dispatch(actionCreator.updateSubscription(data)),
  submitCbToken: (data) => dispatch(actionCreator.submitCbToken(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WidgetWrapper);
