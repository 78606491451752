import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

const ScrollArea = props => {
  const {
    children, scrollClass, scrollMaxHeight, autoHide, scrollEnd
  } = props;
  const scrollClassName = scrollClass || 'ContainerScroll';
  let autoHeight = null;
  let autoHeightMax = null;
  const scrollAutoHide = (autoHide !== undefined) ? autoHide : true;

  if (scrollMaxHeight) {
    autoHeight = true;
    autoHeightMax = scrollMaxHeight;
  }

  const handleUpdate = values => {
    if (values.top >= 1 && scrollEnd) {
      scrollEnd();
    }
  };

  return (
    <Scrollbars
      autoHide={scrollAutoHide}
      className={scrollClassName}
      autoHeight={autoHeight}
      autoHeightMax={autoHeightMax}
      onUpdate={handleUpdate}
    >
      {children}
    </Scrollbars>
  );
};

export default ScrollArea;
