import React, { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';

const emptyThumbnail = {
  background: '#eee',
  minWidth: 202,
  minHeight: 127,
  marginBottom: '0.5em',
};

const ActThumbnail = (props) => {
  const [imagePath, setImagePath] = useState(null);

  useEffect(() => {
    const S3path = props.thumbnailLInk.replace('public/', '');
    Storage.get(S3path, { bucket: process.env.REACT_APP_STORAGE_DEVICE_BUCKET }).then((result) => {
      setImagePath(result);
    });
  }, []);

  let thumbnailImage = null;
  if (imagePath) {
    /* when there is no thumbnail for activity in home page,
    positioning of icon, icontype and timestamp is not aligned - Fixed */
    if (props.imgClass) {
      thumbnailImage = (
        <img src={imagePath} alt="" className={props.imgClass} style={{ background: '#eee' }} />
      );
    } else {
      thumbnailImage = <img src={imagePath} alt="" style={emptyThumbnail} />;
    }
  }
  return thumbnailImage;
};

export default ActThumbnail;
