export const getCity = addressArray => {
  let city = '';
  for (let i = 0; i < addressArray.length; i += 1) {
    if (addressArray[i].types[0] && addressArray[i].types[0] === 'locality') {
      city = addressArray[i].long_name;
      return city;
    }
  }
};

export const getPostalCode = addressArray => {
  let postalCode = '';
  for (let i = 0; i < addressArray.length; i += 1) {
    if (addressArray[i].types[0] && addressArray[i].types[0] === 'postal_code') {
      postalCode = addressArray[i].long_name;
      return postalCode;
    }
  }
};

export const getState = addressArray => {
  let state = '';
  for (let i = 0; i < addressArray.length; i += 1) {
    for (let j = 0; i < addressArray.length; j += 1) {
      if (addressArray[j].types[0] && addressArray[j].types[0] === 'administrative_area_level_1') {
        state = addressArray[j].long_name;
        return state;
      }
    }
  }
};
