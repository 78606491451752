import React, { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import SRUImg from '../../../assets/device/sru_device.png';
import hTImg from '../../../assets/device/home_travel_camera.png';
import overDoorImg from '../../../assets/device/over_door_camera.png';
import classes from '../DeviceList.module.css';

const DevThumbnail = (props) => {
  const { thumbnailLInk, type, altInfo } = props;
  const [imagePath, setImagePath] = useState(null);
  const [noImage, setNoImage] = useState(false);

  useEffect(() => {
    setNoImage(false);
    if (thumbnailLInk) {
      const S3path = thumbnailLInk.replace('public/', '');
      Storage.get(S3path, { bucket: process.env.REACT_APP_STORAGE_DEVICE_BUCKET }).then((result) =>
        setImagePath(result)
      );
    }
  }, []);

  const imageCheck = (e) => {
    setNoImage(true);
    e.target.src = type === 'OverDoorCamera' ? overDoorImg : hTImg;
  };

  let thumbImage = null;
  if (imagePath) {
    if (type === 'SmartReadinessUnit') {
      thumbImage = (
        <div className={classes.imageContainer}>
          <img src={SRUImg} alt={altInfo} className={classes.sruDeviceImg} />
        </div>
      );
    } else if (noImage) {
      thumbImage = (
        <div className={classes.imageContainer}>
          <img
            src={imagePath}
            onError={(e) => imageCheck(e)}
            alt={altInfo}
            className={classes.overDoorAndTravelCamera}
          />
        </div>
      );
    } else {
      thumbImage = (
        <div className={classes.s3ImgContainer}>
          <img
            src={imagePath}
            onError={(e) => imageCheck(e)}
            alt={altInfo}
            className={classes.s3Image}
          />
        </div>
      );
    }
  }
  return thumbImage;
};

export default DevThumbnail;
