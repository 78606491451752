// REQUIREMENT : 4.1.13 Set Activity Zones (Over Door Camera and Home / Travel Camera Only)

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Storage } from 'aws-amplify';
import { objectHasKey } from '../common/utils';
import Forward from '../../assets/icons/Forward.png';
import hTImg from '../../assets/device/home_travel_camera.png';
import overDoorImg from '../../assets/device/over_door_camera.png';
import classes from './Cropper.module.css';

class ActivityZoneDisplay extends Component {
  state = {
    imagePath: null,
    deviceImage: null,
    height: null,
    width: null
  }

  componentDidMount() {
    let cropInformation = {
      x: 0,
      y: 0,
      width: 0,
      height: 0
    };
    const { thumbnailLInk, deviceType, MQTTData } = this.props;
    const S3path = thumbnailLInk.replace('public/', '');
    Storage.list(S3path, { bucket: process.env.REACT_APP_STORAGE_DEVICE_BUCKET })
      .then(result => {
        if (result.length > 0) {
          Storage.get(S3path, { bucket: process.env.REACT_APP_STORAGE_DEVICE_BUCKET })
            .then(res => {
              this.setState({ imagePath: res });
            });
        }
      });

    const height = this.divElement.clientHeight;
    this.setState({ height });
    const width = this.divElement.clientWidth;
    this.setState({ width });

    if (objectHasKey(MQTTData, 'desired')) {
      if (objectHasKey(MQTTData.desired, 'activityZone')) {
        const MQTTActZone = MQTTData.desired.activityZone;
        if (MQTTActZone.length) {
          cropInformation = {
            x: MQTTActZone[0][0],
            y: MQTTActZone[1][0],
            width: (MQTTActZone[0][1] - MQTTActZone[0][0]),
            height: (MQTTActZone[1][1] - MQTTActZone[1][0])
          };
        }
      }
    }

    const { canvas, image } = this.refs;
    const canvas2 = canvas;
    const ctx = canvas2.getContext('2d');
    const img = image;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (deviceType === 'HomeAndTravelCamera') {
      this.setState({ deviceImage: hTImg });
    } else if (deviceType === 'OverDoorCamera') {
      this.setState({ deviceImage: overDoorImg });
    }
    img.onload = () => {
      this.drawCanvasImage(img, ctx, cropInformation);
    };
  }

  componentDidUpdate() {
    const { cropObj, saveActivityZone } = this.props;
    if (saveActivityZone === true) {
      const { canvas, image } = this.refs;
      const canvas2 = canvas;
      const ctx = canvas2.getContext('2d');
      const img = image;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      this.drawCanvasImage(img, ctx, cropObj);
    }
  }

  drawCanvasImage = (img, ctx, cropData) => {
    const { width, height } = this.state;
    const calculatedData = {
      x1: parseInt((width / 1920) * cropData.x),
      y1: parseInt((height / 1080) * cropData.y),
      x2: parseInt((width / 1920) * cropData.width),
      y2: parseInt((height / 1080) * cropData.height)
    };
    ctx.drawImage(img, 0, 0, 288, 160);
    ctx.lineWidth = '6';
    ctx.strokeStyle = '#00FFFF'; // 00CED1,48D1CC
    ctx.beginPath();
    ctx.globalAlpha = 0.2; // To set opacity
    ctx.rect(calculatedData.x1, calculatedData.y1, calculatedData.x2, calculatedData.y2);
    ctx.stroke();
    ctx.fillStyle = '#00FFFF';
    ctx.fill();
  }

  render() {
    const { imagePath, deviceImage } = this.state;
    return (
      <div
        className={classes.imgContainer}
        ref={divElement => { this.divElement = divElement; }}
      >
        <canvas ref="canvas" className={classes.canvasWrap} />
        <img
          ref="image"
          src={(imagePath !== null) ? imagePath : deviceImage}
          alt="Activity zone"
          className={classes.imageWrap}
        />
        <div className={classes.after}>
          Set activity zone
          <img src={Forward} alt="Forward" width="7" className={classes.forwardIcon} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    MQTTData: state.deviceReducer.MQTTInfo,
    cropObj: state.cropBoxReducer.cropObj,
    deviceType: state.deviceReducer.deviceInfo.DeviceType,
    saveActivityZone: state.deviceReducer.isSaveClicked
  }
);

export default connect(mapStateToProps, null)(ActivityZoneDisplay);
