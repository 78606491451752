import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import * as ROUTES from '../../constants/routes';
import {
  selectedEvent,
  setIsAllActivity,
  getDevicePlanInfo,
} from '../Activity/store/action-creator';
import DropdownMenu from './DropdownMenu/DropdownMenu';
import msgConstant from '../common/textConstants';
import CustomDialog from '../ui/CustomDialog/CustomDialog';
import headerLogo from '../../assets/images/HeaderLogo.png';
import classes from './AppHeader.module.css';
import { AuthContext } from '../../Providers/AuthProvider';

const AppHeader = (props) => {
  const {
    hasEventList,
    onSelectedEvent,
    deviceList,
    onSetIsAllActivity,
    onGetDevicePlanInfo,
  } = props;
  const [devices, setDevices] = useState(null);
  const [noEventModal, setNoEventModal] = useState(false);
  const { user } = React.useContext(AuthContext);
  const emailId = user.userEmail;

  useEffect(() => {
    setDevices(deviceList);
  }, [deviceList]);

  const handleActivityList = () => {
    if (devices && !devices.noDeviceList) {
      if (hasEventList && !hasEventList.noEventList) {
        const deviceIdList = [];
        onSetIsAllActivity(true);
        onSelectedEvent(hasEventList.eventData[0]);
        props.history.push(ROUTES.ACTIVITY_DETAILS);
        devices.deviceData.DeviceList.forEach((each) => {
          deviceIdList.push(each.DeviceId);
        });
        onGetDevicePlanInfo({ email: emailId, device_ids: deviceIdList });
      } else {
        setNoEventModal(!noEventModal);
      }
    } else {
      setNoEventModal(!noEventModal);
    }
  };

  const noEventBtnHandler = () => setNoEventModal(!noEventModal);

  const noEventModalConfig = {
    open: noEventModal,
    textAlign: 'left',
    btnClicked: noEventBtnHandler,
  };

  let activityLink = null;

  activityLink = (
    <Grid item>
      <span className="CursorPointer" onClick={handleActivityList} role="presentation">
        ACTIVITY
      </span>
    </Grid>
  );

  const homeHandler = () => {
    if (devices.homeList) {
      if (devices.homeList.length && devices.deviceData !== null) {
        props.history.push(ROUTES.HOME);
      }
    }
  };

  return (
    <div className={classes.AppHeader} style={{ width: '100%', maxWidth: 1200 }}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item style={{ postion: 'relative' }}>
          <img
            src={headerLogo}
            alt="BGZ Logo"
            className="CursorPointer"
            onClick={homeHandler}
            role="presentation"
            style={{ width: 75 }}
          />
        </Grid>
        <Grid>
          <Grid container justify="space-between" alignItems="center" style={{ padding: 6 }}>
            {activityLink}

            <Grid item className={classes.MarginLeft20}>
              <span className="CursorPointer">
                <a
                  href="https://www.bodyguardz.com/customer_care.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', color: 'white' }}
                >
                  SUPPORT
                </a>
              </span>
            </Grid>
            <Grid item className={classes.MarginLeft20}>
              <DropdownMenu {...props} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {noEventModal && (
        <CustomDialog dialogConfig={noEventModalConfig}>
          <div>{msgConstant.NO_EVENTS}</div>
        </CustomDialog>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  hasEventList: state.activityReducer.eventsList,
  deviceList: state.deviceReducer.deviceResp,
});

const mapDispatchToProps = (dispatch) => ({
  onSelectedEvent: (currentEvent) => dispatch(selectedEvent(currentEvent)),
  onSetIsAllActivity: (data) => dispatch(setIsAllActivity(data)),
  onGetDevicePlanInfo: (data) => dispatch(getDevicePlanInfo(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppHeader));
