import firebase from 'firebase/app';
import 'firebase/messaging';

const initializedFirebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyAWFkiB6exD16-8BhKhLT9mmDq8tAd6pqg',
  authDomain: 'bgz-connected-home.firebaseapp.com',
  databaseURL: 'https://bgz-connected-home.firebaseio.com',
  projectId: 'bgz-connected-home',
  storageBucket: '',
  messagingSenderId: '512607077903',
  appId: '1:512607077903:web:dac3d10da4c70804d3857a'
});

const messaging = firebase.messaging.isSupported() ? initializedFirebaseApp.messaging() : null;

if (messaging) {
  messaging.usePublicVapidKey(
    'BPzoxWHFUlJLt0js8mWag8tTt9peyy4adWOKjhVMsd5KkXyFnPwPmMqu1CmANr7ijjqhMRK9FIOnHVCRzOR6MBc'
  );
}

export default messaging;
