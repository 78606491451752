import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import ButtonElem from '../../../../ui/Button/Button';
import CustomDialog from '../../../../ui/CustomDialog/CustomDialog';
import Notification from '../../../../ui/Notification/Notification';
import * as actionCreator from '../../../store/action-creator';
import msgConstant from '../../../../common/textConstants';
import ErrorCodes from '../../../../common/ErrorCodes';
import { objectHasKey } from '../../../../common/utils';
import { forwardBlueIcon } from '../../../../ui/Icons/Icons';
import classes from '../DeviceSettings.module.css';
import { AuthContext } from '../../../../../Providers/AuthProvider';

const DeviceTechInfo = (props) => {
  const { user } = React.useContext(AuthContext);
  const email = user.userEmail;
  const { MQTTData, deviceInfo, fwUpdateHandler } = props;

  const [network, setNetwork] = useState(' ');
  const [FwVersion, setFwVersion] = useState(' ');
  const [wifi, setWifi] = useState('100%');
  const [update, setUpdate] = useState('0');
  const [updateModal, setUpdateModal] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [open, setOpen] = useState(false);
  // const [oldMqttData, setOldMqttData] = useState(null);
  const [isLoaded, setLoaded] = useState(true);
  const [commonError, setCommonError] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState(null);

  useEffect(() => {
    if (MQTTData && isLoaded) {
      setLoaded(false);
      if (objectHasKey(MQTTData, 'reported')) {
        if (objectHasKey(MQTTData.reported, 'ssid')) {
          setNetwork(MQTTData.reported.ssid);
        }
        if (objectHasKey(MQTTData.reported, 'fwUpdate')) {
          setUpdate(MQTTData.reported.fwUpdate);
        }
        if (objectHasKey(MQTTData.reported, 'fwVer')) {
          setFwVersion(MQTTData.reported.fwVer);
        }
        if (objectHasKey(MQTTData.reported, 'wifi-signal')) {
          const wifiSig = MQTTData.reported.wifiSignal;
          if (wifiSig <= 0 && wifiSig >= -50) {
            setWifi('100%');
          } else if (wifiSig <= -50 && wifiSig >= -70) {
            setWifi('75%');
          } else if (wifiSig <= -70 && wifiSig >= -80) {
            setWifi('50%');
          } else if (wifiSig <= -80 && wifiSig >= -100) {
            setWifi('25%');
          } else setWifi('0%');
        }
      }
    }
  }, [MQTTData]);

  const fwUpdateSuccessResp = (resp) => {
    if (resp.data.ResponseCode === 200) {
      if (resp.data.ErrorCode !== 'err_0') {
        if (resp.data.ErrorCode === 'err_602') {
          setOpen(true);
          setToastMessage(ErrorCodes[resp.data.ErrorCode]);
        } else {
          setCommonError(!commonError);
          setCommonErrorMsg(ErrorCodes[resp.data.ErrorCode]);
        }
      }
    } else {
      setCommonError(!commonError);
      setCommonErrorMsg(msgConstant.COMMON_ERROR);
    }
  };

  const fwUpdateErrorResp = () => {
    setCommonError(!commonError);
    setCommonErrorMsg(msgConstant.COMMON_ERROR);
  };

  const updateHandler = () => {
    const fwData = {
      action: 'install',
      data: {
        firmware_version: MQTTData.reported.fwUpdate,
        deviceId: deviceInfo.DeviceId,
        email,
        deviceType: deviceInfo.DeviceType,
      },
    };
    fwUpdateHandler(fwData).then(fwUpdateSuccessResp, fwUpdateErrorResp);
  };

  const btnHandler = (hasAction) => {
    if (hasAction) {
      setUpdateModal(!updateModal);
      updateHandler();
    } else {
      setUpdateModal(!updateModal);
    }
  };

  const onUpdateClickhandler = () => setUpdateModal(!updateModal);

  const removeModalConfig = {
    open: updateModal,
    btnClicked: btnHandler,
    btnText: 'Update',
    title: 'Update Firmware version',
    disabled: true,
    type: 'action',
  };

  let deviceModel = null;

  if (deviceInfo) {
    if (deviceInfo.DeviceType === 'SmartReadinessUnit') {
      deviceModel = 'Emergency Preparedness Unit';
    } else if (deviceInfo.DeviceType === 'OverDoorCamera') {
      deviceModel = 'Portable Over-Door Camera';
    } else deviceModel = 'Home and Travel Camera';
  }

  const onDoneHandler = () => props.TechFlag();
  const handleClose = () => setOpen(false);

  const config = {
    position: 'bottomRight',
    open,
    onClose: handleClose,
  };

  const commonErrorBtnHandler = () => setCommonError(!commonError);

  const commonErrorDialogConfig = {
    open: commonError,
    textAlign: 'left',
    btnClicked: commonErrorBtnHandler,
  };

  const renderDialog = (customConfig, msg) => (
    <CustomDialog dialogConfig={customConfig}>
      <div>{msg}</div>
    </CustomDialog>
  );

  return (
    <>
      <div className={classes.header}>Technical information</div>
      <div className={classes.horLine} />
      <div className={classes.setting}>
        <Grid className={classes.techInfo}>
          <div className={classes.deviceSubHeader}>Device Model</div>
          <p className={classes.subDetail}>{deviceModel}</p>
        </Grid>
      </div>
      <div className={classes.setting}>
        <Grid className={classes.techInfo}>
          <div className={classes.deviceSubHeader}>Network</div>
          <p className={classes.subDetail}>{network || '-'}</p>
        </Grid>
      </div>
      <div className={classes.setting}>
        <Grid className={classes.techInfo}>
          <div className={classes.deviceSubHeader}>Wifi-signal strength</div>
          <p className={classes.subDetail}>{wifi || '-'}</p>
        </Grid>
      </div>
      <div className={classes.setting}>
        <Grid className={classes.techInfo}>
          <div className={classes.deviceSubHeader}>Firmware Version</div>
          <Grid container>
            <Grid item xs={6}>
              <p className={classes.subDetail}>{FwVersion || '-'}</p>
            </Grid>
            {update !== '0' && (
              <Grid item xs={6}>
                <p className={classes.fwUpdate} onClick={onUpdateClickhandler} role="presentation">
                  Update
                  <img src={forwardBlueIcon} alt="Forward" className={classes.forwardIcon} />
                </p>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
      <div className={classes.techInfoButton}>
        <ButtonElem
          btnColor="YellowButton"
          value="Done"
          btnType="submit"
          style={{ width: 140 }}
          clicked={onDoneHandler}
        />
      </div>
      {updateModal &&
        renderDialog(removeModalConfig, `${msgConstant.FIRMWARE_UPDATE_CHECK} ${update}?`)}
      {commonError && renderDialog(commonErrorDialogConfig, commonErrorMsg)}
      {open && <Notification config={config}>{toastMessage}</Notification>}
    </>
  );
};

const mapStateToProps = (state) => ({
  deviceInfo: state.deviceReducer.deviceInfo,
  MQTTData: state.deviceReducer.MQTTInfo,
});

const mapDispatchToProps = () => ({
  fwUpdateHandler: (data) => actionCreator.fwUpdateHandler(data),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceTechInfo);
