// REQUIREMENT : 4.1.14 Configure SD Recording (Home / Travel Camera Only)

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { PubSub } from 'aws-amplify';
import Grid from '@material-ui/core/Grid';
import TypographyElem from '../ui/Typography/Typography';
import * as deviceActionCreator from '../DeviceList/store/action-creator';
import * as actionCreator from './store/action-creator';
import CropperEle from './CropperEle';
import SubHeader from '../ui/SubHeader';
import ActivityZoneActions from './ActivityZoneActions';
import LoadingSpinner from '../ui/LoadingSpinner/LoadingSpinner';
import { objectHasKey } from '../common/utils';
import classes from './Cropper.module.css';

const ActivityZone = (props) => {
  const {
    name,
    deviceInfo,
    MQTTData,
    dispActivityZone,
    refreshCam,
    refreshCamView,
    displayActivityZone,
    cropBoxData,
    saveActivityZone,
    selectedCamera,
  } = props;

  const [isLoaded, setLoaded] = useState(true);

  const updateStatus = (newStatus) => {
    if (deviceInfo) {
      PubSub.publish(`$aws/things/${deviceInfo.DeviceId}/shadow/update`, {
        state: { desired: newStatus },
      });
    }
  };

  useEffect(() => {
    if (MQTTData && isLoaded) {
      setLoaded(false);
      if (objectHasKey(MQTTData, 'desired')) {
        saveActivityZone(false);
      }
    }
  }, [MQTTData]);

  useEffect(() => {
    if (refreshCam) {
      setTimeout(() => {
        refreshCamView(false);
      }, 4000);
    }
  }, [refreshCam]);

  const zoneDisplayHandler = () => {
    displayActivityZone(!dispActivityZone);
  };

  const saveActivityZoneHandler = () => {
    updateStatus({ activityZone: cropBoxData });
    zoneDisplayHandler();
    saveActivityZone(true);
  };

  const spinnerConfig = {
    showSpinner: refreshCam,
    left: '45%',
  };

  const getThumbnailImgPath = (imageFile) => {
    let path = null;
    if (typeof imageFile === 'string') {
      path = imageFile;
    } else if (typeof imageFile === 'object' && imageFile.length) {
      if (deviceInfo.DeviceType === 'OverDoorCamera' && selectedCamera === 'inside') {
        const largeImg = imageFile.find((item) => item.indexOf('1920_1080_inw') > -1);
        path = largeImg || imageFile[0];
      } else {
        const largeImg = imageFile.find((item) => item.indexOf('1920_1080') > -1);
        path = largeImg || imageFile[0];
      }
    }
    return path;
  };

  return (
    <>
      <SubHeader {...props} />
      <TypographyElem content={name} smallText />
      {refreshCam && <LoadingSpinner spinnerConfig={spinnerConfig} />}
      {dispActivityZone && (
        <Grid container direction="column">
          <Grid item>
            <div className={classes.activityZoneContainer}>
              <CropperEle
                dispActivityZone={dispActivityZone}
                source={getThumbnailImgPath(deviceInfo.DeviceThumbnail)}
                // source={deviceInfo.DeviceThumbnail}
                zoneDisplayHandler={zoneDisplayHandler}
              />
            </div>
            <ActivityZoneActions
              zoneDisplayHandler={zoneDisplayHandler}
              onSaveActivityZone={saveActivityZoneHandler}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  deviceInfo: state.deviceReducer.deviceInfo,
  MQTTData: state.deviceReducer.MQTTInfo,
  dispActivityZone: state.deviceReducer.dispActivityZone,
  cropBoxData: state.cropBoxReducer.cropBoxInfo,
  refreshCam: state.cropBoxReducer.refreshCam,
  selectedCamera: state.deviceReducer.selectedCamera,
});

const mapDispatchToProps = (dispatch) => ({
  displayActivityZone: (val) => dispatch(deviceActionCreator.displayActivityZone(val)),
  saveActivityZone: (data) => dispatch(deviceActionCreator.saveActivityZone(data)),
  refreshCamView: (data) => dispatch(actionCreator.refreshCamView(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityZone);
