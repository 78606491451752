import React from 'react';
import * as iconElem from '../Icons/Icons';

const unknownActType = {
  marginRight: '7px',
  width: '9px',
  height: '11px',
  paddingLeft: '8px',
  paddingTop: '10px'
};

const LoadIcon = props => {
  const iconsList = [
    {
      type: 'person',
      icon: iconElem.personIcon
    },
    {
      type: 'motion',
      icon: iconElem.motionIcon
    },
    {
      type: 'sound',
      icon: iconElem.soundIcon
    },
    {
      type: 'noise',
      icon: iconElem.soundIcon
    },
    {
      type: 'Recording',
      icon: iconElem.recordIcon
    }
  ];

  let iconImg = null;
  const iconsPath = iconsList.find(objList => objList.type === props.iconType);
  if (iconsPath) {
    iconImg = <img src={iconsPath.icon} alt={props.iconType} className={props.iconClass} />;
  } else {
    iconImg = <span style={unknownActType} />;
  }

  return iconImg;
};

export default LoadIcon;
