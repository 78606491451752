import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector, useDispatch } from 'react-redux';
import { setFilterIconActive } from '../store/action-creator';
import { filterActiveIcon, filterIcon } from '../../ui/Icons/Icons';
import classes from './ActivityHeader.module.css';

const FilterIcon = ({ clicked }) => {
  const [filterIconComp, setFilterIconComp] = useState(null);

  const dispatch = useDispatch();
  const filterIconActive = useSelector((state) => state.activityReducer.filterIconActive);

  const filterHandler = () => {
    dispatch(setFilterIconActive(!filterIconActive));
    clicked();
  };

  useEffect(() => {
    const icon = (
      <Grid item sm={2} style={{ position: 'relative' }}>
        <img
          src={filterIconActive ? filterActiveIcon : filterIcon}
          alt="filter"
          className={classes.filterImg}
          onClick={filterHandler}
          role="presentation"
        />
      </Grid>
    );
    setFilterIconComp(icon);
  }, [filterIconActive]);

  return filterIconComp;
};

export default FilterIcon;
