import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import StringTooltip from '../../ui/StringTooltip';

const EditUser = (props) => {
  const { homeInfo, userSelected, setDeviceFunction } = props;

  const [selectedDevice, setSelectedDevice] = useState(null);

  useEffect(() => {
    setSelectedDevice(userSelected.devices);
  }, []);

  useEffect(() => {
    setDeviceFunction(selectedDevice);
  }, [selectedDevice]);

  const checkedHandler = () => {
    const deviceArray = [];
    const cb = document.querySelectorAll('.CustomCheckbox input[type="checkbox"]');
    Array.prototype.forEach.call(cb, (elem) => {
      if (elem.checked) {
        deviceArray.push(elem.value);
      }
    });
    setSelectedDevice(deviceArray);
  };

  let deviceAccessList = null;
  deviceAccessList = homeInfo.deviceData.DeviceList.map((list) => (
    <Grid item style={{ padding: '5px 0', borderBottom: '1px solid' }} key={list.DeviceId}>
      <FormControlLabel
        className="CustomCheckbox"
        control={
          <Checkbox
            style={{ color: '#FFD200' }}
            onChange={checkedHandler}
            value={list.DeviceId}
            name={list.DeviceId}
            defaultChecked={userSelected.devices.indexOf(list.DeviceId) !== -1}
          />
        }
        label={<span>{list.DisplayName}</span>}
      />
    </Grid>
  ));
  return (
    <>
      <form autoComplete="off" style={{ width: 280 }}>
        <p>
          User :
          <StringTooltip val={userSelected.user} length={27}>
            <span style={{ color: '#FFD200' }}>{` ${userSelected.user}`}</span>
          </StringTooltip>
        </p>
        <Grid item style={{ padding: '25px 0', borderBottom: '1px solid' }}>
          <h4>Device Access</h4>
        </Grid>
        {deviceAccessList}
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  homeInfo: state.homeListReducer.selectedHomeInfo,
  userSelected: state.homeListReducer.selectedUser,
});

export default connect(mapStateToProps)(EditUser);
