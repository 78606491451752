import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const FilterDateSetting = props => {
  const { list, changed, value } = props;

  const changeHandler = event => changed(event.target.value);

  const filterDateComp = (
    <Select
      labelId="custom_select_drp"
      defaultValue={value}
      onChange={changeHandler}
      className="CustomSelect"
    >
      {
        list.map(listItem => (
          <MenuItem value={listItem} key={listItem}>{listItem}</MenuItem>
        ))
      }
    </Select>
  );

  return filterDateComp;
};

export default FilterDateSetting;
