// REQ: 4.1.7 STREAMING VIDEO / LIVE VIDEO (OVER DOOR CAMERA AND HOME/TRAVEL CAMERA)
import * as actionTypes from './action-types';

const intialState = {
  apiKey: process.env.REACT_APP_TOKBOX_API_KEY,
  token: null,
  isSessionIntiated: false,
  publishAudioStatus: false,
  takeScreenShot: false
};

const updateObject = (prevState, updatedValues) => (
  {
    ...prevState,
    ...updatedValues
  }
);

const reducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.INTIATE_SESSION:
      return updateObject(state, { isSessionIntiated: action.isIntiated });
    case actionTypes.SET_SESSION_ID:
      return updateObject(state, { sessionID: action.sessID });
    case actionTypes.SET_TOKEN:
      return updateObject(state, { token: action.tokenId });
    case actionTypes.RESET_SESSION:
      return intialState;
    case actionTypes.SET_PUBLISH_AUDIO:
      return updateObject(state, { publishAudioStatus: action.audioStatus });
    case actionTypes.SET_SCREENSHOT:
      return updateObject(state, { takeScreenShot: action.screenShot });
    default: return state;
  }
};

export default reducer;
