import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as iconElem from '../../../ui/Icons/Icons';
import classes from './DeviceSettings.module.css';

const valStyle = { fontSize: '11.9px' };
const IndividualSetting = (props) => {
  const {
    clicked,
    displayName,
    displayCode,
    displayHome,
    value,
    deviceInfo,
    deviceResp,
    location,
    batteryLevel,
    activePlan,
  } = props;

  const [name, setName] = useState(null);
  const [code, setCode] = useState(null);
  const [home, setHome] = useState(null);
  const [batRem, setBatteryRem] = useState(null);
  const [planName, setPlanName] = useState(null);

  useEffect(() => {
    setName(deviceInfo.DisplayName);
    setCode(displayCode);
    setHome(deviceResp.deviceData.HomeName);
  }, [deviceInfo, deviceResp]);

  useEffect(() => {
    setBatteryRem(`${batteryLevel}%`);
  }, [batteryLevel]);

  useEffect(() => {
    setPlanName(activePlan);
  }, [activePlan]);

  useEffect(() => setCode(location), [location]);

  return (
    <div className={[classes.indSetting, classes.setting].join(' ')}>
      <Grid container justify="space-between" alignItems="center" style={{ height: '30px' }}>
        <Grid item style={{ fontSize: '14px' }}>
          {value}
        </Grid>
        <Grid item>
          <div className={[classes.InlineBlock, classes.display].join(' ')}>
            {displayName && <span style={valStyle}>{name}</span>}
            {displayCode && <span style={valStyle}>{code}</span>}
            {displayHome && <span style={valStyle}>{home}</span>}
            {batteryLevel && <span style={valStyle}>{batRem}</span>}
            {activePlan && <span style={valStyle}>{planName}</span>}
            {value !== 'Battery Level' && value !== 'Plan' && (
              <img
                src={iconElem.forwardBlackIcon}
                alt=">"
                onClick={clicked}
                role="presentation"
                className={classes.forwardButton}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  deviceInfo: state.deviceReducer.deviceInfo,
  deviceResp: state.deviceReducer.deviceResp,
  location: state.deviceReducer.alertLocation,
});

export default connect(mapStateToProps)(IndividualSetting);
