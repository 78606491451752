import React from 'react';
import Grid from '@material-ui/core/Grid';
import classes from '../Activity.module.css';

const FilterToggle = props => {
  const { filterItem, changed, filterList } = props;

  return (
    <div className={classes.filterItem}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <img
            src={filterItem.icon}
            alt={filterItem.name}
            className={classes.filterItemIcon}
          />
          {filterItem.name}
        </Grid>
        <Grid item>
          <label className="ToggleSwitch">
            <input
              type="checkbox"
              checked={filterList[filterItem.id]}
              onChange={() => changed(filterItem.id, filterList[filterItem.id])}
            />
            <span className="ToggleSliders ToggleRound" />
          </label>
        </Grid>
      </Grid>
    </div>
  );
};

export default FilterToggle;
