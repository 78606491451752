import 'react-app-polyfill/ie11';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { Auth } from 'aws-amplify';
import Axios from 'axios';
import './index.css';
import 'video.js/dist/video-js.min.css';
import App from './App';
import registerServiceWorker from './register-sw';
import get from 'lodash/get';

registerServiceWorker();

Axios.interceptors.request.use(
  async (request) => {
    const session = await Auth.currentSession();
    request.headers.Authorization = get(session, 'idToken.jwtToken');
    return request;
  },
  (error) => Promise.reject(error)
);

Axios.defaults.baseURL = process.env.REACT_APP_API_URL;
Axios.defaults.timeout = 1000 * 300; // wait 5 minute before timing out

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
