import React from 'react';
import Grid from '@material-ui/core/Grid';
import VerticalLogo from '../../../../assets/images/VerticalLogo.png';
import classes from './DeviceDisplay.module.css';

const InitializeCamera = ({ msg }) => (
  <Grid container direction="column">
    <div className={[classes.initializeCamera, classes.streamVideoContainer].join(' ')}>
      <div className={classes.streamVideoError}>
        <img src={VerticalLogo} alt="" className={classes.logoStyle} />
        <div>{msg}</div>
      </div>
    </div>
  </Grid>
);

export default InitializeCamera;
