import * as actionTypes from './action-types';
import profileActionMenus from '../ProfileActionMenus';

const intialState = {
  profileMenuList: profileActionMenus,
  selectedMenu: 1,
  subscriptionList: null,
  photoUpdated: false,
  previousMenu: 1,
  tcVersion: null
};

const updateObject = (prevState, updatedValues) => (
  {
    ...prevState,
    ...updatedValues
  }
);

const reducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PROFILE_MENU:
      return updateObject(state, { selectedMenu: action.menu });
    case actionTypes.SET_PHOTO_UPDATED:
      return updateObject(state, { photoUpdated: action.update });
    case actionTypes.SET_PREVIOUS_MENU:
      return updateObject(state, { previousMenu: action.previousMenu });
    case actionTypes.SET_TC_VERSION:
      return updateObject(state, { tcVersion: action.version });
    default: return state;
  }
};

export default reducer;
