import React from 'react';
import Grid from '@material-ui/core/Grid';
import ProfileMenu from './ProfileMenu/ProfileMenu';
import ScrollArea from '../ui/ScrollBar/ScrollArea';
import ProfileContent from './ProfileContent/ProfileContent';
import classes from './Profile.module.css';

const Profile = () => (
  <>
    <Grid container>
      <Grid item sm={3} className={classes.profileMenuBg}>
        <ScrollArea>
          <ProfileMenu />
        </ScrollArea>
      </Grid>
      <Grid item sm={9}>
        <ProfileContent />
      </Grid>
    </Grid>
  </>
);

export default Profile;
