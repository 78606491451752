// REQUIREMENT: 4.1.17 User Management

import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import InputElem from '../../ui/Input/Input';
import { checkValidity } from '../../common/utils';

class InviteUser extends Component {
  state = {
    userForm: {
      userName: {
        id: 'name',
        required: true,
        elemType: 'input',
        value: '',
        elemConfig: {
          type: 'text',
          name: 'userName',
          placeholder: 'Name',
        },
        validation: {
          required: true,
          minLength: 1,
          maxLength: 256,
        },
        valid: false,
        touched: false,
      },
      emailId: {
        id: 'email',
        elemType: 'input',
        value: '',
        elemConfig: {
          type: 'text',
          name: 'emailId',
          placeholder: 'Email Address',
        },
        validation: {
          required: true,
          isEmail: true,
          maxLength: 256,
        },
        valid: false,
        touched: false,
      },
    },
    selectedDevice: null,
  };

  componentDidUpdate() {
    const { setDeviceFunction, invited, invitedUserName } = this.props;
    const { selectedDevice, userForm } = this.state;
    setDeviceFunction(selectedDevice);
    invited(userForm.emailId.value);
    invitedUserName(userForm.userName.value);
  }

  inputChangeHandler = (event, inputIdentifier) => {
    const { userForm } = this.state;
    const { btnDisabled } = this.props;
    const copyUserForm = { ...userForm };
    const copyNestedProperties = { ...copyUserForm[inputIdentifier] };

    copyNestedProperties.value = event.target.value;

    copyUserForm[inputIdentifier] = copyNestedProperties;

    copyUserForm[inputIdentifier].touched = true;

    copyUserForm[inputIdentifier].valid = checkValidity(
      event.target.value,
      copyUserForm[inputIdentifier].validation
    );

    let formValid = true;
    // eslint-disable-next-line no-restricted-syntax
    for (const inputIdentifiers in copyUserForm) {
      if (Object.prototype.hasOwnProperty.call(copyUserForm, inputIdentifiers)) {
        formValid = copyUserForm[inputIdentifiers].valid && formValid;
      }
    }

    this.setState({ userForm: copyUserForm });
    btnDisabled(formValid);
  };

  checkedHandler = () => {
    const deviceArray = [];
    const cb = document.querySelectorAll('.CustomCheckbox input[type="checkbox"]');
    Array.prototype.forEach.call(cb, (elem) => {
      if (elem.checked) {
        deviceArray.push(elem.value);
      }
    });
    this.setState({ selectedDevice: deviceArray });
  };

  checkSpace = (e) => {
    if (e.keyCode === 32) {
      e.preventDefault();
    }
  };

  render() {
    const { userForm } = this.state;
    const { homeInfo } = this.props;

    let formElemts = null;

    formElemts = Object.keys(userForm).map((keys) => (
      <Grid item key={keys}>
        <InputElem
          value={userForm[keys].value}
          elemType={userForm[keys].elemType}
          elemConfig={userForm[keys].elemConfig}
          changed={(evt) => this.inputChangeHandler(evt, keys)}
          isdisabled={userForm[keys].isdisabled}
          keyEvent={(e) => this.checkSpace(e)}
        />
      </Grid>
    ));

    let deviceAccessList = null;
    deviceAccessList = homeInfo.deviceData.DeviceList.map((list) => (
      <Grid item style={{ padding: '5px 0', borderBottom: '1px solid' }} key={list.DeviceId}>
        <FormControlLabel
          className="CustomCheckbox"
          control={
            <Checkbox
              style={{ color: '#FFD200' }}
              onChange={this.checkedHandler}
              value={list.DeviceId}
              name={list.DeviceId}
            />
          }
          label={<span style={{ fontSize: '14px' }}>{list.DisplayName}</span>}
        />
      </Grid>
    ));

    return (
      <>
        <form autoComplete="off" style={{ width: 280 }}>
          <Grid container direction="column" spacing={3}>
            {formElemts}
          </Grid>
          <Grid item style={{ padding: '25px 0', borderBottom: '1px solid' }}>
            <h4>Device Access</h4>
          </Grid>
          {deviceAccessList}
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  homeInfo: state.homeListReducer.selectedHomeInfo,
});

export default connect(mapStateToProps)(InviteUser);
