import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import * as ROUTES from './constants/routes';

import {
  LoadingScreen,
  Home,
  Instruction,
  ActivityDetails,
  Profile,
  SetupHome,
  EditHome,
  ManageHomes,
  Device,
  UserNotifications,
  NotificationDetails,
  Help,
  InviteUserPage,
} from './components';
import { connect } from 'react-redux';
import * as actionCreator from './components/DeviceList/store/action-creator';
import AppHeader from './components/AppHeader/AppHeader';
import { AuthContext } from './Providers/AuthProvider';
import Notifications from './Notifications';
import { isEmpty } from 'lodash';
import TermsOfServiceDialog from './components/TermsOfServiceDialog';

function AuthenticatedRoot(props) {
  const [loading, setLoading] = React.useState(true);
  const { user } = React.useContext(AuthContext);
  let history = useHistory();

  React.useEffect(() => {
    if (!loading && isEmpty(props.deviceList?.homeList))
      history.push(ROUTES.SET_UP_HOME, {
        lati: 40.422451,
        longi: -111.883324,
        initial: true,
      });
  }, [loading, props.deviceList]);

  React.useEffect(() => {
    if (user) {
      (async () => {
        try {
          await props.onGetDevices(user.userEmail, user.IdentityId);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [user]);

  React.useEffect(() => {
    if (user && !isEmpty(props?.deviceList?.deviceData?.DeviceList)) {
      const list = props.deviceList.deviceData.DeviceList;

      const lookup = {
        HomeAndTravelCamera: 'BodyGuardz Portable 360º Security Camera',
        SmartReadinessUnit: 'BodyGuardz Portable 5-in-1 Emergency Hub',
      };

      const homeAndTravelCamera = list.find((e) => e.DeviceType === 'HomeAndTravelCamera');

      let device = list.length > 1 && homeAndTravelCamera ? homeAndTravelCamera : list[0];

      // eslint-disable-next-line
      delightedStars.survey({
        email: user.userEmail,
        name: user.userName,
        createdAt: user.createdAt ? new Date(user.createdAt) : new Date(),
        properties: {
          questionProductName: 'the ' + lookup[device.DeviceType],
          brand: 'BodyGuardz',
          device: lookup[device.DeviceType],
        },
      });
    }
  }, [user, props.deviceList]);

  // console.log(props?.deviceList);

  if (loading) return <LoadingScreen />;

  if (!user.privacyPolicy || !user.termsOfService) return <TermsOfServiceDialog />;

  return (
    <div style={centerContent}>
      <div style={headerStyle}>
        <AppHeader />
      </div>
      <div style={centerInnerContent}>
        <Route path={ROUTES.HOME} exact component={Home} />
        <Route path={ROUTES.INSTRUCTION} component={Instruction} />
        <Route path={ROUTES.ACTIVITY_DETAILS} component={ActivityDetails} />
        <Route path={ROUTES.PROFILE} component={Profile} />
        <Route path={ROUTES.SET_UP_HOME} component={SetupHome} />
        <Route path={ROUTES.EDIT_HOME} component={EditHome} />
        <Route path={ROUTES.MANAGE_HOMES} component={ManageHomes} />
        <Route path={ROUTES.DEVICE} component={Device} />
        <Route path={ROUTES.NOTIFICATIONS} exact component={UserNotifications} />
        <Route path={ROUTES.NOTIFICATION_DETAILS} component={NotificationDetails} />
        <Route path={ROUTES.HELP} component={Help} />
        <Route path={ROUTES.INVITE_ACCEPTED} component={InviteUserPage} />
      </div>
      <Notifications user={user} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  deviceList: state.deviceReducer.deviceResp,
});

const mapDispatchToProps = (dispatch) => ({
  onGetDevices: (emailId, IdentityId) => dispatch(actionCreator.getDevices(emailId, IdentityId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRoot);

const centerContent = {
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const centerInnerContent = {
  width: '100%',
  maxWidth: 1200,
};

const headerStyle = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  backgroundColor: '#000',
};
