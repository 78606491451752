import Axios from 'axios';
import { Auth, PubSub } from 'aws-amplify';
import * as actionTypes from './action-types';
import { objectHasKey } from '../../common/utils';
import ErrorCodes from '../../common/ErrorCodes';

export const displayActivityZone = (val) => ({
  type: actionTypes.DISPLAY_ACTIVITY_ZONE,
  val,
});

export const fetchDevices = (resp) => ({
  type: actionTypes.GET_DEVICES,
  devResp: resp,
});

export const getDevices = (emailId, IdentityId, homeId, isFromDropdown) => {
  const hasHomeId = homeId ? `&homeid=${homeId}` : '';
  const result = {
    noDeviceList: true,
    deviceData: null,
    homeList: null,
    errorCode: null,
    errorMsg: null,
  };
  return (dispatch) => {
    const promise = new Promise((resolve, reject) => {
      Axios.get(`devices?email=${emailId}&action=listdevice${hasHomeId}`)
        .then((resp) => {
          if (resp.data.ResponseCode === 200) {
            if (resp.data.ErrorCode === 'err_0') {
              if (objectHasKey(resp.data, 'ResponseData')) {
                result.deviceData = resp.data.ResponseData.DeviceData;
                result.homeList = resp.data.ResponseData.HomeList;
                const deviceData = resp.data.ResponseData.DeviceData.DeviceList;
                if (deviceData.length) {
                  const devices = [];
                  for (let i = 0; i < deviceData.length; i += 1) {
                    devices.push(deviceData[i].DeviceId);
                  }
                  const data = {
                    action: 'attach_policy',
                    data: {
                      user_identity: IdentityId,
                      device_ids: devices,
                    },
                  };
                  Axios.post('users', data)
                    .then(() => null /*console.log('Attach Policy called successfully', res)*/)
                    .catch((err) => reject(err));
                }
                if (result.deviceData.DeviceList.length) {
                  result.noDeviceList = false;
                }
              }
            } else {
              result.errorCode = resp.data.ErrorCode;
              result.errorMsg = resp.data.body;
            }
          } else {
            result.errorMsg = resp.data.body;
          }
          if (isFromDropdown && result.errorMsg) {
            console.log(
              'If there is an error when user select home from dropdown, do not dispath response, show previous data'
            );
          } else {
            dispatch(fetchDevices(result));
          }

          resolve(result);
        })
        .catch((err) => {
          console.log({ err });
          const errors = 'Something went wrong 5';
          reject(err);
          result.errorMsg = errors;
          dispatch(fetchDevices(result));
        });
    });
    return promise;
  };
};

export const setDeviceInfo = (obj) => ({
  type: actionTypes.SET_DEVICE_INFO,
  deviceInfo: obj,
});

export const fetchVideo = (url) => ({
  type: actionTypes.GET_VIDEO_PATH,
  videoPath: url,
});

export const updateDeviceInfo = (data, name) => {
  const newData = { ...data };
  newData.DisplayName = name;
  return {
    type: actionTypes.SET_DEVICE_INFO,
    deviceInfo: newData,
  };
};

export const updateHomeInfo = (oldData, homename, id) => (dispatch) => {
  const newData = { ...oldData };
  const index = newData.homeList.findIndex((e) => e?.HomeID === id);
  newData.homeList[index].HomeName = homename;
  newData.deviceData.HomeName = homename;
  dispatch(getDevices(id));
};

export const updateDeviceListData = (deviceList, deviceData, name) => {
  const newdeviceData = { ...deviceData };
  newdeviceData.DisplayName = name;

  const index = deviceList.deviceData.DeviceList.findIndex(
    (el) => el.DeviceId === newdeviceData.DeviceId
  );
  deviceList.deviceData.DeviceList[index] = newdeviceData;
  return {
    type: actionTypes.SET_DEVICES,
    deviceResp: deviceList,
  };
};

export const updateMqtt = (obj) => ({
  type: actionTypes.SET_MQTT_INFO,
  mqttData: obj,
});

export const onUpdateMqttData = (data) => (dispatch) => dispatch(updateMqtt(data));

export const setMQTTUpdated = (MQTTUpdated) => ({
  type: actionTypes.SET_MQTT_UPDATED,
  MQTTUpdated,
});

let subscription;

export const fetchmqttInfo = (id) => (dispatch) => {
  subscription = PubSub.subscribe([
    `$aws/things/${id}/shadow/get/accepted`,
    `$aws/things/${id}/shadow/get/rejected`,
    `$aws/things/${id}/shadow/update/accepted`,
    `$aws/things/${id}/shadow/update/rejected`,
  ]).subscribe({
    next: (data) => {
      if (data !== undefined) {
        const result = data.value.state;
        if (result !== undefined) {
          dispatch(updateMqtt(result));
          dispatch(setMQTTUpdated(true));
        }
      }
    },
    error: (error) => {
      console.error('Rejected', error);
      Auth.currentCredentials()
        .then((cred) => {
          const params = {
            action: 'attach_policy',
            data: {
              user_identity: cred.data.IdentityId,
              device_ids: [id],
            },
          };
          Axios.post('users', params)
            .then((resp) => console.log(resp))
            .catch((err) => console.log(err.response));
        })
        .catch((err) => console.log(err.response));
    },
    close: () => console.log('Done'),
  });
  // setTimeout(() => {
  //   PubSub.publish(`$aws/things/${id}/shadow/get`, '');
  // }, 2000);
};

export const setMqttInfo = (id) => fetchmqttInfo(id);

export const unsubscribe = () => {
  if (subscription) {
    subscription.unsubscribe();
    subscription = null;
  }
};

export const resetMQTTInfo = () => ({
  type: actionTypes.RESET_MQTT_INFO,
});

export const updateMQTT = (newInfo) => ({
  type: actionTypes.UPDATE_MQTT,
  newInfo,
});

export const fetchAlertTypeData = (alertData) => ({
  type: actionTypes.SET_ALERT_TYPE,
  alertTypes: alertData,
});

export const setAlertTypesData = (emailId, deviceId) => (dispatch) => {
  Axios.get(`users?email=${emailId}&action=get_settings&deviceid=${deviceId}`)
    .then((resp) => {
      const result = resp.data.ResponseCode === 200 ? resp.data.ResponseData : null;
      dispatch(fetchAlertTypeData(result));
    })
    .catch((err) => console.log({ err }));
};

export const sendAlertTypeData = (alerts) => {
  const promise = new Promise((resolve, reject) => {
    Axios.post('users', alerts)
      .then((resp) => resolve(resp))
      .catch((err) => reject(err));
  });
  return promise;
};

export const setPageLoader = (value) => ({
  type: actionTypes.SET_LOADER,
  value,
});

export const setAPIError = (error) => ({
  type: actionTypes.SET_API_ERROR,
  error,
});

export const removeDevice = (data) => (dispatch) => {
  dispatch(setPageLoader(true));
  const policyData = {
    action: 'detach_policy',
    data: {
      user_identity: data.IdentityId,
      device_id: data.device_id,
    },
  };
  // [#84850] Not able to remove the camera devices from an account.
  const promise = new Promise((resolve, reject) => {
    Axios.post('users', policyData)
      .then((resp) => {
        if (resp.data.ErrorCode !== 'err_0') {
          dispatch(setAPIError(ErrorCodes[resp.data.ErrorCode]));
        }
        Axios.post('reset', {
          action: 'reset',
          data: {
            device_id: data.device_id,
            email: data.email,
            home_id: data.home_id,
          },
        })
          .then((deviceResponse) => {
            dispatch(getDevices(data.email, data.IdentityId, data.home_id));
            if (resp.data.ErrorCode !== 'err_0') {
              dispatch(setAPIError(ErrorCodes[resp.data.ErrorCode]));
            }
            resolve(deviceResponse.data);
          })
          .catch(() => {
            dispatch(setAPIError('Something went wrong!'));
            reject(ErrorCodes.other);
          });
        dispatch(setPageLoader(false));
      })
      .catch(() => {
        dispatch(setAPIError('Something went wrong!'));
      });
  });
  return promise;
};

export const setFMRadioStatus = (fmStatus) => ({
  type: actionTypes.FM_RADIO_STATUS,
  fmStatus,
});

export const fetchAlertLocation = (alertLocation) => ({
  type: actionTypes.SET_ALERT_LOCATION,
  alertLocation,
});

export const getAlertLocation = (emailId, deviceId) => (dispatch) => {
  Axios.get(`disasteralert?action=get_zipcode&email=${emailId}&thing_name=${deviceId}`)
    .then((resp) => {
      const result = resp.data.ResponseCode === 200 ? resp.data.ResponseData.zip_code : null;
      dispatch(fetchAlertLocation(result));
    })
    .catch((err) => console.log({ err: err.response }));
};

export const setAlertLocation = (alert) => {
  const promise = new Promise((resolve, reject) => {
    Axios.post('disasteralert', alert)
      .then((resp) => resolve(resp))
      .catch((err) => reject(err));
  });
  return promise;
};

export const recordLiveVideo = (archiveId, timerVal) => ({
  type: actionTypes.RECORD_LIVE_VIEDO,
  archiveId,
  timer: timerVal,
});

export const startRecording = (data) => (dispatch) => {
  const promise = new Promise((resolve, reject) => {
    Axios.post('livestream', {
      action: 'start_recording',
      data,
    })
      .then((resp) => {
        if (resp.data.ResponseCode === 200) {
          dispatch(recordLiveVideo(resp.data.ResponseData.archive_id, true));
        }
        resolve(resp.data);
      })
      .catch((err) => reject(err));
  });
  return promise;
};

export const stopRecording = (data) => (dispatch) => {
  const promise = new Promise((resolve, reject) => {
    Axios.post('livestream', {
      action: 'stop_recording',
      data,
    })
      .then((resp) => {
        if (resp.data.ErrorCode !== 'err_0') {
          dispatch(setAPIError(ErrorCodes[resp.data.ErrorCode]));
        } else {
          dispatch(recordLiveVideo(resp.data.ResponseData.archive_id, false));
        }
        resolve(resp);
      })
      .catch((err) => reject(err));
  });
  return promise;
};

export const deleteVideoHistory = (param) => {
  const promise = new Promise((resolve, reject) => {
    Axios.post('devices', param)
      .then((resp) => {
        const result = {
          errorCode: null,
          errorMsg: null,
        };
        const respCode = resp.data.ResponseCode;
        const errCode = resp.data.ErrorCode;
        if (respCode !== 200 || (respCode === 200 && errCode !== 'err_0')) {
          result.errorCode = errCode;
          result.errorMsg = ErrorCodes[errCode];
        }
        resolve(result);
      })
      .catch(() => reject(ErrorCodes.other));
  });

  return promise;
};

export const deviceHasEvents = (param) => {
  const promise = new Promise((resolve, reject) => {
    Axios.post('devices', param)
      .then((resp) => {
        let hasEvents = false;
        if (resp.data.ResponseCode === 200) {
          if (objectHasKey(resp.data, 'ResponseData')) {
            const eventData = resp.data.ResponseData.EventData;
            if (eventData.length) {
              hasEvents = true;
            }
          }
        }
        resolve(hasEvents);
      })
      .catch((err) => reject(err));
  });

  return promise;
};

export const setInitialDevices = (data) => ({
  type: actionTypes.SET_DEVICES,
  deviceResp: data,
});

export const renameDevice = (param) => {
  const promise = new Promise((resolve, reject) => {
    Axios.post('devices', param)
      .then((resp) => resolve(resp))
      .catch((err) => reject(err));
  });

  return promise;
};

export const getDevPlanInfo = (data) => ({
  type: actionTypes.GET_DEVICE_PLAN_INFO,
  devPlanDetails: data,
});

export const getDevicePlanInfo = (data) => (dispatch) => {
  Axios.post('users', {
    action: 'retrieve_plan_details',
    data,
  })
    .then((response) => {
      if (response.data.ErrorCode !== 'err_0') {
        dispatch(setAPIError(ErrorCodes[response.data.ErrorCode]));
      }
      dispatch(getDevPlanInfo(response.data.ResponseData));
    })
    .catch(() => {
      return dispatch(setAPIError('Something went wrong 6'));
    });
};

export const saveActivityZone = (data) => ({
  type: actionTypes.ACTIVITY_ZONE_SAVE,
  data,
});

export const fwUpdateHandler = (param) => {
  const promise = new Promise((resolve, reject) => {
    Axios.post('fw-update', param)
      .then((resp) => resolve(resp))
      .catch((err) => reject(err));
  });
  return promise;
};

export const setSelectedCamera = (selectedCamera) => ({
  type: actionTypes.SELECTED_CAMERA,
  selectedCamera,
});
