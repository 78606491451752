import React, { Component } from 'react';
import OT from '@opentok/client';
import { connect } from 'react-redux';
import { setPublishAudio } from './store/action-creator';

class TokBoxPublisher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
    this.publisher = null;
  }

  componentDidMount() {
    const pubProperties = {
      videoSource: null,
      publishAudio: false,
      publishVideo: false
    };
    this.publisher = OT.initPublisher(null, pubProperties, this.handleError);
    this.publisherEventHandler();
  }

  // [#84808] Two way talk is not working through
  // App(If we talk through camera the voice from app is not hearing).
  componentDidUpdate() {
    const { session, audioStatus } = this.props;
    if (audioStatus) {
      this.publisher.publishAudio(true);
      session.publish(this.publisher, this.handleError);
    } else {
      this.publisher.publishAudio(false);
    }
  }

  componentWillUnmount() {
    const { session, onSetAudioStatus } = this.props;
    this.publisher.publishAudio(false);
    session.unpublish(this.publisher);
    onSetAudioStatus(false);
  }

  publisherEventHandler = () => {
    this.publisher.on({
      accessAllowed: event => {
        console.log('accessAllowed: ', event);
      },
      accessDenied: event => {
        console.log('accessDenied: ', event);
      },
      accessDialogOpened: event => {
        console.log('accessDialogOpened: ', event);
      },
      accessDialogClosed: event => {
        console.log('accessDialogClosed: ', event);
      }
    });
  }

  handleError = error => {
    if (error) {
      const msg = `Failed to Publish: ${error.message}`;
      this.setState({ error: msg });
    }
  }

  render() {
    const { error } = this.state;
    return (
      <div id="publisher_container">
        {error ? <div style={{ display: 'none' }}>{error}</div> : null}
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    audioStatus: state.tokboxReducer.publishAudioStatus
  }
);

const mapDispatchToProps = dispatch => (
  {
    onSetAudioStatus: status => dispatch(setPublishAudio(status))
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(TokBoxPublisher);
