import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actionCreator from './store/action-creator';
import classes from './DeviceList.module.css';

import TypographyElem from '../ui/Typography/Typography';
import ScrollArea from '../ui/ScrollBar/ScrollArea';
import LoadingSpinner from '../ui/LoadingSpinner/LoadingSpinner';
import DeviceItem from './DeviceItem';
import DeviceRecordingPopup from './DeviceRecordingPopup'
import { objectHasKey } from '../common/utils';
import { AuthContext } from '../../Providers/AuthProvider';
import Axios from 'axios';

const DeviceList = (props) => {
  const { user } = React.useContext(AuthContext);
  const userEmail = user.userEmail;
  const spinnerElem = (
    <div className="spinnerContainer">
      <LoadingSpinner spinnerConfig={{ showSpinner: true }} />
    </div>
  );
  const [deviceElem, setDeviceElem] = useState(spinnerElem);
  const [open, setOpen] = useState(false);
  const [deviceData, setDeviceData] = useState({})

  const dispatch = useDispatch();

  const deviceResp = useSelector((state) => state.deviceReducer.deviceResp);

  const HandleDeviceDetails = (data) => {
    // const obj = {
    //   email: userEmail,
    //   device_ids: [data.DeviceId]
    // };
    // dispatch(actionCreator.getDevicePlanInfo(obj));
    props.history.push(`/device/${data.DeviceId}`);
    dispatch(actionCreator.setDeviceInfo(data));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleProceed = () => {
    setOpen(false);
    HandleDeviceDetails(deviceData);
  };

  const isDeviceRecording = async (data) => {
    try {
      const { data: isRecordingData } = await Axios.post('is-recording', { deviceId: data.DeviceId });
      if (isRecordingData?.recordingInProgress) {
        setDeviceData(data);
        handleClickOpen();
      } else {
        HandleDeviceDetails(data);
      }
    } catch (error) {
      console.log(error);
      HandleDeviceDetails(data);
    }
  };

  const renderDevices = () => {
    let homeID = null;
    // [#84226] WiFi status was not showing in home page
    const devList = deviceResp.deviceData.DeviceList;
    if (objectHasKey(deviceResp.deviceData, 'HomeId')) {
      homeID = deviceResp.deviceData?.HomeId;
    }
    const deviceListElem = (
      <ScrollArea scrollClass={classes.deviceList}>
        <DeviceItem
          devList={devList}
          clicked={isDeviceRecording}
          homeID={homeID}
          userEmail={userEmail}
        />
      </ScrollArea>
    );
    setDeviceElem(deviceListElem);
  };

  useEffect(() => {
    if (deviceResp) {
      if (deviceResp.noDeviceList) {
        setDeviceElem(<div id="NoDevices">No Devices</div>);
      } else {
        renderDevices();
      }
    }
  }, [deviceResp]);

  return (
    <>
      <TypographyElem content="Devices" />
      <DeviceRecordingPopup open={open} handleClose={handleClose} handleProceed={handleProceed} />
      {deviceElem}
    </>
  );
};

export default withRouter(DeviceList);
