import React from 'react';
import LoadIcon from '../../ui/LoadIcon/LoadIcon';
import { favoriteActiveIcon } from '../../ui/Icons/Icons';
import { convertTimestamp } from '../../common/utils';
import classes from './ActivityList.module.css';

const ActivityItemLeftContent = props => {
  const { list } = props;
  const activityTypes = ['person', 'sound', 'motion', 'Recording'];

  const isFavouriteItem = val => {
    const isFav = val ? <img src={favoriteActiveIcon} alt="" className={classes.favImage} /> : null;
    return isFav;
  };

  return (
    <div className={classes.leftContent}>
      <LoadIcon iconType={list.ActivityType} iconClass={classes.icon} />
      <span className={classes.text} style={{ textTransform: 'capitalize' }}>
        {activityTypes.indexOf(list.ActivityType) !== -1 ? list.ActivityType : 'Unknown'}
      </span>
      <div className={classes.time}>
        {convertTimestamp(list.Timestamp, true)}
        {isFavouriteItem(list.IsFavourite)}
      </div>
    </div>
  );
};

export default ActivityItemLeftContent;
