import React, { useState } from 'react';
import { connect } from 'react-redux';
import Geocode from 'react-geocode';
import Input from '@material-ui/core/Input';
import ButtonElem from '../../../../ui/Button/Button';
import classes from '../DeviceSettings.module.css';
import * as actionCreator from '../../../store/action-creator';
import { navigateIcon } from '../../../../ui/Icons/Icons';
import CustomDialog from '../../../../ui/CustomDialog/CustomDialog';
import msgConstant from '../../../../common/textConstants';
import ErrorCodes from '../../../../common/ErrorCodes';
import LoadingSpinner from '../../../../ui/LoadingSpinner/LoadingSpinner';
import Notification from '../../../../ui/Notification/Notification';

const AlertLocation = (props) => {
  const { location, email, deviceInfo } = props;
  const inputClassList = [classes.inputClass, 'CustomInput'].join(' ');
  const [zipCode, setZipCode] = useState(location);
  const [showModal, setShowModal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const getPostalCode = (addressArray) => {
    let postalCode = '';
    for (let i = 0; i < addressArray.length; i += 1) {
      if (addressArray[i].types[0] && addressArray[i].types[0] === 'postal_code') {
        postalCode = addressArray[i].long_name;
        return postalCode;
      }
    }
  };

  const currentLocationHandler = () => {
    // eslint-disable-next-line no-unused-expressions
    navigator.permissions &&
      navigator.permissions.query({ name: 'geolocation' }).then((PermissionStatus) => {
        if (PermissionStatus.state === 'granted') {
          navigator.geolocation.getCurrentPosition((position) => {
            Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
              (response) => {
                const addressArray = response.results[0].address_components;
                const zipcode = getPostalCode(addressArray);
                setZipCode(zipcode);
              },
              (err) => console.error(err)
            );
          });
        } else {
          setShowModal(true); // denied
        }
      });
  };

  const dialogConfig = {
    open: showModal,
    textAlign: 'left',
    btnClicked: () => setShowModal(false),
  };

  const onNameChange = (e) => setZipCode(e.target.value);

  // const onNameChange = e => {
  //   const Regex = /^[\d-]+$/;
  //   if (!Regex.test(e.target.value)) {
  //     e.preventDefault();
  //   } else {
  //     setZipCode(e.target.value);
  //   }
  // };

  const onUpdateHandler = () => {
    setSpinner(true);
    const locationData = {
      action: 'update_zipcode',
      data: {
        email,
        zipcode: zipCode,
        thing_name: deviceInfo.DeviceId,
      },
    };
    props.setAlertLocation(locationData).then(
      (resp) => {
        setSpinner(false);
        const respCode = resp.data.ResponseCode;
        const errCode = resp.data.ErrorCode;
        if (respCode !== 200 || (respCode === 200 && errCode !== 'err_0')) {
          setErrorModal(true);
          setErrorMessage(`${ErrorCodes[resp.data.ErrorCode]}`);
        } else {
          setShowNotification(true);
          setTimeout(() => {
            props.newAlertLocation(zipCode);
            props.Locate();
          }, 1500);
        }
      },
      () => {
        setSpinner(false);
        setErrorModal(true);
        setErrorMessage(`${msgConstant.COMMON_ERROR}`);
      }
    );
  };

  const onCancelHandler = () => props.Locate();

  const onleadingSpace = (e) => {
    if (e.keyCode === 32 && !e.target.value.replace(/\s/g, '').length) {
      e.preventDefault();
    }
  };

  const setErrorBtnHandler = () => setErrorModal(!errorModal);

  const errorModalConfig = {
    open: errorModal,
    textAlign: 'left',
    // title: 'Unable to update Zip code',
    btnClicked: setErrorBtnHandler,
  };

  const notificationConfig = {
    position: 'bottomRight',
    open: showNotification,
    onClose: () => setShowNotification(false),
  };

  return (
    <>
      <LoadingSpinner spinnerConfig={{ showSpinner: spinner }} />
      <div className={classes.header}>Alert Location</div>
      <Input
        className={inputClassList}
        onChange={(evt) => onNameChange(evt)}
        value={zipCode || ''}
        placeholder="ZIP Code"
        onKeyDown={(e) => onleadingSpace(e)}
      />
      <div>
        <span className={classes.locateMe} role="presentation" onClick={currentLocationHandler}>
          Locate Me
          <img src={navigateIcon} alt=">" style={{ width: '12px', paddingLeft: '5px' }} />
        </span>
      </div>
      <div className={classes.buttonSet} style={{ display: 'flex' }}>
        <ButtonElem
          btnColor="YellowButton"
          value="Save"
          btnType="submit"
          style={{ width: 120, marginRight: 5 }}
          clicked={onUpdateHandler}
          isbtndisabled={zipCode === '' ? true : undefined}
        />
        <ButtonElem
          btnColor="GreyButton"
          value="Cancel"
          btnType="submit"
          style={{ width: 120 }}
          clicked={onCancelHandler}
        />
      </div>
      {showModal && (
        <CustomDialog dialogConfig={dialogConfig}>
          <div>{msgConstant.ENABLE_LOCATION}</div>
        </CustomDialog>
      )}
      {errorModal && (
        <CustomDialog dialogConfig={errorModalConfig}>
          <div>{errorMessage}</div>
        </CustomDialog>
      )}
      {showNotification && (
        <Notification config={notificationConfig}>
          {msgConstant.ALERT_LOCATION_SUCCESS}
        </Notification>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setAlertLocation: (emailId, deviceId) => actionCreator.setAlertLocation(emailId, deviceId),
  newAlertLocation: (data) => dispatch(actionCreator.fetchAlertLocation(data)),
});

export default connect(null, mapDispatchToProps)(AlertLocation);
