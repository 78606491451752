import React from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { TrunLine } from '../../common/utils';
import { forwardBlueIcon } from '../../ui/Icons/Icons';
import * as actionCreator from '../store/action-creator';
import ProfilePhoto from '../ProfilePhoto';
import LoadingSpinner from '../../ui/LoadingSpinner/LoadingSpinner';
import classes from '../Profile.module.css';
import { AuthContext } from '../../../Providers/AuthProvider';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: 'white',
        color: 'black',
        fontSize: '10px',
        boxShadow: '0 0 10px #f1f1f1',
        border: '5px border #f1f1f1',
      },
      arrow: {
        color: 'white',
      },
    },
  },
});

const AccountOverview = (props) => {
  const { user } = React.useContext(AuthContext);
  const { onSelectedMenu } = props;
  const name = user.userName;

  const handleMenuItem = (menu) => onSelectedMenu(menu);

  return (
    <>
      <LoadingSpinner spinnerConfig={{ showSpinner: false }} />
      <div className={classes.headerText}>Profile</div>
      <ProfilePhoto />
      <div className={classes.borderBottom}>
        <Grid container>
          <Grid item sm={3} className={classes.greyLabel}>
            Name
          </Grid>
          {name && (
            <MuiThemeProvider theme={theme}>
              <Tooltip title={name.length > 25 ? name : ''}>
                <Grid item className={classes.textStyle}>
                  {name && TrunLine(name, 25)}
                </Grid>
              </Tooltip>
            </MuiThemeProvider>
          )}
        </Grid>
      </div>
      <div className={classes.borderBottom}>
        <Grid container>
          <Grid item sm={3} className={classes.greyLabel}>
            Email
          </Grid>
          <Grid item className={classes.textStyle}>
            {user.userEmail}
          </Grid>
        </Grid>
      </div>
      <div className={classes.borderBottom}>
        <Grid container>
          <Grid item sm={3} className={classes.greyLabel}>
            Password
          </Grid>
          <Grid item className={classes.textStyle}>
            <span
              className={[classes.changeText, 'Font14'].join(' ')}
              role="presentation"
              onClick={() => handleMenuItem(4)}
            >
              Change Password
              <img src={forwardBlueIcon} alt="Forward" className={classes.forwardIcon} />
            </span>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: '2em' }}>
        <span
          className={[classes.changeText, 'Font14'].join(' ')}
          role="presentation"
          onClick={() => handleMenuItem(3)}
        >
          Edit Profile
          <img src={forwardBlueIcon} alt="Forward" className={classes.forwardIcon} />
        </span>
      </div>
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onSelectedMenu: (data) => dispatch(actionCreator.setProfileMenu(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountOverview);
