import React, { useEffect, useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { ChevronDown } from 'react-bootstrap-icons';
import classes from '../DeviceSettings.module.css';

const OverDoorCameraDropdown = ({ selectedCamera, changeHandler }) => {
  const [drpComp, setDrpComp] = useState(null);

  useEffect(() => {
    if (selectedCamera) {
      const comp = (
        <div style={{ textAlign: 'center' }}>
          <Select
            labelId="camera_select_drp"
            defaultValue={selectedCamera}
            onChange={changeHandler}
            className="cameraCustomSelect"
            IconComponent={() => <ChevronDown className={classes.ArrowDown} />}
          >
            <MenuItem value="outside">Outside Camera Options</MenuItem>
            <MenuItem value="inside">Inside Camera Options</MenuItem>
          </Select>
        </div>
      );
      setDrpComp(comp);
    }
  }, [selectedCamera]);

  return drpComp;
};

export default OverDoorCameraDropdown;
