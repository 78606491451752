import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    console.log(error);
    return {
      hasError: true,
    };
  }

  componentDidCatch(error, info) {
    console.log('ERROR', error);
    console.log('INFO', info);
    this.setState({
      hasError: true,
      error,
    });
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <>
          <h3>Oops!! Something went wrong</h3>
          <p>
            The error:
            {error && error.toString()}
          </p>
        </>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
