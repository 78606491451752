import React, { useState, useEffect } from 'react';
import FilterDateSetting from './FilterDateSetting';

const FilterDate = (props) => {
  const { dateChanged, hasFilterDate } = props;
  const [month, setMonth] = useState(null);
  const [date, setDate] = useState(null);
  const [year, setYear] = useState(null);

  const monthArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const dateArray = [];
  const yearArray = [];

  for (let i = 1; i <= 31; i += 1) {
    dateArray.push(i);
  }
  const dt = hasFilterDate || new Date();
  const currentDate = dt.getDate();
  const currentMonth = dt.getMonth();
  const currentYear = dt.getFullYear();

  for (let i = currentYear; i >= currentYear - 10; i -= 1) {
    yearArray.push(i);
  }

  const getFulDate = () => {
    const monthIndex = monthArray.indexOf(month);
    const fullDate = new Date(year, monthIndex, date);
    dateChanged({
      fullFormat: fullDate,
      stringFormat: `${monthIndex + 1}/${date}/${year}`,
    });
  };

  useEffect(() => {
    getFulDate();
  }, [month, date, year]);

  if (month === null) {
    setMonth(monthArray[currentMonth]);
  }

  if (date === null) {
    setDate(currentDate);
  }

  if (year === null) {
    setYear(currentYear);
  }

  const changed = (evt, type) => {
    switch (type) {
      case 'month':
        setMonth(evt);
        break;
      case 'date':
        setDate(evt);
        break;
      case 'year':
        setYear(evt);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <span>
        {month ? (
          <FilterDateSetting list={monthArray} changed={(e) => changed(e, 'month')} value={month} />
        ) : (
          ''
        )}
      </span>
      <span>
        {date ? (
          <FilterDateSetting list={dateArray} changed={(e) => changed(e, 'date')} value={date} />
        ) : (
          ''
        )}
      </span>
      <span>
        {year ? (
          <FilterDateSetting list={yearArray} changed={(e) => changed(e, 'year')} value={year} />
        ) : (
          ''
        )}
      </span>
    </>
  );
};

export default FilterDate;
