import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import classes from './subscription.module.css';

const SumOfEachColumn = props => {
  const {
    eachCol, width, columnSumMonthly, planDuration
  } = props;
  const useStyles = makeStyles({
    root: {
      borderBottom: 0
    }
  });

  const muiClasses = useStyles();

  const colLength = eachCol.length;

  return (
    <TableCell align="center" width={width} className={muiClasses.root} style={{ paddingLeft: '2rem' }}>
      <div>
        <div className={classes.tableFontBlack}>
          {(colLength > 0) && colLength}
          &nbsp;
          {(colLength > 1) ? 'cameras' : (colLength === 1) ? 'camera' : ''}
        </div>
        <div className={classes.tableFontBlack}>
          {(colLength > 0)
            && `$ ${(Number((planDuration === 'monthly') ? columnSumMonthly : 0).toFixed(2))}`}
        </div>
      </div>
    </TableCell>
  );
};

export default SumOfEachColumn;
