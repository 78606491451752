import React, { useState, useEffect } from 'react';
import profilePic from '../../assets/images/ProfilePhoto.png';
import { AuthContext } from '../../Providers/AuthProvider';

const ProfilePhoto = (props) => {
  const { user } = React.useContext(AuthContext);
  const { path, profileType } = props;
  const url = path || user.photo;

  const [profile, setProfile] = useState(null);

  const profileStyle = {
    width: 82,
    height: 82,
    borderRadius: '50%',
    margin: '1em 1em 0 1em',
    boxShadow: '0 5px 8px #ccc',
  };

  useEffect(() => {
    if (profileType === 'default') {
      setProfile(profilePic);
    } else {
      setProfile(url);
    }
  }, [url, profileType]);

  const imageCheck = (e) => (e.target.src = profilePic);

  return (
    <img src={profile} alt="profile pic" style={profileStyle} onError={(e) => imageCheck(e)} />
  );
};

export default ProfilePhoto;
