// REQUIREMENT : 4.1.14 Configure SD Recording (Home / Travel Camera Only)
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { PubSub } from 'aws-amplify';
import AWS from 'aws-sdk';
import * as actionCreator from '../../store/action-creator';
import DeviceActions from './DeviceActions/DeviceActions';
import CustomDialog from '../../../ui/CustomDialog/CustomDialog';
import { HOME } from '../../../../constants/routes';
import msgConstant from '../../../common/textConstants';
import { objectHasKey } from '../../../common/utils';
import InitializeCamera from './InitializeCamera';
import DeviceHeader from './DeviceHeader';
import DeviceLiveStream from './DeviceLiveStream';
import { AuthContext } from '../../../../Providers/AuthProvider';

const DeviceDisplay = (props) => {
  const {
    deviceInfo,
    MQTTInfo,
    onstopRecording,
    archiveId,
    thingShadowUpdate,
    isMQTTUpdated,
    // onSetMQTTUpdated, selectedCamera,
    hasMQTTError,
  } = props;
  const { awsConfig } = React.useContext(AuthContext);
  const [liveStream, setLiveStream] = useState(false);
  const [cameraType, setCameraType] = useState(null);
  const [isLoaded, setLoaded] = useState(true);
  const [showLogo, setShowLogo] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [commonError, setCommonError] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState(null);
  const [deviceDisplayComp, setDeviceDisplayComp] = useState(null);
  // const [powerOn, setPowerOn] = useState(null);

  function onGetThingShadow(id) {
    const iotData = new AWS.IotData(awsConfig);
    const params = {
      thingName: id,
    };
    iotData.getThingShadow(params, (err, success) => {
      if (err) {
        hasMQTTError();
      } else {
        const payload = JSON.parse(success.payload);
        thingShadowUpdate(payload.state);
      }
    });
  }

  useEffect(() => {
    if (deviceInfo && awsConfig) {
      onGetThingShadow(deviceInfo.DeviceId);
    }
  }, []);

  const updateStatus = (newStatus) => {
    if (deviceInfo) {
      PubSub.publish(`$aws/things/${deviceInfo.DeviceId}/shadow/update`, {
        state: { desired: newStatus },
      });
    }
  };

  function setTokboxStreamState() {
    if (MQTTInfo.desired.tokboxStreamingState === 0) {
      updateStatus({ tokboxStreamingState: 1 });
    }
  }

  const inCameraTokboxStreamingHanlder = () => {
    if (objectHasKey(MQTTInfo.reported, 'powerInw') && MQTTInfo.reported.powerInw) {
      setTokboxStreamState();
    }
  };

  const LiveStreamHandler = (status) => {
    setShowLogo(false);
    setLiveStream(status);
  };

  const insideCameraOptions = () => {
    if (
      !objectHasKey(MQTTInfo.reported, 'cloudConnectedState') ||
      MQTTInfo.reported.cloudConnectedState === 0 ||
      !objectHasKey(MQTTInfo.reported, 'powerInw') ||
      MQTTInfo.reported.powerInw === 0
      // || !objectHasKey(MQTTInfo.reported, 'tokboxSessionId')
      // || MQTTInfo.reported.tokboxSessionId === ''
    ) {
      LiveStreamHandler(false);
    } else {
      LiveStreamHandler(true);
      inCameraTokboxStreamingHanlder();
    }

    // if (objectHasKey(MQTTInfo.reported, 'powerInw')) {
    //   setPowerOn(MQTTInfo.reported.powerInw);
    // }
  };

  const tokboxStreamingHanlder = () => {
    if (objectHasKey(MQTTInfo.reported, 'power') && MQTTInfo.reported.power) {
      setTokboxStreamState();
    }
  };

  const cloudConnectedStatus = () => {
    if (
      !objectHasKey(MQTTInfo.reported, 'cloudConnectedState') ||
      MQTTInfo.reported.cloudConnectedState === 0 ||
      !objectHasKey(MQTTInfo.reported, 'power') ||
      MQTTInfo.reported.power === 0
      // || !objectHasKey(MQTTInfo.reported, 'tokboxSessionId')
      // || MQTTInfo.reported.tokboxSessionId === ''
    ) {
      LiveStreamHandler(false);
    } else {
      LiveStreamHandler(true);
      tokboxStreamingHanlder();
    }

    // if (objectHasKey(MQTTInfo.reported, 'power')) {
    //   setPowerOn(MQTTInfo.reported.power);
    // }
  };

  const renderDeviceDisplay = () => {
    setTimeout(() => {
      if (deviceInfo.DeviceType === 'OverDoorCamera') {
        if (objectHasKey(MQTTInfo, 'desired')) {
          if (MQTTInfo.desired.activeCamera === 1) {
            insideCameraOptions(); // Over door Inside camera
          } else {
            cloudConnectedStatus(); // Over door Outside camera
          }
        } else {
          LiveStreamHandler(false);
        }
      } else if (objectHasKey(MQTTInfo, 'reported')) {
        // Home and Travel Camera
        cloudConnectedStatus();
      } else {
        LiveStreamHandler(false);
      }
    });
  };

  useEffect(() => {
    if (MQTTInfo && isLoaded) {
      setLoaded(false);
      renderDeviceDisplay();
    }
  }, [MQTTInfo]);

  useEffect(() => {
    if (isMQTTUpdated && !isLoaded) {
      renderDeviceDisplay();
    }
  }, [isMQTTUpdated]);

  const onSetCamera = (data) => setCameraType(data);

  const btnHandler = (status) => {
    if (status) {
      onstopRecording({
        archive_id: archiveId,
        thing_name: deviceInfo.DeviceId,
        end_time: Math.floor(Date.now() / 1000),
      });
      props.history.push(HOME);
    }
    setShowModal(false);
  };

  const dialogConfig = {
    open: showModal,
    type: 'action',
    disabled: true,
    btnClicked: (status) => btnHandler(status),
  };

  const commonErrorBtnHandler = () => {
    setCommonError(!commonError);
    setCommonErrorMsg(null);
  };

  const commonErrorDialogConfig = {
    open: commonError,
    textAlign: 'left',
    btnClicked: commonErrorBtnHandler,
  };

  const renderDialog = (config, msg) => (
    <CustomDialog dialogConfig={config}>
      <div>{msg}</div>
    </CustomDialog>
  );

  useEffect(() => {
    const comp = (
      <>
        <DeviceHeader {...props} />
        {showLogo ? (
          <InitializeCamera msg="Initializing camera..." />
        ) : liveStream ? (
          <div>
            <DeviceLiveStream MQTTInfo={MQTTInfo} deviceInfo={deviceInfo} cameraType={cameraType} />
            <DeviceActions deviceType={deviceInfo.DeviceType} camType={onSetCamera} />
          </div>
        ) : (
          <InitializeCamera msg="Video currently unavailable" />
        )}
      </>
    );
    setDeviceDisplayComp(comp);
  }, [showLogo, liveStream]);

  return (
    <>
      {deviceDisplayComp}
      {showModal && renderDialog(dialogConfig, msgConstant.RECORDING_CHECK)}
      {commonError && renderDialog(commonErrorDialogConfig, commonErrorMsg)}
    </>
  );
};

const mapStateToProps = (state) => ({
  deviceInfo: state.deviceReducer.deviceInfo,
  MQTTInfo: state.deviceReducer.MQTTInfo,
  archiveId: state.deviceReducer.liveVideoArchiveId,
  isMQTTUpdated: state.deviceReducer.MQTTUpdated,
  selectedCamera: state.deviceReducer.selectedCamera,
});

const mapDispatchToProps = (dispatch) => ({
  onResetMQTTData: () => dispatch(actionCreator.resetMQTTInfo()),
  onstopRecording: (data) => dispatch(actionCreator.stopRecording(data)),
  thingShadowUpdate: (data) => dispatch(actionCreator.onUpdateMqttData(data)),
  onResetMQTTUpdated: () => dispatch(actionCreator.setMQTTUpdated(false)),
  onSetMQTTUpdated: () => dispatch(actionCreator.setMQTTUpdated(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDisplay);
