import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import SubHeader from '../ui/SubHeader';
import ScrollArea from '../ui/ScrollBar/ScrollArea';
import { NOTIFICATION_DETAILS } from '../../constants/routes';
import TypographyElem from '../ui/Typography/Typography';
import LoadingSpinner from '../ui/LoadingSpinner/LoadingSpinner';
import { forwardBlackIcon } from '../ui/Icons/Icons';
import { timeConversion } from '../common/utils';
import CustomDialog from '../ui/CustomDialog/CustomDialog';
import { getNotification } from './store/action-creator';
import msgConstant from '../common/textConstants';
import ErrorCodes from '../common/ErrorCodes';
import { AuthContext } from '../../Providers/AuthProvider';

const UserNotifications = (props) => {
  const { user } = React.useContext(AuthContext);
  const emailId = user.userEmail;
  const [spinner, setSpinner] = useState(true);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [scrolled, setScrolled] = useState(false);
  const [paginationSpinner, setPaginationSpinner] = useState(false);

  const notifications = useSelector((state) => state.notificationReducer.notifications);
  const dispatch = useDispatch();

  const containerStyle = { padding: '14px 0px', borderBottom: '1px solid #000000', width: '50%' };

  const handleModalClose = () => setErrorModal(!errorModal);

  const errorModalConfig = {
    open: errorModal,
    type: 'message',
    btnClicked: handleModalClose,
  };

  const getNotificationLists = (HasOtherParams, prevList) => {
    setSpinner(true);
    setScrolled(false);
    const params = {
      action: 'list_notifications',
      data: {
        email: emailId,
        last_evaluated_key: HasOtherParams || '',
      },
    };

    dispatch(getNotification(params, prevList)).then(
      (resp) => {
        setSpinner(false);
        setScrolled(true);
        if (resp.data.ErrorCode !== 'err_0' && resp.data.ErrorCode !== 'err_206') {
          setErrorModal(true);
          setErrorMsg(`${ErrorCodes[resp.data.ErrorCode]}`);
        }
      },
      () => {
        setSpinner(false);
        setErrorModal(true);
        setErrorMsg(msgConstant.COMMON_ERROR);
      }
    );
  };

  const scrollEndHandler = () => {
    const prevList = notifications.notificationData;
    if (notifications.lastEvaluatedKey) {
      if (scrolled) {
        setPaginationSpinner(true);
        getNotificationLists(notifications.lastEvaluatedKey, prevList);
      }
    }
  };

  useEffect(() => {
    getNotificationLists();
  }, []);

  const previousPageHandler = () => props.history.goBack();

  const detailHandler = (data) => {
    props.history.push({
      pathname: NOTIFICATION_DETAILS,
      state: { selected: data },
    });
  };

  const renderLinkedStatus = (category, statusCode) => {
    switch (category) {
      case 'CAMERA_ACTIVITY':
      case 'WEATHER_ALERT':
        return true;
      case 'PANIC_ALERT':
        if (statusCode !== '0') {
          return true;
        }
        break;
      case 'FIRMWARE_UPDATE':
        if (statusCode !== '1') {
          return true;
        }
        break;
      default:
        return null;
    }
  };

  const renderNotifications = () => (
    <div style={{ margin: '30px' }}>
      {notifications &&
        notifications.notificationData.map((val) => (
          <Grid container key={val.notificationId}>
            <Grid item xs={5} style={containerStyle}>
              <p style={{ color: '#CCCCCC', fontSize: '17px' }}>{timeConversion(val.timestamp)}</p>
              <p style={{ fontSize: '17px', fontFamily: 'boldFont' }}>{val.title}</p>
              <p style={{ fontSize: '17px' }}>
                {val.notificationBody.replace('$$%timestamp%$$', timeConversion(val.timestamp))}
              </p>
            </Grid>
            {renderLinkedStatus(val.category, val.statusCode) ? (
              <Grid item>
                <img
                  src={forwardBlackIcon}
                  alt=">"
                  width="12px"
                  onClick={() => detailHandler(val)}
                  role="presentation"
                  style={{ paddingTop: '40px', cursor: 'pointer' }}
                />
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        ))}
    </div>
  );

  const SpinnerComp = () => {
    const spinnerConfig = {
      showSpinner: spinner,
      right: '50%',
      left: 'auto',
    };

    if (paginationSpinner) {
      spinnerConfig.bottom = '10%';
    }
    return <LoadingSpinner spinnerConfig={spinnerConfig} />;
  };

  return (
    <>
      <SpinnerComp />

      <SubHeader clicked={previousPageHandler} {...props} style={{ paddingLeft: 20 }} />
      <div style={{ marginLeft: '20px' }}>
        <TypographyElem content="Notifications" />
      </div>
      <ScrollArea scrollEnd={scrollEndHandler} scrollClass="UserNotificationContainer">
        {notifications && notifications.noNotificationList ? (
          <div style={{ margin: '50px' }}>
            <h4>{msgConstant.NO_NOTIFICATIONS}</h4>
          </div>
        ) : (
          renderNotifications()
        )}
      </ScrollArea>
      {errorModal && (
        <CustomDialog dialogConfig={errorModalConfig}>
          <div>{errorMsg}</div>
        </CustomDialog>
      )}
    </>
  );
};

export default withRouter(UserNotifications);
