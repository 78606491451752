import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SubHeader from '../../../ui/SubHeader';
import TypographyElem from '../../../ui/Typography/Typography';

const DeviceHeader = (props) => {
  const [headerComp, setHeaderComp] = useState(null);

  const deviceInfo = useSelector((state) => state.deviceReducer.deviceInfo);

  useEffect(() => {
    if (deviceInfo) {
      setHeaderComp(
        <>
          <SubHeader {...props} />
          <TypographyElem content={deviceInfo.DisplayName} smallText />
        </>
      );
    }
  }, [deviceInfo]);

  return headerComp;
};

export default DeviceHeader;
