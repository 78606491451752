import React from 'react';
import Button from '@material-ui/core/Button';
import './Button.css';

const button = props => {
  const {
    btnColor, btnType, isbtndisabled, clicked, style, btnSize, value, btnId
  } = props;

  let addClass = '';
  if (value) {
    addClass = value.length > 12 ? 'forgotBtn' : 'btnWidth';
  }
  const classList = ['CustomButton', btnColor, addClass].join(' ');
  const disabledclassList = ['CustomButton', 'disabledButton', addClass].join(' ');
  return (
    <Button
      type={btnType}
      variant="contained"
      className={isbtndisabled ? disabledclassList : classList}
      onClick={clicked}
      style={style}
      size={btnSize}
      disabled={isbtndisabled}
      id={btnId}
    >
      {value}
    </Button>
  );
};

export default button;
