import React from 'react';
import Typography from '@material-ui/core/Typography';
import classes from './Typography.module.css';

const TypographyComp = props => {
  const { smallText, content } = props;
  const classList = smallText ? classes.contentBorder2 : classes.contentBorder;

  return (
    <Typography variant="h5" gutterBottom>
      <span
        className={classList}
      >
        {content}
      </span>
    </Typography>
  );
};

export default TypographyComp;
