import React, { useState, useEffect } from 'react';
import { PubSub } from 'aws-amplify';
import { useSelector, useDispatch } from 'react-redux';
import FMChannelElem from './FMChannel';
import FMChannelList from './FMChannelList';
import FMVolumeSlider from './FMVolumeSlider';
import { setMQTTUpdated } from '../../../../store/action-creator';
import * as iconElem from '../../../../../ui/Icons/Icons';
import Notification from '../../../../../ui/Notification/Notification';
import { objectHasKey } from '../../../../../common/utils';
import msgConstant from '../../../../../common/textConstants';
import classes from '../../DeviceSettings.module.css';

const FMSettings = (props) => {
  const { MQTTData, deviceId, turnOff } = props;
  const fmScanClassList = [classes.fmScanChannel, classes.horLine, 'Font14'].join(' ');
  const fmOffRadioClassList = [classes.fmTurnOff, classes.horLine, 'Font14'].join(' ');
  const [FMChannel, setFMChannel] = useState(null);
  const [FMVolume, setFMVolume] = useState(0);
  const [channelList, setChannelList] = useState(null);
  const [scanForChannel, setScanForChannel] = useState(null);
  const [open, setOpen] = useState(false);
  const [isLoaded, setLoaded] = useState(true);

  const dispatch = useDispatch();
  const isMQTTUpdated = useSelector((state) => state.deviceReducer.MQTTUpdated);

  const renderFMSettings = () => {
    if (objectHasKey(MQTTData, 'reported')) {
      if (objectHasKey(MQTTData.reported, 'fmScannedList')) {
        setChannelList(MQTTData.reported.fmScannedList);
      }
    }

    if (objectHasKey(MQTTData, 'desired')) {
      if (objectHasKey(MQTTData.desired, 'fmChannel')) {
        setFMChannel(MQTTData.desired.fmChannel);
      }
      if (objectHasKey(MQTTData.desired, 'fmVolume')) {
        setFMVolume(MQTTData.desired.fmVolume);
      }
      if (objectHasKey(MQTTData.desired, 'fmAutoScan')) {
        if (objectHasKey(MQTTData.reported, 'fmAutoScan')) {
          setScanForChannel(MQTTData.desired.fmAutoScan);
        }
      }
    }
  };

  useEffect(() => {
    if (MQTTData && isLoaded) {
      setLoaded(false);
      renderFMSettings();
    }
  }, [MQTTData]);

  useEffect(() => {
    if (isMQTTUpdated) {
      dispatch(setMQTTUpdated(false));
      setTimeout(() => {
        renderFMSettings();
      });
    }
  }, [isMQTTUpdated]);

  const handleClose = () => {
    setOpen(false);
    turnOff(1);
  };

  const config = {
    position: 'bottomRight',
    open,
    onClose: handleClose,
  };

  const updateStatus = (newStatus) => {
    if (deviceId) {
      PubSub.publish(`$aws/things/${deviceId}/shadow/update`, { state: { desired: newStatus } });
    }
  };

  const scanChannels = () => {
    if (MQTTData.reported.cloudConnectedState) {
      updateStatus({ fmAutoScan: 1 });
    } else {
      setOpen(true);
    }
  };

  const turnOffRadio = () => {
    if (MQTTData.reported.cloudConnectedState) {
      updateStatus({ fmStatus: 0 });
      turnOff(0);
    } else {
      setOpen(true);
    }
  };

  const onChannelChange = (val) => {
    updateStatus({ fmChannel: val });
    setFMChannel(val);
  };

  return (
    <div style={{ position: 'relative' }}>
      <img
        src={iconElem.closeWhiteIcon}
        alt="FM Close"
        className={classes.fmClose}
        role="presentation"
        onClick={() => turnOff(1)}
      />
      <div className={[classes.settingPanelPadding, 'TextCenter'].join(' ')}>
        <div className={[classes.header, 'TextLeft'].join(' ')}>FM Radio</div>
        {FMChannel && <FMChannelElem FMChannelNo={FMChannel} clicked={updateStatus} />}
        <FMVolumeSlider volume={FMVolume} changeVolume={updateStatus} />
        <FMChannelList list={channelList} clicked={onChannelChange} />
        <div className={classes.horLine} />
        <div className={fmScanClassList}>
          {scanForChannel ? (
            <span style={{ opacity: '0.6' }}>Scanning...</span>
          ) : (
            <span className="CursorPointer" role="presentation" onClick={scanChannels}>
              Scan for channels
            </span>
          )}
        </div>
        <div className={fmOffRadioClassList}>
          <span className="CursorPointer" role="presentation" onClick={turnOffRadio}>
            Turn off radio
          </span>
        </div>
      </div>
      {open && <Notification config={config}>{msgConstant.DEVICE_DISCONNECTED}</Notification>}
    </div>
  );
};

export default FMSettings;
