import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as actionCreator from '../store/action-creator';
import CustomDialog from '../../ui/CustomDialog/CustomDialog';
import Notification from '../../ui/Notification/Notification';
import msgConstant from '../../common/textConstants';
import { AuthContext } from '../../../Providers/AuthProvider';

const DeleteCurrentActivity = (props) => {
  const { user } = React.useContext(AuthContext);

  const { children, list } = props;
  const [modal, setModal] = useState(false);
  const [commonError, setCommonError] = useState(false);
  const [commonErrorMsg] = useState(null);
  const [invtUserModal, setinvtUserModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const userEmail = user.userEmail;
  const selectedEvent = useSelector((state) => state.activityReducer.selectedEvent);
  const deviceResp = useSelector((state) => state.deviceReducer.deviceResp);

  const onDeleteSingleActivity = (params, spinnerElem) => {
    actionCreator
      .deleteSingleActivity(params)
      .then(() => {
        window.location.reload();
      })
      .finally(() => (spinnerElem.style.visibility = 'hidden'));
  };

  const deleteBtnHandler = () => {
    const spinnerContainer = document.querySelector('#spinner_container');
    if (spinnerContainer) {
      setTimeout(() => {
        spinnerContainer.style.visibility = 'visible';
      });
    }
    const params = {
      action: 'delete_single',
      data: {
        email_id: userEmail,
        thingName: selectedEvent.DeviceID,
        activity_id: selectedEvent.ActivityID,
      },
    };

    if (list) {
      params.data.thingName = list.DeviceID;
      params.data.activity_id = list.ActivityID;
    }
    setTimeout(() => {
      onDeleteSingleActivity(params, spinnerContainer);
    }, 1000);
  };

  const toggleDialog = () => {
    const selectedHome = deviceResp.homeList.filter(
      (each) => each?.HomeID === deviceResp.deviceData?.HomeId
    );
    if (selectedHome[0].Creator === userEmail) {
      setModal(!modal);
    } else {
      setinvtUserModal(true);
    }
  };

  const deleteVideoCheck = (status) => {
    if (status) {
      deleteBtnHandler();
    }
    toggleDialog();
  };

  const errorDialogConfig = {
    open: commonError,
    textAlign: 'left',
    btnClicked: () => setCommonError(false),
  };

  const videoDeleteDialogConfig = {
    open: modal,
    type: 'action',
    disabled: true,
    btnClicked: (status) => deleteVideoCheck(status),
  };

  const invitedUserModalConfig = {
    open: invtUserModal,
    type: 'message',
    btnClicked: () => setinvtUserModal(false),
  };

  const renderDialog = (config, msg) => (
    <CustomDialog dialogConfig={config}>
      <div>{msg}</div>
    </CustomDialog>
  );

  const notificationConfig = {
    position: 'bottomRight',
    open: showNotification,
    onClose: () => setShowNotification(false),
  };

  return (
    <>
      <div role="presentation" onClick={() => toggleDialog()}>
        {children}
      </div>
      {commonError && renderDialog(errorDialogConfig, commonErrorMsg)}
      {modal && renderDialog(videoDeleteDialogConfig, msgConstant.VIDEO_DELETE_CHECK)}
      {invtUserModal && renderDialog(invitedUserModalConfig, msgConstant.CREATOR_CHECK)}
      {showNotification && (
        <Notification config={notificationConfig}>{msgConstant.ACTIVITY_REMOVE}</Notification>
      )}
    </>
  );
};

export default DeleteCurrentActivity;
