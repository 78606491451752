const errorCodes = {
  err_0: 'Success',
  err_1: 'Device is blacklisted',
  err_2: 'User does not exist',
  err_3: 'Device does not belong to user',
  err_4: 'User is not mapped to any home',
  err_5: 'Payment incomplete for device',
  err_6: 'User does not have a device of requested type.',
  err_7: 'Unauthorized: Token is not valid for given email',
  // DEVICE APIs
  err_101: 'Only owner of the device can remove the event(s)',
  err_102: 'No home is associated with the user.',
  err_103: 'Device with same name already exists in the home.',
  err_104: 'Not matching event found to set as Favorite',
  err_105: 'No activity yet', // 'Event data not found.'
  err_106: 'User subscription does not have plan associated with it',
  err_107: 'Given token is already registered.Please send a valid token.',
  err_108: 'Home not found',
  err_109: 'Device token cannot be updated. Please provide valid old token of given device type',
  // User APIs
  err_201: 'Plan details not found.',
  err_202: 'User does not have any home mapped yet',
  err_203: 'Operation not allowed because of role (home owner/invitee).',
  err_204: 'All the devices need to be removed to delete home',
  err_205: 'Identity Id does not belong to user.',
  err_206: 'Notifications history not found.',
  err_207: 'Chargebee customer_id does not exist',
  err_208: 'Chargebee subscription_id does not exist.',
  err_209: 'Given user is not the owner of the device',
  err_210: 'Device(s) is/are already shared.',
  err_211: 'Some devices may not be shared and/or unshared.',
  err_212: 'No device to unshare for user',
  err_213: 'Alert settings data not found.',
  err_214: 'Confirmation code is invalid.',
  err_215: 'Confirmation code is already used.',
  err_216: 'TokenId is already used.',
  err_217: 'TokenId does not exist.',
  err_218: 'User does not belong to the home',
  err_219: 'Invitation got expired',
  err_220: 'Home does not exist',
  err_221: 'Chargebee subscription_id is empty',
  err_222: 'Subscription can not be updated. User is not the owner of one or more given device_ids',
  err_223: 'Card details not found',
  err_224: 'Customer details not found',
  err_225: 'Card details expired',
  err_226: 'Cannot update duplicate subscription_id',
  err_227: 'Subscription cannot be updated. Some of the device(s) is/are not mapped to plan',
  err_228: 'Attach policy for few devices did not succeed',
  err_229: 'No invite found for given user or invite is already expired',
  err_230: 'Device to be shared does not exist',
  err_231: 'User is already part of the home',
  // Streams API
  err_301: 'No users found for the device.',
  err_302: 'Could not create session. ',
  err_303: 'Invalid session id for the device',
  err_304: 'Device does not belong to user.',
  err_305: 'Could not start the archive recording.',
  err_306: 'Could not stop the archive recording.',
  // Provision APIs
  err_401: 'Home does not exist',
  err_402: 'Device is already provisioned.',
  err_403: 'Device to be reset is not provisioned to any user.',
  err_404: 'Device verification failed. Serial Number or device Token is not valid.',
  err_405: 'Device does not exist.',
  err_406: 'Operation not allowed because you are on the free plan.',
  err_407: 'Provisioning failed',
  err_408: 'Device does not belong to this home',
  // Disaster APIs
  err_501: 'Alert Event data not found.',
  err_502: 'ZIP Code data not found',
  // OTA APIs
  err_601: 'Could not initiate firmware update. Please try again later', // alert
  err_602: 'A Firmware update has already been queued up for this device.', // toast
  err_603: 'Job cannot be created. Device is either offline or does not have enough power.',
  // Terms and Condition API
  err_701: 'Failed to retrieve latest terms and conditions file version.',
  other: 'Something went wrong 3',
};

export default errorCodes;
