import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Input from '@material-ui/core/Input';
import classes from '../DeviceSettings.module.css';
import * as actionCreator from '../../../store/action-creator';
import ButtonElem from '../../../../ui/Button/Button';
import LoadingSpinner from '../../../../ui/LoadingSpinner/LoadingSpinner';
import CustomDialog from '../../../../ui/CustomDialog/CustomDialog';
import Notification from '../../../../ui/Notification/Notification';
import msgConstant from '../../../../common/textConstants';
import { AuthContext } from '../../../../../Providers/AuthProvider';
// import ErrorCodes from '../../../../common/ErrorCodes';

const DeviceRename = (props) => {
  const { user } = React.useContext(AuthContext);
  const email = user.userEmail;
  const { deviceInfo, deviceList, renameDeviceName } = props;
  const inputClassList = [classes.inputClass, 'CustomInput'].join(' ');
  const [device, setDevice] = useState(deviceInfo.DisplayName);
  const [spinner, setSpinner] = useState(false);
  const [error, setError] = useState(false);
  const [errMessage, setErrMessage] = useState(null);
  const [errorModal, setErrorModal] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    setDevice(deviceInfo.DisplayName);
  }, [deviceInfo]);

  const onleadingSpace = (e) => {
    if (e.keyCode === 32 && !e.target.value.replace(/\s/g, '').length) {
      e.preventDefault();
    }
  };

  const onNameChange = (e) => {
    if (e.target.value.replace(/\s/g, '').length < 0) {
      setError(true);
      setErrMessage(msgConstant.DEVICE_NAME_EMPTY);
    } else if (e.target.value.length === 0) {
      setError(true);
      setErrMessage(msgConstant.DEVICE_NAME_EMPTY);
    } else if (e.target.value.length > 256) {
      setError(true);
      setErrMessage(msgConstant.DEVICE_RENAME_INCORRECT);
    } else {
      setError(false);
    }
    setDevice(e.target.value);
  };

  const toggleSpinner = (data) => setSpinner(data);

  const successResp = (resp) => {
    const respCode = resp.data.ResponseCode;
    const errCode = resp.data.ErrorCode;
    if (respCode !== 200 || (respCode === 200 && errCode !== 'err_0')) {
      setErrorModal(true);
      setApiErrorMsg(msgConstant.DEVICE_RENAME_ERROR);
    } else {
      setShowNotification(true);
      setTimeout(() => {
        props.RenameFlag();
        props.updateDeviceData(deviceInfo, device);
        props.updateDeviceListData(deviceList, deviceInfo, device);
      }, 1500);
    }
  };

  const errorResp = () => {
    setErrorModal(true);
    setApiErrorMsg(`${msgConstant.COMMON_ERROR}`);
    props.RenameFlag();
  };

  const onSaveHandler = () => {
    toggleSpinner(true);
    const params = {
      action: 'rename',
      data: {
        email,
        device_id: deviceInfo.DeviceId,
        nick_name: device,
      },
    };
    renameDeviceName(params)
      .then(successResp, errorResp)
      .finally(() => toggleSpinner(false));
  };

  const RenderBtnSave = () => (
    <ButtonElem
      btnColor="YellowButton"
      value="Save"
      btnType="submit"
      style={{ width: 120, marginRight: 5 }}
      clicked={onSaveHandler}
      isbtndisabled={error ? true : undefined}
    />
  );

  const RenderBtnCancel = () => (
    <ButtonElem
      btnColor="GreyButton"
      value="Cancel"
      btnType="submit"
      style={{ width: 120 }}
      clicked={() => props.RenameFlag()}
    />
  );

  const errorModalConfig = {
    open: errorModal,
    textAlign: 'left',
    // title: 'Unable to rename device',
    btnClicked: () => setErrorModal(!errorModal),
  };

  const notificationConfig = {
    position: 'bottomRight',
    open: showNotification,
    onClose: () => setShowNotification(false),
  };

  return (
    <>
      <LoadingSpinner spinnerConfig={{ showSpinner: spinner }} />
      <div className={classes.header}>Customise your device name</div>
      <Input
        className={inputClassList}
        onChange={(evt) => onNameChange(evt)}
        onKeyDown={(e) => onleadingSpace(e)}
        value={device || ''}
        placeholder="Name"
      />
      <p style={{ color: 'Red', visibility: error ? 'visible' : 'hidden' }}>{errMessage}</p>
      <div className={classes.buttonSet}>
        <RenderBtnSave />
        <RenderBtnCancel />
      </div>
      {errorModal && (
        <CustomDialog dialogConfig={errorModalConfig}>
          <div>{apiErrorMsg}</div>
        </CustomDialog>
      )}
      {showNotification && (
        <Notification config={notificationConfig}>{msgConstant.DEVICE_RENAME_SUCCESS}</Notification>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  deviceInfo: state.deviceReducer.deviceInfo,
  deviceList: state.deviceReducer.deviceResp,
});

const mapDispatchToProps = (dispatch) => ({
  updateDeviceData: (data, name) => dispatch(actionCreator.updateDeviceInfo(data, name)),
  updateDeviceListData: (deviceList, deviceInfo, device) =>
    dispatch(actionCreator.updateDeviceListData(deviceList, deviceInfo, device)),
  renameDeviceName: (data) => actionCreator.renameDevice(data),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceRename);
