const textConstants = {
  COMMON_ERROR: 'Something went wrong 4',
  REGISTERED:
    'Welcome! You have successfully registered a new account.Please verify the link sent to your registered email.',
  // [#84860] In manage password and forgot password page the 'i' icon message is wrongly displayed.
  PASSWORD_CRITERIA:
    'Password should have minimum of 8 characters, 1 Uppercase alphabet, 1 special character and 1 number',
  PASSWORD_MATCH: 'Both passwords must match. Please try again.',
  ACCEPT_INVITE_SUCCESS: "You've successfully accepted the invitation",
  ACCEPT_INVITE_FAILURE: 'There was a problem accepting the invitation',
  FORGOT_PWD: 'Enter your email address. We will send you instructions to reset your password.',
  RESEND_INVITE_SUCCESS: 'Invite was resent successfully.',
  RESEND_INVITE_ERROR: 'An error occurred when resending the invitation',
  FORGOT_PWD_VERIFICATION:
    'A confirmation code has been sent to your registered email. Please enter the code below',
  FORGOT_PWD_SUCCCESS: 'Success! Your password has been updated.',
  INACTIVITY_MESSAGE: 'You have been logged out due to inactivity',
  CHANGE_PWD_CHECK: 'This action will log you out from the app. Do you want to proceed further?',
  SINGLE_DEVICE_FILTER: 'Select at least 1 device to apply the activity filter.',
  SINGLE_TYPE_FILTER: 'At least one type  should be selected',
  INVITE_NO_DEVICES_CHECK: 'At least one device is required to invite a user. Please add a device.',
  ENABLE_LOCATION: 'Please enable location permissions in Settings.',
  DEVICE_DISCONNECTED: 'Device is disconnected',
  FIRMWARE_UPDATE_INITIATED: 'Firmware update has been initiated.',
  FIRMWARE_UPDATE_SUCCESS: 'Firmware version is updated successfully',
  FIRMWARE_UPDATE_CHECK: 'Are you sure you want to update device firmware version to',
  NO_EVENTS: 'No Events to display!',
  NO_NOTIFICATIONS: 'No Notifications to display',
  VIDEO_HISTORY_DELETE_CHECK:
    'Are you sure you want to delete all video history from your account?',
  VIDEO_DELETE_CHECK: 'Are you sure you want to delete this video clip?',
  CREATOR_CHECK: 'You are not authorized to perform this action',
  REMOVE_CAMERA_CHECK:
    'Removing this device will cancel your subscription and delete your video history. Are you sure you would like to continue?',
  REMOVE_SRU_CHECK: 'Are you sure you want to remove this device from your account?',
  REMOVE_HOME_CHECK: 'Are you sure you want to remove this home from your account?',
  REMOVE_USER_CHECK: 'Are you sure you want to remove this user from your current account?',
  SINGLE_HOME_CHECK: 'Please link at least one home to this account.',
  REMOVE_DEVICES_CHECK: 'Please remove all devices before deleting this home location. ',
  REMOVE_ALL_DEVICES_EDIT:
    'No devices were selected. This will remove the user access to this home. Are you sure you want to proceed?',
  IMAGE_TYPE_CHECK: 'Please Upload only png/jpeg image',
  SUBSCRIPTION_EXPIRY: 'Operation not allowed because of expired subscription.',
  SUBSCRIPTION_VALIDATION: 'Operation not allowed because of free subscription',
  RECORDING_CHECK: 'Recording is still in progress.Are you sure you want to exit?',
  DEVICE_CHECK:
    'Login to Bodyguardz website or use Mobile apps to have full access to your account',
  LOGOUT_CHECK: 'Are you sure you want to log out?',
  VIDEO_DOWNLOAD: 'Video download will start shortly.',
  RESEND_GENERIC: 'An error happened when trying to send the invite. Please try again.',
  REMOVE_USER_GENERIC: 'An error occurred when trying to remove the user. Please try again. ',
  DEV_RENAME_GENERIC: 'An error occurred when renaming the device. Please try again.',
  ALERT_LOC_GENERIC: 'An error occurred when setting the alert pincode.',
  NAME_CHAR_LIMIT: 'Sorry. Name must be 256 characters or less.',
  EMAIL_CHAR_LIMIT:
    'Sorry. Email must be 128 characters or less. Please check your email address and try again.',
  INVALID_USER:
    'The email entered does not match any in our records. Please make sure the email address is correct and complete.',
  INVALID_PSW:
    'The password entered does not match the email and password combination. Please try again.',
  USER_NOT_VERIFIED: 'User is not verified.',
  NICKNAME_EMPTY: 'Please enter a nickname for this location.',
  NICKNAME_MIN_CHAR: 'Home Name should have atleast 4 characters',
  NICKNAME_MAX_CHAR:
    'Home name cannot exceed 256 characters or have any special characters. Please try again',
  CREATE_HOME_ERROR: 'There was an error when trying to create a home location. Please try again.',
  CREATE_HOME_SUCCESS: 'Home location created successfully.',
  REMOVE_HOME_ERROR: 'There was an error when trying to remove this home. Please try again.',
  REMOVE_HOME_SUCESS: 'Home location removed successfully.',
  EDIT_HOME_ERROR: 'There was an error when trying to edit this home. Please try again.',
  EDIT_HOME_SUCCESS: 'Home information successfully updated',
  INVITE_USER_SINGLE_DEVICE: 'Please select at least 1 device when inviting a user.',
  INVITE_USER_EMAIL_EMPTY: 'Please enter a valid Email address to invite a user.',
  INVITE_USER_ERROR: 'An error happened when trying to send the invite. Please try again.',
  INVITE_USER_SUCCESS: 'Invite sent successfully.',
  INVITE_USER_EDIT_SUCCESS: 'Edit user details successfully',
  INVITE_USER_EDIT_ERROR: 'Failed to update the changes for this user.',
  REMOVE_USER_ERROR: 'An error occurred when trying to remove the user. Please try again.',
  DEVICE_RENAME_SUCCESS: 'The device was renamed successfully',
  DEVICE_RENAME_ERROR: 'An error occurred when renaming the device. Please try again.',
  DEVICE_RENAME_INCORRECT:
    'Device name cannot exceed 256 characters or have any special characters (!@#$%). Please try again.',
  DEVICE_NAME_EMPTY: 'The device name cannot be empty.',
  ALERT_LOCATION_SUCCESS: 'Alert location was successfully updated',
  VIDEO_PLAYBACK_ERROR: 'There was an error with video playback. Please try again.',
  FAVORITE_UPDATE: 'Favorite successfully updated',
  FAVORITE_UPDATE_ERROR: 'Could not save the changes to the selected Activity.',
  ALERT_TYPES_UPDATE: 'Alert types successfully updated',
  ALERT_TYPES_UPDATE_ERROR: 'Failed to update the changes to the Alert Settings.',
  ACTIVITY_REMOVE: 'Activity removed successfully.',
  ACTIVITY_DELETE_ERROR: 'Could not delete the selected Activity.',
  RECORD_VIDEO_START_ERROR: 'Video recording failed to start.',
  RECORD_VIDEO_STOP_ERROR: 'Video recording failed to stop.',
  DEVICE_REMOVE: 'Device removed successfully.',
  SOUND_TRIGGER: 'This will turn on the device microphone.',
  MICROPHONE_TRIGGER: 'This will disable the Sound Activity Trigger',
  STREAM_SUPPORT:
    'To ensure your activity videos are recorded please ensure that you plug in a flash drive with adequate space or upgrade your subscription.',
};

export default textConstants;
