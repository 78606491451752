import React from 'react';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { toolTip } from '../../ui/Icons/Icons';
import * as profileActionCreator from '../../Profile/store/action-creator';
import classes from './TableTooltip.module.css';
import planInfoKeys from '../planInfoMapping';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: 'white',
        color: 'black',
        fontSize: '10px',
        boxShadow: '0 0 10px #f1f1f1',
        border: '1px solid #f1f1f1',
      },
      arrow: {
        color: '#FFF',
      },
    },
  },
});
const TableTooltip = (props) => {
  const { content, onSelectedMenu } = props;
  const clone = (({ ...others }) => others)(content);
  const contentKeys = Object.keys(clone);

  const handleFullPlanComp = (menu) => {
    onSelectedMenu(menu);
  };

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <Tooltip
          title={
            <div className={classes.tooltipTitle}>
              <div className={classes.tooltipText}>{`${content.PlanName} plan`}</div>
              <div className={classes.greyText}>{content.PlanDescription}</div>
              {contentKeys.map((eachKey) => (
                <div className={classes.displayFlex} key={eachKey}>
                  <div className={classes.plusIcon}>+</div>
                  <div>{planInfoKeys[eachKey]}</div>
                </div>
              ))}
              <div
                className={classes.fullPlanCompLink}
                onClick={() => handleFullPlanComp(2)}
                role="presentation"
              >
                Full plan comparison &gt;
              </div>
            </div>
          }
          interactive
          arrow
        >
          <img src={toolTip} alt="i" className={classes.tooltipIcon} />
        </Tooltip>
      </MuiThemeProvider>
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  onSelectedMenu: (data) => dispatch(profileActionCreator.setProfileMenu(data)),
});

const SubscTooltip = connect(null, mapDispatchToProps)(TableTooltip);
export { SubscTooltip as TableTooltip };

export const SmartLifePlansTooltip = (props) => {
  const { content } = props;

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <Tooltip title={<div className={classes.smpTooltipTitle}>{content}</div>} interactive arrow>
          <img src={toolTip} alt=" " className={classes.tooltipIcon} />
        </Tooltip>
      </MuiThemeProvider>
    </>
  );
};
