import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import classes from './Instruction.module.css';
import Instrction1 from '../../assets/images/instruction1.png';
import Instrction2 from '../../assets/images/instruction2.png';
import Instrction3 from '../../assets/images/instruction3.png';

const Instruction = () => {
  const instructionTextHeaderClassList = ['Font15', classes.InstructionTextHeader].join(' ');
  const instrcutionClassList = ['Font15', classes.InstructionText].join(' ');
  const stepClassList = ['GreyColor', 'Font13', classes.Step].join(' ');
  const instructionBottomClassList = ['GreyColor', 'Font13'].join(' ');

  return (
    <>
      <Container>
        <div className={instructionTextHeaderClassList}>Get started in the BodyGuardz app!</div>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-around"
          className={classes.InstructionContainer}
        >
          <Grid item>
            <div className={stepClassList}>STEP 1</div>
            <div className={instrcutionClassList}>Download the app</div>
            <img src={Instrction1} alt="Download the app" />
            <div>
              <span className={instructionBottomClassList}>Search for the</span>
              <span className={classes.BoldText}> BodyGuardz </span>
              <span className={instructionBottomClassList}>
                App
                <br />
                in Google Play or Appstore
              </span>
            </div>
          </Grid>
          <Grid item>
            <div className={stepClassList}>STEP 2</div>
            <div className={instrcutionClassList}>Scan your device</div>
            <img src={Instrction2} alt="Download the app" />
            <div>
              <span className={instructionBottomClassList}>Scan the</span>
              <span className={classes.BoldText}> QR </span>
              <span className={instructionBottomClassList}>
                code to
                <br />
                add your device
              </span>
            </div>
          </Grid>
          <Grid item>
            <div className={stepClassList}>STEP 3</div>
            <div className={instrcutionClassList}>That’s it!</div>
            <img src={Instrction3} alt="Download the app" />
            <div>
              <span className={instructionBottomClassList}>
                Grab some coffee and wait for the
                <br />
                device to finish the setup.
              </span>
              <span className={classes.BoldText}> Enjoy! </span>
            </div>
          </Grid>
        </Grid>
        <div>
          <span className={classes.TrubleShooting}>Troubleshooting</span>
        </div>
      </Container>
    </>
  );
};

export default Instruction;
