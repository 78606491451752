import React from 'react';
import ButtonElem from '../Button/Button';
import { closeWhiteIcon } from '../Icons/Icons';

const ActionDialog = props => {
  const { dialogConfig } = props;
  const buttonText = dialogConfig.btnText || 'Remove';
  const buttonColor = dialogConfig.btnColor || 'RedButton';
  const buttonCancel = dialogConfig.btnCancel || 'Cancel';
  const buttonCancelWidth = dialogConfig.cancelBtnWidth || 100;
  const buttonDoneWidth = dialogConfig.doneBtnWidth || 100;

  const btnHandler = evt => dialogConfig.btnClicked(evt);

  return (
    <>
      <img
        src={closeWhiteIcon}
        alt="close"
        className="Close"
        role="presentation"
        onClick={() => btnHandler(false)}
      />
      <ButtonElem
        btnColor={buttonColor}
        value={buttonText}
        style={{ width: buttonDoneWidth, marginRight: 20 }}
        clicked={() => btnHandler(true)}
        isbtndisabled={!dialogConfig.disabled}
      />
      <ButtonElem
        btnColor="LightGreyButton"
        value={buttonCancel}
        style={{ width: buttonCancelWidth }}
        clicked={() => btnHandler(false)}
      />
    </>
  );
};

export default ActionDialog;
