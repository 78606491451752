// REQUIREMENT : 4.1.7 Streaming Video / Live Video (Over Door Camera and Home/Travel Camera)

import React, { PureComponent } from 'react';
import OT from '@opentok/client';
import { connect } from 'react-redux';
import * as actionCreator from './store/action-creator';
import TokBoxSubscriber from './TokBoxSubscriber';
import TokBoxPublisher from './TokBoxPublisher';
import CustomDialog from '../ui/CustomDialog/CustomDialog';
import InitializeCamera from '../DeviceList/Device/DeviceDisplay/InitializeCamera';

class TokBox extends PureComponent {
  constructor(props) {
    super(props);
    this.session = null;
    this.state = {
      commonError: false,
      commonErrorMsg: null,
      error: null,
      isInitialized: false,
    };
  }

  componentDidMount() {
    const { tokboxSessionId, user, deviceInfo } = this.props;
    if (user.userEmail && deviceInfo && tokboxSessionId) {
      const params = {
        thing_name: deviceInfo.DeviceId,
        email: user.userEmail,
        session_id: tokboxSessionId,
      };
      this.getTokenForTokbox(params);
    }
  }

  componentWillUnmount() {
    const { onResetSession } = this.props;
    if (this.session) {
      this.session.off();
      this.session.disconnect();
    }
    onResetSession();
  }

  getTokenForTokbox = (params) => {
    const { onGetToken } = this.props;
    onGetToken(params).then(
      (resp) => {
        if (resp.errorMsg) {
          this.setState({ commonError: true });
          this.setState({ commonErrorMsg: resp.errorMsg });
        } else {
          this.initializationSession(resp.tokboxToken);
        }
      },
      (err) => {
        this.setState({ commonError: true });
        this.setState({ commonErrorMsg: err });
      }
    );
  };

  initializationSession = (tokenVal) => {
    const { tokboxConfig, tokboxSessionId } = this.props;
    this.session = OT.initSession(tokboxConfig.apiKey, tokboxSessionId);
    this.connectSession(tokenVal);
  };

  connectSession = (sessionToken) => {
    this.session.connect(sessionToken, (error) => {
      if (error) {
        const msg = `Failed to Connect: ${error.message}`;
        this.setState({ error: msg });
        this.setState({ isInitialized: true });
      } else {
        this.setState({ isInitialized: true });
      }
    });
  };

  render() {
    const { commonError, commonErrorMsg, error, isInitialized } = this.state;
    const { tokboxConfig, deviceInfo } = this.props;
    const errorDialogConfig = {
      open: commonError,
      textAlign: 'left',
      btnClicked: () => this.setState({ commonError: false }),
    };

    return (
      <>
        {isInitialized ? (
          error ? (
            <div>{error}</div>
          ) : (
            <>
              <TokBoxSubscriber
                session={this.session}
                screenShotStatus={tokboxConfig.takeScreenShot}
                deviceType={deviceInfo.DeviceType}
              />
              <TokBoxPublisher session={this.session} />
            </>
          )
        ) : (
          <InitializeCamera msg="Initializing camera..." />
        )}
        {commonError && (
          <CustomDialog dialogConfig={errorDialogConfig}>
            <div>{commonErrorMsg}</div>
          </CustomDialog>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  tokboxConfig: state.tokboxReducer,
});

const mapDispatchToProps = (dispatch) => ({
  onGetToken: (data) => dispatch(actionCreator.getToken(data)),
  onResetSession: () => dispatch(actionCreator.resetSession()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TokBox);
