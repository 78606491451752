export const SET_DEVICE_IDS = 'SET_DEVICE_IDS';
export const GET_EVENT_LIST = 'GET_EVENT_LIST';
export const SELECTED_EVENT = 'SELECTED_EVENT';
export const SET_EVENT_VIDEO_STREAM = 'SET_EVENT_VIDEO_STREAM';
export const SET_ALL_EVENTS = 'SET_ALL_EVENTS';
export const DELETE_VIDEO = 'DELETE_VIDEO';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const RESET_FILTER = 'RESET_FILTER';
export const RESET_DATE_FILTER = 'RESET_DATE_FILTER';
export const GET_DEVICE_PLAN_INFO = 'GET_DEVICE_PLAN_INFO';
export const SET_IS_ALL_ACTIVITY = 'SET_IS_ALL_ACTIVITY';
export const SET_STARRED = 'SET_STARRED';
export const SET_FILTER_ICON_ACTIVE = 'SET_FILTER_ICON_ACTIVE';
export const SET_CURRENT_ACTIVITY_DELETED = 'SET_CURRENT_ACTIVITY_DELETED';
export const SET_ACTIVE_DEVICES = 'SET_ACTIVE_DEVICES';
export const SET_FILTER_DATA = 'SET_FILTER_DATA';
export const SET_ACTIVITY_UPDATED = 'SET_ACTIVITY_UPDATED';
export const SET_NO_ACTIVITY_VIDEO = 'SET_NO_ACTIVITY_VIDEO';
