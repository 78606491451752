import React from 'react';
import * as ROUTES from '../constants/routes';
import { AuthContext } from '../Providers/AuthProvider';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export default function TermsOfServiceDialog() {
  const { agreeToTerms, signOut } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);
  const [agreedAt, setAgreedAt] = React.useState(null);

  async function handleAgree() {
    try {
      setLoading(true);
      await agreeToTerms(agreedAt);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Terms of Service & Privacy Policy'}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={1}>
            <Checkbox
              checked={!!agreedAt}
              onChange={(e, v) => setAgreedAt(v ? new Date() : null)}
              color="primary"
            />
          </Grid>
          <Grid item xs={11} style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="caption">
              By checking this box you agree to{' '}
              <a href={ROUTES.TERMS_OF_SERVICE} target="_blank" rel="noopener noreferrer">
                Our terms of Service
              </a>{' '}
              and{' '}
              <a href={ROUTES.PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={signOut} color="primary">
          Disagree
        </Button>
        {loading ? (
          <CircularProgress />
        ) : (
          <Button loading={loading} disabled={!agreedAt} onClick={handleAgree} color="primary">
            {'Agree & Continue'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
