import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

const SubscriptionCheckbox = (props) => {
  const { isChecked, value, onCheckBoxChange } = props;
  return (
    <Checkbox
      style={{ color: '#FFD200' }}
      checked={isChecked}
      value={value}
      onChange={onCheckBoxChange}
    />
  );
};

export default SubscriptionCheckbox;
