import React from 'react';
import { getDateTime } from '../../common/utils';
import './VideoPlayer.css';

const CaptureScreenshot = props => {
  const {
    captured, resetCapture, videoWidth, videoHeight
  } = props;

  if (captured) {
    const video = document.querySelector('video');
    const canvas = document.querySelector('#screenshot_canvas');
    if (video && canvas) {
      canvas.width = videoWidth;
      canvas.height = videoHeight;
      const context = canvas.getContext('2d');
      context.fillRect(0, 0, videoWidth, videoHeight);
      context.drawImage(video, 0, 0, videoWidth, videoHeight);

      const img = document.createElement('img');
      img.setAttribute('crossOrigin', 'anonymous');
      img.setAttribute('src', canvas.toDataURL());

      const link = document.createElement('a');
      link.target = '_blank';
      link.download = `SmartLife-${getDateTime()}.png`;
      link.href = img.src;
      link.click();
      link.remove();
      img.remove();
    }
    setTimeout(() => {
      resetCapture(false);
    });
  }

  return <canvas id="screenshot_canvas" />;
};

export default CaptureScreenshot;
