// REQUIREMENT: 4.1.16 'Home' Group Management

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TypographyElem from '../ui/Typography/Typography';
import CustomDialog from '../ui/CustomDialog/CustomDialog';
import LoadingSpinner from '../ui/LoadingSpinner/LoadingSpinner';
import * as actionCreator from './store/action-creator';
import Homecard from './Homecard';
import classes from './ManageHomes.module.css';
import SubHeader from '../ui/SubHeader';
import ScrollArea from '../ui/ScrollBar/ScrollArea';
import { SET_UP_HOME } from '../../constants/routes';
import { addHomeIcon } from '../ui/Icons/Icons';
import Notification from '../ui/Notification/Notification';
import ErrorCodes from '../common/ErrorCodes';
import msgConstant from '../common/textConstants';
import { AuthContext } from '../../Providers/AuthProvider';

const ManageHomes = (props) => {
  const { user } = React.useContext(AuthContext);
  const emailId = user.userEmail;
  const [spinner, setSpinner] = useState(true);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [homeDetails, setHomeDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [commonError, setCommonError] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState(null);
  const [empty, setEmpty] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    // Web: [#84859] Locate me option is not working while adding new home.
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const lati = position.coords.latitude;
        setLatitude(lati);
        const longi = position.coords.longitude;
        setLongitude(longi);
      },
      (err) => {
        console.log('Google location error ', err);
      },
      {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0,
      }
    );
  }, []);

  const toggleSpinner = (data) => setSpinner(data);

  const commonErrorBtnHandler = () => setCommonError(!commonError);

  const commonErrorDialogConfig = {
    open: commonError,
    textAlign: 'left',
    btnClicked: commonErrorBtnHandler,
  };

  useEffect(() => {
    actionCreator
      .getHomes(emailId)
      .then((resp) => {
        toggleSpinner(false);
        if (resp.data.ErrorCode === 'err_0') {
          setHomeDetails(resp.data.ResponseData);
          dispatch(actionCreator.setHomeInfo(resp.data.ResponseData));
        } else if (resp.data.ErrorCode === 'err_202') {
          setEmpty(!empty);
        } else {
          setCommonError(!commonError);
          setApiErrorMessage(ErrorCodes[resp.data.ErrorCode]);
        }
      })
      .catch(() => {
        toggleSpinner(false);
        setCommonError(!commonError);
        setApiErrorMessage(`${msgConstant.COMMON_ERROR}`);
      });
  }, []);

  const listItems = homeDetails.map((d) => (
    <Grid item key={d.home_id} xs={4} style={{ paddingTop: '5px' }}>
      <Homecard data={d} name={d.home_name} />
    </Grid>
  ));

  // Bug [#84302] - In Edge browser not able to click on Add a new home.
  const geoLocationSuccess = () => {
    props.history.push(SET_UP_HOME, {
      lati: latitude,
      longi: longitude,
    });
  };

  const geoLocationError = (err) => {
    setOpen(true);
    let errCodeMsg = '';
    switch (err.code) {
      case err.PERMISSION_DENIED:
        errCodeMsg = 'User denied the request for Geolocation.';
        break;
      case err.POSITION_UNAVAILABLE:
        errCodeMsg = 'Location information is unavailable.';
        break;
      case err.TIMEOUT:
        errCodeMsg = 'The request to get user location timed out.';
        break;
      case err.UNKNOWN_ERROR:
        errCodeMsg = 'An unknown error occurred.';
        break;
      default:
        break;
    }
    setErrorMsg(errCodeMsg);
    props.history.push(SET_UP_HOME, {
      lati: 40.422451,
      longi: -111.883324,
    });
  };

  const newHomeHandler = () => {
    // Web: [#84859] Locate me option is not working while adding new home.
    const options = {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    };
    navigator.geolocation.getCurrentPosition(geoLocationSuccess, geoLocationError, options);
  };

  const handleClose = () => setOpen(false);
  const notificationConfig = {
    open,
    type: 'error',
    onClose: handleClose,
  };

  const renderHomes = () => (
    <>
      <div style={{ margin: '1em' }}>
        {homeDetails ? <Grid container>{listItems}</Grid> : 'No Home List'}
      </div>
    </>
  );

  const previousPageHandler = () => props.history.goBack();

  return (
    <>
      <LoadingSpinner spinnerConfig={{ showSpinner: spinner }} />

      <ScrollArea>
        <div className={classes.manageHomePadding}>
          <SubHeader {...props} clicked={previousPageHandler} />
          <TypographyElem content="Manage Homes" />
          <div className={classes.addNewHome}>
            <span onClick={newHomeHandler} role="presentation" className="CursorPointer">
              <img src={addHomeIcon} alt="+" />
              <span>Add a New Home</span>
            </span>
          </div>
          {empty ? <div style={{ margin: '50px' }}>No homes to display</div> : renderHomes()}
        </div>
      </ScrollArea>
      {open && errorMsg && <Notification config={notificationConfig}>{errorMsg}</Notification>}
      {commonError && (
        <CustomDialog dialogConfig={commonErrorDialogConfig}>
          <div>{apiErrorMessage}</div>
        </CustomDialog>
      )}
    </>
  );
};

export default ManageHomes;
