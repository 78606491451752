// REQUIREMENT : 4.1.6 CAMERA CONTROL
import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import Axios from 'axios';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import DeviceSettings from './DeviceSettings/DeviceSettings';
import SruDisplay from './SRU_Display/SRU_Display';
import DeviceDisplay from './DeviceDisplay/DeviceDisplay';
import * as actionCreator from '../store/action-creator';
import CustomDialog from '../../ui/CustomDialog/CustomDialog';
import { DEVICE, HOME } from '../../../constants/routes';
import ActivityZone from '../../Cropper/ActivityZone';
import msgConstant from '../../common/textConstants';
import ErrorBoundary from '../../../ErrorBoundary/ErrorBoundary';
import * as homeActionCreator from '../../ManageHomes/store/action-creator';
import classes from './Device.module.css';
import { AuthContext } from '../../../Providers/AuthProvider';

const Device = (props) => {
  const { user } = React.useContext(AuthContext);

  const {
    deviceInfo,
    deviceLists,
    onSetFMRadioStaus,
    dispActivityZone,
    onSetMQTTInfo,
    onResetMQTTData,
    onResetMQTTUpdated,
  } = props;
  const [displayModal, setDisplayModal] = useState(false);
  const [MQTTError, SetmMQTTError] = useState(false);

  useEffect(() => {
    const deviceId = props.match.params.id;
    const device = deviceLists?.deviceData?.DeviceList.find((e) => e.DeviceId === deviceId);
    props.onSetDeviceInfo(device);

    ReactGA.pageview(DEVICE);
    onSetMQTTInfo(deviceInfo?.DeviceId);
  }, [deviceInfo]);

  useEffect(() => {
    Axios.get(`users?email=${user.userEmail}&action=listhomes`)
      .then((resp) => {
        const selectedHome = resp.data.ResponseData.find(
          (hme) => hme.home_id === deviceLists.deviceData?.HomeId
        );
        props.onSelectedHome(selectedHome);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(
    () => () => {
      onSetFMRadioStaus(0);
      actionCreator.unsubscribe();
      onResetMQTTData();
      onResetMQTTUpdated();
    },
    []
  );

  const btnHandler = () => {
    setDisplayModal(false);
    props.history.push(HOME);
  };

  const dialogConfig = {
    open: displayModal,
    type: 'message',
    btnClicked: (status) => btnHandler(status),
  };

  const renderSRUDisplay = () => (
    <Grid item xs={9}>
      <div className={classes.device}>
        <SruDisplay name={deviceInfo?.DisplayName} />
      </div>
    </Grid>
  );

  const MQTTErrorHandler = () => SetmMQTTError(true);

  const renderDeviceDisplay = () => (
    <Grid item xs={9} className={classes.deviceDetails}>
      {dispActivityZone ? (
        <ActivityZone name={deviceInfo?.DisplayName} />
      ) : (
        <DeviceDisplay hasMQTTError={MQTTErrorHandler} {...props} />
      )}
    </Grid>
  );

  const renderDeviceSetting = () => (
    <Grid item xs={3}>
      <DeviceSettings {...props} />
    </Grid>
  );

  const renderDeviceSettingError = () => (
    <Grid item xs={3} style={{ paddingTop: '4em' }}>
      Unable to fetch the device settings. Please try again.
    </Grid>
  );

  if (!deviceInfo) return <div>Loading</div>;

  return (
    <ErrorBoundary>
      <div>
        <Grid container>
          {deviceInfo?.DeviceType === 'SmartReadinessUnit'
            ? renderSRUDisplay()
            : renderDeviceDisplay()}
          {MQTTError ? renderDeviceSettingError() : renderDeviceSetting()}
        </Grid>
      </div>
      {displayModal && (
        <CustomDialog dialogConfig={dialogConfig}>
          <div>{msgConstant.SUBSCRIPTION_EXPIRY}</div>
        </CustomDialog>
      )}
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => ({
  deviceInfo: state.deviceReducer.deviceInfo,
  deviceLists: state.deviceReducer.deviceResp,
  dispActivityZone: state.deviceReducer.dispActivityZone,
});

const mapDispatchToProps = (dispatch) => ({
  onSelectedHome: (info) => dispatch(homeActionCreator.selectedHome(info)),
  onSetFMRadioStaus: (status) => dispatch(actionCreator.setFMRadioStatus(status)),
  onSetMQTTInfo: (data) => dispatch(actionCreator.setMqttInfo(data)),
  onResetMQTTData: () => dispatch(actionCreator.resetMQTTInfo()),
  onResetMQTTUpdated: () => dispatch(actionCreator.setMQTTUpdated(false)),
  onSetDeviceInfo: (data) => dispatch(actionCreator.setDeviceInfo(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Device));
