import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import BgImage from '../BgImage/BgImage';
import verticalLogo from '../../assets/images/VerticalLogo.png';
import ButtonElem from '../ui/Button/Button';
import TypographyElem from '../ui/Typography/Typography';
import { HOME } from '../../constants/routes';
import classes from './InviteUserPage.module.css';
import axios from 'axios';
import { AuthContext } from '../../Providers/AuthProvider';

const InviteUserPage = (props) => {
  const { user } = React.useContext(AuthContext);

  React.useEffect(() => {
    (async () => {
      try {
        if (!user) return;

        const confirmationCode = props.match.params.confirmationCode;

        const body = {
          action: 'confirm_share_device',
          data: {
            user: user.userEmail,
            confirmation_code: confirmationCode,
          },
        };

        const data = await axios.post('users', body);
        console.log('success', data);
      } catch (error) {
        console.log('something went wrong :(', error);
      }
    })();
  }, [user]);

  return (
    <>
      <BgImage />
      <div align="center" style={{ paddingTop: 35 }}>
        <img src={verticalLogo} alt="BGZ Logo" width="71" />
      </div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ height: 'calc(100vh - 8.5em)' }}
      >
        <Box>
          <Card className={['noBorderRadius', classes.CardContainer].join(' ')}>
            <CardContent>
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <TypographyElem content="Invitation Accepted" />
                </Grid>
                <Grid item>
                  <div className={classes.inviteText}>
                    <p>Just create an account or sign into your</p>
                    <p>existing account to gain access.</p>
                  </div>
                </Grid>
                <Grid item alignItems="center" justify="center" container>
                  <ButtonElem
                    btnColor="BlackButton"
                    value="Continue"
                    btnType="submit"
                    style={{ width: 172, margin: '2px' }}
                    clicked={() => props.history.push(HOME)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default InviteUserPage;
