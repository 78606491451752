import React, { Component } from 'react';
import { getDateTime } from '../common/utils';
import PanSlider from './PanSlider/PanSlider';
import InitializeCamera from '../DeviceList/Device/DeviceDisplay/InitializeCamera';
import classes from './PanSlider/PanSlider.module.css';

class TokBoxSubscriber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isStreamCreated: false
    };
    this.subscriber = null;
  }

  componentDidMount() {
    this.streamCreated();
  }

  componentDidUpdate() {
    const { screenShotStatus } = this.props;
    if (screenShotStatus && this.subscriber) {
      const imgData = this.subscriber.getImgData();
      const img = document.createElement('img');
      img.setAttribute('src', `data:image/png;base64,${imgData}`);
      const link = document.createElement('a');
      link.download = `SmartLife-${getDateTime()}.png`;
      link.href = img.src;
      link.click();
      link.remove();
      img.remove();
    }
  }

  streamCreated = () => {
    const { session } = this.props;
    const subProperties = {
      insertMode: 'replace',
      width: '100%',
      height: '100%',
      style: { nameDisplayMode: 'off' }
    };
    session.on('streamCreated', event => {
      event.preventDefault();
      this.setState({ isStreamCreated: true });
      this.subscriber = session.subscribe(event.stream, 'subscriber_container', subProperties,
        error => {
          if (error) {
            const msg = `Failed to Subscribe: ${error.message}`;
            this.handleError(msg);
          }
        });
    });
  }

  handleError = errorMsg => {
    const subscribeElem = document.querySelector('#subscriber_container');
    if (subscribeElem) {
      subscribeElem.innerHTML = '';
    }
    this.setState({ error: errorMsg });
  }

  render() {
    const { error, isStreamCreated } = this.state;
    const { deviceType } = this.props;
    return (
      <>
        {error ? <div>{error}</div> : null}
        {
          isStreamCreated
            ? <div id="subscriber_container" />
            : <InitializeCamera msg="Initializing camera..." />
        }
        {
          deviceType === 'HomeAndTravelCamera'
          && (
            <div className={classes.videoSliderWrap} id="pan_slider">
              <PanSlider />
            </div>
          )
        }
      </>
    );
  }
}

export default TokBoxSubscriber;
