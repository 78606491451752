import React from 'react';
import SensitivitySlider from '../SensitivitySlider';
import classes from '../../DeviceSettings.module.css';

const FMVolumeSlider = props => {
  const { volume, changeVolume } = props;
  const handleFMVolume = (event, val) => changeVolume({ fmVolume: val });

  return (
    <>
      <div className={classes.fmVolumeSlider}>
        <SensitivitySlider handleClicked={handleFMVolume} sliderVal={volume} max={15} />
        <div className={classes.fMVolumeText}>Volume</div>
      </div>
    </>
  );
};

export default FMVolumeSlider;
