// REQUIREMENT : 4.1.10 Stored Video Management

import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import ActivityList from '../ActivityList/ActivityList';
import ActivityFilter from '../ActivityFilter/ActivityFilter';
import ActivityHeader from '../ActivityHeader/ActivityHeader';

const RightContentDetails = () => {
  const [firstTimeLoaded, setFirstTimeLoaded] = useState(true);

  const filterIconActive = useSelector((state) => state.activityReducer.filterIconActive);

  const filterToggleHandler = () => setFirstTimeLoaded(false);

  return (
    <Grid item xs={3} style={{ marginLeft: 15 }}>
      <ActivityHeader filterToggle={filterToggleHandler} />
      {filterIconActive ? <ActivityFilter /> : <ActivityList firstTimeLoaded={firstTimeLoaded} />}
    </Grid>
  );
};

export default RightContentDetails;
