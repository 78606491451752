import * as actionTypes from './action-types';

const intialState = {
  deviceIdList: null,
  isAllActivity: false,
  eventsList: null,
  selectedEvent: null,
  allEvents: null,
  /* [#84643] Activity page is crashing in webapp */
  devicePlanInfo: null,
  deleteVideo: null,
  filter: {
    motion: 1,
    person: 1,
    sound: 1,
    recording: 1,
    isFavourite: 0,
    deletionSoon: 0,
    date: null
  },
  starred: 0,
  filterIconActive: false,
  activeDevices: null,
  filterData: null,
  activityUpdated: false,
  noActivityVideo: false
};

const updateObject = (prevState, updatedValues) => (
  {
    ...prevState,
    ...updatedValues
  }
);

const reducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DEVICE_IDS:
      return updateObject(state, { deviceIdList: action.deviceIds });
    case actionTypes.GET_EVENT_LIST:
      return updateObject(state, { eventsList: action.evtList });
    case actionTypes.SELECTED_EVENT:
      return updateObject(state, { selectedEvent: action.selectedEvt });
    case actionTypes.SET_ALL_EVENTS:
      return updateObject(state, { allEvents: action.allEvts });
    case actionTypes.DELETE_VIDEO:
      return updateObject(state, { deleteVideo: action.deleteVideoStatus });
    case actionTypes.UPDATE_FILTER:
      return updateObject(state, { filter: action.filterStatus });
    case actionTypes.RESET_FILTER:
      return updateObject(state, { filter: intialState.filter });
    case actionTypes.GET_DEVICE_PLAN_INFO:
      return updateObject(state, { devicePlanInfo: action.devPlanDetails });
    case actionTypes.SET_IS_ALL_ACTIVITY:
      return updateObject(state, { isAllActivity: action.isAllActivity });
    case actionTypes.SET_STARRED:
      return updateObject(state, { starred: action.starred });
    case actionTypes.SET_FILTER_ICON_ACTIVE:
      return updateObject(state, { filterIconActive: action.filterIconActive });
    case actionTypes.SET_ACTIVE_DEVICES:
      return updateObject(state, { activeDevices: action.activeDevices });
    case actionTypes.SET_FILTER_DATA:
      return updateObject(state, { filterData: action.filterData });
    case actionTypes.SET_ACTIVITY_UPDATED:
      return updateObject(state, { activityUpdated: action.activityUpdated });
    case actionTypes.SET_NO_ACTIVITY_VIDEO:
      return updateObject(state, { noActivityVideo: action.noActivityVideo });
    default: return state;
  }
};

export default reducer;
