import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector, useDispatch } from 'react-redux';
import { selectedEvent, setIsAllActivity } from '../store/action-creator';
import ActivityItemLeftContent from './ActivityItemLeftContent';
import ActivityItemRightContent from './ActivityItemRightContent';
import { timeStampToDateFormat } from '../../common/utils';
import ActivityItemDelete from './ActivityItemDelete';
import ClipsDeletedSoon from './ClipsDeletedSoon';
import classes from './ActivityList.module.css';

const ActivityListItem = (props) => {
  const { event } = props;

  const dispatch = useDispatch();
  const currentEvent = useSelector((state) => state.activityReducer.selectedEvent);

  const dateGroupHeader = (evt) => {
    const rowID = `actDay_${evt}`;
    const divElem = (
      <div className={classes.activityDay} id={rowID}>
        {timeStampToDateFormat(evt, null, true)}
      </div>
    );
    return divElem;
  };

  const selectedEventHandler = (data) => {
    dispatch(setIsAllActivity(false));
    dispatch(selectedEvent(data));
  };

  const activeEvent = (list) => {
    let isActive = <span />;
    if (currentEvent?.ActivityID === list.ActivityID) {
      isActive = <div className={classes.activeItem} />;
    }
    return isActive;
  };

  const getNestedList = (nestedList, dateGroup) =>
    nestedList.map((list) => {
      const eventRowID = `${list.DeviceID}_${list.ActivityID}_${dateGroup}`;
      const actID = `actID_${list.ActivityID}`;
      return (
        <Grid item key={eventRowID} className={classes.activityListContainer} id={actID}>
          <div
            className={classes.activityItem}
            onClick={() => selectedEventHandler(list)}
            role="presentation"
          >
            {activeEvent(list)}
            <ActivityItemLeftContent list={list} />
            <ActivityItemRightContent list={list} />
          </div>
          <ActivityItemDelete list={list} />
        </Grid>
      );
    });

  return (
    <>
      <ClipsDeletedSoon event={event.Activities} />
      {dateGroupHeader(event.DateGroup)}
      <Grid container direction="column">
        {getNestedList(event.Activities, event.DateGroup)}
      </Grid>
    </>
  );
};

export default React.memo(ActivityListItem);
