import { combineReducers } from 'redux';

import deviceReducer from './components/DeviceList/store/reducer';
import profileReducer from './components/Profile/store/reducer';
import activityReducer from './components/Activity/store/reducer';
import tokboxReducer from './components/TokBox/store/reducer';
import cropBoxReducer from './components/Cropper/store/reducer';
import homeListReducer from './components/ManageHomes/store/reducer';
import subscriptionReducer from './components/ChargebeeSubscription/store/reducer';
import homePageReducer from './components/Home/store/reducer';
import notificationReducer from './components/UserNotifications/store/reducer';

const appReducer = combineReducers({
  deviceReducer,
  profileReducer,
  activityReducer,
  tokboxReducer,
  cropBoxReducer,
  homeListReducer,
  subscriptionReducer,
  homePageReducer,
  notificationReducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
