import Axios from 'axios';
import * as actionTypes from './action-types';

export const setDeviceToken = deviceToken => (
  {
    type: actionTypes.SET_DEVICE_TOKEN,
    deviceToken
  }
);

export const addOrUpdateDeviceToken = data => {
  const promise = new Promise((resolve, reject) => {
    Axios.post('devices', data)
      .then(resp => {
        if (resp.data.ResponseCode === 200) {
          resolve(resp);
        }
      })
      .catch(err => reject(err));
  });
  return promise;
};

export const getDeviceInfo = data => {
  console.log('Device data received', data);
  const promise = new Promise((resolve, reject) => {
    Axios.post('devices', data)
      .then(resp => {
        if (resp.data.ResponseCode === 200) {
          resolve(resp);
        }
      })
      .catch(err => reject(err));
  });
  return promise;
};

export const setTokenStatus = data => (
  {
    type: actionTypes.SET_TOKEN_STATUS,
    isTokenSent: data
  }
);

export const setNotificationStatus = data => {
  console.log('Notification Status', data);
  return (
    {
      type: actionTypes.SET_NOTIFICATION_STATUS,
      notificationStatus: data
    }
  );
};

export const setNotificationPayload = data => {
  console.log('Notification payload', data);
  return (
    {
      type: actionTypes.SET_NOTIFICATION_PAYLOAD,
      notificationPayload: data
    }
  );
};
