// REQUIREMENT: 4.1.18 Subscription and Payment Management

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ButtonElem from '../ui/Button/Button';
import PaymentWidget from './PaymentWidget/PaymentWidget';
import LoadingSpinner from '../ui/LoadingSpinner/LoadingSpinner';
import SubscriptionCheckbox from './SubscriptionCheckbox';
import { TableTooltip } from './TableTooltip/TableTooltip';
import { tickMark } from '../ui/Icons/Icons';
import * as subsActionCreator from './store/action-creator';
import * as profileActionCreator from '../Profile/store/action-creator';
import homeTravelCam from '../../assets/device/HomeTravelCam.png';
import OverDoorCam from '../../assets/device/OverDoorCam.png';
import SRUdev from '../../assets/device/sru_display.png';
import classes from './subscription.module.css';
import ScrollArea from '../ui/ScrollBar/ScrollArea';
import SumOfEachColumn from './SumOfEachColumn';
import EditSubscription from './EditSubscription';
import CustomDialog from '../ui/CustomDialog/CustomDialog';
import ViewPlans from './ViewPlans';
import ErrorCodes from '../common/ErrorCodes';
import Notification from '../ui/Notification/Notification';
import { AuthContext } from '../../Providers/AuthProvider';

let metadata = [];
let uniqueArr = [];
let monthlyPlanList = [];
let annualPlanList = [];
let eachColSumMonthly = [];
let eachColSumAnnual = [];
// let colSumAnnual = [];
let colSumMonthly = [];
let monthlyTotal = 0;
const annualTotal = 0;

const SubscriptionTable = (props) => {
  const {
    subscriptionData,
    handleProfileMenu,
    isPaymentComplete,
    getUserSubscription,
    updateSubscription,
    apiError,
    retrieveCardDetils,
  } = props;
  const { user } = React.useContext(AuthContext);
  const userEmail = user.userEmail;
  const [isEditPlan, onEditPlansClick] = useState(false);
  const [planList, setPlanList] = useState([]);
  const [activePlanDetails, setActivePlandetails] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [tableCheckboxList, setTableCheckboxList] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [cardModal, setCardModal] = useState(false);
  const [currentTotal, setCurrentTotal] = useState();
  const [subTotalMonthly, setSubTotalMonthly] = useState();
  const [subTotalAnnual, setSubTotalAnnual] = useState();
  const [planDuration, selectedDuration] = useState('');
  const [deviceMappingList, setDeviceMappingList] = useState([]);
  const [initialDevMapping, setInitialDevMapping] = useState([]);
  const [DeviceIdsList, setDeviceIdList] = useState([]);
  const [planChanged, setPlanChanged] = useState(0);
  const [onlyPlanChanged, setonlyPlanChanged] = useState(0);
  const [selectedPlanInfo, setSelectedPlanInfo] = useState([]);
  const [columnLength, setColumnLength] = useState([]);
  const [columnSum, setColumnSum] = useState([]);
  const [columnSumMonthly, setColSumMonthly] = useState([]);
  // const [columnSumAnnual, setColSumAnnual] = useState([]);
  const [annualPlanDetails, setAnnualPlans] = useState([]);
  const [monthlyPlanDetails, setMonthlyPlans] = useState([]);
  const [errorModal, setErrorModal] = useState(false);
  const [manageCard, setManageCard] = useState(false);
  const [commonError, setCommonError] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [headerColWidth, setHeaderColWidth] = useState('');
  const [bodyColWidth, setBodyColWidth] = useState('');
  const [isArchived, setIsArchived] = useState(false);
  const [nonSRUDev, setNonSRUDev] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [planInfoList, setPlanInfoList] = useState([]);
  const [cardError, setCardError] = useState(false);
  const [disableEditPlans, setDisableEditPlans] = useState(false);
  const [runCalculateSubscriptionChange, invokeRunCalculateSubscriptionChange] = useState(false);
  const [downgradeToFree, setDowngradeToFree] = useState(false);
  const [upgradeFromFree, setUpgradeFromFree] = useState(false);
  const [upgradeDeluxeToPremium, setUpgradeDeluxeToPremium] = useState(false);
  const [downgradePremiumToDeluxe, setDowngradePremiumToDeluxe] = useState(false);
  const [activeSubscriptions, setActiveSubscriptions] = useState({});

  const useStyles = makeStyles({
    root: {
      borderBottom: 0,
      // maxWidth: '50rem',
      maxWidth: '100%',
      overflowX: 'auto',
    },
    // table: {
    //   width: '50rem'
    //   // width: ('100%' - '28.75rem')
    // }
  });
  const muiClasses = useStyles();

  const calculateSum = (length, planId, duration) => {
    // const checkedAnnualPlan = annualPlanList.filter(val => val.DisplayName === planId);
    const checkedMonthlyPlan = monthlyPlanList.filter((val) => val.DisplayName === planId);
    // const annualModel = checkedAnnualPlan[0].PricingModel;
    const monthlyModel = checkedMonthlyPlan[0].PricingModel;
    if (monthlyModel === 'tiered') {
      const keyList = Object.keys(checkedMonthlyPlan[0].Price);
      const intArray = keyList.map((eachKey) => {
        if (eachKey !== 'NA') {
          return parseInt(eachKey);
        }
        return eachKey;
      });
      intArray.some((eachKey, index) => {
        if (length <= eachKey) {
          if (eachKey !== 'NA') {
            // colSumAnnual = (checkedAnnualPlan.length > 0)
            //   && (checkedAnnualPlan[0].Price[eachKey] * length);
            colSumMonthly =
              checkedMonthlyPlan.length > 0 && checkedMonthlyPlan[0].Price[eachKey] * length;
            return eachKey;
          }
          if (eachKey === 'NA' && length > intArray[index - 1]) {
            // colSumAnnual = (checkedAnnualPlan.length > 0)
            //   && (checkedAnnualPlan[0].Price[eachKey] * length);
            colSumMonthly =
              checkedMonthlyPlan.length > 0 && checkedMonthlyPlan[0].Price[eachKey] * length;
            return eachKey;
          }
        }
        return false;
      });
    } else {
      // const keyAnnual = Object.keys(checkedAnnualPlan[0].Price)[0];
      const keyMonthly = Object.keys(checkedMonthlyPlan[0].Price)[0];
      // colSumAnnual = (checkedAnnualPlan.length > 0)
      //   && (checkedAnnualPlan[0].Price[keyAnnual] * (Math.ceil(length / keyAnnual)));
      colSumMonthly =
        checkedMonthlyPlan.length > 0 &&
        checkedMonthlyPlan[0].Price[keyMonthly] * Math.ceil(length / keyMonthly);
    }
    eachColSumMonthly.push(colSumMonthly);
    // eachColSumAnnual.push(colSumAnnual);
    monthlyTotal = eachColSumMonthly.reduce((x, y) => x + y);
    // annualTotal = eachColSumAnnual.reduce((a, b) => a + b);
    setColSumMonthly(eachColSumMonthly);
    // setColSumAnnual(eachColSumAnnual);
    if (duration === 'monthly') {
      setColumnSum(eachColSumMonthly);
    }
    // else if (duration === 'annual') {
    //   setColumnSum(eachColSumAnnual);
    // }
  };

  const calculateTotalSum = (duration) => {
    if (duration === 'monthly') {
      setCurrentTotal(monthlyTotal);
    } else if (duration === 'annual') {
      setCurrentTotal(annualTotal);
    }
  };
  const calculateSubTotal = () => {
    setSubTotalMonthly(monthlyTotal);
    setSubTotalAnnual(annualTotal);
  };

  const trueValues = (ele) => ele === true;
  const monthlyPlans = (el) => el.Duration === 'monthly' || el.Duration === '';
  const annualPlans = (el) => el.Duration === 'annual' || el.Duration === '';
  const removeEmptyIds = (e) => e !== '';

  useEffect(() => {
    getUserSubscription(userEmail)
      .then((resp) => {
        if (resp.ErrorCode !== 'err_0') {
          setCommonError(true);
          setCommonErrorMsg(ErrorCodes[resp.ErrorCode]);
        }
      })
      .catch((err) => {
        if (err) {
          setCommonError(true);
          setCommonErrorMsg('Something went wrong!');
        }
      });
  }, []);

  const setTableData = () => {
    const checkBoxList = [];
    const tempDevMappingList = [];
    let activePlansList = [];
    let activePlanDuration = '';
    if (Object.keys(subscriptionData).length === 0 && subscriptionData.constructor === Object) {
      setLoader(true);
    } else {
      setDeviceList(subscriptionData.deviceData);
      if (subscriptionData.SelectedPlanDuration !== '') {
        activePlanDuration = subscriptionData.SelectedPlanDuration;
        selectedDuration(activePlanDuration);
      } else {
        activePlanDuration = 'monthly';
        selectedDuration(activePlanDuration);
      }

      monthlyPlanList = subscriptionData.planDetails.filter(monthlyPlans);
      annualPlanList = subscriptionData.planDetails.filter(annualPlans);
      const sortedArrayList = subscriptionData.planInfo.sort((a, b) => a.Rank - b.Rank);
      // BUG-[#84372] Blank page showing for subscription page edit plans
      if (activePlanDuration === 'monthly' || activePlanDuration === '') {
        activePlansList = monthlyPlanList;
        setActivePlandetails(monthlyPlanList);
      } else if (activePlanDuration === 'annual') {
        activePlansList = annualPlanList;
        setActivePlandetails(annualPlanList);
      }
      setMonthlyPlans(monthlyPlanList);
      setAnnualPlans(annualPlanList);
      const uniqueSet = new Set(sortedArrayList.map((each) => each.DisplayName));
      uniqueArr = [...uniqueSet];
      const nonSRUDevices = subscriptionData.deviceData.filter(
        (eachDev) => eachDev.DeviceType !== 'SmartReadinessUnit'
      );
      if (nonSRUDevices.length > 0) {
        setPlanList(uniqueArr);
      } else {
        setNonSRUDev(false);
        const freePlanInfo = subscriptionData.planDetails.filter(
          (eachPlan) => eachPlan.DisplayName === 'Free'
        );
        freePlanInfo[0] && setPlanList([freePlanInfo[0]?.DisplayName]);
      }
      setPlanInfoList(sortedArrayList);
      if (uniqueArr.length > 0) {
        setHeaderColWidth(70 / uniqueArr.length);
        setBodyColWidth(70 / uniqueArr.length);
      }
      subscriptionData.deviceData.forEach((eachDev) => {
        const tempCheckboxList = [];
        const deviceIdList = [];
        activePlansList.forEach((eachPlan, colInd) => {
          if (eachPlan.UserSubscriptionID === eachDev.DeviceSubscription) {
            tempCheckboxList[colInd] = true;
            deviceIdList[colInd] = eachDev.ThingName;
          } else {
            tempCheckboxList[colInd] = false;
            deviceIdList[colInd] = '';
          }
        });
        checkBoxList.push(tempCheckboxList);
        tempDevMappingList.push(deviceIdList);
      });
      const acceptedValues = [0];
      const planInfoArr = JSON.parse(JSON.stringify(subscriptionData.planInfo));
      planInfoArr.forEach((each) => {
        ['Rank', 'PlanStatus', 'isPopular', 'DisplayName', 'BillingInterval'].forEach(
          (e) => delete each[e]
        );
      });
      // BUG-[#84296] Additional plans are showing in tooltip of subscription page
      const filteredObject = planInfoArr.map((eachObj) =>
        Object.keys(eachObj).reduce((each, ind) => {
          if (!acceptedValues.includes(eachObj[ind])) {
            each[ind] = eachObj[ind];
          }
          return each;
        }, {})
      );
      setSelectedPlanInfo(filteredObject);
      setLoader(false);
    }
    setTableCheckboxList(checkBoxList);
    setInitialDevMapping(tempDevMappingList);
    setDeviceMappingList(tempDevMappingList);
    const lengthArray = activePlansList.map((e, ind) =>
      checkBoxList.map((each) => each[ind]).filter(trueValues)
    );
    const devIdArr = activePlansList.map((e, ind) =>
      tempDevMappingList.map((each) => each[ind]).filter(removeEmptyIds)
    );
    setDeviceIdList(devIdArr);
    setColumnLength(lengthArray);
    // eslint-disable-next-line max-len
    eachColSumMonthly = [];
    eachColSumAnnual = [];
    lengthArray.map((eachColumn, i) =>
      calculateSum(eachColumn.length, uniqueArr[i], activePlanDuration)
    );
    calculateTotalSum(activePlanDuration);
    calculateSubTotal(eachColSumAnnual, eachColSumMonthly);
  };

  useEffect(() => {
    if (subscriptionData) {
      setTableData();
    } else {
      setErrorModal(true);
      setLoader(false);
    }
  }, [subscriptionData]);

  useEffect(() => {
    if (isPaymentComplete === true) {
      setCardModal(false);
      getUserSubscription(userEmail)
        .then((resp) => {
          if (resp.ErrorCode !== 'err_0') {
            setCommonError(true);
            setCommonErrorMsg(ErrorCodes[resp.ErrorCode]);
          }
        })
        .catch((err) => {
          if (err) {
            setCommonError(true);
            setCommonErrorMsg('Something went wrong!');
          }
        });
      onEditPlansClick(false);
    }
  }, [isPaymentComplete]);

  const handleCheckboxChange = (event, rowInd, checkIndex, planName, deviceId) => {
    // const planDetails = activePlanDetails;
    // const inactiveSubsc = planDetails.filter(
    //   (eachPlan) => eachPlan.DisplayName === planName && eachPlan.SubscriptionStatus === 'inactive'
    // );
    const archivedPlan = subscriptionData.planInfo.filter(
      (eachPlan) => eachPlan.DisplayName === planName && eachPlan.PlanStatus === 'archived'
    );
    if (archivedPlan.length > 0) {
      setIsArchived(true);
      setCommonErrorMsg('You can no longer subscribe to this plan');
    }
    const { checked } = event.target;
    const tempArray = tableCheckboxList;
    const tempDevMapList = deviceMappingList;
    if (checked === true) {
      tempArray[rowInd].fill(false);
      tempArray[rowInd][checkIndex] = true;
      tempDevMapList[rowInd].fill('');
      tempDevMapList[rowInd][checkIndex] = deviceId;
    }
    setTableCheckboxList([...tempArray]);
    setDeviceMappingList([...tempDevMapList]);
    const lengthArray = planList.map((e, ind) =>
      tableCheckboxList.map((each) => each[ind]).filter(trueValues)
    );
    const deviceIdArr = planList.map((e, ind) =>
      tempDevMapList.map((each) => each[ind]).filter(removeEmptyIds)
    );
    setDeviceIdList(deviceIdArr);
    setColumnLength(lengthArray);
    invokeRunCalculateSubscriptionChange(!runCalculateSubscriptionChange)
    eachColSumMonthly = [];
    eachColSumAnnual = [];
    lengthArray.map((eachColumn, i) => calculateSum(eachColumn.length, uniqueArr[i], planDuration));
    calculateSubTotal(eachColSumAnnual, eachColSumMonthly);
  };

  useEffect(() => {
    calculateSubscriptionChange();
  }, [runCalculateSubscriptionChange]);

  const createMasterdata = (planDetails) => {
    metadata = [];
    if (planChanged) {
      planDetails.map((each, ind) =>
        metadata.push({
          plan_id: each.PlanId,
          quantity: columnLength[ind].length.toString(),
          device_ids: DeviceIdsList[ind],
          displayName: each.DisplayName,
        })
      );
      metadata.forEach((eachData) => {
        activePlanDetails.forEach((eachPlan) => {
          if (eachData.displayName === eachPlan.DisplayName) {
            eachData.subscription_id = eachPlan.UserSubscriptionID;
            delete eachData.displayName;
          }
        });
      });
    } else {
      planDetails.map((each, ind) =>
        metadata.push({
          subscription_id: each.UserSubscriptionID,
          plan_id: each.PlanId,
          quantity: columnLength[ind].length.toString(),
          device_ids: DeviceIdsList[ind],
        })
      );
    }
  };

  const handleConfirmChanges = () => {
    setDisableEditPlans(true);
    let activeSubtotal;
    let isOnlyPlanChanged = 0;
    if (planDuration !== subscriptionData.SelectedPlanDuration) {
      if (initialDevMapping !== deviceMappingList) {
        isOnlyPlanChanged = 0;
        setonlyPlanChanged(0);
      } else {
        isOnlyPlanChanged = 1;
        setonlyPlanChanged(1);
      }
    }
    if (planChanged) {
      if (planDuration === 'monthly') {
        createMasterdata(monthlyPlanDetails);
        activeSubtotal = subTotalMonthly;
      } else if (planDuration === 'annual') {
        createMasterdata(annualPlanDetails);
        activeSubtotal = subTotalAnnual;
      }
    } else {
      if (planDuration === 'monthly') {
        activeSubtotal = subTotalMonthly;
      } else if (planDuration === 'annual') {
        activeSubtotal = subTotalAnnual;
      }
      createMasterdata(activePlanDetails);
    }

    if (activeSubtotal <= currentTotal || activeSubtotal === 0) {
      onEditPlansClick(false);
      setLoader(true);
      updateSubscription({
        email: userEmail,
        customerId: subscriptionData.CustomerID,
        metadata,
        planChanged: isOnlyPlanChanged,
      })
        .then((resp) => {
          setDisableEditPlans(false);
          if (resp.ErrorCode !== 'err_0') {
            setCommonError(true);
            setCommonErrorMsg(ErrorCodes[resp.ErrorCode]);
          }
          setDowngradeToFree(false);
          setUpgradeFromFree(false);
          setUpgradeDeluxeToPremium(false);
          setDowngradePremiumToDeluxe(false);
        })
        .catch((err) => {
          setDisableEditPlans(false);
          if (err) {
            setCommonError(true);
            setCommonErrorMsg('Something went wrong!');
          }
        });
      setCardModal(false);
      setTimeout(() => {
        setConfirmationModal(true);
        setLoader(false);
      }, 3000);
    } else {
      // setLoader(true);
      setCardModal(!cardModal);
      // setTimeout(() => {
      //   setLoader(false);
      // }, 3000);
      setManageCard(false);
      retrieveCardDetils({
        email: userEmail,
        customer_id: subscriptionData.CustomerID,
      })
        .then((resp) => {
          if (resp.ErrorCode !== 'err_0') {
            setCommonErrorMsg(ErrorCodes[resp.ErrorCode]);
            setCardError(true);
            // setCommonError(true);
            // setCommonErrorMsg(ErrorCodes[resp.ErrorCode]);
          }
        })
        .catch((err) => {
          if (err) {
            setCommonError(true);
            setCommonErrorMsg('Something went wrong!');
          }
        });
    }
  };

  const handleCancelChange = () => {
    onEditPlansClick(false);
    setDowngradeToFree(false);
    setUpgradeFromFree(false);
    setUpgradeDeluxeToPremium(false);
    setDowngradePremiumToDeluxe(false);
    setTableData();
  };

  const handleEditPlans = () => {
    calculateActiveSubscriptions();
    onEditPlansClick(true);
  };

  const onPlanDurationClick = (duration) => {
    selectedDuration(duration);
    setPlanChanged(true);
  };

  const handlePaymentClose = () => setCardModal(!cardModal);

  const handleViewPlan = (menu) => handleProfileMenu(menu);

  const handleSubmitSuccess = () => {
    handlePaymentClose();
    onEditPlansClick(false);
    setDowngradeToFree(false);
    setUpgradeFromFree(false);
    setUpgradeDeluxeToPremium(false);
    setDowngradePremiumToDeluxe(false);
    if (manageCard === false) {
      setLoader(true);
      setTimeout(() => {
        setLoader(false);
        setConfirmationModal(true);
        setDisableEditPlans(false);
      }, 4000);
    }
  };

  const handleManageCard = () => {
    setCardModal(true);
    setManageCard(true);
    retrieveCardDetils({
      email: userEmail,
      customer_id: subscriptionData.CustomerID,
    })
      .then((resp) => {
        if (resp.ErrorCode !== 'err_0') {
          setCommonErrorMsg(ErrorCodes[resp.ErrorCode]);
          setCardError(true);
        }
      })
      .catch((err) => {
        if (err) {
          setCommonError(true);
          setCommonErrorMsg('Something went wrong!');

          setCardModal(false);
        }
      });
  };

  const handleClose = () => setErrorModal(false);

  const modalConfig = {
    open: errorModal,
    type: 'message',
    btnClicked: () => handleClose(),
  };

  const editPlansButton = {
    width: 172,
    marginTop: 10,
    float: 'right',
  };

  const manageCardButton = {
    width: 172,
    marginRight: 16,
  };

  const spinnerConfig = {
    showSpinner: isLoader,
    top: '150%',
    left: '45%',
    // zIndex: '-1'
  };

  const handleCommonError = (isError, msg) => {
    if (isError) {
      setCommonErrorMsg(msg);
      setCommonError(isError);
    }
  };

  const commonErrorBtnHandler = () => {
    setCommonErrorMsg(null);
    setCommonError(false);
  };

  const commonErrorDialogConfig = {
    open: commonError,
    textAlign: 'left',
    btnClicked: commonErrorBtnHandler,
  };

  const handleConfirmationOk = () => setConfirmationModal(false);

  const handleConfirmModal = () => setConfirmationModal(true);

  const favNotificationConfig = {
    position: 'bottomRight',
    open: confirmationModal,
    onClose: handleConfirmationOk,
  };

  function calculateActiveSubscriptions() {
    const subscriptionObj = {};
    activePlanDetails.forEach((s) => {
      subscriptionObj[s.PlanId] = Number(s.SubscriptionQuantity) || 0;
    });
    setActiveSubscriptions({ ...subscriptionObj });
  }

  async function calculateSubscriptionChange() {
    await createMasterdata(activePlanDetails);
    const changedSubscriptions = {};
    metadata.forEach((s) => {
      changedSubscriptions[s.plan_id] = Number(s.quantity) || 0;
    });
    if (activeSubscriptions.free < changedSubscriptions.free) {
      setDowngradeToFree(true);
      setUpgradeFromFree(false)
    } else {
      setDowngradeToFree(false);
    }
    if (activeSubscriptions.free > changedSubscriptions.free) {
      setUpgradeFromFree(true);
    } else {
      setUpgradeFromFree(false);
    }
    if (
      activeSubscriptions.deluxe > changedSubscriptions.deluxe &&
      activeSubscriptions.premium < changedSubscriptions.premium
    ) {
      setUpgradeDeluxeToPremium(true);
    } else {
      setUpgradeDeluxeToPremium(false);
    }
    if (
      activeSubscriptions.premium > changedSubscriptions.premium &&
      activeSubscriptions.deluxe < changedSubscriptions.deluxe
    ) {
      setDowngradePremiumToDeluxe(true);
    } else {
      setDowngradePremiumToDeluxe(false);
    }
  }

  return (
    <>
      {cardModal && !isPaymentComplete && (
        <PaymentWidget
          user={user}
          openVal={cardModal}
          onClose={handlePaymentClose}
          cardError={cardError}
          cardErrorMsg={commonErrorMsg}
          metadata={metadata}
          planChanged={onlyPlanChanged}
          onSubmitSuccess={handleSubmitSuccess}
          manageCard={manageCard}
          handleCommonError={handleCommonError}
          handleConfirmModal={handleConfirmModal}
        />
      )}
      {apiError && <CustomDialog dialogConfig={modalConfig}>{apiError}</CustomDialog>}
      {(commonError || isArchived) && (
        <CustomDialog dialogConfig={commonErrorDialogConfig}>
          <div>{commonErrorMsg}</div>
        </CustomDialog>
      )}
      {confirmationModal && (
        <Notification config={favNotificationConfig}>
          <div>Changes have been updated!</div>
        </Notification>
      )}
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 80 }}>
          <div className={classes.subscriptionHeading}>Subscription</div>
          {nonSRUDev && (
            <ButtonElem
              btnColor="YellowButton"
              value="Manage card"
              clicked={handleManageCard}
              style={manageCardButton}
            />
          )}
        </div>
        {isLoader && <LoadingSpinner spinnerConfig={spinnerConfig} />}
        {deviceList.length > 0 ? (
          <div>
            <div style={{ overflow: 'auto' }}>
              <Table className={classes.tableWrapper}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" width="5%" />
                    <TableCell align="left" width="25%" style={{ paddingLeft: '1.5rem' }}>
                      <div className={classes.tableFontGreySmall}>Device Name</div>
                    </TableCell>
                    {planList.map((each, i) => (
                      <TableCell
                        align="center"
                        key={each}
                        // width="150px"
                        width={`${headerColWidth}%`}
                        style={{ padding: 5 }}
                      >
                        <div
                          className={[classes.displayFlexCenter, classes.tableFontGreyBig].join(
                            ' '
                          )}
                        >
                          <TableTooltip key={each} content={selectedPlanInfo[i]} />
                          {each}
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
              </Table>
              <ScrollArea scrollClass={classes.tableScroll} scrollMaxHeight="258px">
                <Table className={[classes.tableWrapper, muiClasses.table].join(' ')}>
                  <TableBody>
                    {deviceList.map((device, rowInd) => (
                      <TableRow key={device.DeviceName}>
                        <TableCell align="center" width="5%" style={{ padding: 5 }}>
                          {
                            // BUG-[#84292] In subscription page only image for portable camera
                            // is present even it have SRU device in it
                            device.DeviceType === 'OverDoorCamera' ? (
                              <img src={OverDoorCam} alt="overdoor" />
                            ) : device.DeviceType === 'HomeAndTravelCamera' ? (
                              <img src={homeTravelCam} alt="homeTravel" />
                            ) : device.DeviceType === 'SmartReadinessUnit' ? (
                              <img src={SRUdev} alt="SRU" style={{ width: 51, height: 70 }} />
                            ) : (
                              ''
                            )
                          }
                        </TableCell>
                        <TableCell align="left" style={{ padding: 5 }} width="25%">
                          <div className={classes.tableFontBlack}>
                            {device.DeviceName}
                            {device.DeviceType === 'HomeAndTravelCamera' ? (
                              <div className={classes.deviceDescription}>Portable 360°</div>
                            ) : device.DeviceType === 'OverDoorCamera' ? (
                              <div className={classes.deviceDescription}>Portable Over-Door</div>
                            ) : device.DeviceType === 'SmartReadinessUnit' ? (
                              <div className={classes.deviceDescription}>Smart Readiness</div>
                            ) : (
                              ''
                            )}
                          </div>
                        </TableCell>
                        {planList.map((eachlist, checkInd) => {
                          const checkIndex = checkInd;
                          return (
                            <TableCell
                              align="center"
                              key={checkIndex}
                              style={{ padding: 5 }}
                              // width="150px"
                              // width="20%"
                              width={`${bodyColWidth}%`}
                            >
                              {isEditPlan === true && device.DeviceType !== 'SmartReadinessUnit' ? (
                                <SubscriptionCheckbox
                                  isChecked={tableCheckboxList[rowInd][checkInd]}
                                  value={eachlist.PlanName}
                                  onCheckBoxChange={
                                    // eslint-disable-next-line max-len
                                    (event) =>
                                      handleCheckboxChange(
                                        event,
                                        rowInd,
                                        checkIndex,
                                        eachlist,
                                        device.ThingName
                                      )
                                  }
                                />
                              ) : (
                                tableCheckboxList[rowInd][checkInd] && (
                                  <img src={tickMark} alt=" " />
                                )
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </ScrollArea>
              <Table className={classes.tableWrapper}>
                <TableBody>
                  <TableRow>
                    <TableCell
                      className={muiClasses.root}
                      width="30%"
                      style={{ padding: 5 }}
                      // width={isEditPlan ? '18%' : '50%'}
                    >
                      <div className={classes.tableFontGreyBig}>
                        Current plan total: ${Number(currentTotal).toFixed(2)}
                        {subscriptionData.SelectedPlanDuration === 'monthly' ? '/month' : '/year'}
                      </div>
                    </TableCell>
                    {isEditPlan ? (
                      <>
                        {planList.map((eachPlanId, ind) => (
                          <SumOfEachColumn
                            key={eachPlanId}
                            eachCol={columnLength[ind]}
                            planId={eachPlanId}
                            columnSum={columnSum[ind]}
                            columnSumMonthly={columnSumMonthly[ind]}
                            // colSumAnnual={columnSumAnnual[ind]}
                            width={`${bodyColWidth}%`}
                            planDuration={planDuration}
                          />
                        ))}
                      </>
                    ) : (
                      <TableCell className={muiClasses.root}>
                        {nonSRUDev && (
                          <ButtonElem
                            btnColor="YellowButton"
                            value="Edit Plans"
                            clicked={handleEditPlans}
                            style={editPlansButton}
                            isbtndisabled={disableEditPlans}
                          />
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            {isEditPlan && (
              <EditSubscription
                subTotalMonthly={subTotalMonthly}
                subTotalAnnual={subTotalAnnual}
                setSubTotalAnnual={setSubTotalAnnual}
                planDuration={planDuration}
                planDurationClick={onPlanDurationClick}
                handleConfirmChanges={handleConfirmChanges}
                handleCancelChange={handleCancelChange}
                downgradeToFree={downgradeToFree}
                upgradeFromFree={upgradeFromFree}
                upgradeDeluxeToPremium={upgradeDeluxeToPremium}
                downgradePremiumToDeluxe={downgradePremiumToDeluxe}
              />
            )}
          </div>
        ) : (
          <ViewPlans handleViewPlan={handleViewPlan} />
        )}
      </>
    </>
  );
};

const mapStateToProps = (state) => ({
  subscriptionData: state.subscriptionReducer.userSubscription,
  isPaymentComplete: state.subscriptionReducer.isPaymentComplete,
  paymentSourceStatus: state.subscriptionReducer.paymentSourceStatus,
  apiError: state.subscriptionReducer.apiError,
});

const mapDispatchToProps = (dispatch) => ({
  handleProfileMenu: (data) => dispatch(profileActionCreator.setProfileMenu(data)),
  getUserSubscription: (emailID) => dispatch(subsActionCreator.getUserSubscription(emailID)),
  updateSubscription: (data) => dispatch(subsActionCreator.updateSubscription(data)),
  retrieveCardDetils: (data) => dispatch(subsActionCreator.retrieveCardDetils(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubscriptionTable));
