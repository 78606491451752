import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { forwardBlackIcon } from '../../ui/Icons/Icons';
import classes from './AllActivity.module.css';

const AllActivity = ({ clicked }) => {
  const [allActivityElem, setAllActivityElem] = useState(null);

  useEffect(() => {
    setAllActivityElem(
      <Box className={classes.AllActivity} onClick={clicked} role="presentation">
        <span>
          All activity
          <img src={forwardBlackIcon} alt="Forward" width="20" />
        </span>
      </Box>
    );
  }, []);

  return allActivityElem;
};

export default AllActivity;
