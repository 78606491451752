import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import msgConstant from '../../common/textConstants';
import { PROFILE } from '../../../constants/routes';
import VJSIcons from './VJSIcons';

const SubscriptionTooltip = props => {
  const { reviewShareSaveVideo, clicked, btnType } = props;
  const [showTooltip, setShowTooltip] = useState(false);

  const theme = createMuiTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          backgroundColor: 'white',
          color: 'black',
          fontSize: '10px',
          boxShadow: '0 0 10px #f1f1f1',
          border: '1px solid #f1f1f1'
        },
        arrow: {
          color: '#FFF'
        }
      }
    }
  });

  const handleSubscClick = () => props.history.push(PROFILE);

  const subscriptionInfo = () => (
    <>
      <div>
        {msgConstant.SUBSCRIPTION_VALIDATION}
      </div>
      <div
        style={{ color: 'blue', cursor: 'pointer' }}
        onClick={handleSubscClick}
        role="presentation"
      >
        Click here to upgrade your plan.
      </div>
    </>
  );

  const handleMouseEnter = () => {
    if (reviewShareSaveVideo === 0) {
      setShowTooltip(true);
    }
    setTimeout(() => {
      setShowTooltip(false);
    }, 3000);
  };

  const subscriptionStatus = () => reviewShareSaveVideo === 0;

  return (
    <MuiThemeProvider theme={theme}>
      <Tooltip
        title={subscriptionInfo()}
        interactive
        arrow
        open={showTooltip}
      >
        <div
          onMouseEnter={handleMouseEnter}
          onClick={subscriptionStatus() ? null : () => clicked()}
          role="presentation"
        >
          <VJSIcons btnType={btnType} />
        </div>
      </Tooltip>
    </MuiThemeProvider>
  );
};

export default withRouter(SubscriptionTooltip);
