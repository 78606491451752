// REQUIREMENT : 4.1.15 Configuration of emergency weather/natural disaster alarms

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { sendAlertTypeData, fetchAlertTypeData } from '../../../store/action-creator';
import ButtonElem from '../../../../ui/Button/Button';
import classes from '../DeviceSettings.module.css';
import CustomDialog from '../../../../ui/CustomDialog/CustomDialog';
import msgConstant from '../../../../common/textConstants';
import LoadingSpinner from '../../../../ui/LoadingSpinner/LoadingSpinner';
import Notification from '../../../../ui/Notification/Notification';
import { AuthContext } from '../../../../../Providers/AuthProvider';

const alertTypeHeader = { fontSize: '14px', color: '#000000' };

const AlertType = (props) => {
  const { user } = React.useContext(AuthContext);
  const email = user.userEmail;
  const { setAlert, deviceInfo } = props;

  const [notifyType, setNotifyType] = useState(null);
  const [userData, setUserData] = useState(null);
  const [deviceData, setDeviceData] = useState(null);
  // const [initialAlert, setInitialAlert] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const alertTypeData = useSelector((state) => state.deviceReducer.alertTypes);
  const dispatch = useDispatch();

  const onUpdateHandler = () => {
    setSpinner(true);
    const userSetting = userData.reduce((res, field, index) => {
      res[notifyType[index]] = field;
      return res;
    }, {});

    const deviceSetting = deviceData.reduce((res, field, index) => {
      res[notifyType[index]] = field;
      return res;
    }, {});

    const params = {
      action: 'update_settings',
      data: {
        email,
        device_id: deviceInfo.DeviceId,
        alert_settings: {
          user: userSetting,
          device: deviceSetting,
        },
      },
    };
    // if ((JSON.stringify(params.data.alert_settings) !== JSON.stringify(initialAlert[1]))) {
    sendAlertTypeData(params)
      .then((resp) => {
        setSpinner(false);
        const respCode = resp.data.ResponseCode;
        const errCode = resp.data.ErrorCode;
        if (respCode !== 200 || (respCode === 200 && errCode !== 'err_0')) {
          setErrorModal(true);
          // setErrorMessage(`${ErrorCodes[resp.data.ErrorCode]}`);
          setErrorMessage(msgConstant.ALERT_TYPES_UPDATE_ERROR);
        } else {
          setShowNotification(true);
          setTimeout(() => {
            dispatch(fetchAlertTypeData(params.data));
            setAlert();
          }, 1500);
        }
      })
      .catch(() => {
        setSpinner(false);
        setErrorModal(true);
        setErrorMessage(`${msgConstant.COMMON_ERROR}`);
      });
    // }
  };

  const setErrorBtnHandler = () => setErrorModal(!errorModal);

  const errorModalConfig = {
    open: errorModal,
    textAlign: 'left',
    btnClicked: setErrorBtnHandler,
  };

  useEffect(() => {
    setNotifyType(Object.keys(alertTypeData.alert_settings.user));
    // Above can be device as well as both are same
    setUserData(Object.values(alertTypeData.alert_settings.user));
    setDeviceData(Object.values(alertTypeData.alert_settings.device));
    // setInitialAlert(Object.values(alertTypeData));
  }, []);

  const onToggle = (type, ind) => {
    switch (type) {
      case 'device': {
        const newDeviceData = { ...deviceData };
        newDeviceData[ind] = deviceData[ind] ? 0 : 1;
        setDeviceData(Object.values(newDeviceData));
        break;
      }
      case 'user': {
        const newUserData = { ...userData };
        newUserData[ind] = newUserData[ind] ? 0 : 1;
        setUserData(Object.values(newUserData));
        break;
      }
      default:
        break;
    }
  };

  let alertElem = null;
  if (notifyType && userData && deviceData) {
    alertElem = (
      <Table aria-label="simple table" className="AlertTypeTableCell">
        <TableHead>
          <TableRow>
            <TableCell align="center" />
            <TableCell align="center" style={alertTypeHeader}>
              Alarm
            </TableCell>
            <TableCell align="center" style={alertTypeHeader}>
              Push
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notifyType.map((evt, ind) => (
            <TableRow key={evt}>
              <TableCell className="AlertTypeLeftAlign">{evt}</TableCell>
              <TableCell align="center">
                <label className="ToggleSwitch">
                  <input
                    type="checkbox"
                    checked={deviceData[ind]}
                    onChange={() => onToggle('device', ind)}
                  />
                  <span className="ToggleSliders ToggleRound" />
                </label>
              </TableCell>
              <TableCell align="center">
                <label className="ToggleSwitch">
                  <input
                    type="checkbox"
                    checked={userData[ind]}
                    onChange={() => onToggle('user', ind)}
                  />
                  <span className="ToggleSliders ToggleRound" />
                </label>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  const notificationConfig = {
    position: 'bottomRight',
    open: showNotification,
    onClose: () => setShowNotification(false),
  };

  return (
    <>
      <LoadingSpinner spinnerConfig={{ showSpinner: spinner }} />
      <div className={classes.header}>Alert Types</div>
      <div>{alertElem}</div>
      <p style={{ paddingTop: '15px', fontFamily: 'bookItalicFont' }} className="Font14">
        Push notification settings are for this user only. Alarm notifications will sound on
        emergency unit. Alarm settings can be modified by any authorized user.
      </p>
      {/* Bug[#84399]
        - Alignment issue for 'Done' and 'Cancel' buttons for alert types in SRU settings. */}
      <Grid
        container
        spacing={2}
        justify="center"
        alignItems="center"
        className={classes.techInfoButton}
      >
        <Grid item>
          <ButtonElem
            btnColor="YellowButton"
            value="Done"
            btnType="submit"
            style={{ width: 135 }}
            clicked={onUpdateHandler}
          />
        </Grid>
        <Grid item>
          <ButtonElem
            btnColor="GreyButton"
            value="Cancel"
            btnType="submit"
            style={{ width: 135 }}
            clicked={() => setAlert()}
          />
        </Grid>
      </Grid>
      {errorModal && (
        <CustomDialog dialogConfig={errorModalConfig}>
          <div>{errorMessage}</div>
        </CustomDialog>
      )}
      {showNotification && (
        <Notification config={notificationConfig}>{msgConstant.ALERT_TYPES_UPDATE}</Notification>
      )}
    </>
  );
};

export default AlertType;
