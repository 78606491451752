import React, { Component } from 'react';
import ButtonElem from '../../../ui/Button/Button';
import classes from '../DeviceSettings/DeviceSettings.module.css';

class ErrorHandler extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true
    };
  }

  componentDidCatch(error, info) {
    console.log(error);
    console.log(info);
  }


  render() {
    const { setFlag, header, children } = this.props;
    const { hasError } = this.state;
    const onDoneHandler = () => {
      setFlag();
    };
    if (hasError) {
      return (
        <div>
          <div className={classes.header}>{header}</div>
          <div style={{ paddingTop: '10px' }}>Something went wrong! Try again later</div>
          <div className={classes.techInfoButton}>
            <ButtonElem btnColor="YellowButton" value="Close" btnType="submit" style={{ width: 140 }} clicked={onDoneHandler} />
          </div>
        </div>
      );
    }
    return children;
  }
}

export default ErrorHandler;
