import React from 'react';
import { useSelector } from 'react-redux';
import RecordingTimer from './RecordingTimer/RecordingTimer';

const DeviceRecordingTimer = ({ clsName }) => {
  const startTimer = useSelector(state => state.deviceReducer.startTimer);

  return (
    <>
      {
        startTimer && (
          <div className={clsName}>
            <RecordingTimer startTimer={startTimer} />
          </div>
        )
      }
    </>
  );
};

export default DeviceRecordingTimer;
