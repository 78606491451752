import React, { useState, useEffect } from 'react';
import { withGoogleMap, GoogleMap, InfoWindow, Marker } from 'react-google-maps';
import Geocode from 'react-geocode';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from 'react-google-places-autocomplete';
import loader from '../../../assets/icons/5.gif';
import { getCity, getPostalCode, getState } from '../commonFunction';
import classes from '../ManageHomes.module.css';
import CustomDialog from '../../ui/CustomDialog/CustomDialog';
import * as iconElem from '../../ui/Icons/Icons';
import msgConstant from '../../common/textConstants';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
Geocode.enableDebug();

const Map = (props) => {
  const { zoom, center, height, initialLocation, formType } = props;

  const [selection, setSelection] = useState(null);

  const [mapPosition, setMapPosition] = useState({
    lat: center.lat,
    lng: center.lng,
  });
  const [markerPosition, setMarkerPosition] = useState({
    lat: center.lat,
    lng: center.lng,
  });
  const [infoAddress, setInfoAddress] = useState('');
  const [showModal, setShowModal] = useState(false);

  const editHomeDataChange = () => {
    const data = {
      home_address: initialLocation.split('&?')[0],
      city: initialLocation.split('&?')[1],
      state: initialLocation.split('&?')[2],
      zipCode: initialLocation.split('&?')[3],
      latitude: mapPosition.lat,
      longitude: mapPosition.lng,
    };
    setInfoAddress(`${data.home_address},${data.city},${data.state},${data.zipCode}`);
    props.dataFnc(data);
  };

  const addHomeDataChange = () => {
    Geocode.fromLatLng(mapPosition.lat, mapPosition.lng).then(
      (response) => {
        const newAddress = response.results[0].formatted_address;
        const addressArray = response.results[0].address_components;
        const city = getCity(addressArray);
        const zipCode = getPostalCode(addressArray);
        const state = getState(addressArray);
        setInfoAddress(newAddress);
        const data = {
          home_address: newAddress,
          city,
          state,
          zipCode,
          latitude: mapPosition.lat,
          longitude: mapPosition.lng,
        };
        props.dataFnc(data);
      },
      (error) => console.error(error)
    );
  };

  useEffect(() => {
    if (formType === 'editHome') {
      editHomeDataChange();
    } else {
      addHomeDataChange();
    }
  }, []);

  const okBtnHandler = () => setShowModal(false);

  // #84229] Edit home is giving default location in location details

  // Web: [#84859] Locate me option is not working while adding new home.
  const currentLocationHandler = () => {
    // eslint-disable-next-line no-unused-expressions
    navigator.permissions &&
      navigator.permissions.query({ name: 'geolocation' }).then((PermissionStatus) => {
        if (PermissionStatus.state === 'granted') {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
                (response) => {
                  const newAddress = response.results[0].formatted_address;
                  const addressArray = response.results[0].address_components;
                  const city = getCity(addressArray);
                  const zipCode = getPostalCode(addressArray);
                  const state = getState(addressArray);
                  setInfoAddress(newAddress);
                  setMapPosition({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  });
                  setMarkerPosition({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  });
                  const data = {
                    home_address: newAddress,
                    city,
                    state,
                    zipCode,
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                  };
                  props.dataFnc(data);
                },
                (error) => console.error(error)
              );
            },
            (locationError) => {
              console.log('Google current location Error: ', locationError);
            },
            {
              enableHighAccuracy: true,
              timeout: 10000,
              maximumAge: 0,
            }
          );
        } else {
          setShowModal(true); // denied
        }
      });
  };

  const onInfoWindowClose = () => console.log('InfoWindow Closed');

  const onMarkerDragEnd = (event) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();
    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        const addressArray = response.results[0].address_components;
        const city = getCity(addressArray);
        const state = getState(addressArray);
        const zipCode = getPostalCode(addressArray);
        setInfoAddress(address);
        setMapPosition({ lat: newLat, lng: newLng });
        setMarkerPosition({ lat: newLat, lng: newLng });
        const data = {
          home_address: address,
          city,
          state,
          zipCode,
          latitude: newLat,
          longitude: newLng,
        };
        props.dataFnc(data);
      },
      (error) => console.error(error)
    );
  };

  const mapURL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;

  React.useEffect(() => {
    if (!selection) return;

    (async () => {
      const results = await geocodeByPlaceId(selection.value.place_id).catch((error) =>
        console.error(error)
      ); // -------------  Can use geocodeByAddress as well

      const latlng = await getLatLng(results[0]);
      const address = results[0].formatted_address;
      const addressArray = results[0].address_components;
      const city = getCity(addressArray);
      const state = getState(addressArray);
      const zipCode = getPostalCode(addressArray);

      setMarkerPosition(latlng);
      setMapPosition(latlng);
      setInfoAddress(address);
      const data = {
        home_address: address,
        city,
        state,
        zipCode,
        latitude: latlng.lat,
        longitude: latlng.lng,
      };
      props.dataFnc(data);
    })();
  }, [selection]);

  const AsyncMap = withGoogleMap(() => (
    <>
      <GoogleMap defaultZoom={zoom} defaultCenter={{ lat: mapPosition.lat, lng: mapPosition.lng }}>
        {/* InfoWindow on top of marker */}
        <InfoWindow
          onClose={onInfoWindowClose}
          position={{ lat: markerPosition.lat + 0.0018, lng: markerPosition.lng }}
        >
          <div>
            <span style={{ padding: 0, margin: 0 }}>{infoAddress}</span>
          </div>
        </InfoWindow>
        {/* Marker */}
        <Marker
          draggable
          onDragEnd={onMarkerDragEnd}
          position={{ lat: markerPosition.lat, lng: markerPosition.lng }}
        />
        <Marker />
      </GoogleMap>
    </>
  ));

  const dialogConfig = {
    open: showModal,
    textAlign: 'left',
    btnClicked: okBtnHandler,
  };

  let map;
  if (center.lat !== undefined) {
    map = (
      <>
        <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
          <div style={{ paddingLeft: '5px', paddingRight: '20px', paddingTop: '10px' }}>
            <GooglePlacesAutocomplete
              inputStyle={{
                border: '1px solid #000',
                width: '98%',
                height: '30px',
                paddingLeft: '10px',
              }}
              suggestionsClassNames={{
                container: classes.suggestionContainer,
                suggestion: classes.suggestion,
                suggestionActive: classes.suggestionActive,
              }}
              placeholder="Search"
              loader={<img src={loader} alt="loading ..." />}
              selectProps={{
                value: selection,
                onChange: setSelection,
              }}
            />
            <br />
            <br />
            <AsyncMap
              googleMapURL={mapURL}
              loadingElement={<div style={{ height: '100%' }} />}
              containerElement={<div style={{ height }} />}
              mapElement={<div style={{ height: '100%' }} />}
            />
          </div>
          <br />
        </div>
        <div>
          <span className={classes.locateMe} role="presentation" onClick={currentLocationHandler}>
            Locate Me
            <img
              src={iconElem.navigateIcon}
              alt=">"
              style={{ width: '12px', paddingLeft: '5px' }}
            />
          </span>
        </div>
        {showModal && (
          <CustomDialog dialogConfig={dialogConfig}>
            <div>{msgConstant.ENABLE_LOCATION}</div>
          </CustomDialog>
        )}
      </>
    );
  } else {
    map = <div style={{ height }} />;
  }
  return map;
};

export default Map;
