import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import ActThumbnail from './ActThumbnail/ActThumbnail';
import LoadIcon from '../ui/LoadIcon/LoadIcon';
import { convertTimestamp } from '../common/utils';
import classes from './Activity.module.css';

const Activities = (props) => {
  const { list, clicked } = props;
  const activityTypes = ['person', 'sound', 'motion', 'Recording'];
  const [actElem, setActElem] = useState(null);

  const RenderThumbnail = () => (
    <ActThumbnail
      thumbnailLInk={
        list.ThumbnailLInk[1] === undefined ? list.ThumbnailLInk[0] : list.ThumbnailLInk[1]
      }
      altInfo={list.ActivityType}
    />
  );

  const RenderActivityInfo = () => (
    <div>
      <span>
        <LoadIcon iconType={list.ActivityType} iconClass={classes.icon} />
        <span className="Font13" style={{ textTransform: 'capitalize' }}>
          {activityTypes.indexOf(list.ActivityType) !== -1 ? list.ActivityType : 'Unknown'}
        </span>
      </span>
      <span className={classes.time}>{convertTimestamp(list.Timestamp, true)}</span>
    </div>
  );

  useEffect(() => {
    const elem = (
      <Box className={classes.activityListItems} onClick={() => clicked(list)} role="presentation">
        <RenderThumbnail />
        <RenderActivityInfo />
      </Box>
    );
    setActElem(elem);
  }, []);

  return actElem;
};

export default Activities;
