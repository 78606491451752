import * as actionTypes from './action-types';

const intialState = {
  cropBoxInfo: [],
  cropObj: {},
  refreshCam: false
};

const updateObject = (prevState, updatedValues) => (
  {
    ...prevState,
    ...updatedValues
  }
);

const reducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CROPBOX_INFO:
      return updateObject(state, { cropBoxInfo: action.data.zoneArray, cropObj: action.data.crop });
    case actionTypes.RFRESH_CAM_VIEW:
      return updateObject(state, { refreshCam: action.data });
    default: return state;
  }
};

export default reducer;
