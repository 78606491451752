// REQUIREMENT : 4.1.9 Event History / Stored Video Lists

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { setActiveDevices, setActivityUpdated, setFilterIconActive } from '../store/action-creator';
import ButtonElem from '../../ui/Button/Button';
import Notification from '../../ui/Notification/Notification';
import msgConstant from '../../common/textConstants';

let devIDArray = null;
let filterIconStatus = false;

const DeviceFilter = () => {
  const [cameras, setCameras] = useState(null);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const deviceResp = useSelector((state) => state.deviceReducer.deviceResp);
  const activeDevices = useSelector((state) => state.activityReducer.activeDevices);
  const filterIconActive = useSelector((state) => state.activityReducer.filterIconActive);
  const allEvents = useSelector((state) => state.activityReducer.allEvents);

  const smallBtn = {
    fontSize: 9.1,
    fontFamily: 'bookFont',
    height: '19.6px',
    padding: '0 12px',
  };

  useEffect(
    () => () => {
      devIDArray = null;
      filterIconStatus = false;
    },
    []
  );

  useEffect(() => {
    if (activeDevices) {
      if (activeDevices.devIDs.length) {
        devIDArray = activeDevices.devIDs;
      }
    }
  }, [activeDevices]);

  useEffect(() => {
    if (filterIconActive) {
      filterIconStatus = true;
    }
  }, [filterIconActive]);

  const getDeviceID = () => {
    const obj = {
      homeID: deviceResp?.deviceData?.HomeId,
      devIDs: [],
    };
    return obj;
  };

  /* [#84700]-
  After applying the filter to one device that device date is not goes off still it is displaying
  (screen shot attached). */
  const reloadEvents = () => {
    const deviceObj = getDeviceID();
    deviceObj.devIDs = devIDArray;
    dispatch(setActiveDevices(deviceObj));
    dispatch(setActivityUpdated(true));
  };

  const handleDeviceFilter = (id) => {
    if (filterIconStatus) {
      dispatch(setFilterIconActive(false));
    }
    const greyBtn = 'GreyButton';
    const elem = document.querySelector(`#devID_${id}`);
    const index = devIDArray.indexOf(id);
    // already exists devices
    if (index > -1) {
      /* [#84702] After applying activity type filter,if only one device is present
      in activity page we can filter that device also */
      if (devIDArray.length === 1) {
        setOpen(true);
      } else {
        elem.classList.add(greyBtn);
        devIDArray.splice(index, 1);
        reloadEvents();
      }
    } else {
      devIDArray.push(id);
      elem.classList.remove(greyBtn);
      reloadEvents();
    }
  };

  const renderCameras = (cameraList) => {
    const elem = cameraList.map((list) => (
      <Grid item key={list.DeviceId}>
        <ButtonElem
          btnColor="BlackButton"
          value={list.DisplayName}
          btnSize="small"
          style={smallBtn}
          btnId={`devID_${list.DeviceId}`}
          clicked={() => handleDeviceFilter(list.DeviceId)}
        />
      </Grid>
    ));
    setCameras(elem);
  };

  useEffect(() => {
    if (deviceResp) {
      const data = getDeviceID();
      dispatch(setActiveDevices(data));
    }
  }, []);

  useEffect(() => {
    if (allEvents) {
      if (allEvents.deviceList) {
        const ids = allEvents.deviceList.map((item) => item.DeviceId);
        if (devIDArray === null) {
          devIDArray = ids;
          renderCameras(allEvents.deviceList);
        } else {
          const isDiffArray = devIDArray.filter((e) => ids.indexOf(e) < 0);
          if (isDiffArray.length) {
            devIDArray = ids;
            renderCameras(allEvents.deviceList);
          }
        }
      }
    }
  }, [allEvents]);

  const config = {
    position: 'bottomRight',
    open,
    onClose: () => setOpen(false),
  };

  return (
    <>
      {cameras}
      {open && <Notification config={config}>{msgConstant.SINGLE_DEVICE_FILTER}</Notification>}
    </>
  );
};

export default DeviceFilter;
