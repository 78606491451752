import React, { Component } from 'react';

class CanvasComp extends Component {
  componentDidMount() {
    const { canvas, image } = this.refs;
    const ctx = canvas.getContext('2d');
    const img = image;
    img.onload = () => ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  }

  componentDidUpdate() {
    const { shouldConvert, onUploadPhoto } = this.props;
    if (shouldConvert) {
      const canvas = document.getElementById('canvas');
      const dataURL = canvas.toDataURL('image/jpeg');
      const buf = new Buffer.from(dataURL.replace(/^data:image\/\w+;base64,/, ''), 'base64');
      const type = dataURL.split(';')[0].split('/')[1];
      onUploadPhoto(type, buf);
    }
  }

  render() {
    const { imageSrc } = this.props;
    return (
      <>
        <canvas ref="canvas" id="canvas" style={{ display: 'none' }} />
        <img
          ref="image"
          src={imageSrc}
          alt="Act"
          style={{ display: 'none' }}
          crossOrigin="anonymous"
        />
      </>
    );
  }
}

export default CanvasComp;
