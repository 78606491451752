import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ButtonWrapper from './ButtonWrapper';
import VideoDownload from './VideoDownload';
import VideoFavourite from './VideoFavourite';
import VideoShare from './VideoShare';
import DeleteCurrentActivity from '../../Activity/DeleteCurrentActivity/DeleteCurrentActivity';

const VJSActionButtons = (props) => {
  const { config } = props;
  const [actionBtn, setActionBtn] = useState(null);

  const insertButtonToController = () => {
    const btns = document.querySelector(`#${config.btnId}`);
    if (btns) {
      btns.style.visibility = 'visible';
      const controlBar = document.getElementsByClassName('vjs-control-bar')[0];
      controlBar.appendChild(btns);
    }
  };

  useEffect(() => {
    if (config) {
      let btn = null;
      switch (config.btnType) {
        case 'ScreenShot':
          btn = <ButtonWrapper {...props} clicked={() => config.clicked(true)} />;
          break;
        case 'Download':
          btn = <VideoDownload {...props} />;
          break;
        case 'Star':
          btn = <VideoFavourite {...props} />;
          break;
        case 'Delete':
          btn = (
            <DeleteCurrentActivity>
              <ButtonWrapper {...props} />
            </DeleteCurrentActivity>
          );
          break;
        case 'Share':
          btn = <VideoShare {...props} />;
          break;
        default:
          break;
      }

      setActionBtn(btn);
      setTimeout(() => {
        insertButtonToController();
      }, 200);
    }
  }, []);

  return actionBtn;
};

export default withRouter(VJSActionButtons);
