import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import { connect } from 'react-redux';
import CanvasComp from './CanvasComp';

import msgConstant from '../../../common/textConstants';
import CustomDialog from '../../../ui/CustomDialog/CustomDialog';
import { forwardBlueIcon } from '../../../ui/Icons/Icons';
import ButtonElem from '../../../ui/Button/Button';
import ProfilePhoto from '../../ProfilePhoto';

import LoadingSpinner from '../../../ui/LoadingSpinner/LoadingSpinner';
import * as profileActionCreator from '../../store/action-creator';
import classes from '../../Profile.module.css';
import { AuthContext } from '../../../../Providers/AuthProvider';

const EditProfile = (props) => {
  const { updateName, user, uploadProfilePhoto } = React.useContext(AuthContext);
  const { onSelectedMenu, onPhotoUpdated } = props;

  const [initial] = useState({ profileName: user.userName, profilePic: user.photo });
  const [spinner, setSpinner] = useState(false);
  const [photo, setPhoto] = useState(user.photo);
  const [name, setName] = useState(user.userName || '');
  const [error, setError] = useState(false);
  const [convert, setConvert] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [file, setFile] = useState(false);

  const toggleSpinner = (data) => setSpinner(data);
  const warningBtnHandler = () => setImageModal(!imageModal);

  const dialogConfig = {
    open: imageModal,
    btnClicked: warningBtnHandler,
  };

  useEffect(() => () => onPhotoUpdated(false), []);

  const onChangeHandler = (e) => {
    const validExtensions = ['png', 'jpeg', 'jpg'];
    const fileLocal = e.target.files[0];
    const fileName = fileLocal.name;
    let fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
    fileNameExt = fileNameExt.toLowerCase();
    if (validExtensions.indexOf(fileNameExt) !== -1) {
      setFile(fileLocal);
      const reader = new FileReader();
      reader.readAsDataURL(fileLocal);
      reader.onloadend = () => setPhoto(reader.result);
    } else warningBtnHandler();
  };

  const handleMenuItem = (menu) => onSelectedMenu(menu);
  const onCancelHandler = () => handleMenuItem(1);

  const onNameChange = (e) => {
    setName(e.target.value);
    if (e.target.value.length > 256) {
      setError(true);
    } else setError(false);
  };

  const nameUpdate = async () => {
    try {
      await updateName(name);
      toggleSpinner(false);
      handleMenuItem(1);
    } catch (error) {
      console.error(error);
    }
  };

  const uploadPhoto = (fileLocal) => {
    uploadProfilePhoto(fileLocal)
      .then(() => {
        setConvert(false);
        onPhotoUpdated(true);
        handleMenuItem(1);
      })
      .catch((e) => console.log('Photo not uploaded', e))
      .finally(() => {
        onPhotoUpdated(false);
        setConvert(false);
        toggleSpinner(false);
      });
  };

  // #84523 Profile pic is vanishing if user edit the name (trackeritem-84523)
  const onSaveHandler = () => {
    if (name !== initial.profileName) {
      toggleSpinner(true);
      nameUpdate();
    }
    if (photo !== initial.profilePic) {
      toggleSpinner(true);
      if (photo.indexOf('image/jpeg') !== -1) {
        // eslint-disable-next-line new-cap
        // const data = new Buffer.from(photo.replace(/^data:image\/\w+;base64,/, ''), 'base64');
        // const photoType = photo.split(';')[0].split('/')[1];
        uploadProfilePhoto(file)
          .then(() => {
            onPhotoUpdated(true);
            toggleSpinner(false);
            handleMenuItem(1);
          })
          .catch(() => toggleSpinner(false))
          .finally(() => {
            onPhotoUpdated(false);
            toggleSpinner(false);
          });
      } else setConvert(true);
    }
    if (photo === initial.profilePic && name === initial.profileName) {
      handleMenuItem(1);
    }
  };

  return (
    <>
      <LoadingSpinner spinnerConfig={{ showSpinner: spinner }} />
      <>
        <div className={classes.headerText}>Profile</div>
        <ProfilePhoto path={photo} />

        <input
          accept="image/x-png,image/jpeg, image/jpg"
          className={classes.profilePhoto}
          id="browse_File"
          type="file"
          onChange={onChangeHandler}
        />
        <div>
          <label htmlFor="browse_File" style={{ paddingLeft: '1.5em' }}>
            <span className={[classes.changeText, 'Font10'].join(' ')}>Change Photo</span>
          </label>
        </div>
        <div className={classes.borderBottom}>
          <Grid container>
            <Grid item sm={3} className={classes.greyLabel} style={{ paddingTop: '1.6em' }}>
              Name
            </Grid>
            <Grid item className={classes.textStyle}>
              <Input
                className="CustomInput ProfileInput"
                value={name}
                onChange={(evt) => onNameChange(evt)}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.borderBottom}>
          <Grid container>
            <Grid item sm={3} className={classes.greyLabel}>
              Email
            </Grid>
            <Grid item className={classes.textStyle}>
              {user.userEmail}
            </Grid>
          </Grid>
        </div>
        <div className={classes.borderBottom}>
          <Grid container>
            <Grid item sm={3} className={classes.greyLabel}>
              Password
            </Grid>
            <Grid item className={classes.textStyle}>
              <span
                className={[classes.changeText, 'Font14'].join(' ')}
                role="presentation"
                onClick={() => handleMenuItem(4)}
              >
                Change Password
                <img src={forwardBlueIcon} alt="Forward" className={classes.forwardIcon} />
              </span>
            </Grid>
          </Grid>
        </div>
        <div className={classes.buttonSet}>
          <ButtonElem
            btnColor="YellowButton"
            value="Save Changes"
            btnType="submit"
            style={{ width: 172, margin: '35px' }}
            clicked={onSaveHandler}
            isbtndisabled={name === '' || name === undefined || error}
          />
          <ButtonElem
            btnColor="GreyButton"
            value="Cancel"
            btnType="submit"
            style={{ width: 172 }}
            clicked={onCancelHandler}
          />
          <CanvasComp imageSrc={photo} shouldConvert={convert} onUploadPhoto={uploadPhoto} />
        </div>
        {imageModal && (
          <CustomDialog dialogConfig={dialogConfig}>
            <div>{msgConstant.IMAGE_TYPE_CHECK}</div>
          </CustomDialog>
        )}
      </>
    </>
  );
};

const mapStateToProps = (state) => ({
  updated: state.profileReducer.photoUpdated,
});

const mapDispatchToProps = (dispatch) => ({
  onSelectedMenu: (data) => dispatch(profileActionCreator.setProfileMenu(data)),
  onPhotoUpdated: (data) => dispatch(profileActionCreator.setPhotoUpdated(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
