import React, { useEffect, useState } from 'react';
import { PubSub } from 'aws-amplify';
import { Range } from 'react-range';
import { connect } from 'react-redux';
import * as deviceActionCreator from '../../DeviceList/store/action-creator';
import { objectHasKey } from '../../common/utils';
import classes from './PanSlider.module.css';

const PanSlider = (props) => {
  const { MQTTInfo, deviceID, isMQTTUpdated, onSetMQTTUpdated } = props;

  const [range, setRange] = useState([0]);
  const [isLoaded, setLoaded] = useState(true);
  const [panSliderComp, setPanSliderComp] = useState(null);

  const updatePanSliderRange = (val) => {
    const tempRange = [];
    tempRange.push(val);
    setRange(tempRange);
  };

  useEffect(() => {
    if (MQTTInfo && isLoaded) {
      setLoaded(false);
      if (objectHasKey(MQTTInfo, 'desired')) {
        if (objectHasKey(MQTTInfo.desired, 'horizontalAngle')) {
          updatePanSliderRange(MQTTInfo.desired.horizontalAngle);
        }
      }
    }
  }, [MQTTInfo]);

  useEffect(() => {
    if (isMQTTUpdated) {
      if (objectHasKey(MQTTInfo.desired, 'horizontalAngle')) {
        const currentVal = MQTTInfo.desired.horizontalAngle;
        if (currentVal !== range[0]) {
          updatePanSliderRange(currentVal);
          onSetMQTTUpdated();
        }
      }
    }
  }, [isMQTTUpdated]);

  const updateStatus = (newStatus) => {
    if (MQTTInfo) {
      PubSub.publish(`$aws/things/${deviceID}/shadow/update`, { state: { desired: newStatus } });
    }
  };

  const updateRangeHandler = (values) => {
    updateStatus({ horizontalAngle: parseInt(values[0], 10) });
    updatePanSliderRange(values[0]);
  };

  useEffect(() => {
    const comp = (
      <div>
        <Range
          step={5}
          min={-130}
          max={130}
          values={range}
          onChange={(values) => updateRangeHandler(values)}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              className={classes.sliderWrap}
              style={{ ...props.style }}
              role="presentation"
            >
              <div ref={props.ref} className={classes.sliderBar}>
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props }) => (
            <div {...props} className={classes.sliderThumbWrap} style={{ ...props.style }}>
              <div className={classes.sliderThumb}>
                {range}
                &deg;
              </div>
              <div />
            </div>
          )}
        />
      </div>
    );
    setPanSliderComp(comp);
  }, [range]);

  return panSliderComp;
};

const mapStateToProps = (state) => ({
  MQTTInfo: state.deviceReducer.MQTTInfo,
  deviceID: state.deviceReducer.deviceInfo.DeviceId,
  isMQTTUpdated: state.deviceReducer.MQTTUpdated,
});

const mapDispatchToProps = (dispatch) => ({
  onSetMQTTUpdated: () => dispatch(deviceActionCreator.setMQTTUpdated(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PanSlider);
