import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector, useDispatch } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { getTimezoneList } from '../store/action-creator';
// import { getAllZoneName } from '../../common/utils';
import TypographyElem from '../../ui/Typography/Typography';
import ScrollArea from '../../ui/ScrollBar/ScrollArea';
import CustomDialog from '../../ui/CustomDialog/CustomDialog';
import LoadingSpinner from '../../ui/LoadingSpinner/LoadingSpinner';
import classes from './Timezone.module.css';
import { AuthContext } from '../../../Providers/AuthProvider';

const TimezoneList = (props) => {
  const { user } = React.useContext(AuthContext);
  const userEmail = user.userEmail;
  const { zoneName, latestSelectedZone } = props;
  const [currentZone, setCurrentZone] = useState(zoneName);
  const [zoneList, setZoneList] = useState(null);
  const [tmpZoneList, setTmpZoneList] = useState(null);
  const [commonError, setCommonError] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState(null);
  const [spinner, setSpinner] = useState(false);

  const dispatch = useDispatch();
  const timezoneList = useSelector((state) => state.homeListReducer.timezoneList);

  const checkedHandler = (name) => {
    setCurrentZone(name);
    latestSelectedZone(name);
  };

  useEffect(() => {
    if (userEmail && timezoneList == null) {
      setSpinner(true);
      dispatch(getTimezoneList(userEmail))
        .then(
          (resp) => {
            if (resp.errorCode === null && resp.zoneList) {
              setZoneList(resp);
            } else if (resp.errorMsg) {
              setCommonError(true);
              setCommonErrorMsg(resp.errorMsg);
              setZoneList('no zonename');
            }
          },
          (err) => {
            setCommonError(true);
            setCommonErrorMsg(err);
            setZoneList('no zonename');
          }
        )
        .finally(() => setSpinner(false));
    }
  }, []);

  useEffect(() => {
    if (timezoneList) {
      if (timezoneList.errorCode === null && timezoneList.zoneList) {
        setZoneList(timezoneList.zoneList);
        setTmpZoneList(timezoneList.zoneList);
      }
    }
  }, [timezoneList]);

  const AllZoneNamesList = ({ zones }) =>
    zones.map((list) => (
      <Grid item className={classes.zoneName} key={list}>
        <FormControlLabel
          className="CustomCheckbox"
          control={
            <Checkbox
              checked={list === currentZone}
              style={{ color: '#FFD200' }}
              onChange={() => checkedHandler(list)}
              value={list}
              name="zoneCB"
            />
          }
          label={<span style={{ fontSize: '14px' }}>{list}</span>}
        />
      </Grid>
    ));

  const inputChangeHandler = (evt) => {
    let searchText = evt.target.value.trim();
    if (searchText !== '') {
      searchText = searchText.toLowerCase();
      let filteredList = [];
      // filteredList = tmpZoneList.filter(item => new RegExp(searchText, 'i').test(item));
      filteredList = tmpZoneList.filter((item) => item.toLowerCase().indexOf(searchText) !== -1);
      setZoneList(filteredList);
    } else {
      setZoneList(tmpZoneList);
    }
  };

  const spinnerConfig = {
    showSpinner: spinner,
    left: '40%',
  };

  const errorDialogConfig = {
    open: commonError,
    textAlign: 'left',
    btnClicked: () => setCommonError(false),
  };

  return (
    <>
      <LoadingSpinner spinnerConfig={spinnerConfig} />
      <TypographyElem content="Setup Time Zone" />
      <div style={{ marginTop: '2em' }}>Select Time Zone</div>
      <div>
        <input
          type="text"
          name="zonename"
          className={classes.searchZoneInput}
          onChange={(evt) => inputChangeHandler(evt)}
        />
      </div>
      <ScrollArea scrollClass={classes.allZoneNames} scrollMaxHeight="calc(100vh - 29em)">
        {zoneList ? (
          zoneList !== 'no zonename' ? (
            <Grid container style={{ paddingRight: 15 }}>
              <AllZoneNamesList zones={zoneList} />
            </Grid>
          ) : (
            <div style={{ padding: 10 }}>Zonename list not found</div>
          )
        ) : null}
      </ScrollArea>
      {commonError && (
        <CustomDialog dialogConfig={errorDialogConfig}>
          <div>{commonErrorMsg}</div>
        </CustomDialog>
      )}
    </>
  );
};

export default TimezoneList;
