import React, { useEffect, useState } from 'react';
import ActThumbnail from '../ActThumbnail/ActThumbnail';
import { secondsToHms } from '../../common/utils';
import classes from './ActivityList.module.css';

const ActivityItemRightContent = (props) => {
  const { list } = props;
  const [actImg, setActImg] = useState(null);

  const getImageSize = (duration) => {
    let imgClassName = null;
    if (duration <= 50) {
      imgClassName = classes.extraSmallVideo;
    } else if (duration > 50 && duration <= 100) {
      imgClassName = classes.smallVideo;
    } else if (duration > 100 && duration <= 200) {
      imgClassName = classes.normalVideo;
    } else if (duration > 200) {
      imgClassName = classes.largeVideo;
    }
    return imgClassName;
  };

  const getThumbnailImg = (listElem) => (listElem[1] === undefined ? listElem[0] : listElem[1]);

  useEffect(() => {
    if (list) {
      const elem = (
        <span className={classes.rightContent}>
          <ActThumbnail
            thumbnailLInk={getThumbnailImg(list.ThumbnailLInk)}
            altInfo={list.ActivityType}
            imgClass={getImageSize(list.DurationSeconds)}
          />
          {+list.DurationSeconds > 0 && (
            <span className={classes.videoTime}>{secondsToHms(+list.DurationSeconds)}</span>
          )}
        </span>
      );
      setActImg(elem);
    }
  }, []);

  return actImg;
};

export default ActivityItemRightContent;
