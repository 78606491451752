import * as actionTypes from './action-types';

const intialState = {
  notifications: null
};

const updateObject = (prevState, updatedValues) => (
  {
    ...prevState,
    ...updatedValues
  }
);

const reducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_NOTIFICATION:
      return updateObject(state, { notifications: action.notifications });
    default: return state;
  }
};

export default reducer;
