import axiosInstance from '../../../axiosInstance';
import * as actionTypes from './action-types';

export const setProfileMenu = (menu) => ({
  type: actionTypes.SET_PROFILE_MENU,
  menu,
});

export const setPreviousMenu = (prevMenu) => ({
  type: actionTypes.SET_PREVIOUS_MENU,
  previousMenu: prevMenu,
});

export const setPhotoUpdated = (update) => ({
  type: actionTypes.SET_PHOTO_UPDATED,
  update,
});

export const setTCVersion = (version) => ({
  type: actionTypes.SET_TC_VERSION,
  version,
});

export const getTCVersion = (params) => (dispatch) => {
  const result = {
    errorCode: null,
    errorMsg: null,
  };
  const promise = new Promise((resolve, reject) => {
    axiosInstance
      .post('terms-condition', params)
      .then((resp) => {
        if (resp.data.ResponseCode === 200) {
          if (resp.data.ErrorCode === 'err_0') {
            const version = resp.data.ResponseData;
            const obj = {
              termsCondition: version['terms-condition'],
              privacyPolicy: version['privacy-policy'],
            };
            dispatch(setTCVersion(obj));
          } else {
            result.errorCode = resp.data.ErrorCode;
            result.errorMsg = resp.data.body;
          }
        }
        resolve(result);
      })
      .catch(() => {
        const errors = 'Something went wrong 7';
        reject(errors);
        dispatch(setTCVersion(null));
      });
  });

  return promise;
};

export const resetTCVersion = () => (dispatch) => dispatch(setTCVersion(null));
