import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import { ACTIVITY_DETAILS } from '../../constants/routes';
import * as actionCreator from './store/action-creator';
import TypographyElem from '../ui/Typography/Typography';
import CustomDialog from '../ui/CustomDialog/CustomDialog';
import LoadingSpinner from '../ui/LoadingSpinner/LoadingSpinner';
import ScrollArea from '../ui/ScrollBar/ScrollArea';
import { getZoneName } from '../common/utils';
import ErrorCodes from '../common/ErrorCodes';
import Activities from './Activities';
import AllActivity from './AllActivity/AllActivity';
import classes from './Activity.module.css';
import { AuthContext } from '../../Providers/AuthProvider';

const Activity = (props) => {
  const { user } = React.useContext(AuthContext);

  const [events, setEvents] = useState(null);
  const [commonError, setCommonError] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState(null);

  const dispatch = useDispatch();
  const userEmail = user.userEmail;
  const deviceResp = useSelector((state) => state.deviceReducer.deviceResp);

  const resetVariables = () => {
    setEvents(null);
    setCommonError(false);
    setCommonErrorMsg(null);
  };

  const eventSuccessResp = (resp) => {
    if (resp.errorCode === null && resp.eventData) {
      setEvents(resp.eventData);
    } else if (resp.errorCode === 'err_105') {
      setEvents('no events');
    } else if (resp.errorMsg) {
      setCommonError(true);
      setCommonErrorMsg(resp.errorMsg);
      setEvents('no events');
    }
  };

  const eventErrorResp = (err) => {
    setCommonError(true);
    setCommonErrorMsg(err);
    setEvents('no events');
  };

  const gethomePageActivities = (params) => {
    resetVariables();
    dispatch(actionCreator.getEventList(params)).then(eventSuccessResp, eventErrorResp);
  };

  useEffect(() => {
    if (userEmail && deviceResp) {
      const params = {
        action: 'list_events',
        data: {
          email: userEmail,
          event_count: 5,
          home_id: deviceResp?.deviceData?.HomeId,
          timezone: getZoneName(),
        },
      };
      gethomePageActivities(params);
    }
  }, [deviceResp]);

  const activityDetailsHandler = (obj) => {
    const deviceIDs = deviceResp.deviceData.DeviceList.map((item) => item.DeviceId);
    const data = {
      email: userEmail,
      device_ids: deviceIDs,
    };
    dispatch(actionCreator.getDevicePlanInfo(data));
    dispatch(actionCreator.selectedEvent(obj));
    props.history.push(ACTIVITY_DETAILS);
  };

  const HasActivity = () => (
    <ScrollArea scrollClass={classes.activityList} autoHide={false}>
      <Box display="flex" flexDirection="row">
        {events.map((list) => (
          <Activities list={list} key={list.ActivityID} clicked={activityDetailsHandler} />
        ))}
        <Box>
          <AllActivity
            clicked={() => {
              dispatch(actionCreator.setIsAllActivity(true));
              activityDetailsHandler(events[0]);
            }}
          />
        </Box>
      </Box>
    </ScrollArea>
  );

  const HasNoActivity = () => <div id="NoActivities">{ErrorCodes.err_105}</div>;

  const spinnerConfig = {
    showSpinner: true,
    top: '65%',
  };

  const errorDialogConfig = {
    open: commonError,
    textAlign: 'left',
    btnClicked: () => setCommonError(false),
  };

  return (
    <>
      <TypographyElem content="Activity" />
      {events ? (
        events !== 'no events' ? (
          <HasActivity />
        ) : (
          <HasNoActivity />
        )
      ) : (
        <LoadingSpinner spinnerConfig={spinnerConfig} />
      )}
      {commonError && (
        <CustomDialog dialogConfig={errorDialogConfig}>
          <div>{commonErrorMsg}</div>
        </CustomDialog>
      )}
    </>
  );
};

export default Activity;
