import React from 'react';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const ShareTooltip = props => {
  const { videoPath } = props;

  const doneBtnHandler = () => {
    const toolTipComp = document.querySelector('#shareVJSBtn .tooltiptext');
    toolTipComp.style.visibility = 'hidden';
  };

  const copyBtnHandler = () => {
    const copyText = document.querySelector('#shareVJSBtn .shareUrl');
    copyText.select();
    document.execCommand('copy');
    doneBtnHandler();
  };

  const spinnerConfig = {
    showSpinner: true,
    color: '#0970C8'
  };

  return (
    <div className="tooltiptext">
      Share this link with anyone you'd like to see the video.
      <div className="linkWrapper">
        <div style={{ marginRight: 5 }}>Link:</div>
        {
          videoPath && <input type="text" defaultValue={videoPath} className="shareUrl" />
        }
        {
          videoPath === null && (
            <span style={{ position: 'absolute', top: 27, left: '55%' }}>
              <LoadingSpinner spinnerConfig={spinnerConfig} size={20} />
            </span>
          )
        }
      </div>
      <div className="shareButtonWrap">
        <button type="button" className="copyButton" onClick={copyBtnHandler}>Copy link</button>
        <button type="button" className="doneButton" onClick={doneBtnHandler}>done</button>
      </div>
    </div>
  );
};

export default ShareTooltip;
