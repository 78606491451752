import merge from 'lodash/merge';
import * as actionTypes from './action-types';

const intialState = {
  deviceResp: null,
  deviceInfo: null,
  videoPath: null,
  MQTTInfo: null,
  dispActivityZone: false,
  alertTypes: null,
  FMRadioStatus: 0,
  isLoader: false,
  alertLocation: '',
  liveVideoArchiveId: null,
  startTimer: false,
  devPlanInfo: {},
  isSaveClicked: false,
  apiError: null,
  MQTTUpdated: false,
  selectedCamera: null,
};

const updateObject = (prevState, updatedValues) => ({
  ...prevState,
  ...updatedValues,
});

const reducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DEVICES:
      return updateObject(state, { deviceResp: action.devResp });
    case actionTypes.SET_DEVICES:
      return updateObject(state, { deviceResp: action.deviceResp });
    case actionTypes.SET_DEVICE_INFO:
      return updateObject(state, { deviceInfo: action.deviceInfo });
    case actionTypes.GET_VIDEO_PATH:
      return updateObject(state, { videoPath: action.videoPath });
    case actionTypes.SET_MQTT_INFO: {
      let newData = action.mqttData;
      if (state.MQTTInfo) {
        newData = merge(state.MQTTInfo, newData);
      }
      return updateObject(state, { MQTTInfo: newData });
    }
    case actionTypes.RESET_MQTT_INFO:
      return updateObject(state, { MQTTInfo: null });
    case actionTypes.GET_CONNECTION_STATUS:
      return updateObject(state, { conncectionStatus: action.statusInfo });
    case actionTypes.DISPLAY_ACTIVITY_ZONE:
      return updateObject(state, { dispActivityZone: action.val });
    case actionTypes.UPDATE_MQTT:
      return updateObject(state, { MQTTInfo: action.newInfo });
    case actionTypes.SET_ALERT_TYPE:
      return updateObject(state, { alertTypes: action.alertTypes });
    case actionTypes.FM_RADIO_STATUS:
      return updateObject(state, { FMRadioStatus: action.fmStatus });
    case actionTypes.SET_LOADER:
      return updateObject(state, { isLoader: action.value });
    case actionTypes.SET_ALERT_LOCATION:
      return updateObject(state, { alertLocation: action.alertLocation });
    case actionTypes.RECORD_LIVE_VIEDO:
      return updateObject(state, {
        liveVideoArchiveId: action.archiveId,
        startTimer: action.timer,
      });
    case actionTypes.GET_DEVICE_PLAN_INFO:
      if (action.devPlanDetails.DevicePlanDetails[0].PlanStatus !== 'active') {
        return updateObject(state, { devPlanInfo: action.devPlanDetails.FreePlanDetails[0] });
      }
      return updateObject(state, { devPlanInfo: action.devPlanDetails.DevicePlanDetails[0] });
    case actionTypes.ACTIVITY_ZONE_SAVE:
      return updateObject(state, { isSaveClicked: action.data });
    case actionTypes.SET_API_ERROR:
      return updateObject(state, { apiError: action.error });
    case actionTypes.SET_MQTT_UPDATED:
      return updateObject(state, { MQTTUpdated: action.MQTTUpdated });
    case actionTypes.SELECTED_CAMERA:
      return updateObject(state, { selectedCamera: action.selectedCamera });
    default:
      return state;
  }
};

export default reducer;
