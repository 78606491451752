// REQUIREMENT: 4.1.16 'Home' Group Management

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import SubHeader from '../../ui/SubHeader';
import TypographyElem from '../../ui/Typography/Typography';
import Map from '../Map/Map';
import EditHomeForm from './EditHomeForm';
import classes from '../ManageHomes.module.css';
import ScrollArea from '../../ui/ScrollBar/ScrollArea';
import ErrorBoundary from '../../../ErrorBoundary/ErrorBoundary';
import { AuthContext } from '../../../Providers/AuthProvider';

const EditHome = (props) => {
  const { user } = React.useContext(AuthContext);
  const emailId = user.userEmail;
  const { location, google } = props;

  const [changedLocation, setChangedLocation] = useState({});

  const homeSelected = useSelector((state) => state.homeListReducer.selectedHome);

  useEffect(() => {
    setChangedLocation(homeSelected);
  }, []);

  const onLocationChanged = (data) => setChangedLocation(data);

  // const homePageHandler = () => props.history.push(location.state.prevPath);
  const homePageHandler = () => props.history.goBack();

  return (
    <ErrorBoundary>
      <ScrollArea>
        <SubHeader clicked={homePageHandler} {...props} style={{ paddingLeft: 18 }} />
        <div style={{ padding: '10px 20px' }}>
          <TypographyElem content="Edit Home" />
        </div>
        {changedLocation.latitude && (
          <Grid container style={{ padding: '0 20px 20px 5px' }}>
            <Grid item xs={4} className={classes.topPadding}>
              <EditHomeForm
                Location={changedLocation}
                path={location.state.prevPath}
                DeviceId={location.state.prevDeviceId}
              />
            </Grid>
            <Grid item xs={6}>
              <Map
                google={google}
                center={{
                  lat: parseFloat(changedLocation.latitude, 10),
                  lng: parseFloat(changedLocation.longitude, 10),
                }}
                height="360px"
                width="70%"
                email={emailId}
                zoom={15}
                dataFnc={onLocationChanged}
                initialLocation={changedLocation.home_address}
                formType="editHome"
              />
            </Grid>
          </Grid>
        )}
      </ScrollArea>
    </ErrorBoundary>
  );
};

export default EditHome;
