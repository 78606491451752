// REQUIREMENT: 4.1.16 'Home' Group Management

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TypographyElem from '../../ui/Typography/Typography';
import Map from '../Map/Map';
import AddHomeForm from './AddHomeForm';
import SubHeader from '../../ui/SubHeader';
import classes from '../ManageHomes.module.css';
import ScrollArea from '../../ui/ScrollBar/ScrollArea';
import { AuthContext } from '../../../Providers/AuthProvider';

const SetupHome = (props) => {
  const { user } = React.useContext(AuthContext);
  const emailId = user.userEmail;
  const { google, location } = props;

  const { lati, longi, initial } = location.state;

  const [latitude, setLatitude] = useState(lati);
  const [longitude, setLongitude] = useState(longi);
  const [changedLocation, setChangedLocation] = useState({});

  useEffect(() => {
    // Web: [#84859] Locate me option is not working while adding new home.
    const options = {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    };
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log(position);
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      },
      (err) => console.log(err),
      options
    );
  }, []);

  const home = useSelector((state) => state.homeListReducer.homeInfo);

  const onLocationChanged = (data) => setChangedLocation(data);
  const previousPageHandler = () => props.history.goBack();

  return (
    <div>
      <ScrollArea>
        {home !== null ? (
          <SubHeader clicked={previousPageHandler} {...props} style={{ paddingLeft: 20 }} />
        ) : (
          ''
        )}
        <div style={{ marginLeft: '20px' }}>
          <TypographyElem content="Add new home" />
        </div>
        <Grid container>
          <Grid item xs={4} className={classes.topPadding} style={{ marginLeft: 10 }}>
            {changedLocation.home_address && (
              <AddHomeForm Location={changedLocation} initial={initial} />
            )}
          </Grid>
          <Grid item xs={6}>
            <div style={{ marginLeft: '5px' }}>
              <Map
                google={google}
                center={{ lat: latitude, lng: longitude }}
                height="350px"
                width="70%"
                email={emailId}
                zoom={15}
                dataFnc={onLocationChanged}
                formType="addHome"
              />
            </div>
          </Grid>
        </Grid>
      </ScrollArea>
    </div>
  );
};

export default SetupHome;
