// REQUIREMENT: 4.1.18 Subscription and Payment Management

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LoadingSpinner from '../ui/LoadingSpinner/LoadingSpinner';
import * as iconEle from '../ui/Icons/Icons';
import ButtonElem from '../ui/Button/Button';
import * as profileActionCreator from '../Profile/store/action-creator';
import { SmartLifePlansTooltip } from './TableTooltip/TableTooltip';
import planInfoMappingKeys from './planInfoMapping';

const SmartLifePlans = (props) => {
  const { userSubscription, onSelectedMenu } = props;
  const [planList, setPlanList] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [planInfoKeys, setPlanInfoKeys] = useState([]);
  const [planInfoValues, setPlanInfoValues] = useState([]);
  // const [headerColWidth, setHeaderColWidth] = useState('');
  const [bodyColWidth, setBodyColWidth] = useState('');

  useEffect(() => {
    if (Object.keys(userSubscription).length === 0 && userSubscription.constructor === Object) {
      setLoader(true);
    } else {
      let planInfoArr = [];
      if (userSubscription.planInfo) {
        setPlanList(userSubscription.planInfo);
        planInfoArr = JSON.parse(JSON.stringify(userSubscription.planInfo));
      }
      const temp = [];
      planInfoArr.forEach((each) => {
        [
          'PlanDescription',
          'Rank',
          'PlanName',
          'PlanStatus',
          'DisplayName',
          'BillingInterval',
          'PricingModel',
        ].forEach((e) => delete each[e]);
      });
      planInfoArr.forEach((each) => {
        // eslint-disable-next-line max-len
        if (
          (Number(each.daysOfStorage) === each.daysOfStorage && each.daysOfStorage % 1 !== 0) ===
          false
        ) {
          each.DaysOfStorage = `${each.DaysOfStorage}.0`;
        }
        if (each.isPopular === 0) {
          each.isPopular = '';
        } else if (each.isPopular === 1) {
          each.isPopular = 'popular';
        }
      });
      if (userSubscription.planInfo.length > 0) {
        // setHeaderColWidth(75 / userSubscription.planInfo.length);
        setBodyColWidth(70 / userSubscription.planInfo.length);
      }
      planInfoArr.map((each) => temp.push(Object.values(each)));
      setPlanInfoValues(temp);
      planInfoArr[0] && setPlanInfoKeys(Object.keys(planInfoArr[0] || {}));
      setLoader(false);
    }
  }, [userSubscription]);

  const handleManagePlans = (menu) => onSelectedMenu(menu);

  const underlineText = {
    borderBottom: 'none',
    padding: '1rem 0rem',
    textDecoration: 'underline',
    textAlign: planList.length === 1 && 'left',
    paddingLeft: planList.length === 1 && '2rem',
  };

  const tableCellText = {
    fontWeight: 'bold',
    borderBottom: 'none',
    padding: 0,
  };

  const greyBackground = {
    background: '#000000',
    color: '#F1F1F1',
  };

  const managePlansButton = {
    // width: '90%',
    marginBottom: 10,
  };

  const tableMargin = {
    marginBottom: 10,
  };

  const tableContent = {
    borderBottom: 'none',
    fontWeight: 'bold',
    padding: 14,
  };

  const displayFlex = {
    display: 'flex',
  };

  const blackTickImage = {
    width: 11,
    height: 11,
  };

  return (
    <>
      <LoadingSpinner spinnerConfig={{ showSpinner: isLoader }} />
      <>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={underlineText} width="25%">
                {/* GET ONE MONTH FREE WHEN YOU PAY YEARLY! */}
              </TableCell>
              {planList.map((each, i) => (
                <TableCell
                  align="center"
                  key={`planList ${i}`}
                  style={tableCellText}
                  // width={`${headerColWidth}%`}
                >
                  <div style={{ background: each.isPopular ? '#F1F1F1' : '' }}>
                    {each.isPopular ? <div style={greyBackground}>Most Popular</div> : ''}
                    <div
                      style={{
                        marginTop: each.isPopular ? 0 : 24,
                        maxWidth: planList.length > 1 && 220,
                      }}
                    >
                      {each.DisplayName}
                      <div>
                        <div>{each.PlanDescription}</div>
                        {each.PlanDescription !== 'Included' ? (
                          <ButtonElem
                            btnColor="YellowButton"
                            value="Manage Plans"
                            clicked={() => handleManagePlans(1)}
                            style={managePlansButton}
                          />
                        ) : (
                          <div style={tableMargin} />
                        )}
                      </div>
                    </div>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {planInfoKeys.map((each, index) => (
              <React.Fragment key={`planInfoKey ${index}`}>
                {each !== 'isPopular' ? (
                  <TableRow style={index % 2 ? { background: '#F1F1F1' } : { background: 'white' }}>
                    <TableCell align="center" style={tableContent} width="30%">
                      <div style={displayFlex}>
                        <SmartLifePlansTooltip content={planInfoMappingKeys[each]} />
                        {planInfoMappingKeys[each]}
                      </div>
                    </TableCell>
                    {planInfoValues.map((eachVal, i) => (
                      <TableCell
                        key={`planInfoValue ${i}`}
                        align="center"
                        width={`${bodyColWidth}%`}
                        style={{
                          borderBottom: 'none',
                          fontWeight: 'bold',
                          background: eachVal.includes('popular')
                            ? index % 2 === 1
                              ? '#CCCCCC'
                              : '#F1F1F1'
                            : index % 2 === 1
                            ? '#F1F1F1'
                            : 'white',
                        }}
                      >
                        {typeof eachVal[index] === 'string' ? (
                          eachVal[index].includes('.') ? (
                            <div>{`${parseInt(eachVal[index], 10)} days`}</div>
                          ) : (
                            <>{eachVal[index]}</>
                          )
                        ) : eachVal[index] === 1 ? (
                          <img src={iconEle.blackTickIcon} alt="Yes" style={blackTickImage} />
                        ) : (
                          ''
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ) : (
                  <TableRow />
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </>
    </>
  );
};

const mapStateToProps = (state) => ({
  userSubscription: state.subscriptionReducer.userSubscription,
});

const mapDispatchToProps = (dispatch) => ({
  onSelectedMenu: (data) => dispatch(profileActionCreator.setProfileMenu(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SmartLifePlans));
