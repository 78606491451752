import * as iconElem from '../../ui/Icons/Icons';

const filterArray = [
  {
    name: 'Activity type',
    list: [
      {
        id: 'motion',
        name: 'Motion',
        icon: iconElem.motionIcon
      },
      {
        id: 'person',
        name: 'Person',
        icon: iconElem.personIcon
      },
      {
        id: 'sound',
        name: 'Sound',
        icon: iconElem.soundIcon
      },
      {
        id: 'recording',
        name: 'Recording',
        icon: iconElem.recordIcon
      }
    ]
  },
  {
    name: 'Other',
    list: [
      {
        id: 'isFavourite',
        name: 'Starred',
        icon: iconElem.starredIcon
      },
      {
        id: 'deletionSoon',
        name: 'Deleted Soon',
        icon: iconElem.warningIcon
      }
    ]
  }
];

export default filterArray;
