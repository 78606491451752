import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AuthContext } from '../../../Providers/AuthProvider';
import { downloadVideo } from '../../Activity/store/action-creator';
import { unixToMilisecond } from '../../common/utils';
import ShareTooltip from './ShareTooltip';
import SubscriptionTooltip from './SubscriptionTooltip';

const VideoShare = (props) => {
  const { user } = React.useContext(AuthContext);
  const IdentityId = user.IdentityId;
  const { config } = props;
  const [shareBtn, setShareBtn] = useState(null);
  const [reviewShare, setReviewShare] = useState(0);
  const [shareVideoPath, setShareVideoPath] = useState(null);

  const selectedEvent = useSelector((state) => state.activityReducer.selectedEvent);
  const devicePlanInfo = useSelector((state) => state.activityReducer.devicePlanInfo);

  const onGetVideoPath = (params) => {
    downloadVideo(params, true).then((data) => {
      setShareVideoPath(data.shareVideoPath);
    });
  };
  useEffect(() => {
    if (selectedEvent) {
      const startTime = unixToMilisecond(selectedEvent.Timestamp);
      const endTime = unixToMilisecond(selectedEvent.Timestamp + selectedEvent.DurationSeconds);
      const streamName = selectedEvent.DeviceID;
      const params = {
        startTime,
        endTime,
        streamName,
        cognitoIdentityId: IdentityId,
        activityId: selectedEvent.ActivityID,
        activityType: selectedEvent.ActivityType,
      };
      onGetVideoPath(params);
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (devicePlanInfo && selectedEvent) {
      let selectedPlan = null;
      selectedPlan = devicePlanInfo.DevicePlanDetails.filter(
        (each) => selectedEvent.DeviceID === each.ThingName && each.PlanStatus === 'active'
      );
      if (selectedPlan.length) {
        setReviewShare(selectedPlan[0].ReviewShareSaveVideo);
      } else {
        setReviewShare(devicePlanInfo.FreePlanDetails.ReviewShareSaveVideo);
      }
    }
  }, [devicePlanInfo, selectedEvent]);

  const shareBtnHandler = () => {
    const shareElem = document.querySelector('#shareVJSBtn .tooltiptext');
    if (shareElem) {
      const getVisibility = shareElem.style.visibility;
      shareElem.style.visibility = getVisibility === 'visible' ? 'hidden' : 'visible';
    }
  };

  useEffect(() => {
    const btn = (
      <div id={config.btnId} className="customVJSIcon vjs-control">
        <SubscriptionTooltip
          reviewShareSaveVideo={reviewShare}
          clicked={shareBtnHandler}
          btnType={config.btnType}
        />
        <ShareTooltip videoPath={shareVideoPath} />
      </div>
    );
    setShareBtn(btn);
  }, [reviewShare, shareVideoPath]);

  return shareBtn;
};

export default VideoShare;
