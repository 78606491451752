import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actionCreator from '../../Activity/store/action-creator';
import CustomDialog from '../CustomDialog/CustomDialog';
import ButtonWrapper from './ButtonWrapper';
import msgConstant from '../../common/textConstants';
import Notification from '../Notification/Notification';
import { AuthContext } from '../../../Providers/AuthProvider';

let hasAllEvents = null;
const VideoFavourite = (props) => {
  const { user } = React.useContext(AuthContext);
  const userEmail = user.userEmail;
  let isStarred = null;

  const [favouriteComp, setFavouriteComp] = useState(null);
  const [commonError, setCommonError] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const dispatch = useDispatch();
  const selectedEvent = useSelector((state) => state.activityReducer.selectedEvent);
  const allEvents = useSelector((state) => state.activityReducer.allEvents);

  useEffect(
    () => () => {
      hasAllEvents = null;
    },
    []
  );
  useEffect(() => {
    if (allEvents) {
      hasAllEvents = allEvents;
    }
  }, [allEvents]);

  const loaderHandler = (visibilityStatus) => {
    const spinnerContainer = document.querySelector('#spinner_container');
    if (spinnerContainer) {
      setTimeout(() => {
        spinnerContainer.style.visibility = visibilityStatus;
      });
    }
  };

  const onUpdateFavourite = () => {
    isStarred = isStarred ? 0 : 1;
    dispatch(actionCreator.updateFavourite(hasAllEvents, isStarred, selectedEvent.ActivityID));
    dispatch(actionCreator.setStarred(isStarred));
  };

  const onSetToFavourite = (params) => {
    actionCreator
      .addToFavorite(params)
      .then(
        (data) => {
          if (data.errorMsg) {
            setCommonError(true);
            // setCommonErrorMsg(data.errorMsg);
            setCommonErrorMsg(msgConstant.FAVORITE_UPDATE_ERROR);
          } else {
            setShowNotification(true);
            onUpdateFavourite();
          }
        },
        (err) => {
          setCommonError(true);
          setCommonErrorMsg(err);
        }
      )
      .finally(() => loaderHandler('hidden'));
  };

  const favouriteHandler = () => {
    loaderHandler('visible');
    if (selectedEvent) {
      if (isStarred === null) {
        isStarred = selectedEvent.IsFavourite;
      }

      const params = {
        action: 'update_favourites',
        data: {
          email: userEmail,
          thingName: selectedEvent.DeviceID,
          favourite: [selectedEvent.ActivityID],
        },
      };
      onSetToFavourite(params);
    }
  };

  useEffect(() => {
    const btn = <ButtonWrapper {...props} clicked={favouriteHandler} />;
    setFavouriteComp(btn);
  }, []);

  const errorDialogConfig = {
    open: commonError,
    textAlign: 'left',
    btnClicked: () => setCommonError(false),
  };

  const notificationConfig = {
    position: 'bottomRight',
    open: showNotification,
    onClose: () => setShowNotification(false),
  };

  return (
    <>
      {favouriteComp}
      {commonError && (
        <CustomDialog dialogConfig={errorDialogConfig}>
          <div>{commonErrorMsg}</div>
        </CustomDialog>
      )}
      {showNotification && (
        <Notification config={notificationConfig}>{msgConstant.FAVORITE_UPDATE}</Notification>
      )}
    </>
  );
};

export default VideoFavourite;
