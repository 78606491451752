import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TypographyElem from '../../ui/Typography/Typography';
import AccountOverview from './AccountOverview';
import EditProfile from './EditProfile/EditProfile';
import ChangePassword from './ChangePassword';
import PrivacySetting from './PrivacyPolicy';
import SubscriptionTable from '../../ChargebeeSubscription/SubscriptionTable';
import SmartLifePlans from '../../ChargebeeSubscription/SmartLifePlans';
import TermsOfService from './TermsOfService';
import { resetTCVersion } from '../store/action-creator';
import ScrollArea from '../../ui/ScrollBar/ScrollArea';
import classes from '../Profile.module.css';

const ProfileContent = (props) => {
  const { selectedMenuItem, menuList } = props;
  const [TCVersion, setTCVersion] = useState(null);
  const [PPVersion, setPPVersion] = useState(null);

  const getLatestTCVersion = (ver) => setTCVersion(ver);
  const getLatestPPVersion = (ver) => setPPVersion(ver);

  let profileContentPage = null;
  if (selectedMenuItem) {
    switch (selectedMenuItem) {
      case 1:
        profileContentPage = (
          <>
            <AccountOverview />
            <SubscriptionTable />
          </>
        );
        break;
      case 2:
        profileContentPage = <SmartLifePlans />;
        break;
      case 3:
        profileContentPage = <EditProfile />;
        break;
      case 4:
        profileContentPage = <ChangePassword />;
        break;
      case 5:
        profileContentPage = <PrivacySetting latestPPVersion={getLatestPPVersion} />;
        break;
      case 6:
        // onResetTCVersion();
        profileContentPage = <TermsOfService latestTCVersion={getLatestTCVersion} />;
        break;
      default:
        break;
    }
  }

  let profileMenuName = null;
  if (selectedMenuItem) {
    const menuObj = menuList.filter((list) => list.id === selectedMenuItem);
    if (menuObj.length) {
      profileMenuName = menuObj[0].menu;
      if (TCVersion && selectedMenuItem === 6) {
        profileMenuName += TCVersion;
      }
      if (PPVersion && selectedMenuItem === 5) {
        profileMenuName += PPVersion;
      }
    }
  }

  return (
    <div className={classes.containerPadding}>
      <>
        {profileMenuName && selectedMenuItem !== 7 && <TypographyElem content={profileMenuName} />}

        <ScrollArea scrollClass={classes.profileContentBlock}>
          <div className={classes.contentPadRight}>{profileContentPage}</div>
        </ScrollArea>
      </>
    </div>
  );
};

const mapStateToProps = (state) => ({
  menuList: state.profileReducer.profileMenuList,
  selectedMenuItem: state.profileReducer.selectedMenu,
  previousMenu: state.profileReducer.previousMenu,
  deviceToken: state.homePageReducer.deviceToken,
});

const mapDispatchToProps = (dispatch) => ({
  onResetTCVersion: () => dispatch(resetTCVersion()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileContent));
