import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TokBox from '../../../TokBox/TokBox';
import classes from './DeviceDisplay.module.css';
import DeviceRecordingTimer from './DeviceRecordingTimer';
import { AuthContext } from '../../../../Providers/AuthProvider';

const DeviceLiveStream = (props) => {
  const { user } = React.useContext(AuthContext);
  const { MQTTInfo, deviceInfo, cameraType } = props;

  const [liveStreamComp, setLiveStreamComp] = useState(null);

  useEffect(() => {
    const comp = (
      <Grid item>
        <div className={classes.streamVideoContainer}>
          <div className={classes.streamVideoText}>
            <div className={classes.streamVideoDeviceText}>
              {deviceInfo.DeviceType === 'OverDoorCamera' && (cameraType || '')}
            </div>
            <span className={classes.connected} />
            Live
          </div>
          <DeviceRecordingTimer clsName={classes.recordingTimerBlock} />
          <TokBox
            deviceInfo={deviceInfo}
            tokboxSessionId={MQTTInfo.reported.tokboxSessionId}
            user={user}
          />
        </div>
      </Grid>
    );
    setLiveStreamComp(comp);
  }, []);

  return liveStreamComp;
};

export default DeviceLiveStream;
