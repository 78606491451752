import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actionCreator from '../../../store/action-creator';
import CustomDialog from '../../../../ui/CustomDialog/CustomDialog';
import Notification from '../../../../ui/Notification/Notification';
import ErrorHandler from '../../ErrorHandler/ErrorHandler';
import IndividualSetting from '../IndividualSetting';
import DeviceRename from '../Common_settings/DeviceRename';
import DeviceTechInfo from '../Common_settings/DeviceTechInfo';
import AlertLocation from './AlertLocation';
import AlertType from './AlertType';
import classes from '../DeviceSettings.module.css';
import msgConstant from '../../../../common/textConstants';
import ErrorCodes from '../../../../common/ErrorCodes';
import { HOME } from '../../../../../constants/routes';
import { AuthContext } from '../../../../../Providers/AuthProvider';

const removeStyle = {
  color: '#B52A2A',
  padding: '15px 0px',
  cursor: 'pointer',
  fontSize: '14px',
};

const SruSettings = (props) => {
  const { user } = React.useContext(AuthContext);
  const email = user.userEmail;
  const IdentityId = user.IdentityId;
  const {
    deviceInfo,
    removeDevice,

    deviceResp,
    history,
    location,
    resetAlertLocation,
  } = props;
  const [deviceData, setDeviceData] = useState(deviceInfo);
  const [techInfo, setTechInfo] = useState(false);
  const [alertLocation, setAlertLocation] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [rename, setRename] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const [invtUserModal, setinvtUserModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [commonError, setCommonError] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState(null);

  const onSetRename = () => setRename(!rename);
  const onSetTechInfo = () => setTechInfo(!techInfo);
  const onSetAlertLocation = () => setAlertLocation(!alertLocation);
  const onSetAlertType = () => setAlertType(!alertType);

  useEffect(() => {
    props.onGetAlterType(email, deviceInfo.DeviceId);
    props.onGetAlertLocation(email, deviceInfo.DeviceId);
  }, []);

  useEffect(() => {
    setDeviceData(deviceInfo);
  }, [deviceInfo]);

  const removeHandler = () => {
    // BUG-[#84291] Not able to delete the device
    const data = {
      device_id: deviceData.DeviceId,
      email,
      home_id: deviceResp.deviceData?.HomeId,
      history,
      IdentityId,
    };
    removeDevice(data).then(
      (resp) => {
        const respCode = resp.ResponseCode;
        const errCode = resp.ErrorCode;
        if (respCode !== 200 || (respCode === 200 && errCode !== 'err_0')) {
          setCommonError(!commonError);
          setErrorMsg(ErrorCodes[errCode]);
        } else {
          setShowNotification(true);
          setNotificationMsg(msgConstant.DEVICE_REMOVE);
          setTimeout(() => {
            props.history.push(HOME);
          }, 1000);
        }
      },
      (err) => {
        setCommonError(!commonError);
        setErrorMsg(err);
      }
    );
  };

  const removeModalHandler = () => {
    const selectedHome = deviceResp.homeList.filter(
      (each) => each?.HomeID === deviceResp?.deviceData?.HomeId
    );
    if (selectedHome[0].Creator === email) {
      setRemoveModal(!removeModal);
    } else setinvtUserModal(true);
  };

  const btnHandler = (hasAction) => {
    if (hasAction) {
      removeHandler();
    } else setRemoveModal(!removeModal);
  };

  useEffect(() => () => resetAlertLocation(null), []);

  const removeModalConfig = {
    open: removeModal,
    btnClicked: btnHandler,
    btnText: 'Remove',
    title: 'Remove Device',
    disabled: true,
    type: 'action',
  };

  const handleClose = () => setinvtUserModal(false);

  const invitedUserModalConfig = {
    open: invtUserModal,
    type: 'message',
    title: 'Unable to remove device',
    btnClicked: () => handleClose(),
  };

  const renderDialog = (customConfig, msg) => (
    <CustomDialog dialogConfig={customConfig}>
      <div>{msg}</div>
    </CustomDialog>
  );

  const notificationConfig = {
    position: 'bottomRight',
    open: showNotification,
    onClose: () => setShowNotification(false),
  };

  const commonErrorDialogConfig = {
    open: commonError,
    textAlign: 'left',
    btnClicked: () => setCommonError(!commonError),
  };

  return (
    <div className={classes.settingPanelPadding}>
      {!alertLocation && !rename && !techInfo && !alertType && (
        <>
          <div className={classes.header}>Settings</div>
          <div className={classes.horLine} />
          <IndividualSetting
            clicked={onSetRename}
            displayName={deviceData.DisplayName}
            value="Name"
          />
          <IndividualSetting clicked={onSetTechInfo} value="Technical Information" />
          <IndividualSetting
            clicked={onSetAlertLocation}
            displayCode={location}
            value="Alert Location"
          />
          <IndividualSetting clicked={onSetAlertType} value="Alert Types" {...props} />
          <div style={removeStyle} onClick={removeModalHandler} role="presentation">
            Remove Device
          </div>
          {removeModal && renderDialog(removeModalConfig, msgConstant.REMOVE_SRU_CHECK)}
          {invtUserModal && renderDialog(invitedUserModalConfig, msgConstant.CREATOR_CHECK)}
        </>
      )}
      {rename && <DeviceRename RenameFlag={onSetRename} />}
      {techInfo && (
        <ErrorHandler setFlag={onSetTechInfo} header="Technical Information">
          <DeviceTechInfo TechFlag={onSetTechInfo} {...props} />
        </ErrorHandler>
      )}
      {alertLocation && (
        <ErrorHandler setFlag={onSetAlertLocation} header="Alert Location">
          <AlertLocation Locate={onSetAlertLocation} {...props} />
        </ErrorHandler>
      )}
      {alertType && (
        <ErrorHandler setFlag={onSetAlertType} header="Alert Types">
          <AlertType setAlert={onSetAlertType} {...props} />
        </ErrorHandler>
      )}
      {showNotification && (
        <Notification config={notificationConfig}>{notificationMsg}</Notification>
      )}
      {commonError && renderDialog(commonErrorDialogConfig, errorMsg)}
    </div>
  );
};

const mapStateToProps = (state) => ({
  MQTTData: state.deviceReducer.MQTTInfo,
  deviceInfo: state.deviceReducer.deviceInfo,
  deviceResp: state.deviceReducer.deviceResp,
  location: state.deviceReducer.alertLocation,
});

const mapDispatchToProps = (dispatch) => ({
  removeDevice: (data) => dispatch(actionCreator.removeDevice(data)),
  onGetAlterType: (emailId, deviceId) =>
    dispatch(actionCreator.setAlertTypesData(emailId, deviceId)),
  onGetAlertLocation: (emailId, deviceId) =>
    dispatch(actionCreator.getAlertLocation(emailId, deviceId)),
  resetAlertLocation: (data) => dispatch(actionCreator.fetchAlertLocation(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SruSettings));
