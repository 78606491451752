const registerServiceWorker = () => {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    console.log('Service Worker and Push is supported');
    navigator.serviceWorker
      .register('firebase-messaging-sw.js', {
        updateViaCache: 'none'
      })
      .then(registration => {
        console.log('Registration successful, scope is:', registration.scope);
      })
      .catch(err => {
        console.log('Service worker registration failed, error:', err);
      });
  } else {
    console.warn('Push messaging is not supported');
  }
};

export default registerServiceWorker;
