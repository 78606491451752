import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import CustomDialog from '../ui/CustomDialog/CustomDialog';
import LoadingSpinner from '../ui/LoadingSpinner/LoadingSpinner';
import msgConstant from './textConstants';
import { addOrUpdateDeviceToken } from '../Home/store/action-creator';

// [#84350] No homes are showing in manage home page if user leave the site signedin for a day
class IdleTimeWrap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      isTimedOut: false,
      spinner: false,
    };

    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  componentDidUpdate() {
    const { isTimedOut } = this.state;
    if (isTimedOut) {
      setTimeout(() => {
        this.setState({ showModal: false });
        this.handleLogout();
      }, 60000); // Logout after 60 seconds(Plus 30min of inactivity)
    }
  }

  handleModalClose = () => {
    this.setState({ showModal: false });
    this.handleLogout();
  };

  handleLogout = () => {
    this.setState({ spinner: true });
    const { deviceToken, onAddOrUpdateDeviceToken, user, signOut } = this.props;
    if (user.idToken) {
      signOut()
        .then(() => {
          const deviceTokenData = {
            action: 'update_token',
            data: {
              email: user.userEmail,
              device_type: 'web',
              new_device_token: '',
              old_device_token: deviceToken,
            },
          };
          onAddOrUpdateDeviceToken(deviceTokenData).then(
            (resp) => {
              console.log(resp);
            },
            (err) => {
              console.log(err);
            }
          );
        })
        .catch(() => this.setState({ spinner: false }));
    }
  };

  _onAction = () => this.setState({ isTimedOut: false });

  _onActive = () => this.setState({ isTimedOut: false });

  _onIdle = () => {
    this.setState({ showModal: true });
    this.idleTimer.reset();
    this.setState({ isTimedOut: true });
  };

  render() {
    const { showModal, spinner } = this.state;

    const dialogConfig = {
      open: showModal,
      type: 'message',
      btnClicked: () => this.handleModalClose(),
    };

    return (
      <>
        <LoadingSpinner spinnerConfig={{ spinnerConfig: spinner }} />
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={1800000}
        />
        <div className="">
          <CustomDialog dialogConfig={dialogConfig}>
            <div>{msgConstant.INACTIVITY_MESSAGE}</div>
          </CustomDialog>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  deviceToken: state.homePageReducer.deviceToken,
});

const mapDispatchToProps = (dispatch) => ({
  onAddOrUpdateDeviceToken: (data) => dispatch(addOrUpdateDeviceToken(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IdleTimeWrap));
