import React, { useState, useEffect } from 'react';
import { ExclamationCircle } from 'react-bootstrap-icons';
import { deleteIcon } from '../../ui/Icons/Icons';
import DeleteCurrentActivity from '../DeleteCurrentActivity/DeleteCurrentActivity';
import classes from './ActivityList.module.css';

const ActivityItemDelete = (props) => {
  const { list } = props;
  const [deleteBtn, setDeleteButton] = useState(null);

  useEffect(() => {
    if (list) {
      const btnElem = (
        <>
          <DeleteCurrentActivity list={list}>
            {list.ToBeDeletedSoon === 1 ? (
              <ExclamationCircle className={classes.toBeDeletedSoon} />
            ) : null}
            <div className={classes.activityDelete}>
              <img src={deleteIcon} alt="del" style={{ margin: '5px' }} />
            </div>
          </DeleteCurrentActivity>
        </>
      );
      setDeleteButton(btnElem);
    }
  }, []);

  return deleteBtn;
};

export default ActivityItemDelete;
