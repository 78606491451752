import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#CCCCCC',
        color: 'black',
        fontSize: '10px',
        boxShadow: '0 0 10px #f1f1f1',
        border: '5px border #f1f1f1'
      }
    }
  }
});

const StringTooltip = ({ val, length, children }) => (
  <MuiThemeProvider theme={theme}>
    <Tooltip title={val.length > length ? val : ''}>
      {children}
    </Tooltip>
  </MuiThemeProvider>
);

export default StringTooltip;
