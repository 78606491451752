import React, { useState, useEffect } from 'react';
// import Loadash from 'lodash';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { PubSub } from 'aws-amplify';
import { connect } from 'react-redux';
import * as iconElem from '../../../../ui/Icons/Icons';
import classes from './DeviceActions.module.css';
import * as deviceActionCraetor from '../../../store/action-creator';
import * as actionCreator from '../../../../TokBox/store/action-creator';
import { PROFILE } from '../../../../../constants/routes';
import msgConstant from '../../../../common/textConstants';
import { objectHasKey, getBitPosition } from '../../../../common/utils';
// import ErrorCodes from '../../../../common/ErrorCodes';
import CustomDialog from '../../../../ui/CustomDialog/CustomDialog';
import Notification from '../../../../ui/Notification/Notification';
import { AuthContext } from '../../../../../Providers/AuthProvider';

const DeviceActions = (props) => {
  const { user } = React.useContext(AuthContext);
  const userEmail = user.userEmail;
  const {
    audioStatus,
    onSetAudioStatus,
    deviceType,
    MQTTInfo,
    deviceInfo,
    // onUpdateMQTT,
    onSetScreenShot,
    onstartRecording,
    archiveId,
    onstopRecording,
    camType,

    deviceResp,
  } = props;
  // const [oldMqttData, setOldMqttData] = useState(null);
  const [isLoaded, setLoaded] = useState(true);
  const [siren, setSiren] = useState(null);
  const [screenShot, setScreenShot] = useState(false);
  const [record, setRecord] = useState(false);
  const [camera, setCamera] = useState(null);
  const [showRecordTooltip, setShowRecordTooltip] = useState(false);
  // const [showSirenTooltip, setShowSirenTooltip] = useState(false);
  const [showTalkTooltip, setShowTalkTooltip] = useState(false);
  const [commonError, setCommonError] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState(null);
  const [subscriptionList, setSubscriptionList] = useState(null);

  const theme = createMuiTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          backgroundColor: 'white',
          color: 'black',
          fontSize: '10px',
          boxShadow: '0 0 10px #f1f1f1',
          border: '1px solid #f1f1f1',
        },
        arrow: {
          color: '#FFF',
        },
      },
    },
  });

  useEffect(() => {
    if (MQTTInfo && isLoaded) {
      setLoaded(false);
      if (objectHasKey(MQTTInfo, 'desired')) {
        if (objectHasKey(MQTTInfo.desired, 'panicState')) {
          setSiren(MQTTInfo.desired.panicState);
        }
        if (objectHasKey(MQTTInfo.desired, 'activeCamera')) {
          setCamera(MQTTInfo.desired.activeCamera);
          camType(MQTTInfo.desired.activeCamera ? 'INDOOR CAMERA' : 'OUTDOOR CAMERA');
        }
      }

      if (objectHasKey(MQTTInfo, 'reported')) {
        if (objectHasKey(MQTTInfo.reported, 'subscriptionValue')) {
          const subValue = MQTTInfo.reported.subscriptionValue;
          const obj = {
            activityZone: getBitPosition(subValue, 1),
            twoWayTalk: getBitPosition(subValue, 2),
            personDetection: getBitPosition(subValue, 3),
            motionDetection: getBitPosition(subValue, 4),
            recording: getBitPosition(subValue, 5),
          };
          setSubscriptionList(obj);
        }
      }
    }
  }, [MQTTInfo]);

  const updateStatus = (newStatus) => {
    if (deviceInfo) {
      PubSub.publish(`$aws/things/${deviceInfo.DeviceId}/shadow/update`, {
        state: { desired: newStatus },
      });
    }
  };

  const stateChanged = (type, status) => {
    switch (type) {
      case 'siren':
        {
          const sirenStatus = status ? 0 : 1;
          setSiren(sirenStatus);
          updateStatus({ panicState: sirenStatus });
        }
        break;
      case 'talk':
        if (subscriptionList && subscriptionList.twoWayTalk === 1) {
          onSetAudioStatus(!audioStatus);
        }
        break;
      case 'screenShot':
        setScreenShot(true);
        onSetScreenShot(true);

        // Bug[#84349] - Screenshot button color is not changing after selection.
        setTimeout(() => {
          setScreenShot(false);
          onSetScreenShot(false);
        }, 800);
        break;
      case 'switchCamera':
        {
          const camStatus = status ? 0 : 1;
          setCamera(camStatus);
          camType(status ? 'INDOOR CAMERA' : 'OUTDOOR CAMERA');
          updateStatus({ activeCamera: camStatus });
        }
        break;
      case 'record':
        if (subscriptionList && subscriptionList.recording === 1) {
          setRecord(!record);
          if (record === true) {
            onstopRecording({
              archive_id: archiveId,
              thing_name: deviceInfo.DeviceId,
              end_time: Math.floor(Date.now() / 1000),
            }).then(
              (resp) => {
                const respCode = resp.data.ResponseCode;
                const errCode = resp.data.ErrorCode;
                if (respCode !== 200 || (respCode === 200 && errCode !== 'err_0')) {
                  setShowNotification(true);
                  setNotificationMsg(msgConstant.RECORD_VIDEO_STOP_ERROR);
                }
              },
              (err) => {
                if (err) {
                  setCommonError(true);
                  setCommonErrorMsg('Something went wrong!');
                }
              }
            );
          } else if (record === false) {
            const params = {
              thing_name: deviceInfo.DeviceId,
              thing_type: deviceInfo.DeviceType,
              email: userEmail,
              start_time: Math.floor(Date.now() / 1000),
              session_id: MQTTInfo.reported.tokboxSessionId,
              home_id: deviceResp.deviceData?.HomeId,
            };
            onstartRecording(params)
              .then((resp) => {
                if (
                  resp.ResponseCode !== 200 ||
                  (resp.ResponseCode === 200 && resp.ErrorCode !== 'err_0')
                ) {
                  setShowNotification(true);
                  setNotificationMsg(msgConstant.RECORD_VIDEO_START_ERROR);
                }
              })
              .catch((err) => {
                if (err) {
                  setCommonError(true);
                  setCommonErrorMsg('Something went wrong!');
                }
              });
          }
        }
        break;
      default:
        break;
    }
  };

  const getClassList = (type) => {
    let newClassName = classes.deviceIconsText;
    switch (type) {
      case 'talk':
        if (audioStatus) {
          newClassName = [classes.deviceIconsText, classes.activeIcon].join(' ');
        }
        break;
      case 'siren':
        if (siren) {
          newClassName = [classes.deviceIconsText, classes.activeIcon].join(' ');
        }
        break;
      case 'screenShot':
        if (screenShot) {
          newClassName = [classes.deviceIconsText, classes.activeIcon].join(' ');
        }
        break;
      case 'record':
        if (record) {
          newClassName = [classes.deviceIconsText, classes.activeIcon].join(' ');
        }
        break;
      default:
        break;
    }
    return newClassName;
  };

  let gridElem = null;

  if (deviceType === 'OverDoorCamera') {
    gridElem = (
      <Grid
        item
        align="center"
        className="CursorPointer"
        role="presentation"
        onClick={() => stateChanged('switchCamera', camera)}
      >
        <img src={iconElem.switchCamera} alt="Switch Camera" className={classes.screenShotIcon} />
        <div className={getClassList('switchCamera')}>Switch Camera</div>
      </Grid>
    );
  }

  const handlePopoverOpen = (type) => {
    switch (type) {
      case 'talk':
        if (subscriptionList && subscriptionList.twoWayTalk === 0) {
          setShowTalkTooltip(true);
          setTimeout(() => {
            setShowTalkTooltip(false);
          }, 5000);
        }
        break;
      case 'record':
        if (subscriptionList && subscriptionList.recording === 0) {
          setShowRecordTooltip(true);
          setTimeout(() => {
            setShowRecordTooltip(false);
          }, 5000);
        }
        break;
      default:
        break;
    }
  };

  const handleSubscClick = () => props.history.push(PROFILE);

  const commonErrorBtnHandler = () => {
    setCommonErrorMsg(null);
    setCommonError(false);
  };

  const commonErrorDialogConfig = {
    open: commonError,
    textAlign: 'left',
    btnClicked: commonErrorBtnHandler,
  };

  const notificationConfig = {
    position: 'bottomRight',
    open: showNotification,
    onClose: () => setShowNotification(false),
  };

  return (
    <>
      {commonError && (
        <CustomDialog dialogConfig={commonErrorDialogConfig}>
          <div>{commonErrorMsg}</div>
        </CustomDialog>
      )}
      <Grid
        container
        spacing={4}
        alignItems="center"
        justify="center"
        style={{ marginTop: '0.2em' }}
      >
        {gridElem}
        <Grid
          item
          align="center"
          className="CursorPointer"
          role="presentation"
          onClick={() => stateChanged('screenShot')}
        >
          <img
            src={screenShot ? iconElem.screenshotActiveIcon : iconElem.screenshotIcon}
            alt=""
            className={classes.screenShotIcon}
          />
          <div className={getClassList('screenShot')}>Screenshot</div>
        </Grid>
        <MuiThemeProvider theme={theme}>
          <Tooltip
            title={
              <>
                <div>{msgConstant.SUBSCRIPTION_VALIDATION}</div>
                <div
                  style={{ color: 'blue', cursor: 'pointer' }}
                  onClick={handleSubscClick}
                  role="presentation"
                >
                  Click here to upgrade your plan.
                </div>
              </>
            }
            interactive
            arrow
            placement="bottom"
            open={showRecordTooltip}
          >
            <Grid
              item
              align="center"
              className="CursorPointer"
              onClick={() => stateChanged('record')}
              onMouseEnter={() => handlePopoverOpen('record')}
            >
              <img
                src={record ? iconElem.recordActive : iconElem.recordIcon}
                alt="Record"
                className={classes.recordIcon}
              />
              <div className={getClassList('record')}>Record</div>
            </Grid>
          </Tooltip>
        </MuiThemeProvider>
        <MuiThemeProvider theme={theme}>
          <Tooltip
            title={
              <>
                <div>{msgConstant.SUBSCRIPTION_VALIDATION}</div>
                <div
                  style={{ color: 'blue', cursor: 'pointer' }}
                  onClick={handleSubscClick}
                  role="presentation"
                >
                  Click here to upgrade your plan.
                </div>
              </>
            }
            interactive
            arrow
            placement="bottom"
            open={showTalkTooltip}
          >
            <Grid
              item
              align="center"
              className="CursorPointer"
              role="presentation"
              onClick={() => stateChanged('talk')}
              onMouseEnter={() => handlePopoverOpen('talk')}
            >
              <img
                src={audioStatus ? iconElem.talkOnIcon : iconElem.talkOffIcon}
                alt="Talk"
                className={classes.talkIcon}
              />
              <div className={getClassList('talk')}>Talk</div>
            </Grid>
          </Tooltip>
        </MuiThemeProvider>
        {/* <MuiThemeProvider theme={theme}>
          <Tooltip
            title={(
              <>
                <div>
                  {msgConstant.SUBSCRIPTION_VALIDATION}
                </div>
                <div
                  style={{ color: 'blue', cursor: 'pointer' }}
                  onClick={handleSubscClick}
                  role="presentation"
                >
                  Click here to upgrade your plan.
                </div>
              </>
            )}
            interactive
            arrow
            placement="right"
            open={showSirenTooltip}
          > */}
        <Grid
          item
          align="center"
          className="CursorPointer"
          onClick={() => stateChanged('siren', siren)}
          // onMouseEnter={() => handlePopoverOpen('siren')}
        >
          <img
            role="presentation"
            src={siren ? iconElem.sirenOnIcon : iconElem.sirenOffIcon}
            alt="Siren"
            className={classes.sirenIcon}
          />
          <div className={getClassList('siren')}>Siren</div>
        </Grid>
        {/* </Tooltip>
        </MuiThemeProvider> */}
      </Grid>
      {showNotification && (
        <Notification config={notificationConfig}>{notificationMsg}</Notification>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  audioStatus: state.tokboxReducer.publishAudioStatus,
  deviceInfo: state.deviceReducer.deviceInfo,
  MQTTInfo: state.deviceReducer.MQTTInfo,
  archiveId: state.deviceReducer.liveVideoArchiveId,
  deviceResp: state.deviceReducer.deviceResp,
});

const mapDispatchToProps = (dispatch) => ({
  onSetAudioStatus: (status) => dispatch(actionCreator.setPublishAudio(status)),
  onUpdateMQTT: (data) => dispatch(deviceActionCraetor.updateMQTT(data)),
  onSetScreenShot: (status) => dispatch(actionCreator.setScreenShot(status)),
  onstartRecording: (data) => dispatch(deviceActionCraetor.startRecording(data)),
  onstopRecording: (data) => dispatch(deviceActionCraetor.stopRecording(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeviceActions));
