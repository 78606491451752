// REQUIREMENT : 4.1.9 Event History / Stored Video Lists

import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import FilterIcon from './FilterIcon';
import DeviceFilter from './DeviceFilter';
import classes from './ActivityHeader.module.css';

const ActivityHeader = (props) => {
  const { filterToggle } = props;
  const [headerComp, setHeaderComp] = useState(null);

  const updateScrollbarHeight = () => {
    const btnElem = document.querySelector('#btn_container');
    if (btnElem) {
      const scrollHeight = btnElem.offsetHeight + 92;
      const elem = document.querySelector('.scrollContainer');
      if (elem) {
        elem.style.height = `calc(100vh - ${scrollHeight}px)`;
      }
    }

    const actElem = document.querySelector('.scrollContainer');
    if (actElem && actElem.childNodes) {
      const node = actElem.childNodes;
      for (let i = 0; i < node.length; i += 1) {
        if (i === 2) {
          node[i].style.top = '15px';
        }
      }
    }
  };

  setTimeout(() => {
    updateScrollbarHeight();
  });

  useEffect(() => {
    const comp = (
      <Grid id="btn_container" container direction="column" className={classes.btnContainer}>
        <Grid item>
          <div className={classes.filterActivityHeader}>Activity</div>
        </Grid>
        <Grid item container direction="row">
          <Grid item container direction="row" sm={10} spacing={1} className={classes.buttonList}>
            <DeviceFilter />
          </Grid>
          <FilterIcon clicked={filterToggle} />
        </Grid>
      </Grid>
    );

    setHeaderComp(comp);
  }, []);

  return headerComp;
};

export default ActivityHeader;
