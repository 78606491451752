import * as actionTypes from './action-types';

const intialState = {
  homeInfo: null,
  selectedHome: null,
  selectedHomeInfo: null,
  selectedUser: null,
  timezoneList: null
};

const updateObject = (prevState, updatedValues) => (
  {
    ...prevState,
    ...updatedValues
  }
);

const reducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.SET_HOME_LIST:
      return updateObject(state, { homeInfo: action.homeInfo });
    case actionTypes.SELECTED_HOME:
      return updateObject(state, { selectedHome: action.selectedHme });
    case actionTypes.SELECTED_HOME_DATA:
      return updateObject(state, { selectedHomeInfo: action.selectedHomeInfo });
    case actionTypes.SELECTED_USER:
      return updateObject(state, { selectedUser: action.selectedUser });
    case actionTypes.SET_TIMEZONE_LIST:
      return updateObject(state, { timezoneList: action.timezoneList });
    default: return state;
  }
};

export default reducer;
