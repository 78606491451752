import * as actionTypes from './action-types';

export const setCropBoxData = (data) => ({
  type: actionTypes.SET_CROPBOX_INFO,
  data,
});

export const refreshCamView = (data) => ({
  type: actionTypes.RFRESH_CAM_VIEW,
  data,
});
