// REQUIREMENT : 4.1.10 Stored Video Management

import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import * as actionCreator from '../store/action-creator';
import LeftContentDetails from './LeftContentDetails';
import RightContentDetails from './RightContentDetails';
import classes from './ActivityDetails.module.css';

const ActivityDetails = () => {
  const dispatch = useDispatch();
  useEffect(
    () => () => {
      dispatch(actionCreator.setActivityUpdated(false));
      dispatch(actionCreator.setActiveDevices(null));
      dispatch(actionCreator.setFilterData(null));
      dispatch(actionCreator.setIsAllActivity(false));
      dispatch(actionCreator.setFilterIconActive(false));
      dispatch(actionCreator.setNoActivityVideo(false));
      dispatch(actionCreator.resetFilter());
    },
    []
  );

  return (
    <>
      <div className={classes.activityDetails}>
        <Grid container>
          <LeftContentDetails />
          <RightContentDetails />
        </Grid>
      </div>
    </>
  );
};

export default ActivityDetails;
