import React, { useState } from 'react';
import classes from './subscription.module.css';
import ButtonElem from '../ui/Button/Button';
import Checkbox from '@material-ui/core/Checkbox';

const EditSubscription = (props) => {
  const {
    subTotalMonthly,
    planDuration,
    planDurationClick,
    subTotalAnnual,
    handleConfirmChanges,
    handleCancelChange,
    downgradeToFree,
    upgradeFromFree,
    upgradeDeluxeToPremium,
    downgradePremiumToDeluxe,
  } = props;

  const confirmButton = {
    width: 172,
    marginTop: 10,
    fontFamily: 'boldFont',
    fontSize: 13,
  };

  const [recievedConfirmation, setRecievedConfirmation] = useState(false);

  const confirmChanges = () => {
    if (downgradeToFree && !recievedConfirmation) {
      alert(
        'To proceed please check the box confirming you would like to downgrade your subscription.'
      );
    } else {
      handleConfirmChanges();
    }
  };

  function renderDowngradeToFree() {
    return (
      <div className={classes.confrimDowngradeBox}>
        <div className={classes.confrimDowngradeBoxTitle}>
          You're {(upgradeDeluxeToPremium || downgradePremiumToDeluxe) && "also"} about to downgrade your paid subscription to the free version. You'll still have
          some great features but the following items will be removed from your subscription:
        </div>
        <ul>
          <li>Cloud storage for your videos.</li>
          <li>Rich notifications and smart detection alerts.</li>
          <li>Customized activity zones.</li>
          <li style={{ marginBottom: '10px' }}>The ability to review, share and save videos.</li>
        </ul>
        <div>
          Please note that this change will take effect immediately. Our team will issue a pro-rated
          refund based on the number of days left in your billing cycle. You should see that refund
          within 3 to 5 business days. Thanks for being part of the BodyGuardz family.
          <br></br>
          You will need to reconfigure your activity triggers settings for your camera.
        </div>
        <div>
          <Checkbox
            style={{ color: '#FFD200' }}
            checked={recievedConfirmation}
            onChange={() => setRecievedConfirmation(!recievedConfirmation)}
          />
          I would like to continue to downgrade my subscription.
        </div>
      </div>
    );
  }

  function renderUpgradeFromFree() {
    return (
      <div className={classes.confrimDowngradeBox}>
        <div className={classes.confrimDowngradeBoxTitle}>
          Congratulations on upgrading your subscription.
        </div>
        <div>
          You will need to reconfigure your activity triggers settings for your camera.
        </div>
      </div>
    );
  }

  function renderDowngradeToDeluxe() {
    return (
      <div className={classes.confrimDowngradeBox}>
        <div className={classes.confrimDowngradeBoxTitle}>
          You're about to downgrade your premium subscription to the deluxe version.
        </div>
        <div>
          Please note that this change will take effect immediately. Our team will issue a pro-rated
          refund based on the number of days left in your billing cycle. You should see that refund
          within 3 to 5 business days. Thanks for being part of the BodyGuardz family.
          <br></br>
          You will need to reconfigure your activity triggers settings for your camera.
        </div>
      </div>
    );
  }

  function renderUpgradeDeluxeToPremium() {
    return (
      <div className={classes.confrimDowngradeBox}>
        <div className={classes.confrimDowngradeBoxTitle}>
          Congratulations you're about to upgrade your deluxe subscription to the premium version.
        </div>
        <div>
          Please note that this change will take effect immediately. Our team will issue a pro-rated
          refund based on the number of days left in your billing cycle. You should see that refund
          within 3 to 5 business days. Thanks for being part of the BodyGuardz family.
          <br></br>
          You will need to reconfigure your activity triggers settings for your camera.
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={classes.subTotalRow}>
        {planDuration === 'monthly' ? (
          <>New monthly subtotal ${Number(subTotalMonthly).toFixed(2)}</>
        ) : (
          <>New yearly subtotal ${Number(subTotalAnnual).toFixed(2)}</>
        )}
      </div>
      <div className={[classes.displayFlexCenter, classes.planDurationWrap].join(' ')}>
        <div>
          <div className={classes.durationBoxWrap}>Monthly</div>
          <div
            className={classes.durationBox}
            style={{
              border: planDuration === 'monthly' ? '4px solid #FFD200' : '2px solid #F1F1F1',
            }}
            onClick={() => planDurationClick('monthly')}
            role="presentation"
          >
            ${Number(subTotalMonthly).toFixed(2)}
            /month
          </div>
        </div>
        {/* <div className={classes.boxLeftMargin}>
          <div className={classes.durationBoxWrap}>Yearly</div>
          <div
            className={classes.durationBox}
            style={{
              border: planDuration === 'annual' ? '4px solid #FFD200' : '2px solid #F1F1F1',
            }}
            role="presentation"
            onClick={() => planDurationClick('annual')}
          >
            ${Number(subTotalAnnual).toFixed(2)}
            /year
          </div>
        </div> */}
      </div>
      {upgradeDeluxeToPremium && renderUpgradeDeluxeToPremium()}
      {downgradePremiumToDeluxe && renderDowngradeToDeluxe()}
      {downgradeToFree && renderDowngradeToFree()}
      {upgradeFromFree && renderUpgradeFromFree()}
      <div className={classes.justifyCenter}>
        <ButtonElem
          btnColor="YellowButton"
          value="Confirm changes &gt;"
          clicked={() => confirmChanges()}
          style={confirmButton}
        />
      </div>
      <div className={classes.justifyCenter}>
        <div className={classes.cancelChanges} onClick={handleCancelChange} role="presentation">
          &lt; Cancel changes
        </div>
      </div>
    </>
  );
};

export default EditSubscription;
