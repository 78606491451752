import React from 'react';
import { withRouter } from 'react-router-dom';
import { backArrowIcon } from './Icons/Icons';
import classes from './SubHeader.module.css';
import { HOME } from '../../constants/routes';

const SubHeader = (props) => {
  const { history, style, clicked } = props;
  const homePageHandler = () => history.push(HOME);
  const hasClickEvent = clicked || homePageHandler;

  return (
    <span onClick={hasClickEvent} role="presentation" className={classes.backBtn} style={style}>
      <img src={backArrowIcon} alt="back" />
      Back
    </span>
  );
};

export default withRouter(SubHeader);
