import * as actionTypes from './action-types';

const intialState = {
  userSubscription: {},
  paymentSourceStatus: null,
  updateSubscriptionStatus: '',
  isPaymentComplete: false,
  cardDetails: {}
};

const updateObject = (prevState, updatedValues) => (
  {
    ...prevState,
    ...updatedValues
  }
);

const reducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_SUBSCRIPTION:
      return updateObject(state, { userSubscription: action.subscResp });
    case actionTypes.ADD_PAYMENT_SOURCE:
      return updateObject(state, { paymentSourceStatus: action.status });
    case actionTypes.UPDATE_SUBSCRIPTION:
      return updateObject(state, { updateSubscriptionStatus: action.status });
    case actionTypes.PAYMENT_COMPLETED:
      return updateObject(state, { isPaymentComplete: action.isComplete });
    case actionTypes.RETRIEVE_EXISTING_CARD:
      return updateObject(state, { cardDetails: action.data });
    default: return state;
  }
};

export default reducer;
