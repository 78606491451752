import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ScrollArea from '../../../ui/ScrollBar/ScrollArea';
import CameraSettings from './Camera_Settings/CameraSettings';
import SruSettings from './SRU_settings/SruSettings';
import classes from './DeviceSettings.module.css';
import LoadingSpinner from '../../../ui/LoadingSpinner/LoadingSpinner';
import FMSettings from './SRU_settings/FMSettings/FMSettings';
import * as actionCreator from '../../store/action-creator';

const DeviceSettings = (props) => {
  const [settingComp, setSettingComp] = useState(null);
  const [spinner, setSpinner] = useState(true);
  const [isLoaded, setLoaded] = useState(true);

  const dispatch = useDispatch();
  const deviceInfo = useSelector((state) => state.deviceReducer.deviceInfo);
  const MQTTInfo = useSelector((state) => state.deviceReducer.MQTTInfo);
  const FMRadioStatus = useSelector((state) => state.deviceReducer.FMRadioStatus);
  const isMQTTUpdated = useSelector((state) => state.deviceReducer.MQTTUpdated);

  useEffect(() => {
    setTimeout(() => {
      setSpinner(false);
    }, 2000);
  }, []);

  const updateFMStatus = (status) => dispatch(actionCreator.setFMRadioStatus(status));

  const RenderFMSetting = () => (
    <FMSettings MQTTData={MQTTInfo} deviceId={deviceInfo.DeviceId} turnOff={updateFMStatus} />
  );

  // [#84849] Not able to go FM Channel scan page once we turn ON the FM in SRU screen.
  useEffect(() => {
    if (deviceInfo && MQTTInfo && isLoaded) {
      setLoaded(false);
      if (deviceInfo.DeviceType === 'SmartReadinessUnit') {
        if (MQTTInfo.desired.fmStatus) {
          setSettingComp(<RenderFMSetting />);
        } else {
          setSettingComp(<SruSettings {...props} />);
        }
      } else {
        setSettingComp(<CameraSettings {...props} />);
      }
    }
  }, [MQTTInfo]);

  // [#84849] Not able to go FM Channel scan page once we turn ON the FM in SRU screen.
  useEffect(() => {
    if (isMQTTUpdated || FMRadioStatus) {
      if (isMQTTUpdated) {
        dispatch(actionCreator.setMQTTUpdated(false));
      }
      if (deviceInfo.DeviceType === 'SmartReadinessUnit') {
        if (FMRadioStatus === 2 || (FMRadioStatus === 0 && MQTTInfo.desired.fmStatus)) {
          setSettingComp(<RenderFMSetting />);
        } else {
          setSettingComp(<SruSettings {...props} />);
        }
      } else {
        setSettingComp(<CameraSettings {...props} />);
      }
    }
  }, [isMQTTUpdated, FMRadioStatus]);

  const spinnerConfig = {
    showSpinner: spinner,
    right: '10%',
    top: '45%',
    left: 'auto',
  };

  return (
    <>
      <LoadingSpinner spinnerConfig={spinnerConfig} />
      <ScrollArea scrollClass={classes.settingPanel}>{settingComp}</ScrollArea>
    </>
  );
};

export default DeviceSettings;
