import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classes from './ProfileMenu.module.css';
import * as actionCreator from '../store/action-creator';
import CustomDialog from '../../ui/CustomDialog/CustomDialog';
import msgConstant from '../../common/textConstants';
import { addOrUpdateDeviceToken } from '../../Home/store/action-creator';
import { AuthContext } from '../../../Providers/AuthProvider';

const ProfileMenu = (props) => {
  const { signOut, user } = React.useContext(AuthContext);
  const email = user.userEmail;
  const { menuList, selectedMenuItem, onSelectedMenu, deviceToken } = props;
  const [logoutModal, setLogoutModal] = useState(false);

  const handleMenuItem = (menu) => {
    if (menu === 7) {
      setLogoutModal(!logoutModal);
    } else {
      onSelectedMenu(menu);
    }
  };

  useEffect(() => () => onSelectedMenu(1), []);

  let profileMenus = null;
  profileMenus = menuList.map((list) => (
    <div
      className={classes.menuItem}
      key={list.id}
      onClick={() => handleMenuItem(list.id)}
      role="presentation"
    >
      {selectedMenuItem === list.id ? <div className={classes.activeMenu} /> : ''}
      <img src={list.icon} alt={list.menu} style={list.iconStyle} />
      <span>{list.menu}</span>
    </div>
  ));

  const logoutHandler = async () => {
    await signOut();
    const deviceTokenData = {
      action: 'update_token',
      data: {
        email,
        device_type: 'web',
        new_device_token: '',
        old_device_token: deviceToken,
      },
    };
    await addOrUpdateDeviceToken(deviceTokenData);
  };

  const btnHandler = (hasAction) => {
    if (hasAction) {
      logoutHandler();
    } else {
      setLogoutModal(!logoutModal);
    }
  };

  const logoutModalConfig = {
    open: logoutModal,
    btnClicked: btnHandler,
    btnText: 'Log Out',
    title: 'Log Out',
    disabled: true,
    type: 'action',
    btnCancel: 'Stay logged in',
    cancelBtnWidth: '130px',
    doneBtnWidth: '130px',
  };

  return (
    <>
      {profileMenus}
      {logoutModal && (
        <CustomDialog dialogConfig={logoutModalConfig}>
          <div>{msgConstant.LOGOUT_CHECK}</div>
        </CustomDialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  deviceToken: state.homePageReducer.deviceToken,
  menuList: state.profileReducer.profileMenuList,
  selectedMenuItem: state.profileReducer.selectedMenu,
});

const mapDispatchToProps = (dispatch) => ({
  onSelectedMenu: (data) => dispatch(actionCreator.setProfileMenu(data)),
  onSetPreviousMenu: (data) => dispatch(actionCreator.setPreviousMenu(data)),
  onAddOrUpdateDeviceToken: (data) => addOrUpdateDeviceToken(data),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);
