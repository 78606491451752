import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { ExclamationCircle } from 'react-bootstrap-icons';
import { PROFILE } from '../../../constants/routes';
import classes from './ActivityList.module.css';

const ClipsDeletedSoon = (props) => {
  const { event } = props;
  const [clipsComp, setClipsComp] = useState(null);

  const profilePageHandler = () => props.history.push(PROFILE);
  useEffect(() => {
    if (event) {
      let elem = null;
      const hasDeletedSoon = event.filter((list) => list.ToBeDeletedSoon === 1);
      const cnt = hasDeletedSoon.length;
      if (cnt) {
        const clipText = cnt > 1 ? 'clips' : 'clip';
        elem = (
          <div className={classes.deletedSoonBlock}>
            <ExclamationCircle className={classes.deletedSoonIcon} />
            <span className={classes.deletedSoonText}>
              {`${cnt} ${clipText} will soon be deleted. `}
              <span
                className={classes.addMoreStorage}
                onClick={profilePageHandler}
                role="presentation"
              >
                Add more storage
              </span>
            </span>
          </div>
        );
      }
      setClipsComp(elem);
    }
  }, []);

  return clipsComp;
};

export default withRouter(ClipsDeletedSoon);
