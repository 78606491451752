export const HOME = '/';
export const INVITE_ACCEPTED = '/inviteAccepted/:confirmationCode';
export const SIGN_UP = '/signup';
export const FORGOT_PASSWORD = '/forgotpassword';
export const FORGOT_PSW_VERIFY = '/forgotpasswordVerification';
export const INSTRUCTION = '/instruction';
export const SIGNUP_SPLASH = '/signUpSplash';
export const ACTIVITY_DETAILS = '/activities';
export const DEVICE_DETAILS = '/deviceDetails';
export const PROFILE = '/profile';
export const SET_UP_HOME = '/setuphome';
export const MANAGE_HOMES = '/managehomes';
export const EDIT_HOME = '/edithome';
export const DEVICE = '/device/:id';
export const NOTIFICATIONS = '/notifications';
export const NOTIFICATION_DETAILS = '/notifications/details';
export const HELP = '/help';
export const MANAGE_CARD = '/profile/manageCard';
export const TERMS_OF_SERVICE = '/terms-of-service';
export const PRIVACY_POLICY = '/privacy-policy';
