import React, { useEffect, useState } from 'react';
import VJSIcons from './VJSIcons';

const ButtonWrapper = (props) => {
  const { config, clicked } = props;
  const [wrapper, setWrapper] = useState(null);

  useEffect(() => {
    setWrapper(
      <div
        id={config.btnId}
        className="customVJSIcon vjs-control"
        onClick={clicked ? () => clicked(config.btnType) : null}
        role="presentation"
      >
        <VJSIcons btnType={config.btnType} />
      </div>
    );
  }, []);

  return wrapper;
};

export default ButtonWrapper;
