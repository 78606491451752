import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as iconElem from '../Icons/Icons';
import './VideoPlayer.css';

const VJSIcons = (props) => {
  const { btnType } = props;
  const [icon, setIcon] = useState(null);

  const starred = useSelector((state) => state.activityReducer.starred);

  const getIcon = (type) => {
    let iconName = null;
    switch (type) {
      case 'ScreenShot':
        iconName = iconElem.eventScreenShotIcon;
        break;
      case 'Download':
        iconName = iconElem.activityDownloadIcon;
        break;
      case 'Star':
        iconName = starred ? iconElem.favoriteFillIcon : iconElem.favoriteIcon;
        break;
      case 'Delete':
        iconName = iconElem.activityDeleteIcon;
        break;
      case 'Share':
        iconName = iconElem.activityShareIcon;
        break;
      default:
        break;
    }
    return iconName;
  };

  useEffect(() => {
    if (btnType) {
      const elem = (
        <>
          <img src={getIcon(btnType)} alt="" />
          <div className="iconText">{btnType}</div>
        </>
      );
      setIcon(elem);
    }
  }, [starred]);

  return icon;
};

export default VJSIcons;
