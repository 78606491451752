import React from 'react';
import cardClass from './Payment.module.css';

const CardDetails = props => {
  const { cardDetails } = props;

  return (
    <div style={{ padding: 30 }}>
      <div className={cardClass.displayFlex}>
        <div className={cardClass.cardProperty}>Card Number:</div>
        <div className={cardClass.cardValue}>{cardDetails.masked_number}</div>
      </div>
      <div className={cardClass.displayFlex}>
        <div className={cardClass.cardProperty}>Card Type:</div>
        <div className={cardClass.cardValue}>{cardDetails.card_type}</div>
      </div>
      <div className={cardClass.displayFlex}>
        <div className={cardClass.cardProperty}>Expiry:</div>
        <div className={cardClass.cardValue}>
          {cardDetails.expiry_month}
          /
          {cardDetails.expiry_year}
        </div>
      </div>
      <div className={cardClass.displayFlex}>
        <div className={cardClass.cardProperty}>Status:</div>
        <div className={cardClass.cardValue}>{cardDetails.status}</div>
      </div>
    </div>
  );
};

export default CardDetails;
