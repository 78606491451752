import React from 'react';
import ButtonElem from '../Button/Button';

const MessageDialog = props => {
  const { dialogConfig } = props;
  const buttonText = dialogConfig.btnText || 'Ok';
  const btnHandler = evt => dialogConfig.btnClicked(evt);

  return (
    <ButtonElem
      btnColor="BlackButton"
      value={buttonText}
      style={{ width: 80 }}
      clicked={() => btnHandler(true)}
    />
  );
};

export default MessageDialog;
