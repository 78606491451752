// REQUIREMENT : 4.1.10 Stored Video Management

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import SubHeader from '../../ui/SubHeader';
import TypographyElem from '../../ui/Typography/Typography';
import ActivityVideo from './ActivityVideo';
import classes from './ActivityDetails.module.css';

const LeftContentDetails = () => {
  const [detailsComp, setDetailsComp] = useState(null);

  const selectedEvent = useSelector((state) => state.activityReducer.selectedEvent);
  const isAllActivity = useSelector((state) => state.activityReducer.isAllActivity);
  const deviceResp = useSelector((state) => state.deviceReducer.deviceResp);

  const getDeviceDetails = (id, isTypographyContent) => {
    let details = null;
    if (deviceResp) {
      if (isAllActivity && isTypographyContent) {
        details = 'All activity';
      } else {
        const deviceObj = deviceResp.deviceData.DeviceList.find((list) => list.DeviceId === id);
        if (deviceObj) {
          details = deviceObj.DisplayName;
        }
      }
    }
    return details;
  };

  useEffect(() => {
    if (selectedEvent) {
      const comp = (
        <Grid item xs={8} className={classes.actVideoSection}>
          <SubHeader />
          <TypographyElem content={getDeviceDetails(selectedEvent.DeviceID, true)} smallText />
          <ActivityVideo
            selectedEvent={selectedEvent}
            deviceInfo={getDeviceDetails(selectedEvent.DeviceID, false)}
          />
        </Grid>
      );
      setDetailsComp(comp);
    } else {
      const comp = (
        <Grid item xs={8} className={classes.actVideoSection}>
          <div
            style={{
              background: '#eee',
              color: '#999',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              aspectRatio: '16/9',
              marginTop: 20,
            }}
          >
            Select Video Clip
          </div>
        </Grid>
      );
      setDetailsComp(comp);
    }
  }, [selectedEvent]);

  return detailsComp;
};

export default LeftContentDetails;
