// REQUIREMENT : 4.1.9 Event History / Stored Video Lists

import React, { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import merge from 'lodash/merge';
import * as actionCreator from '../store/action-creator';
import ActivityListItem from './ActivityListItem';
import { getZoneName, objectHasKey } from '../../common/utils';
import ErrorCodes from '../../common/ErrorCodes';
import ScrollArea from '../../ui/ScrollBar/ScrollArea';
import CustomDialog from '../../ui/CustomDialog/CustomDialog';
import LoadingSpinner from '../../ui/LoadingSpinner/LoadingSpinner';
import classes from './ActivityList.module.css';
import { AuthContext } from '../../../Providers/AuthProvider';

const ActivityList = (props) => {
  const { user } = React.useContext(AuthContext);

  const { firstTimeLoaded } = props;
  const [events, setEvents] = useState(null);
  const [commonError, setCommonError] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState(null);
  const [hasEvalKey, setEvalKey] = useState(false);
  const [pageScrolled, setPageScrolled] = useState(false);

  const dispatch = useDispatch();
  const userEmail = user.userEmail;
  const allEvents = useSelector((state) => state.activityReducer.allEvents);
  const activeDevices = useSelector((state) => state.activityReducer.activeDevices);
  const filterData = useSelector((state) => state.activityReducer.filterData);
  const activityUpdated = useSelector((state) => state.activityReducer.activityUpdated);

  const resetVariables = () => {
    setCommonError(false);
    setCommonErrorMsg(null);
  };

  const eventSuccessResp = (resp) => {
    if (resp.errorCode === null && resp.eventData) {
      setEvents(resp);
      if (resp.lastEvaluatedKey) {
        setEvalKey(true);
      }
      if (activityUpdated) {
        dispatch(actionCreator.selectedEvent(resp.eventData[0].Activities[0]));
      }
    } else if (resp.errorCode === 'err_105') {
      setEvents('no events');
      dispatch(actionCreator.setNoActivityVideo(true));
    } else if (resp.errorMsg) {
      setCommonError(true);
      setCommonErrorMsg(resp.errorMsg);
      setEvents('no events');
      dispatch(actionCreator.setNoActivityVideo(true));
    }
  };

  const eventErrorResp = (err) => {
    setCommonError(true);
    setCommonErrorMsg(err);
    setEvents('no events');
  };

  const onGetEventsList = (finalParams, prevEventList) => {
    dispatch(actionCreator.getEventList(finalParams, prevEventList))
      .then(eventSuccessResp, eventErrorResp)
      .finally(() => {
        dispatch(actionCreator.setActivityUpdated(false));
        setPageScrolled(false);
      });
  };

  const getEvents = (hasOtherParams, prevEventList) => {
    resetVariables();
    let finalParams = {};
    const params = {
      action: 'list_events',
      data: {
        email: userEmail,
        event_count: 20,
        home_id: activeDevices?.homeID,
        timezone: getZoneName(),
      },
    };

    if (activeDevices.devIDs.length) {
      params.data.device_ids = activeDevices.devIDs;
    }
    finalParams = hasOtherParams ? merge(params, hasOtherParams) : params;
    if (objectHasKey(finalParams.data, 'event_type')) {
      const recordingIndex = finalParams.data.event_type.indexOf('recording');

      if (recordingIndex !== -1) {
        finalParams.data.event_type[recordingIndex] = 'Recording';
      }
    }
    onGetEventsList(finalParams, prevEventList);
  };

  // Execute this code on filter applied or device filter, call list events API
  useEffect(() => {
    if (activityUpdated) {
      dispatch(actionCreator.setNoActivityVideo(false));
      setEvents(null);
      getEvents(filterData);
    }
  }, [activityUpdated]);

  // Execute this code first time, call list events API
  useEffect(() => {
    if (activeDevices && firstTimeLoaded && !activityUpdated) {
      getEvents();
    }
  }, [activeDevices]);

  // Execute this code only filter icon toggled. Not calling list events API
  useEffect(() => {
    if (!firstTimeLoaded && allEvents && !activityUpdated) {
      if (allEvents.errorCode === null && allEvents.eventData.length) {
        setEvents(allEvents);
        if (allEvents.lastEvaluatedKey) {
          setEvalKey(true);
        }
      } else {
        setEvents('no events');
      }
    }
  }, [allEvents]);

  const scrollEndHandler = () => {
    if (events && hasEvalKey) {
      setEvalKey(false);
      setPageScrolled(true);
      const obj = {
        data: {
          last_evaluated_key: events.lastEvaluatedKey,
        },
      };
      if (filterData) {
        merge(obj, filterData);
      }
      const prevEventList = events.eventData;
      setTimeout(() => {
        getEvents(obj, prevEventList);
      });
    }
  };

  const spinnerConfig2 = {
    showSpinner: true,
    right: '40%',
    left: 'auto',
    bottom: 'auto',
  };

  const RenderActivityListItem = () => {
    const renderElem = events.eventData.map((event, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <ActivityListItem event={event} key={index} />
    ));

    return (
      <>
        {renderElem}
        <div className={classes.loadMoreEvents}>
          {pageScrolled && <LoadingSpinner spinnerConfig={spinnerConfig2} />}
        </div>
      </>
    );
  };

  const spinnerConfig = {
    showSpinner: true,
    right: '40%',
    left: 'auto',
    top: '40%',
  };

  const commonSpinnerConfig = {
    showSpinner: true,
    right: '10%',
    left: 'auto',
    top: '50%',
  };

  const errorDialogConfig = {
    open: commonError,
    textAlign: 'left',
    btnClicked: () => setCommonError(false),
  };

  return (
    <>
      <ScrollArea scrollClass="scrollContainer" scrollEnd={scrollEndHandler}>
        {events ? (
          events !== 'no events' ? (
            <RenderActivityListItem />
          ) : (
            <div>{ErrorCodes.err_105}</div>
          )
        ) : (
          <LoadingSpinner spinnerConfig={spinnerConfig} />
        )}
      </ScrollArea>
      {commonError && (
        <CustomDialog dialogConfig={errorDialogConfig}>
          <div>{commonErrorMsg}</div>
        </CustomDialog>
      )}
      <div id="spinner_container" style={{ visibility: 'hidden' }}>
        <LoadingSpinner spinnerConfig={commonSpinnerConfig} />
      </div>
    </>
  );
};

export default memo(ActivityList);
