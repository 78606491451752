import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import SubHeader from '../ui/SubHeader';
import ScrollArea from '../ui/ScrollBar/ScrollArea';
import * as iconElem from '../ui/Icons/Icons';
import TypographyElem from '../ui/Typography/Typography';

const Help = (props) => {
  const useStyles = makeStyles(() => ({
    card: {
      width: '60%',
      height: '6em',
      margin: '1.5em',
    },
  }));
  const muiclasses = useStyles();

  const previousPageHandler = () => props.history.goBack();

  const infoList = [
    {
      id: 1,
      menu: 'Portable 5-in-1 Emergency Hub',
      icon: iconElem.sruInfoIcon,
      iconStyle: {
        width: '3em',
      },
      navPage: 'https://www.bodyguardz.com/5-in-1-emergency-hub-support.html',
    },
    {
      id: 2,
      menu: 'Portable 360° Security Camera',
      icon: iconElem.htCameraInfoIcon,
      iconStyle: {
        width: '3em',
      },
      navPage: null,
    },
    {
      id: 3,
      menu: 'Portable Over-Door Camera',
      icon: iconElem.odCameraInfoIcon,
      iconStyle: {
        width: '3em',
        // height: 17
      },
      navPage: null,
    },
    {
      id: 4,
      menu: 'Smart Life Plan',
      icon: iconElem.subscriptionInfoIcon,
      iconStyle: {
        width: '3em',
      },
      navPage: null,
    },
  ];

  return (
    <>
      <ScrollArea>
        <SubHeader clicked={previousPageHandler} {...props} style={{ paddingLeft: 20 }} />
        <div style={{ marginLeft: '20px' }}>
          <TypographyElem content="Help" />
        </div>
        {infoList.map((list) => (
          <Card className={[muiclasses.card, 'help'].join(' ')} key={list.id}>
            <CardContent
              className={muiclasses.cardContent}
              style={{ background: list.id !== 1 ? '#f3f3f3' : '' }}
            >
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <img src={list.icon} alt="" />
                </Grid>
                <Grid item xs={8}>
                  <div style={{ paddingTop: '2.2em' }}>{list.menu}</div>
                </Grid>
                <Grid item xs={1}>
                  <a href={list.navPage} target="_blank" rel="noopener noreferrer">
                    <img
                      src={iconElem.forwardBlackIcon}
                      alt=""
                      style={{
                        paddingTop: '2.2em',
                        width: '16px',
                        cursor: list.id === 1 ? 'pointer' : 'auto',
                      }}
                    />
                  </a>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
      </ScrollArea>
    </>
  );
};

export default Help;
