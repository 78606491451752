// REQUIREMENT : 4.1.8 Stored Video Streaming

import React, { PureComponent } from 'react';
import videojs from 'video.js';
import VJSActionButtons from './VJSActionButtons';
import CaptureScreenshot from './CaptureScreenshot';
import 'video.js/dist/video-js.min.css';
import './VideoPlayer.css';

const videoOptions = {
  autoplay: true,
  sources: [{
    src: '',
    type: ''
  }],
  controls: true,
  controlBar: {
    fullscreenToggle: false,
    pictureInPictureToggle: false
  },
  currentWidth: 0,
  currentHeight: 0
};

// [#84703] In Activity page while scrolling the list the left side video is refreshing.
class VideoPlayer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      capture: false,
      btnCreate: false,
      btnArray: [
        { btnType: 'ScreenShot', btnId: 'screenshotVJSBtn' },
        { btnType: 'Download', btnId: 'downloadVJSBtn' },
        { btnType: 'Star', btnId: 'starVJSBtn' },
        { btnType: 'Delete', btnId: 'deleteVJSBtn' },
        { btnType: 'Share', btnId: 'shareVJSBtn' }
      ]
    };
  }

  componentDidMount() {
    const { videoConfig } = this.props;
    videoOptions.sources[0].src = videoConfig.path;
    if (videoConfig.videoMode === 'ON_DEMAND') {
      videoOptions.sources[0].type = 'application/x-mpegURL';
    } else if (videoConfig.videoMode === 'RECORDING') {
      videoOptions.sources[0].type = '';
    }
    this.onLoadedMetaData();
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  onLoadedMetaData() {
    this.player = videojs(this.videoNode, videoOptions, function onPlayerReady() {
      this.on('loadedmetadata', () => {
        const videoElem = document.querySelector('video');
        if (videoElem) {
          videoOptions.currentWidth = videoElem.videoWidth;
          videoOptions.currentHeight = videoElem.videoHeight;
        }
      });
    });

    if (this.player) {
      this.setState({ btnCreate: true });
    }
  }

  screenshotBtnHandler = status => this.setState({ capture: status });

  renderActionButtons = () => {
    const { btnArray } = this.state;
    const { videoConfig } = this.props;

    return btnArray.map(elem => {
      const btnConfig = {
        btnId: elem.btnId,
        btnType: elem.btnType,
        videoPath: videoConfig.path,
        clicked: this.screenshotBtnHandler
      };
      return <VJSActionButtons config={btnConfig} key={elem.btnId} />;
    });
  }

  render() {
    const { btnCreate, capture } = this.state;
    return (
      <>
        <div data-vjs-player>
          <video
            id="videoPlayer"
            ref={node => this.videoNode = node}
            className="video-js vjs-default-skin vjs-big-play-centered"
            crossOrigin="anonymous"
          />
          {
            btnCreate && this.renderActionButtons()
          }
        </div>
        <CaptureScreenshot
          captured={capture}
          videoWidth={videoOptions.currentWidth}
          videoHeight={videoOptions.currentHeight}
          resetCapture={this.screenshotBtnHandler}
        />
      </>
    );
  }
}

export default VideoPlayer;
