import React, { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import './Notification.css';

const PushNotification = props => {
  const { config, children } = props;
  const autoHide = config.autoHide || 10000;
  const type = config.type || 'success'; // other type is - error
  // const callFunction = config.callFunction || null;
  // const closeBtn = config.closeBtn || false;
  const getPosition = notificationPosition => {
    let pos = null;
    switch (notificationPosition) {
      case 'topLeft':
        pos = { vertical: 'top', horizontal: 'left' }; break;
      case 'topCenter':
        pos = { vertical: 'top', horizontal: 'center' }; break;
      case 'topRight':
        pos = { vertical: 'top', horizontal: 'right' }; break;
      case 'bottomLeft':
        pos = { vertical: 'bottom', horizontal: 'Left' }; break;
      case 'bottomCenter':
        pos = { vertical: 'bottom', horizontal: 'center' }; break;
      case 'bottomRight':
        pos = { vertical: 'bottom', horizontal: 'right' }; break;
      default: break;
    }
    return pos;
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return false;
    }
    config.onClose();
  };

  const position = getPosition('topLeft');

  const updateColor = notifyType => {
    const elem = document.querySelector('.NotificationBackup .MuiSnackbarContent-root');
    if (elem) {
      if (notifyType === 'error') {
        elem.classList.add('NotificationBackup-error');
      } else if (notifyType === 'message') {
        elem.classList.add('NotificationBackup-message');
      }
    }
  };

  useEffect(() => {
    updateColor(type);
  });

  const closeBtn = config.closeBtn
    ? (
      <span className="NotificationCloseBackup" onClick={config.onClose} role="presentation">
        x
      </span>
    )
    : null;

  return (
    <>
      <Snackbar
        className="NotificationBackup"
        open={config.open}
        autoHideDuration={autoHide}
        TransitionComponent={Slide}
        onClose={handleClose}
        anchorOrigin={position}
        message={children}
        action={closeBtn}
      />
    </>
  );
};

export default PushNotification;
