// REQUIREMENT : 4.1.10 Stored Video Management

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actionCreator from '../store/action-creator';
import LoadingSpinner from '../../ui/LoadingSpinner/LoadingSpinner';
import CustomDialog from '../../ui/CustomDialog/CustomDialog';
import VideoPlayer from '../../ui/VideoPlayer/VideoPlayer';
import { timeStampToDate } from '../../common/utils';
import msgConstant from '../../common/textConstants';
import ActivityVideoInfo from './ActivityVideoInfo';
import classes from './ActivityDetails.module.css';
import { AuthContext } from '../../../Providers/AuthProvider';

const ActivityVideo = (props) => {
  const { user } = React.useContext(AuthContext);
  const userEmail = user.userEmail;

  const { selectedEvent } = props;
  const [video, setVideo] = useState(null);
  const [commonError, setCommonError] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState(null);
  const [videoMode, setVideoMode] = useState(null);

  const dispatch = useDispatch();
  const noActivityVideo = useSelector((state) => state.activityReducer.noActivityVideo);

  useEffect(() => {
    if (noActivityVideo) {
      setVideo('no video');
    }
  }, [noActivityVideo]);

  const resetVariables = () => {
    setVideo(null);
    setCommonError(false);
    setCommonErrorMsg(null);
  };

  // [#84703] In Activity page while scrolling the list the left side video is refreshing.
  const getVideoStreamURL = (params) => {
    actionCreator.fetchEventVideoStream(params).then(
      (resp) => {
        if (resp.errorCode === null && resp.streamVideoPath) {
          setVideo(resp.streamVideoPath);
        } else if (resp.errorCode) {
          setCommonError(true);
          setCommonErrorMsg(msgConstant.VIDEO_PLAYBACK_ERROR);
          setVideo('no video');
        }
      },
      () => setVideo('no video')
    );
  };

  useEffect(() => {
    if (selectedEvent) {
      resetVariables();
      const startTime = selectedEvent.Timestamp;
      const endTime = selectedEvent.Timestamp + selectedEvent.DurationSeconds;
      let mode = 'ON_DEMAND';
      let deviceName = selectedEvent.StreamingCamera;
      if (selectedEvent.ActivityType.toLowerCase() === 'recording') {
        mode = 'RECORDING';
        deviceName = selectedEvent.DeviceID;
      }

      setVideoMode(mode);
      const params = {
        action: 'get_hls_link',
        data: {
          email: userEmail,
          device_name: deviceName,
          mode,
        },
      };

      if (mode === 'ON_DEMAND') {
        params.data.start_time = timeStampToDate(startTime);
        params.data.end_time = timeStampToDate(endTime);
      } else if (mode === 'RECORDING') {
        params.data.activity_id = selectedEvent.ActivityID;
      }
      getVideoStreamURL(params);
      dispatch(actionCreator.setStarred(selectedEvent.IsFavourite));
    }
  }, [selectedEvent]);

  const VideoRender = () => {
    const videoConfig = {
      path: video,
      videoMode,
    };

    return (
      <div className={classes.videoContainer}>
        <div className={classes.streamVideoText}>
          <ActivityVideoInfo {...props} />
        </div>
        <VideoPlayer videoConfig={videoConfig} />
      </div>
    );
  };

  // [#84600] Activity page showing something went wrong(screen shot attached)
  const VideoError = () => (
    <div className={classes.videoContainer} style={{ background: '#989898' }}>
      <div className={classes.streamVideoError}>Video currently unavailable</div>
    </div>
  );

  const spinnerConfig = {
    showSpinner: true,
    left: '40%',
    zIndex: '-1',
  };

  const errorDialogConfig = {
    open: commonError,
    textAlign: 'left',
    btnClicked: () => setCommonError(false),
  };

  return (
    <>
      {video ? (
        video !== 'no video' ? (
          <VideoRender />
        ) : (
          <VideoError />
        )
      ) : (
        <LoadingSpinner spinnerConfig={spinnerConfig} />
      )}
      {commonError && (
        <CustomDialog dialogConfig={errorDialogConfig}>
          <div>{commonErrorMsg}</div>
        </CustomDialog>
      )}
    </>
  );
};

export default ActivityVideo;
