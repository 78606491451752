// REQUIREMENT : 4.1.10 Stored Video Management
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { convertTimestamp } from '../../common/utils';

const ActivityVideoInfo = (props) => {
  const { selectedEvent, deviceInfo } = props;
  const [eventInfo, setEventInfo] = useState(null);

  const isAllActivity = useSelector((state) => state.activityReducer.isAllActivity);

  useEffect(() => {
    const allActivity = isAllActivity ? `${deviceInfo}, ` : '';
    const dateSet = convertTimestamp(selectedEvent.Timestamp, null, true);

    const info = `${allActivity}
    ${selectedEvent.ActivityType},
    ${dateSet}, ${convertTimestamp(selectedEvent.Timestamp, true)}`;

    setEventInfo(info);
  }, []);

  return eventInfo;
};

export default ActivityVideoInfo;
