// -------------------- AWS S3 bucket supporting function ---------------------
import { Storage } from 'aws-amplify';
import moment from 'moment';
import timeZone from 'moment-timezone';

export const SetS3Config = (bucket, level) => {
  Storage.configure({
    bucket,
    level,
    region: 'us-east-1',
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOl_ID,
  });
};

// --------------------- Truncating line after some length --------------------
export const TrunLine = (text, length) => {
  if (text.length > length) {
    return `${text.substring(0, length)}...`;
  }
  return text;
};

// -----------------------  CONVERT Epoch and UNIX Time to Normal Timestamp ----------------------

export const convertTimestamp = (timestamp, AMPM, dateGroup) => {
  const d = new Date(timestamp * 1000); // Convert the passed timestamp to milliseconds
  // const yy = d.getFullYear().toString().substr(-2);
  // const mm = (`0${d.getMonth() + 1}`).slice(-2);// Months are zero based. Add leading 0.
  // const dd = (`0${d.getDate()}`).slice(-2);// Add leading 0.

  if (dateGroup) {
    const dt = d.toDateString();
    let actualDate = dt.split(' ');
    actualDate = `${actualDate[1]} ${actualDate[2]} ${actualDate[3]}`;

    let today = new Date();
    today = today.toDateString();
    let todaysDate = today.split(' ');
    todaysDate = `${todaysDate[1]} ${todaysDate[2]} ${todaysDate[3]}`;

    return actualDate === todaysDate ? 'today' : actualDate;
  }

  const hh = d.getHours();
  let h = hh;
  const min = `0${d.getMinutes()}`.slice(-2); // Add leading 0.
  let ampm = 'AM';

  // 2019- 8-29-10:29 AM

  // 8-12-19 11:22:32 AM
  if (hh > 12) {
    h = hh - 12;
    ampm = 'PM';
  } else if (hh === 12) {
    h = 12;
    ampm = 'PM';
  } else if (hh === 0) {
    h = 12;
  }

  // ie: 2013-02-18, 8:35 AM
  // const time = yyyy + '-' + mm + '-' + dd + ', ' + h + ':' + min + ' ' + ampm;

  // ie: 08-29-19 10:29 AM
  // const time = `${mm}-${dd}-${yy} ${h}:${min} ${ampm}`;

  const time = AMPM ? `${h}:${min} ${ampm}` : `${h}:${min}`;
  return time;
};

export const timeStampToDate = (timestamp) => {
  const dt = timestamp ? moment.utc(timestamp * 1000).format('MM-DD-YY hh:mm:ss A') : null;
  return dt;
};

export const timeStampToDateFormat = (timestamp) => {
  const dt = timestamp ? moment.utc(timestamp * 1000).format('MMM DD YYYY') : null;
  return dt;
};

export const unixToMilisecond = (ts) => {
  const miliSecond = ts ? (ts * 1000).toString() : null;
  return miliSecond;
};

export const secondsToHms = (secs) => {
  const hours = Math.floor(secs / 3600);

  const divisorForMintues = secs % 3600;
  const minutes = Math.floor(divisorForMintues / 60);

  const divisorForSeconds = divisorForMintues % 60;
  const seconds = Math.ceil(divisorForSeconds);

  const hDisplay = hours > 0 ? `${hours}hr ` : '';
  const mDisplay = minutes > 0 ? `${minutes}min ` : '';
  const sDisplay = seconds > 0 ? `${seconds}sec` : '';

  return `${hDisplay}${mDisplay}${sDisplay}`;
};

export const objectHasKey = (obj, key) => {
  if (obj) {
    const status = Object.prototype.hasOwnProperty.call(obj, key);
    return status;
  }
};

export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== '' && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }
  // ! @ # $ % ^ & * ( ) _ + - = [ ] { } | '
  if (rules.isPassword) {
    const pattern =
      // eslint-disable-next-line
      /^(?=.*[A-Z])(?=.*\d)(?=.*[\^\$\*\.\[\]{}\(\)\?\-!@#%&\/,><:;\|_~`])[A-Za-z\d\^\$\*\.\[\]{}\(\)\?\-!@#%&\/,><:;\|_~`]{8,99}$/;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
};

export const addSecondsToTimeStamp = (val, sec) => {
  const dt = new Date(val * 1000);
  const addedSec = dt.setSeconds(dt.getSeconds() + sec);
  const timeStampVal = addedSec / 1000;
  return timeStampVal;
};

export const addDays = (date, days) => {
  if (date && days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    const yy = result.getFullYear().toString();
    const mm = `0${result.getMonth() + 1}`.slice(-2);
    const dd = `0${result.getDate()}`.slice(-2);
    return `${mm}-${dd}-${yy}`;
  }
};

export const timeStampToDateTime = (timestamp) => {
  const d = new Date(timestamp * 1000);
  const yy = d.getFullYear().toString().substr(-2);
  const mm = `0${d.getMonth() + 1}`.slice(-2);
  const dd = `0${d.getDate()}`.slice(-2);

  const sec = d.getSeconds();
  const hh = d.getHours();
  let h = hh;
  const min = `0${d.getMinutes()}`.slice(-2);
  let ampm = 'AM';

  if (hh > 12) {
    h = hh - 12;
    ampm = 'PM';
  } else if (hh === 12) {
    h = 12;
    ampm = 'PM';
  } else if (hh === 0) {
    h = 12;
  }

  const dt = `${mm}/${dd}/${yy} ${h}:${min}:${sec} ${ampm}`;
  return dt;
};

export const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties,
});

/* It accpets only mm/dd/yyyy format */
export const validateDate = (dateStr) => {
  const regExp = /^(\d\d?)\/(\d\d?)\/(\d{4})$/;
  const matches = dateStr.match(regExp);
  let isValid = matches;
  const maxDate = [0, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  if (matches) {
    const month = parseInt(matches[1]);
    const date = parseInt(matches[2]);
    const year = parseInt(matches[3]);

    isValid = month <= 12 && month > 0;
    // eslint-disable-next-line no-bitwise
    isValid &= date <= maxDate[month] && date > 0;

    const leapYear = year % 400 === 0 || (year % 4 === 0 && year % 100 !== 0);
    // eslint-disable-next-line no-bitwise
    isValid &= month !== 2 || leapYear || date <= 28;
  }

  return isValid;
};

export const getDateTime = () => {
  const date = moment().local().format('YYYYMMDD');
  const time = moment().local().format('HHmm');
  return `${date}-${time}`;
};

export const timeConversion = (timestamp) => {
  const formattedDate = moment
    .utc(timestamp * 1000)
    .local()
    .format('dddd, MMMM DD, YYYY');
  const formattedTime = moment
    .utc(timestamp * 1000)
    .local()
    .format('HH:mm A');
  return `${formattedDate} at ${formattedTime}`;
};

export const getZoneName = () => timeZone.tz.guess(true);

export const getAllZoneName = () => timeZone.tz.names();

export const currentTimeZoneConversion = () => moment().format('X'); // lowercase 'x' for milliseconds

// eslint-disable-next-line no-bitwise
export const getBitPosition = (num, pos) => (num >> (pos - 1)) & 1;
