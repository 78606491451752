import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MessageDialog from './MessageDialog';
import ActionDialog from './ActionDialog';
import './CustomDialog.css';

const CustomDialog = props => {
  const { dialogConfig, children } = props;
  const textPosition = dialogConfig.textAlign || 'center';
  const dialogWidth = dialogConfig.maxWidth || 'xs';
  const dialogType = dialogConfig.type || 'message';
  const buttonAlign = dialogConfig.btnAlign || 'center';

  return (
    <>
      <Dialog
        open={dialogConfig.open}
        className="customDialog"
        align={textPosition}
        maxWidth={dialogWidth}
      >
        {
          dialogConfig.title && <DialogTitle>{dialogConfig.title}</DialogTitle>
        }
        <DialogContent>{children}</DialogContent>
        <DialogActions align={buttonAlign}>
          {
            dialogType === 'message' ? <MessageDialog {...props} /> : <ActionDialog {...props} />
          }
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomDialog;
