import React from 'react';
import { useDispatch } from 'react-redux';
import Input from '@material-ui/core/Input';
import { TrunLine } from '../../common/utils';
import * as actionCreator from '../store/action-creator';
import { userDeleteIcon, editIcon } from '../../ui/Icons/Icons';
import classes from './EditHome.module.css';
import StringTooltip from '../../ui/StringTooltip';

const AuthorisedUser = props => {
  const {
    editUser, deleteUser, userId, deviceLists
  } = props;

  const dispatch = useDispatch();

  const inviteClassList = ['CustomInput', 'ManageHomeFormInput', 'Font13', classes.invite].join(' ');

  const onEditHandler = () => {
    const data = { user: userId, devices: deviceLists };
    dispatch(actionCreator.userSelected(data));
    editUser();
  };

  const onDeleteHandler = () => {
    const data = { user: userId };
    dispatch(actionCreator.userSelected(data));
    deleteUser(true);
  };

  return (
    <div style={{ position: 'relative' }}>
      <StringTooltip val={userId} length={27}>
        <Input
          className={inviteClassList}
          value={userId.length > 27 ? TrunLine(userId, 27) : userId}
          readOnly
        />
      </StringTooltip>
      <img
        src={editIcon}
        alt="Delete"
        className={classes.userEditIcon}
        onClick={onEditHandler}
        role="presentation"
      />
      <img
        src={userDeleteIcon}
        alt="Delete"
        className={classes.userDeleteIcon}
        onClick={onDeleteHandler}
        role="presentation"
      />
    </div>
  );
};

export default AuthorisedUser;
