import React, { useState, useEffect } from 'react';

const RecordingTimer = props => {
  const { startTimer } = props;
  const [seconds, setSeconds] = useState(0);
  const timerText = {
    color: '#FFF'
  };

  useEffect(() => {
    let interval = null;
    if (startTimer) {
      interval = setInterval(() => {
        setSeconds(() => seconds + 1);
      }, 1000);
    } else if (!startTimer && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [startTimer, seconds]);

  return (
    <div style={timerText}>
      {
        (`0${Math.floor(seconds / 3600) % 3600}`).slice(-2)
      }
      :
      {
        (`0${Math.floor(seconds / 60) % 60}`).slice(-2)
      }
      :
      {
        (`0${Math.floor(seconds / 1) % 60}`).slice(-2)
      }
    </div>
  );
};

export default RecordingTimer;
