import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PubSub } from 'aws-amplify';
import Grid from '@material-ui/core/Grid';
import * as actionCreator from '../../../store/action-creator';
import { getHomeDevices } from '../../../../ManageHomes/store/action-creator';
import CustomDialog from '../../../../ui/CustomDialog/CustomDialog';
import LoadingSpinner from '../../../../ui/LoadingSpinner/LoadingSpinner';
import ErrorHandler from '../../ErrorHandler/ErrorHandler';
import IndividualSetting from '../IndividualSetting';
import DeviceRename from '../Common_settings/DeviceRename';
import DeviceTechInfo from '../Common_settings/DeviceTechInfo';
import Notification from '../../../../ui/Notification/Notification';
import ActivityTriggers from './ActivityTriggers';
import ImgOn from '../../../../../assets/device/Light_ON.png';
import ImgOff from '../../../../../assets/device/Light_OFF.png';
import classes from '../DeviceSettings.module.css';
import { EDIT_HOME, HOME } from '../../../../../constants/routes';
import msgConstant from '../../../../common/textConstants';
import { objectHasKey, getZoneName } from '../../../../common/utils';
import ErrorCodes from '../../../../common/ErrorCodes';
import OverDoorCameraDropdown from './OverdoorCameraDropdown';
import { AuthContext } from '../../../../../Providers/AuthProvider';

let isActivityTriggerPage = false;
const CameraSettings = (props) => {
  const { user } = React.useContext(AuthContext);
  const email = user.userEmail;
  const IdentityId = user.IdentityId;
  const {
    deviceInfo,
    deviceResp,
    MQTTData,
    removeDevice,

    history,
    onDeleteVideoHistory,
    hasEvents,

    isMQTTUpdated,
    onSetMQTTUpdated,
    onSelectedCamera,
    selectedCamera,
  } = props;

  // const [deviceData, setDeviceData] = useState(deviceInfo);
  const [light, setLight] = useState(0);
  const [trigger, setTrigger] = useState(false);
  const [rename, setRename] = useState(false);
  const [techInfo, setTechInfo] = useState(false);
  const [camera, setCamera] = useState(0);
  const [microphone, setMicrophone] = useState(0);
  const [deviceHome, setDeviceHome] = useState(null);
  const [isLoaded, setLoaded] = useState(true);
  // const [oldMqttData, setOldMqttData] = useState(null);
  const [videoModal, setVideoModal] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const [evtList, setEvtList] = useState(false);
  const [invtUserModal, setinvtUserModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [commonError, setCommonError] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [batteryLevel, setBatteryLevel] = useState(0);
  const [showMicrophoneModal, setShowMicrophoneModal] = useState(false);
  const [mpStatus, setMPStatus] = useState(0);
  const [inCameraStatus, setInCameraStatus] = useState(false);
  const [activePlan, setActivePlan] = useState(null);
  const disabledclsName = [classes.deleteClass, classes.disabledDeleteBtn].join(' ');

  let selectedHome = null;
  if (deviceResp) {
    selectedHome = deviceResp.homeList.filter(
      (each) => each?.HomeID === deviceResp.deviceData?.HomeId
    );
  }

  const commonErrorBtnHandler = () => setCommonError(!commonError);

  const commonErrorDialogConfig = {
    open: commonError,
    textAlign: 'left',
    btnClicked: commonErrorBtnHandler,
  };

  const onSetRename = () => setRename(!rename);
  const onSetTechInfo = () => setTechInfo(!techInfo);
  const onSetTrigger = () => {
    setTrigger(!trigger);
    setTimeout(() => {
      isActivityTriggerPage = true;
      // eslint-disable-next-line no-use-before-define
      renderCameraSetting();
    });
  };

  useEffect(
    () => () => {
      isActivityTriggerPage = false;
      onSelectedCamera(null);
    },
    []
  );

  const onSetHome = () => {
    if (selectedHome[0].Creator === email) {
      props
        .onGetHomeData(email, deviceResp.deviceData.HomeName)
        .then((resp) => {
          if (resp.data.ErrorCode === 'err_0') {
            props.history.push({
              pathname: EDIT_HOME,
              state: {
                prevPath: props.location.pathname,
                prevDeviceId: deviceInfo.DeviceId,
              },
            });
          } else {
            setCommonError(!commonError);
            setErrorMsg(ErrorCodes[resp.data.ErrorCode]);
          }
        })
        .catch(() => {
          setCommonError(!commonError);
          setErrorMsg(`${msgConstant.COMMON_ERROR}`);
        });
    } else setinvtUserModal(true);
  };

  const deleteVideoHandler = (status) => {
    if (status) {
      setSpinner(true);
      const params = {
        action: 'delete_all',
        data: { email_id: email, thingName: deviceInfo.DeviceId },
      };
      onDeleteVideoHistory(params)
        .then(
          (resp) => {
            const msg = resp.errorMsg ? resp.errorMsg : 'Successfully deleted';
            setNotificationMsg(msg);
            setShowNotification(true);
          },
          () => {
            setNotificationMsg('Failed to delete video history');
            setShowNotification(true);
          }
        )
        .catch()
        .finally(() => {
          setEvtList(false);
          setTimeout(() => {
            props.history.push(HOME);
          }, 1000);
          // setVideoDeleteModal(true);
        });
    }
    setVideoModal(!videoModal);
  };

  const removeCameraHandler = () => {
    // BUG-[#84291] Not able to delete the device
    const data = {
      device_id: deviceInfo.DeviceId,
      email,
      home_id: deviceResp.deviceData?.HomeId,
      history,
      IdentityId,
    };
    setRemoveModal(!removeModal);
    setSpinner(true);
    // [#84850] Not able to remove the camera devices from an account.
    removeDevice(data).then(
      (resp) => {
        const respCode = resp.ResponseCode;
        const errCode = resp.ErrorCode;
        if (respCode !== 200 || (respCode === 200 && errCode !== 'err_0')) {
          setCommonError(!commonError);
          setErrorMsg(ErrorCodes[errCode]);
        } else {
          setShowNotification(true);
          setNotificationMsg(msgConstant.DEVICE_REMOVE);
          setTimeout(() => {
            props.history.push(HOME);
          }, 1000);
        }
      },
      (err) => {
        setCommonError(!commonError);
        setErrorMsg(err);
      }
    );
  };

  const videoBtnHandler = () => {
    if (selectedHome[0].Creator === email) {
      setVideoModal(!videoModal);
    } else setinvtUserModal(true);
  };

  const removeModalHandler = () => {
    if (selectedHome[0].Creator === email) {
      setRemoveModal(!removeModal);
    } else setinvtUserModal(true);
  };

  const removeHandler = (hasAction) => {
    if (hasAction) {
      removeCameraHandler();
    } else {
      setRemoveModal(!removeModal);
    }
  };

  const updateStatus = (newStatus) => {
    if (deviceInfo) {
      PubSub.publish(`$aws/things/${deviceInfo.DeviceId}/shadow/update`, {
        state: { desired: newStatus },
      });
    }
  };

  const normalCameraSetting = () => {
    if (objectHasKey(MQTTData.desired, 'nightLight')) {
      if (light !== MQTTData.desired.nightLight) {
        setLight(MQTTData.desired.nightLight);
      }
    }
    if (objectHasKey(MQTTData.desired, 'power')) {
      if (camera !== MQTTData.desired.power) {
        setCamera(MQTTData.desired.power);
      }
    }
    if (objectHasKey(MQTTData.desired, 'microphone')) {
      if (microphone !== MQTTData.desired.microphone) {
        setMicrophone(MQTTData.desired.microphone);
      }
    }
    if (objectHasKey(MQTTData.reported, 'batRem')) {
      // eslint-disable-next-line eqeqeq
      if (batteryLevel != MQTTData.reported.batRem) {
        setBatteryLevel(MQTTData.reported.batRem);
      }
    }
    if (objectHasKey(MQTTData.reported, 'activePlan')) {
      if (activePlan !== MQTTData.reported.activePlan) {
        setActivePlan(MQTTData.reported.activePlan);
      }
    }
  };

  const inCameraSetting = () => {
    if (objectHasKey(MQTTData.desired, 'nightLightInw')) {
      if (light !== MQTTData.desired.nightLightInw) {
        setLight(MQTTData.desired.nightLightInw);
      }
    }
    if (objectHasKey(MQTTData.desired, 'powerInw')) {
      if (camera !== MQTTData.desired.powerInw) {
        setCamera(MQTTData.desired.powerInw);
      }
    }
    if (objectHasKey(MQTTData.desired, 'microphoneInw')) {
      if (microphone !== MQTTData.desired.microphoneInw) {
        setMicrophone(MQTTData.desired.microphoneInw);
      }
    }
    if (objectHasKey(MQTTData.reported, 'batRem')) {
      // eslint-disable-next-line eqeqeq
      if (batteryLevel != MQTTData.reported.batRem) {
        setBatteryLevel(MQTTData.reported.batRem);
      }
    }
    if (objectHasKey(MQTTData.reported, 'activePlan')) {
      if (activePlan !== MQTTData.reported.activePlan) {
        setActivePlan(MQTTData.reported.activePlan);
      }
    }
  };

  const renderCameraSetting = () => {
    if (deviceInfo.DeviceType === 'OverDoorCamera') {
      if (objectHasKey(MQTTData.desired, 'activeCamera') && MQTTData.desired.activeCamera) {
        inCameraSetting();
        if (!isActivityTriggerPage) {
          onSelectedCamera('inside');
        }
      } else {
        if (!isActivityTriggerPage) {
          onSelectedCamera('outside');
        }
        normalCameraSetting();
      }
    } else {
      normalCameraSetting();
    }
  };

  useEffect(() => {
    if (MQTTData && isLoaded) {
      if (objectHasKey(MQTTData, 'desired')) {
        setLoaded(false);
        setTimeout(() => {
          renderCameraSetting();
        });
      }
    }
  }, [MQTTData]);

  useEffect(() => {
    if (isMQTTUpdated && !trigger) {
      onSetMQTTUpdated();
      setTimeout(() => {
        if (deviceInfo.DeviceType === 'OverDoorCamera') {
          if (selectedCamera && selectedCamera === 'inside') {
            inCameraSetting();
          } else {
            normalCameraSetting();
          }
        } else {
          normalCameraSetting();
        }
      });
    }
  }, [isMQTTUpdated]);

  /* [#84886] 'Delete video history' is disabled in settings page
  even though events are present in activity list. */
  useEffect(() => {
    const params = {
      action: 'list_events',
      data: {
        home_id: deviceResp.deviceData?.HomeId,
        email,
        device_ids: [deviceInfo.DeviceId],
        event_count: 0,
        timezone: getZoneName(),
      },
    };
    hasEvents(params).then(
      (resp) => {
        if (resp) {
          setEvtList(true);
        }
      },
      () => setEvtList(false)
    );
  }, [deviceInfo.DeviceId, email, hasEvents]);

  useEffect(() => {
    setDeviceHome(deviceResp.deviceData.HomeName);
  }, [deviceResp]);

  const onToggle = (type, status) => {
    let isInCamera = false;
    const newStatus = {};
    if (deviceInfo.DeviceType === 'OverDoorCamera') {
      if (selectedCamera && selectedCamera === 'inside') {
        isInCamera = true;
      }
    }

    switch (type) {
      case 'camera': {
        const cameraStatus = status ? 0 : 1;
        setCamera(cameraStatus);
        if (isInCamera) {
          newStatus.powerInw = cameraStatus;
        } else {
          newStatus.power = cameraStatus;
        }
        updateStatus(newStatus);
        break;
      }
      case 'light': {
        const lightStatus = status ? 0 : 1;
        setLight(lightStatus);
        if (isInCamera) {
          newStatus.nightLightInw = lightStatus;
        } else {
          newStatus.nightLight = lightStatus;
        }
        updateStatus(newStatus);
        break;
      }
      case 'microphone': {
        // Microphone and Sound detection handling.
        const getMPStatus = status ? 0 : 1;
        if (
          (status && isInCamera && MQTTData.desired.soundDetectionInw) || // Overdoor Inward camera
          (status && !isInCamera && MQTTData.desired.soundDetection) // HTC
        ) {
          setMPStatus(getMPStatus);
          setInCameraStatus(isInCamera);
          setShowMicrophoneModal(true);
        } else {
          if (isInCamera) {
            newStatus.microphoneInw = getMPStatus;
          } else {
            newStatus.microphone = getMPStatus;
          }
          updateStatus(newStatus);
        }
        break;
      }
      default:
        break;
    }
  };

  const NightLight = () => (
    <div className={classes.setting}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="Font14" style={{ paddingTop: '10px' }}>
          Night light
        </div>
        <div onClick={() => onToggle('light', light)} role="presentation">
          <img src={light ? ImgOn : ImgOff} alt="light" width="30" style={{ cursor: 'pointer' }} />
        </div>
      </div>
    </div>
  );

  const ToggleElem = (prop) => (
    <div className={classes.setting}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item className="Font14">
          {prop.name}
        </Grid>
        <Grid item>
          <label className="ToggleSwitch">
            <input
              type="checkbox"
              checked={prop.value}
              onChange={() => prop.change(`${prop.type}`, prop.value)}
            />
            <span className="ToggleSliders ToggleRound" />
          </label>
        </Grid>
      </Grid>
    </div>
  );

  const videoModalConfig = {
    open: videoModal,
    btnClicked: (status) => deleteVideoHandler(status),
    btnText: 'Delete',
    title: 'Delete Video History',
    disabled: true,
    type: 'action',
  };

  const cameraModalConfig = {
    open: removeModal,
    btnClicked: removeHandler,
    btnText: 'Remove',
    title: 'Remove Camera',
    disabled: true,
    type: 'action',
  };

  const handleModalClose = () => setinvtUserModal(false);

  const invitedUserModalConfig = {
    open: invtUserModal,
    type: 'message',
    btnClicked: () => handleModalClose(),
  };

  const changeHandler = (event) => {
    const val = event.target.value;
    onSelectedCamera(val);
    setTimeout(() => {
      if (val === 'outside') {
        normalCameraSetting();
      } else {
        inCameraSetting();
      }
    });
  };

  const RenderDeleteVideoHistory = () => (
    <div className={classes.setting} align="center">
      <span
        className={evtList ? classes.deleteClass : disabledclsName}
        onClick={evtList ? videoBtnHandler : null}
        role="presentation"
      >
        Delete Video History
      </span>
    </div>
  );

  const RenderRemoveCamera = () => (
    <div className={classes.setting} align="center">
      <span className={classes.deleteClass} onClick={removeModalHandler} role="presentation">
        Remove Camera
      </span>
    </div>
  );

  const renderDialog = (customConfig, msg) => (
    <CustomDialog dialogConfig={customConfig}>
      <div>{msg}</div>
    </CustomDialog>
  );

  const notificationConfig = {
    position: 'bottomRight',
    open: showNotification,
    onClose: () => setShowNotification(false),
  };

  const spinnerConfig = {
    showSpinner: spinner,
    left: '40%',
  };

  const btnHandler = (status) => {
    if (status) {
      const newStatus = {};
      setMicrophone(mpStatus);
      if (inCameraStatus) {
        newStatus.microphoneInw = mpStatus;
        newStatus.soundDetectionInw = 0;
      } else {
        newStatus.microphone = mpStatus;
        newStatus.soundDetection = 0;
      }
      updateStatus(newStatus);
    }
    setShowMicrophoneModal(false);
  };

  const mpDialogConfig = {
    open: showMicrophoneModal,
    type: 'action',
    disabled: true,
    btnText: 'OK',
    btnClicked: (status) => btnHandler(status),
  };

  return (
    <div className={classes.settingPanelPadding}>
      {!rename && !techInfo && !trigger && (
        <>
          <div className={classes.header}>Settings</div>
          <div className={classes.horLine} />
          {deviceInfo.DeviceType === 'HomeAndTravelCamera' && <NightLight />}
          <div className={classes.sectionHeader}>DEVICE OPTIONS</div>
          <IndividualSetting clicked={onSetHome} displayHome={deviceHome} value="Location" />
          <IndividualSetting
            clicked={onSetRename}
            displayName={deviceInfo.DisplayName}
            value="Name"
          />
          <IndividualSetting value="Battery Level" batteryLevel={batteryLevel} />
          <IndividualSetting value="Plan" activePlan={activePlan} />
        </>
      )}
      {/* [#84889] In Out Door camera Live streaming settings
        Switch camera drop down is not displayed. */}
      {/* <OverDoorCamera camType={cameraType} changed={changeHandler} /> */}
      {!rename && !techInfo && !trigger && (
        <>
          {<OverDoorCameraDropdown selectedCamera={selectedCamera} changeHandler={changeHandler} />}
          {deviceInfo.DeviceType === 'OverDoorCamera' && <NightLight />}
          <ToggleElem name="Camera On/Off" value={camera} change={onToggle} type="camera" />
          <IndividualSetting clicked={onSetTrigger} value="Activity Triggers" />
          <ToggleElem
            name="Microphone On/Off"
            value={microphone}
            change={onToggle}
            type="microphone"
          />
          <div className={classes.sectionHeader}>ABOUT</div>
          <IndividualSetting clicked={onSetTechInfo} value="Technical Information" />
          <RenderDeleteVideoHistory />
          <RenderRemoveCamera />
        </>
      )}
      {rename && <DeviceRename RenameFlag={onSetRename} />}
      {techInfo && (
        <ErrorHandler setFlag={onSetTechInfo} header="Technical Information">
          <DeviceTechInfo TechFlag={onSetTechInfo} {...props} />
        </ErrorHandler>
      )}
      {trigger && (
        <ErrorHandler setFlag={onSetTrigger} header="Alert Triggers">
          <ActivityTriggers triggerHandler={onSetTrigger} MqttInfo={MQTTData} trigger={trigger} />
        </ErrorHandler>
      )}
      {invtUserModal && renderDialog(invitedUserModalConfig, msgConstant.CREATOR_CHECK)}
      {videoModal && renderDialog(videoModalConfig, msgConstant.VIDEO_HISTORY_DELETE_CHECK)}
      {removeModal && renderDialog(cameraModalConfig, msgConstant.REMOVE_CAMERA_CHECK)}
      {commonError && renderDialog(commonErrorDialogConfig, errorMsg)}
      {/* [#84850] Not able to remove the camera devices from an account. */}
      {showNotification && (
        <Notification config={notificationConfig}>{notificationMsg}</Notification>
      )}
      {spinner && <LoadingSpinner spinnerConfig={spinnerConfig} />}
      {showMicrophoneModal && (
        <CustomDialog dialogConfig={mpDialogConfig}>
          <div>{msgConstant.MICROPHONE_TRIGGER}</div>
        </CustomDialog>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  MQTTData: state.deviceReducer.MQTTInfo,
  deviceInfo: state.deviceReducer.deviceInfo,
  deviceResp: state.deviceReducer.deviceResp,
  isMQTTUpdated: state.deviceReducer.MQTTUpdated,
  selectedCamera: state.deviceReducer.selectedCamera,
});

const mapDispatchToProps = (dispatch) => ({
  onResetMQTTData: () => dispatch(actionCreator.resetMQTTInfo()),
  removeDevice: (data) => dispatch(actionCreator.removeDevice(data)),
  onDeleteVideoHistory: (param) => actionCreator.deleteVideoHistory(param),
  hasEvents: (param) => actionCreator.deviceHasEvents(param),
  onGetHomeData: (email, homeId) => getHomeDevices(email, homeId),
  onSetMQTTUpdated: () => dispatch(actionCreator.setMQTTUpdated(false)),
  onSelectedCamera: (type) => dispatch(actionCreator.setSelectedCamera(type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CameraSettings));
