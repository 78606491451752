import React from 'react';
import Grid from '@material-ui/core/Grid';
import classes from '../DeviceSettings.module.css';

const TriggerEle = prop => {
  const {
    source, value, name, type, change
  } = prop;
  return (
    <>
      <Grid container justify="space-between" alignItems="center" className={classes.toggleItem} style={{ margin: '10px 0px' }}>
        <Grid item>
          <img src={source} alt="Sound" className={classes.toggleIcons} />
          <span className="Font11">{name}</span>
        </Grid>
        <Grid item>
          <label className="ToggleSwitch">
            <input type="checkbox" checked={value} onChange={() => change(`${type}`, value)} />
            <span className="ToggleSliders ToggleRound" />
          </label>
        </Grid>
      </Grid>
    </>
  );
};

export default TriggerEle;
