import React from 'react';
import classes from '../../DeviceSettings.module.css';

const FMChannelList = props => {
  const { list, clicked } = props;
  const handleFMChannel = val => clicked(val);

  return (
    <>
      <h5 style={{ marginTop: '2em', marginBottom: '10px' }}>Available channels</h5>
      {
        list && list.map(channel => (
          <div key={channel} className={classes.fmChannels}>
            <span
              className="CursorPointer"
              role="presentation"
              onClick={() => handleFMChannel(channel)}
            >
              {channel}
            </span>
          </div>
        ))
      }
    </>
  );
};

export default FMChannelList;
