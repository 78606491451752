import React, { useState } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { PubSub } from 'aws-amplify';
import messaging from './init-fcm';
import IdleTimeWrap from './components/common/IdleTimeWrap';
import { DEVICE, NOTIFICATION_DETAILS } from './constants/routes';
import * as homePageAC from './components/Home/store/action-creator';
import NotificationMsg from './components/ui/Notification/PushNotification';
import { getDevicePlanInfo, setDeviceInfo } from './components/DeviceList/store/action-creator';
import { selectedHome } from './components/ManageHomes/store/action-creator';
import CustomDialog from './components/ui/CustomDialog/CustomDialog';
import msgConstant from './components/common/textConstants';
import ErrorCodes from './components/common/ErrorCodes';
import { timeConversion } from './components/common/utils';
import { AuthContext } from './Providers/AuthProvider';

const Notifications = (props) => {
  const { signOut } = React.useContext(AuthContext);
  const { user, getDeviceInfo, onSetDeviceInfo } = props;

  const isAuthenticated = !!user;
  const [open, setOpen] = useState(false);
  const [msgData, setMsgData] = useState(null);
  const [deviceInformation, setDeviceInformation] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [commonError, setCommonError] = useState(false);
  const [msgBody, setMsgBody] = useState(null);
  const [description, setDescription] = useState(null);

  const commonErrorBtnHandler = () => setCommonError(!commonError);

  const commonErrorDialogConfig = {
    open: commonError,
    textAlign: 'left',
    btnClicked: commonErrorBtnHandler,
  };

  const initializeReactGA = () => ReactGA.initialize('UA-156099705-1');

  initializeReactGA();

  const handleClose = () => {
    const notificationElem = document.querySelector('.NotificationBackup');
    if (notificationElem) {
      setOpen(false);
    }
  };

  const openLiveStreaming = () => {
    handleClose();
    const params = {
      action: 'get_device_details',
      data: {
        user_email: user.userEmail,
        device_id: deviceInformation['device-id'],
      },
    };
    getDeviceInfo(params)
      .then((resp) => {
        const respData = resp.data.ResponseData;
        if (resp.data.ErrorCode === 'err_0') {
          let devThumnailPath = null;
          if (typeof respData.deviceThumbnail === 'string') {
            devThumnailPath = respData.deviceThumbnail;
          } else if (
            typeof respData.deviceThumbnail === 'object' &&
            respData.deviceThumbnail.length
          ) {
            devThumnailPath = respData.deviceThumbnail[0];
          }

          const deviceData = {
            DeviceType: respData.deviceType,
            DisplayName: respData.deviceName,
            DeviceId: respData.deviceId,
            DeviceThumbnail: devThumnailPath,
          };
          if (deviceData.DeviceType !== 'SmartReadinessUnit') {
            getDevicePlanInfo({
              email: user.userEmail,
              device_ids: [deviceData.DeviceId],
            });
          }
          onSetDeviceInfo(deviceData);
          props.history.push(DEVICE);
        } else {
          setErrorMsg(ErrorCodes[resp.data.ErrorCode]);
          setCommonError(!commonError);
        }
      })
      .catch(() => {
        setErrorMsg(`${msgConstant.COMMON_ERROR}`);
        setCommonError(!commonError);
      });
  };

  // [#84946] App gets hung if we perform clicks and actions while a notification is being received.
  const pushNotificationHandler = () => {
    setTimeout(() => {
      messaging.onMessage((payload) => {
        // console.log('Msg received in App.js', payload);
        if (payload.data.body.indexOf('$$%timestamp%$$') !== -1) {
          setMsgBody(
            payload.data.body.replace('$$%timestamp%$$', timeConversion(payload.data.timestamp))
          );
        } else {
          setMsgBody(payload.data.body);
        }
        if (payload.data.description.indexOf('$$%timestamp%$$') !== -1) {
          setDescription(
            payload.data.description.replace(
              '$$%timestamp%$$',
              timeConversion(payload.data.timestamp)
            )
          );
        } else {
          setDescription(payload.data.description);
        }
        if (payload.data['device-data']) {
          setDeviceInformation(JSON.parse(payload.data['device-data']));
        }
        setMsgData(payload.data);
        setOpen(true);
      });
    }, 1000);
  };

  React.useEffect(() => {
    if (messaging && isAuthenticated) {
      const notificationElem = document.querySelector('.NotificationBackup');
      if (notificationElem) {
        setOpen(false);
      }
      pushNotificationHandler();
    }
  }, [messaging, isAuthenticated]);

  const openNotification = () => {
    handleClose();
    switch (msgData.category) {
      case 'CAMERA_ACTIVITY':
        openLiveStreaming();
        break;
      case 'WEATHER_ALERT':
      case 'FIRMWARE_UPDATE':
      case 'PANIC_ALERT':
        {
          console.log('msgData', msgData);
          console.log(msgData['status-code']);
          console.log('Device ID', deviceInformation['device-id']);
          // if (msgData.Category === 'PANIC_ALERT') {
          //   console.log('I am in here');
          PubSub.subscribe([
            `$aws/things/${deviceInformation['device-id']}/shadow/update/accepted`,
            `$aws/things/${deviceInformation['device-id']}/shadow/update/rejected`,
          ]).subscribe({
            next: (data) => console.log(data),
            error: (err) => console.log(err),
            close: () => console.log('Done'),
          });
          const data = {
            title: msgData.title,
            description,
            timestamp: msgData.timestamp,
            category: msgData.category,
            statusCode: msgData['status-code'],
            deviceId: deviceInformation['device-id'],
            firmwareVersion: deviceInformation['firmware-version'],
            body: msgBody,
            // email: deviceInformation['user-email'],
            deviceType: deviceInformation['device-type'],
          };
          props.history.push({
            pathname: NOTIFICATION_DETAILS,
            state: { selected: data },
          });
        }
        break;
      default:
        return null;
    }
  };

  const config = {
    position: 'bottomRight',
    open,
    onClose: handleClose,
    type: 'error',
    closeBtn: true,
    // callFunction: openNotification
  };

  const renderAction = (param) => {
    // console.log('Statuscode', msgData['status-code']);
    switch (param) {
      case 'CAMERA_ACTIVITY':
        return <span className="notificationLink">View &gt;</span>;
      case 'WEATHER_ALERT':
        return <span className="notificationLink">Details &gt;</span>;
      case 'PANIC_ALERT':
        if (msgData['status-code'] !== '0') {
          return <span className="notificationLink">Details &gt;</span>;
        }
        break;
      case 'FIRMWARE_UPDATE':
        if (msgData['status-code'] !== '1') {
          return <span className="notificationLink">Details &gt;</span>;
        }
        break;
      default:
        return null;
    }
  };

  return (
    <>
      {
        /* BUG-[#84379] Devices are not showing in subscription
        page if user leaves the site idle for sometime */
        user.userEmail && <IdleTimeWrap user={user} signOut={signOut} />
      }
      {open && msgData && isAuthenticated && (
        <NotificationMsg config={config}>
          <span style={{ paddingRight: '10px' }}>{`${msgData.title}:`}</span>
          <span>{`${msgBody} `}</span>
          <span onClick={openNotification} role="presentation">
            {renderAction(msgData.category)}
          </span>
        </NotificationMsg>
      )}
      {commonError && isAuthenticated && (
        <CustomDialog dialogConfig={commonErrorDialogConfig}>
          <div>{errorMsg}</div>
        </CustomDialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  deviceList: state.deviceReducer.deviceResp,
  isLoader: state.deviceReducer.isLoader,
  isTokenSent: state.homePageReducer.isTokenSent,
  deviceToken: state.homePageReducer.deviceToken,
});

const mapDispatchToProps = (dispatch) => ({
  onSelectedHome: (data) => dispatch(selectedHome(data)),
  onSetDeviceToken: (data) => dispatch(homePageAC.setDeviceToken(data)),
  onAddOrUpdateDeviceToken: (data) => homePageAC.addOrUpdateDeviceToken(data),
  onTokenSentStatus: (data) => dispatch(homePageAC.setTokenStatus(data)),
  getDevicePlanInfo: (data) => dispatch(getDevicePlanInfo(data)),
  onSetDeviceInfo: (data) => dispatch(setDeviceInfo(data)),
  getDeviceInfo: (data) => homePageAC.getDeviceInfo(data),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
