import React from 'react';

const TermsOfService = () => {
  return (
    <iframe
      frameBorder="0"
      style={{ width: '100%', height: '100vh' }}
      src="https://docs.google.com/document/d/e/2PACX-1vR7mmxOVl0TNYDkCcgJJlHzy9IsDNnwAWTLEahYfa12oRb5nwjtD9npJoWiE2p4_bJubzuWGGhUWzN0/pub?embedded=true"
    ></iframe>
  );
};
export default TermsOfService;
