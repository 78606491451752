import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Axios from 'axios';
import ButtonElem from '../../ui/Button/Button';
import classes from '../ManageHomes.module.css';
import { HOME, MANAGE_HOMES } from '../../../constants/routes';
import LoadingSpinner from '../../ui/LoadingSpinner/LoadingSpinner';
import CustomDialog from '../../ui/CustomDialog/CustomDialog';
import Notification from '../../ui/Notification/Notification';
// import ErrorCodes from '../../common/ErrorCodes';
import msgConstant from '../../common/textConstants';
import { getDevices } from '../../DeviceList/store/action-creator';
import TimeZone from '../TimeZone/TimeZone';
import { AuthContext } from '../../../Providers/AuthProvider';

const AddHomeForm = (props) => {
  const { Location, history, initial } = props;
  const { user } = React.useContext(AuthContext);
  const email = user.userEmail;
  const IdentityId = user.IdentityId;
  const [position, setPosition] = useState({ lat: '', lng: '' });
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');

  const [homeName, setHomeName] = useState('');
  const [errorModal, setErrorModal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [touched, setTouched] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isEmptyHomeName, setIsEmptyHomeName] = useState(false);
  const [commonError, setCommonError] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState(null);
  const [zoneName, setZoneName] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const updateMapForm = (Locate = {}) => {
    setAddress(Locate?.home_address?.split(',')[0]);
    setCity(Locate.city);
    setState(Locate.state);
    setZipCode(Locate.zipCode);
    setPosition({
      lat: Locate.latitude,
      lng: Locate.longitude,
    });
  };

  const toggleSpinner = (data) => setSpinner(data);

  useEffect(() => {
    updateMapForm(Location);
  }, [Location]);

  const onSaveHandler = () => {
    toggleSpinner(true);
    const add = Location.home_address.split(',', 1);
    const fullAddress = `${add}&?${Location.city}&?${Location.state}&?${Location.zipCode}`;
    const params = {
      action: 'addhome',
      data: {
        email,
        home_name: homeName.trim(),
        latitude: position.lat.toString(),
        longitude: position.lng.toString(),
        home_address: fullAddress,
        region_pair: zoneName,
      },
    };

    Axios.post('users', params)
      .then((resp) => {
        console.log(resp);
        toggleSpinner(false);
        const respCode = resp.data.ResponseCode;
        const errCode = resp.data.ErrorCode;
        if (respCode !== 200 || (respCode === 200 && errCode !== 'err_0')) {
          setErrorModal(true);
          setErrorMessage(msgConstant.CREATE_HOME_ERROR);
        } else {
          setShowNotification(true);
          setTimeout(() => {
            props.onGetDevices(email, IdentityId, null, true).then(
              (data) => {
                if (data.errorMsg) {
                  setCommonError(true);
                  setCommonErrorMsg(data.errorMsg);
                } else {
                  history.push(initial ? HOME : MANAGE_HOMES);
                }
              },
              (err) => {
                setCommonError(true);
                setCommonErrorMsg(err);
              }
            );
          }, 800);
        }
      })
      .catch(() => {
        toggleSpinner(false);
        setErrorModal(true);
        setErrorMessage(`${msgConstant.COMMON_ERROR}`);
      });
  };

  const errorBtnHandler = () => setErrorModal(!errorModal);

  const errorDialogConfig = {
    open: errorModal,
    textAlign: 'left',
    btnClicked: errorBtnHandler,
  };

  const onHomeChange = (evt) => {
    if (evt.target.value.replace(/\s/g, '').length < 0) {
      setIsEmptyHomeName(true);
    } else {
      setIsEmptyHomeName(false);
      setTouched(true);
      setHomeName(evt.target.value);
    }
  };

  const onleadingSpace = (e) => {
    if (e.keyCode === 32 && !homeName.replace(/\s/g, '').length) {
      e.preventDefault();
    }
  };

  const onAddressChange = (evt) => setAddress(evt.target.value);
  const onCityChange = (evt) => setCity(evt.target.value);
  const onStateChange = (evt) => setState(evt.target.value);
  const onZipCodeChange = (evt) => setZipCode(evt.target.value);

  const onCancelHandler = () => history.push(initial ? HOME : MANAGE_HOMES);

  let ErrorDisplay;

  if (homeName === '') {
    ErrorDisplay = msgConstant.NICKNAME_EMPTY;
  } else if (homeName.length > 256) {
    ErrorDisplay = msgConstant.NICKNAME_MAX_CHAR;
  } else if (homeName.length < 4) {
    ErrorDisplay = msgConstant.NICKNAME_MIN_CHAR;
  }

  const commonErrorDialogConfig = {
    open: commonError,
    textAlign: 'left',
    btnClicked: () => setCommonError(!commonError),
  };

  const getUpdatedZone = (name) => setZoneName(name);

  const notificationConfig = {
    position: 'bottomRight',
    open: showNotification,
    onClose: () => setShowNotification(false),
  };

  return (
    <>
      <LoadingSpinner spinnerConfig={{ showSpinner: spinner }} />
      <Grid container direction="column">
        <Grid item xs={10}>
          <div style={{ marginBottom: '30px', paddingLeft: '10px' }}>
            <h4 className={classes.nickName}> Set a nickname for this location</h4>
            <div style={{ width: '95%' }}>
              <Input
                name="home"
                className="CustomInput ManageHomeFormInput"
                onChange={(evt) => onHomeChange(evt)}
                onKeyDown={(e) => onleadingSpace(e)}
                placeholder="Home Name"
                value={homeName || ''}
                fullWidth
              />
              <p
                style={{
                  visibility:
                    (homeName === '' || homeName.length > 20 || homeName.length < 4) && touched
                      ? 'visible'
                      : 'hidden',
                  color: '#B52A2A',
                  fontSize: '14px',
                }}
              >
                {ErrorDisplay}
              </p>
            </div>
          </div>
        </Grid>
        <Grid item xs={10}>
          <h4 className={classes.differentLocation}> Need a different location?</h4>
          <div className={classes.form} style={{ width: '95%', paddingLeft: '10px' }}>
            <Input
              name="address"
              className="CustomInput ManageHomeFormInput"
              onChange={(evt) => onAddressChange(evt)}
              value={address || ''}
              placeholder="Address"
              fullWidth
            />
            <Input
              name="city"
              className="CustomInput ManageHomeFormInput"
              onChange={(evt) => onCityChange(evt)}
              value={city || ''}
              placeholder="City"
              fullWidth
            />
            <Input
              name="state"
              className="CustomInput ManageHomeFormInput"
              onChange={(evt) => onStateChange(evt)}
              value={state || ''}
              placeholder="State"
              fullWidth
            />
            <Input
              name="zipCode"
              className="CustomInput ManageHomeFormInput"
              onChange={(evt) => onZipCodeChange(evt)}
              value={zipCode || ''}
              placeholder="ZIP Code"
              fullWidth
            />
            <TimeZone coordinate={position} updatedZone={getUpdatedZone} />
          </div>
          <div className={classes.buttonSet}>
            <ButtonElem
              btnColor="YellowButton"
              value="Save"
              btnType="submit"
              style={{ width: 130, marginRight: '10px' }}
              clicked={onSaveHandler}
              isbtndisabled={
                isEmptyHomeName || homeName === '' || homeName.length > 20 || homeName.length < 4
                  ? true
                  : undefined
              }
            />
            <ButtonElem
              btnColor="GreyButton"
              value="Cancel"
              btnType="submit"
              style={{ width: 130 }}
              clicked={onCancelHandler}
            />
          </div>
        </Grid>
        <br />
        <br />
      </Grid>
      {errorModal && <CustomDialog dialogConfig={errorDialogConfig}>{errorMessage}</CustomDialog>}
      {commonError && (
        <CustomDialog dialogConfig={commonErrorDialogConfig}>
          <div>{commonErrorMsg}</div>
        </CustomDialog>
      )}
      {showNotification && (
        <Notification config={notificationConfig}>{msgConstant.CREATE_HOME_SUCCESS}</Notification>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  homeSelected: state.homeListReducer.selectedHome,
});

const mapDispatchToProps = (dispatch) => ({
  onGetDevices: (email, IdentityId, id, isFromAddHome) =>
    dispatch(getDevices(email, IdentityId, id, isFromAddHome)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddHomeForm));
