import React, { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

const Notification = props => {
  const { config, children } = props;
  const autoHide = config.autoHide || 3000;
  const type = config.type || 'success'; // other type is - error

  const getPosition = notificationPosition => {
    let pos = null;
    switch (notificationPosition) {
      case 'topLeft':
        pos = { vertical: 'top', horizontal: 'left' }; break;
      case 'topCenter':
        pos = { vertical: 'top', horizontal: 'center' }; break;
      case 'topRight':
        pos = { vertical: 'top', horizontal: 'right' }; break;
      case 'bottomLeft':
        pos = { vertical: 'bottom', horizontal: 'Left' }; break;
      case 'bottomCenter':
        pos = { vertical: 'bottom', horizontal: 'center' }; break;
      case 'bottomRight':
        pos = { vertical: 'bottom', horizontal: 'right' }; break;
      default: break;
    }
    return pos;
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return false;
    }
    config.onClose();
  };

  const position = getPosition(config.position || 'bottomCenter');

  const updateColor = () => {
    const elem = document.querySelector('.Notification .MuiSnackbarContent-root');
    if (elem) {
      elem.classList.add('Notification-error');
    }
  };

  const closeBtn = type === 'error' ? null : <span className="NotificationClose" onClick={config.onClose} role="presentation">x</span>;

  useEffect(() => {
    if (type === 'error') {
      updateColor();
    }
  });

  return (
    <>
      <Snackbar
        className="Notification"
        open={config.open}
        autoHideDuration={autoHide}
        TransitionComponent={Slide}
        onClose={handleClose}
        anchorOrigin={position}
        message={<span>{children}</span>}
        action={closeBtn}
      />
    </>
  );
};

export default Notification;
