export const GET_DEVICES = 'GET_DEVICES';
export const SET_DEVICES = 'SET_DEVICES';
export const SET_DEVICE_INFO = 'SET_DEVICE_INFO';
export const GET_VIDEO_PATH = 'GET_VIDEO_PATH';
export const SET_GROUP = 'SET_GROUP';
export const SET_MQTT_INFO = 'SET_MQTT_INFO';
export const RESET_MQTT_INFO = 'RESET_MQTT_INFO';
export const GET_CONNECTION_STATUS = 'GET_CONNECTION_STATUS';
export const DISPLAY_ACTIVITY_ZONE = 'DISPLAY_ACTIVITY_ZONE';
export const UPDATE_MQTT = 'UPDATE_MQTT';
export const SET_ALERT_TYPE = 'SET_ALERT_TYPE';
export const FM_RADIO_STATUS = 'FM_RADIO_STATUS';
export const SET_LOADER = 'SET_PAGE_LOADER';
export const SET_ALERT_LOCATION = 'SET_ALERT_LOCATION';
export const RECORD_LIVE_VIEDO = 'RECORD_LIVE_VIEDO';
export const GET_DEVICE_PLAN_INFO = 'GET_DEVICE_PLAN_INFO';
export const ACTIVITY_ZONE_SAVE = 'ACTIVITY_ZONE_SAVE';
export const SET_API_ERROR = 'SET_API_ERROR';
export const SET_MQTT_UPDATED = 'SET_MQTT_UPDATED';
export const SELECTED_CAMERA = 'SELECTED_CAMERA';
