import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import * as iconElem from '../../../../../ui/Icons/Icons';
import classes from '../../DeviceSettings.module.css';

const FMChannel = (props) => {
  const { FMChannelNo, clicked } = props;
  const [minusBtn, setMinusBtn] = useState(null);
  const [plusBtn, setPlusBtn] = useState(null);
  const [oldInpValue, setInpValue] = useState('');
  const [channelTuned, setChannelTuned] = useState(FMChannelNo);

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    handleFMVolumeBtns(FMChannelNo);
  }, []);

  useEffect(() => {
    setChannelTuned(FMChannelNo);
  }, [FMChannelNo]);

  const updateFMChannel = (type) => {
    const fmInput = document.querySelector('#fm_channel');
    let currentChannel = +fmInput.value;
    if (type === 'minus') {
      currentChannel -= 0.1;
    } else if (type === 'plus') {
      currentChannel += 0.1;
    }
    let updatedChannel = currentChannel.toFixed(1);
    if (updatedChannel <= 88) {
      updatedChannel = '88.0';
    } else if (updatedChannel >= 108) {
      updatedChannel = 108;
    }
    fmInput.value = updatedChannel;
    clicked({ fmChannel: updatedChannel });
    // eslint-disable-next-line no-use-before-define
    handleFMVolumeBtns(updatedChannel);
  };

  const getIcon = (val) => {
    const iconType = val === 'plus' ? iconElem.plusIcon : iconElem.minusIcon;
    return (
      <img
        src={iconType}
        alt={val}
        className={classes.iconWidth}
        onClick={() => updateFMChannel(val)}
        role="presentation"
      />
    );
  };

  const handleFMVolumeBtns = (val) => {
    setMinusBtn(getIcon('minus'));
    setPlusBtn(getIcon('plus'));

    if (val <= 88) {
      setMinusBtn(<img src={iconElem.minusIcon} alt="Minus" className={classes.disabledBtn} />);
    } else if (val >= 108) {
      setPlusBtn(<img src={iconElem.plusIcon} alt="Plus" className={classes.disabledBtn} />);
    }
  };

  const validNumber = new RegExp(/^\d*\.?\d*$/);
  const validateNumber = (evt) => {
    const lastValid = document.getElementById('fm_channel');
    const currentValue = evt.target.value;
    if (currentValue === '.') {
      lastValid.value = '';
    } else if (validNumber.test(currentValue)) {
      lastValid.value = currentValue;
      setInpValue(currentValue);
    } else {
      lastValid.value = oldInpValue;
    }
  };

  return (
    <>
      <div align="center" style={{ marginTop: '1.5em' }}>
        <img src={iconElem.fmSignalIcon} alt="FM Signal" className={classes.iconWidth} />
      </div>
      <Grid container justify="space-between" style={{ padding: '0 20px' }}>
        <Grid item className="PadTop5">
          {minusBtn}
        </Grid>
        <Grid item>
          <input
            id="fm_channel"
            type="text"
            maxLength="5"
            className={classes.fm_channel}
            readOnly
            value={channelTuned}
            onBlur={() => updateFMChannel('edit')}
            autoComplete="off"
            onInput={(event) => validateNumber(event)}
          />
          <span className="Font12" style={{ marginLeft: '2px' }}>
            FM
          </span>
        </Grid>
        <Grid item className="PadTop5">
          {plusBtn}
        </Grid>
      </Grid>
    </>
  );
};

export default FMChannel;
