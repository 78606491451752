import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { ChevronRight } from 'react-bootstrap-icons';
import { currentTimeZoneConversion, objectHasKey } from '../../common/utils';
import CustomDialog from '../../ui/CustomDialog/CustomDialog';
import LoadingSpinner from '../../ui/LoadingSpinner/LoadingSpinner';
import TimezoneList from './TimezoneList';
import classes from './Timezone.module.css';

const TimeZone = (props) => {
  const { coordinate, updatedZone } = props;
  const [zoneName, setZoneName] = useState(null);
  const [modal, setModal] = useState(false);
  const [tmpZone, setTmpZone] = useState(null);

  const homeSelected = useSelector((state) => state.homeListReducer.selectedHome);

  const updateZoneName = (lat, lng) => {
    setTmpZone(null);
    const timestampVal = currentTimeZoneConversion();
    const parms = `location=${lat},${lng}&timestamp=${timestampVal}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
    const url = `https://maps.googleapis.com/maps/api/timezone/json?${parms}`;
    fetch(url)
      .then((resp) => resp.json())
      .then((data) => {
        if (data.status === 'OK' && objectHasKey(data, 'timeZoneId')) {
          setZoneName(data.timeZoneId);
          updatedZone(data.timeZoneId);
        } else {
          setZoneName('America/Chicago');
          updatedZone('America/Chicago');
        }
      })
      .catch(() => {
        setZoneName('America/Chicago');
        updatedZone('America/Chicago');
      });
  };

  useEffect(() => {
    setZoneName(null);
    if (
      homeSelected &&
      objectHasKey(homeSelected, 'region_pair') &&
      homeSelected.region_pair !== ''
    ) {
      setZoneName(homeSelected.region_pair);
      updatedZone(homeSelected.region_pair);
    } else if (coordinate.lat !== '' && coordinate.lng !== '') {
      setTimeout(() => {
        updateZoneName(coordinate.lat, coordinate.lng);
      }, 300);
    }
  }, [coordinate]);

  const btnHandler = (hasAction) => {
    if (hasAction) {
      if (tmpZone) {
        setZoneName(tmpZone);
        updatedZone(tmpZone);
      }
    }
    setModal(!modal);
  };

  const dialogConfig = {
    textAlign: 'left',
    btnAlign: 'left',
    btnText: 'Save',
    btnColor: 'YellowButton',
    open: modal,
    btnClicked: btnHandler,
    type: 'action',
    cancelBtnWidth: '127px',
    doneBtnWidth: '127px',
    disabled: true,
  };

  const latestSelectedZone = (name) => setTmpZone(name);

  const spinnerConfig = {
    showSpinner: true,
    color: '#0970C8',
    left: '-2em',
    top: '0',
  };

  return (
    <>
      <Grid container justify="space-between" alignItems="center" className={classes.timezoneBlock}>
        <Grid item className={classes.timezoneLabel}>
          Timezone
        </Grid>
        <Grid item className={classes.selectedTimezone}>
          {zoneName ? (
            <span onClick={() => setModal(!modal)} role="presentation">
              {zoneName}
              <ChevronRight className={classes.forwardArraowIcon} />
            </span>
          ) : (
            <div style={{ marginTop: 25 }}>
              <LoadingSpinner spinnerConfig={spinnerConfig} size={20} />
            </div>
          )}
        </Grid>
      </Grid>
      {modal && (
        <CustomDialog dialogConfig={dialogConfig}>
          <TimezoneList zoneName={zoneName} latestSelectedZone={latestSelectedZone} />
        </CustomDialog>
      )}
    </>
  );
};

export default TimeZone;
