import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { downloadVideo } from '../../Activity/store/action-creator';
import { unixToMilisecond } from '../../common/utils';
import msgConstant from '../../common/textConstants';
import CustomDialog from '../CustomDialog/CustomDialog';
import Notification from '../Notification/Notification';
import SubscriptionTooltip from './SubscriptionTooltip';
import { AuthContext } from '../../../Providers/AuthProvider';

const VideoDownload = (props) => {
  const { user } = React.useContext(AuthContext);
  const IdentityId = user.IdentityId;
  const { config } = props;
  const [downloadBtn, setDownloadBtn] = useState(null);
  const [commonError, setCommonError] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState(null);
  const [open, setOpen] = useState(false);

  const selectedEvent = useSelector((state) => state.activityReducer.selectedEvent);

  const onDownloadVideo = (params) => {
    downloadVideo(params).then(
      (data) => {
        if (data.errorMsg) {
          setCommonError(true);
          setCommonErrorMsg(data.errorMsg);
        }
      },
      (err) => {
        setCommonError(true);
        setCommonErrorMsg(err);
      }
    );
  };

  const downloadBtnHandler = () => {
    if (selectedEvent) {
      setOpen(true);
      const notificationContainer = document.querySelector('#notification_container');
      if (notificationContainer) {
        notificationContainer.style.display = 'block';
      }
      const startTime = unixToMilisecond(selectedEvent.Timestamp);
      const endTime = unixToMilisecond(selectedEvent.Timestamp + selectedEvent.DurationSeconds);
      const streamName = selectedEvent.DeviceID;
      const params = {
        startTime,
        endTime,
        streamName,
        cognitoIdentityId: IdentityId,
        activityId: selectedEvent.ActivityID,
        activityType: selectedEvent.ActivityType,
      };
      onDownloadVideo(params);
    }
  };

  useEffect(() => {
    if (config) {
      const btn = (
        <div id={config.btnId} className="customVJSIcon vjs-control">
          <SubscriptionTooltip clicked={downloadBtnHandler} btnType={config.btnType} />
        </div>
      );
      setDownloadBtn(btn);
    }
  }, []);

  const errorDialogConfig = {
    open: commonError,
    textAlign: 'left',
    btnClicked: () => setCommonError(false),
  };

  const notificationConfig = {
    open,
    position: 'bottomRight',
    autoHide: 5000,
    onClose: () => setOpen(false),
  };

  return (
    <>
      {downloadBtn}
      {commonError && (
        <CustomDialog dialogConfig={errorDialogConfig}>
          <div>{commonErrorMsg}</div>
        </CustomDialog>
      )}
      <div id="notification_container" style={{ display: 'none' }}>
        <Notification config={notificationConfig}>{msgConstant.VIDEO_DOWNLOAD}</Notification>
      </div>
    </>
  );
};

export default VideoDownload;
