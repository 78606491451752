// REQUIREMENT : 4.1.9 Event History / Stored Video Lists

import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector, useDispatch } from 'react-redux';
import pick from 'lodash/pick';
import * as actionCreator from '../store/action-creator';
import ScrollArea from '../../ui/ScrollBar/ScrollArea';
import * as iconElem from '../../ui/Icons/Icons';
import ButtonElem from '../../ui/Button/Button';
import Notification from '../../ui/Notification/Notification';
import { validateDate } from '../../common/utils';
import msgConstant from '../../common/textConstants';
import FilterDate from '../FilterSetting/FilterDate';
import filterArray from '../FilterSetting/FilterList';
import FilterToggle from '../FilterSetting/FilterToggle';
import classes from '../Activity.module.css';

const ActivityFilter = () => {
  const [startDt, setStartDt] = useState(false);
  const [endDt, setEndDt] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [open, setOpen] = useState(false);
  const [typeCheck, setTypeCheck] = useState(false);

  const dispatch = useDispatch();
  const filterList = useSelector((state) => state.activityReducer.filter);

  const filterToggle = (type, status) => {
    let filterStatus = null;
    switch (type) {
      case 'motion':
        filterStatus = status ? 0 : 1;
        break;
      case 'person':
        filterStatus = status ? 0 : 1;
        break;
      case 'sound':
        filterStatus = status ? 0 : 1;
        break;
      case 'recording':
        filterStatus = status ? 0 : 1;
        break;
      case 'isFavourite':
        filterStatus = status ? 0 : 1;
        break;
      case 'deletionSoon':
        filterStatus = status ? 0 : 1;
        break;
      default:
        break;
    }
    const copyFilterList = { ...filterList };
    copyFilterList[type] = filterStatus;
    const activityTypes = pick(copyFilterList, ['motion', 'person', 'sound', 'recording']);
    const check = Object.values(activityTypes).some((val) => val === 1);

    if (check) {
      dispatch(actionCreator.updateFilter(filterList, type, filterStatus));
    } else {
      setTypeCheck(true);
    }
  };

  const nestedFilterList = (list) =>
    list.map((item) => (
      <FilterToggle
        key={item.id}
        filterItem={item}
        changed={filterToggle}
        filterList={filterList}
      />
    ));

  let filterComp = null;
  let hasFilterStartDate = null;
  let hasFilterEndDate = null;

  if (filterList) {
    filterComp = filterArray.map((elem) => (
      <div key={elem.name}>
        <div className={classes.filterHeader}>{elem.name}</div>
        <div style={{ padding: '13px 0' }}>{nestedFilterList(elem.list)}</div>
      </div>
    ));

    if (filterList.date) {
      hasFilterStartDate = filterList.date.start;
      hasFilterEndDate = filterList.date.end;
    }
  }

  const startDateHandler = () => setStartDt(true);

  const endDateHandler = () => setEndDt(true);

  const selectedStartDate = (dt) => setStartDate(dt);

  const selectedEndDate = (dt) => setEndDate(dt);

  const dateFormmat = (val) => {
    const dt = val.getDate();
    const mon = val.getMonth();
    const yr = val.getFullYear();

    return [yr, mon + 1, dt];
  };

  const getActiveEventTypes = () => {
    const eventType = ['person', 'motion', 'sound', 'recording'];
    const arr = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const key in filterList) {
      if (eventType.indexOf(key) !== -1 && filterList[key]) {
        arr.push(key);
      }
    }
    return arr;
  };

  const resetAllEventList = (type, val) => {
    let filterObj = null;

    if (type === 'apply') {
      filterObj = {
        data: {},
      };

      if (val) {
        filterObj.data.start_date = dateFormmat(val.start);
        filterObj.data.end_date = dateFormmat(val.end);
      } else if (filterList.date) {
        filterObj.data.start_date = dateFormmat(filterList.date.start);
        filterObj.data.end_date = dateFormmat(filterList.date.end);
      }

      const eventTypes = getActiveEventTypes();
      if (eventTypes.length) {
        filterObj.data.event_type = eventTypes;
      }
      if (filterList.isFavourite) {
        filterObj.data.marked_as_favourites = 1;
      }
      if (filterList.deletionSoon) {
        filterObj.data.to_be_deleted_soon = 1;
      }
    } else {
      dispatch(actionCreator.resetFilter());
    }

    dispatch(actionCreator.setFilterData(filterObj));
    dispatch(actionCreator.setActivityUpdated(true));
    setTimeout(() => {
      dispatch(actionCreator.setFilterIconActive(false));
    });
  };

  // [#84701] After filtering the device the left side Video is still playing old Events.
  const ApplyBtnHandler = () => {
    if (startDate && endDate) {
      const todayDate = new Date();
      let invalidDates = false;
      const sDate = startDate.fullFormat;
      const eDate = endDate.fullFormat;

      if (validateDate(startDate.stringFormat) === 0) {
        invalidDates = true;
      } else if (validateDate(endDate.stringFormat) === 0) {
        invalidDates = true;
      } else if (eDate > todayDate) {
        invalidDates = true;
      } else if (eDate < sDate) {
        invalidDates = true;
      }

      if (invalidDates) {
        setOpen(true);
        return false;
      }
      const dateObj = {
        start: sDate,
        end: eDate,
      };
      dispatch(actionCreator.updateFilter(filterList, 'date', dateObj));
      resetAllEventList('apply', dateObj);
    } else {
      resetAllEventList('apply', null);
    }
  };

  const resetFilterHandler = () => resetAllEventList('reset', null);

  const clearDates = () => {
    setStartDt(false);
    setEndDt(false);
    setStartDate(null);
    setEndDate(null);
    dispatch(actionCreator.resetDateFilter(filterList));
  };

  const notificationConfig = {
    open,
    type: 'error',
    onClose: () => setOpen(false),
  };

  const typeConfig = {
    open: typeCheck,
    position: 'bottomRight',
    onClose: () => setTypeCheck(false),
  };

  return (
    <>
      <ScrollArea scrollClass="scrollContainer">
        <div className={classes.filterHeader}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item style={{ fontSize: '11.9px', fontFamily: 'boldFont' }}>
              Search by date
            </Grid>
            <Grid item className={classes.clearDate} onClick={clearDates}>
              Clear Dates&nbsp;
              <img src={iconElem.forwardBlueIcon} alt="angle right" />
            </Grid>
          </Grid>
        </div>
        <div style={{ padding: '10px 0' }} align="center">
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.filterItem}
            style={{ height: 36 }}
          >
            <Grid item>Start Date</Grid>
            <Grid item>
              {startDt ? (
                <FilterDate dateChanged={selectedStartDate} hasFilterDate={hasFilterStartDate} />
              ) : (
                <span
                  className={['CursorPointer', classes.setDate].join(' ')}
                  onClick={startDateHandler}
                  role="presentation"
                >
                  Set&nbsp;
                  <img src={iconElem.forwardBlackSmIcon} alt="angle right" />
                </span>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.filterItem}
            style={{ height: 36 }}
          >
            <Grid item>End Date</Grid>
            <Grid item>
              {endDt ? (
                <FilterDate dateChanged={selectedEndDate} hasFilterDate={hasFilterEndDate} />
              ) : (
                <span
                  className={['CursorPointer', classes.setDate].join(' ')}
                  onClick={endDateHandler}
                  role="presentation"
                >
                  Set&nbsp;
                  <img src={iconElem.forwardBlackSmIcon} alt="angle right" />
                </span>
              )}
            </Grid>
          </Grid>
        </div>
        {filterComp}
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.filterItem}
          style={{ marginTop: '1.5em' }}
        >
          <Grid item>
            <span className={classes.resetFilter} role="presentation" onClick={resetFilterHandler}>
              Reset all filters&nbsp;
              <img src={iconElem.forwardBlueIcon} alt="angle right" />
            </span>
          </Grid>
          <Grid item>
            <ButtonElem
              btnColor="YellowButton"
              value="Apply"
              clicked={ApplyBtnHandler}
              style={{ width: 172, height: 28, marginTop: '1em' }}
            />
          </Grid>
        </Grid>
        {typeCheck && (
          <Notification config={typeConfig}>{msgConstant.SINGLE_TYPE_FILTER}</Notification>
        )}
        {open && <Notification config={notificationConfig}>Invalid Date</Notification>}
      </ScrollArea>
    </>
  );
};

export default ActivityFilter;
