import React from 'react';
import { Storage } from 'aws-amplify';
import ReactCrop from 'react-image-crop';
import 'cropperjs/dist/cropper.min.css';
import { connect } from 'react-redux';
import { setCropBoxData } from './store/action-creator';
import 'react-image-crop/dist/ReactCrop.css';
import hTImg from '../../assets/device/home_travel_camera.png';
import overDoorImg from '../../assets/device/over_door_camera.png';
import classes from './Cropper.module.css';

class CropperEle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imagePath: null,
      deviceImage: null,
      crop: {
        x: 100,
        Y: 100,
        width: 150,
        height: 150
      },
      height: null,
      width: null
    };
    this.fetchImage = this.fetchImage.bind(this);
  }

  componentDidMount() {
    const { deviceType, MQTTData } = this.props;
    this.fetchImage();
    const height = this.divElement.clientHeight;
    const width = this.divElement.clientWidth;
    this.setState({ height, width });
    const MQTTActZone = MQTTData.desired.activityZone;
    if (MQTTActZone) {
      const cropInformation = {
        x: parseInt((width / 1920) * MQTTActZone[0][0]),
        y: parseInt((height / 1080) * MQTTActZone[1][0]),
        width: parseInt((width / 1920) * (MQTTActZone[0][1] - MQTTActZone[0][0])),
        height: parseInt((height / 1080) * (MQTTActZone[1][1] - MQTTActZone[1][0]))
      };
      this.setState({ crop: cropInformation });
    }
    if (deviceType === 'HomeAndTravelCamera') {
      this.setState({ deviceImage: hTImg });
    } else if (deviceType === 'OverDoorCamera') {
      this.setState({ deviceImage: overDoorImg });
    }
  }

  componentDidUpdate(prevProps) {
    const { refreshCam } = this.props;
    if (refreshCam && prevProps.refreshCam !== refreshCam) {
      this.fetchImage();
    }
  }

  componentWillUnmount() {
    const { dispActivityZone, zoneDisplayHandler } = this.props;
    if (dispActivityZone) {
      zoneDisplayHandler();
    }
  }

  onCropComplete = cropData => {
    const defaultCrop = {
      x: 100,
      Y: 100,
      width: 150,
      height: 150
    };
    const { width, height } = this.state;
    if (cropData.width === 0 || cropData.height === 0) {
      this.setState({ crop: defaultCrop });
    } else {
      const { onSetCropInfo } = this.props;
      const { crop } = this.state;
      // const zoneArray = [];
      this.setState({ crop: cropData });
      window.localStorage.setItem('CropBoxData', JSON.stringify(cropData));
      const calculatedData = {
        x: parseInt((1920 / width) * crop.x),
        y: parseInt((1080 / height) * crop.y),
        width: parseInt((1920 / width) * crop.width),
        height: parseInt((1080 / height) * crop.height)
      };
      const widthData = [calculatedData.x, calculatedData.x + calculatedData.width];
      const heightData = [calculatedData.y, calculatedData.y + calculatedData.height];
      const newZoneArray = [widthData, heightData];

      // const cropArray = Object.values(calculatedData);
      // for (let i = 0; i < (cropArray.length); i += 2) {
      //   console.log(cropArray.slice(i, i + 2), 'cropArray.slice(i, i + 2)')
      //   zoneArray.push(cropArray.slice(i, i + 2));
      // }
      // console.log(newZoneArray, 'newZoneArray');
      onSetCropInfo({ zoneArray: newZoneArray, crop: calculatedData });
    }
  }

  onCropChange = cropData => {
    this.setState({ crop: cropData });
  }

  fetchImage() {
    const { source } = this.props;
    const S3path = source.replace('public/', '');
    Storage.list(S3path, { bucket: process.env.REACT_APP_STORAGE_DEVICE_BUCKET })
      .then(result => {
        if (result.length > 0) {
          Storage.get(S3path, { bucket: process.env.REACT_APP_STORAGE_DEVICE_BUCKET })
            .then(res => {
              this.setState({ imagePath: res });
            });
        }
      })
      .catch(err => console.log(err, 'err'));
  }

  render() {
    const { imagePath, crop, deviceImage } = this.state;
    return (
      <div className={classes.cropWrap} ref={divElement => { this.divElement = divElement; }}>
        <ReactCrop
          src={(imagePath !== null) ? imagePath : deviceImage}
          crop={crop}
          onComplete={this.onCropComplete}
          onChange={this.onCropChange}
        />
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    MQTTData: state.deviceReducer.MQTTInfo,
    refreshCam: state.cropBoxReducer.refreshCam,
    deviceType: state.deviceReducer.deviceInfo.DeviceType
  }
);

const mapDispatchToProps = dispatch => (
  {
    onSetCropInfo: data => dispatch(setCropBoxData(data))
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(CropperEle);
