import React from 'react';

const PrivacySetting = () => {
  return (
    <iframe
      frameBorder="0"
      style={{ width: '100%', height: '100vh' }}
      src="https://docs.google.com/document/d/e/2PACX-1vQyubobOtoHlYf_O253y9--7nSquxKqsouA0lxar-hqDq0Nm0cz8luxZzyu-EvHkQ/pub?embedded=true"
    ></iframe>
  );
};
export default PrivacySetting;
