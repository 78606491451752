import React, { useState } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  EyeFill,
  EyeSlashFill
} from 'react-bootstrap-icons';
import IconButton from '@material-ui/core/IconButton';
import InfoImgIcon from '../../../assets/icons/Info.png';
import msgConstant from '../../common/textConstants';
import './Input.css';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: 'white',
        color: 'black',
        fontSize: '10px',
        boxShadow: '0 0 2px #f1f1f1',
        border: '1px solid #f1f1f1',
        top: '10px',
        right: '-50px'
      },
      arrow: {
        color: '#FFF',
        top: '7px !important'
      }
    }
  }
});

const InputElement = props => {
  const classRequired = props.classRequired ? props.classRequired : 'CustomInput';
  let inputElem = null;

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = event => event.preventDefault();

  let inpPadding = 'EyeIconPadding';
  if (props.elemConfig.type === 'password' && props.elemConfig.info === 'true') {
    inpPadding = 'InfoIconPadding';
  }

  switch (props.elemType) {
    case 'input':
      if (props.elemConfig.type === 'password') {
        inputElem = (
          <Input
            value={props.value}
            {...props.elemConfig}
            type={showPassword ? 'text' : 'password'}
            onChange={props.changed}
            className={[classRequired, inpPadding].join(' ')}
            disabled={props.isdisabled}
            onKeyDown={props.keyEvent}
            endAdornment={(
              <>
                {props.elemConfig.info
                  && (
                    <InputAdornment position="end" className="infoIconStyle">
                      <MuiThemeProvider theme={theme}>
                        <Tooltip title={msgConstant.PASSWORD_CRITERIA} arrow placement="right-end">
                          <img src={InfoImgIcon} className="infoImage" alt="" />
                        </Tooltip>
                      </MuiThemeProvider>
                    </InputAdornment>
                  )}
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  style={{ position: 'absolute', right: '-5px', paddingBottom: '22px' }}
                >
                  {showPassword ? <EyeFill /> : <EyeSlashFill />}
                </IconButton>
              </>
            )}
            fullWidth
          />
        );
      } else {
        inputElem = (
          <Input
            value={props.value}
            {...props.elemConfig}
            onChange={props.changed}
            className={classRequired}
            disabled={props.isdisabled}
            onKeyDown={props.keyEvent}
            fullWidth
          />
        );
      }
      break;
    default: inputElem = '';
  }

  return inputElem;
};

export default InputElement;
