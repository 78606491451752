import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { PubSub } from 'aws-amplify';
import SubHeader from '../ui/SubHeader';
import TypographyElem from '../ui/Typography/Typography';
import { fwUpdateHandler } from '../DeviceList/store/action-creator';
import { NOTIFICATIONS } from '../../constants/routes';
import ScrollArea from '../ui/ScrollBar/ScrollArea';
import ButtonElem from '../ui/Button/Button';
import { timeConversion } from '../common/utils';
import msgConstant from '../common/textConstants';
import ErrorCodes from '../common/ErrorCodes';
import Notification from '../ui/Notification/Notification';
import CustomDialog from '../ui/CustomDialog/CustomDialog';
import { AuthContext } from '../../Providers/AuthProvider';

const NotificationDetails = (props) => {
  const { user } = React.useContext(AuthContext);
  const email = user.userEmail;
  const { location } = props;

  console.log('Notification Details', props);

  const [detail, setDetail] = useState(null);
  const [toastMessage, setToastMessage] = useState(null);
  const [open, setOpen] = useState(false);
  const [commonError, setCommonError] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState(null);

  useEffect(() => {
    console.log(location.state.selected);
    setDetail(location.state.selected);
  }, []);

  useEffect(() => {
    setDetail(location.state.selected);
  }, [location.state.selected]);

  const notificationHandler = () => props.history.push(NOTIFICATIONS);

  const previousPageHandler = () => props.history.goBack();

  const panicHandler = () => {
    console.log('Panic is turned Off');
    console.log('Notification device ID', detail.deviceId);
    PubSub.publish(`$aws/things/${detail.deviceId}/shadow/update`, {
      state: { desired: { panicState: 0 } },
    });
  };

  const updateFwHandler = () => {
    const fwData = {
      action: 'install',
      data: {
        firmware_version: detail.firmwareVersion,
        deviceId: detail.deviceId,
        email,
        deviceType: detail.deviceType,
      },
    };
    fwUpdateHandler(fwData).then(
      (resp) => {
        if (resp.data.ResponseCode === 200) {
          if (resp.data.ErrorCode !== 'err_0') {
            if (resp.data.ErrorCode === 'err_602') {
              setOpen(true);
              setToastMessage(ErrorCodes[resp.data.ErrorCode]);
            } else {
              setCommonError(!commonError);
              setCommonErrorMsg(ErrorCodes[resp.data.ErrorCode]);
            }
          }
        } else {
          setCommonError(!commonError);
          setCommonErrorMsg(msgConstant.COMMON_ERROR);
        }
      },
      () => {
        setCommonError(!commonError);
        setCommonErrorMsg(msgConstant.COMMON_ERROR);
      }
    );
  };

  const btnComponent = () => {
    switch (detail.category) {
      case 'PANIC_ALERT':
        if (detail.category !== '0') {
          return (
            <ButtonElem
              btnColor="BlackButton"
              value="Turn Off"
              btnType="submit"
              style={{ width: 130, margin: '5px' }}
              clicked={panicHandler}
            />
          );
        }
        break;
      case 'FIRMWARE_UPDATE':
        // eslint-disable-next-line no-case-declarations
        const btnStatus = detail.statusCode;
        return (
          <ButtonElem
            btnColor="BlackButton"
            value={btnStatus === '0' ? 'Update' : 'Retry'}
            btnType="submit"
            style={{ width: 130, margin: '5px' }}
            clicked={updateFwHandler}
          />
        );
      default:
        return null;
    }
  };

  const handleClose = () => setOpen(false);

  const config = {
    position: 'bottomRight',
    open,
    onClose: handleClose,
  };

  const commonErrorBtnHandler = () => setCommonError(!commonError);

  const commonErrorDialogConfig = {
    open: commonError,
    textAlign: 'left',
    btnClicked: commonErrorBtnHandler,
  };

  return (
    <>
      <ScrollArea>
        <SubHeader clicked={previousPageHandler} {...props} style={{ paddingLeft: 20 }} />
        <div style={{ marginLeft: '20px' }}>
          <TypographyElem content="Notifications" />
        </div>
        {detail && (
          <div style={{ marginTop: '50px', paddingLeft: '20px' }}>
            <Grid container>
              <Grid item xs={6}>
                <p style={{ color: '#CCCCCC' }}>{timeConversion(detail.timestamp)}</p>
                <p style={{ fontSize: '17px', paddingBottom: '30px' }} className="BoldFont">
                  {detail.title}
                </p>
                <p style={{ paddingBottom: '30px' }}>
                  {detail.description.replace('$$%timestamp%$$', timeConversion(detail.timestamp))}
                </p>
              </Grid>
            </Grid>
            {btnComponent()}
            <ButtonElem
              btnColor="GreyButton"
              value="Cancel"
              btnType="submit"
              style={{ width: 130 }}
              clicked={notificationHandler}
            />
          </div>
        )}
        {commonError && (
          <CustomDialog dialogConfig={commonErrorDialogConfig}>
            <div>{commonErrorMsg}</div>
          </CustomDialog>
        )}
        {open && <Notification config={config}>{toastMessage}</Notification>}
      </ScrollArea>
    </>
  );
};

export default NotificationDetails;
