import Axios from 'axios';
import * as actionTypes from './action-types';
import ErrorCodes from '../../common/ErrorCodes';

export const setHomeInfo = (obj) => ({
  type: actionTypes.SET_HOME_LIST,
  homeInfo: obj,
});

export const selectedHome = (selectedHme) => ({
  type: actionTypes.SELECTED_HOME,
  selectedHme,
});

export const selectedHomeData = (selectedHomeInfo) => ({
  type: actionTypes.SELECTED_HOME_DATA,
  selectedHomeInfo,
});

export const userSelected = (data) => ({
  type: actionTypes.SELECTED_USER,
  selectedUser: data,
});

export const getHomes = (emailId) => {
  const promise = new Promise((resolve, reject) => {
    Axios.get(`users?email=${emailId}&action=listhomes`)
      .then((resp) => {
        if (resp.data.ResponseCode === 200) {
          resolve(resp);
        }
      })
      .catch((err) => reject(err));
  });
  return promise;
};

export const getHomeDevices = (emailId, homeId) => {
  const promise = new Promise((resolve, reject) => {
    Axios.get(`devices?email=${emailId}&action=listdevice&homeid=${homeId}`)
      .then((resp) => {
        if (resp.data.ResponseCode === 200) {
          resolve(resp);
        }
      })
      .catch((err) => reject(err));
  });
  return promise;
};

export const setSelectedHome = (data) => {
  const promise = new Promise((resolve, reject) => {
    Axios.post('users', data)
      .then((resp) => {
        if (resp.data.ResponseCode === 200) {
          resolve(resp);
        }
      })
      .catch((err) => reject(err));
  });
  return promise;
};

export const editHome = (data) => {
  const promise = new Promise((resolve, reject) => {
    Axios.post('users', data)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => reject(err));
  });
  return promise;
};

export const inviteUser = (param) => {
  const promise = new Promise((resolve, reject) => {
    Axios.post('users', param)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => reject(err));
  });
  return promise;
};

export const editUser = (data) => {
  const promise = new Promise((resolve, reject) => {
    Axios.post('users', data)
      .then((resp) => resolve(resp))
      .catch((err) => reject(err));
  });
  return promise;
};

export const deleteUser = (data) => {
  const promise = new Promise((resolve, reject) => {
    Axios.post('users', data)
      .then((resp) => resolve(resp))
      .catch((err) => reject(err));
  });
  return promise;
};

export const resendInvite = (data) => {
  const promise = new Promise((resolve, reject) => {
    Axios.post('users', data)
      .then((resp) => resolve(resp))
      .catch((err) => reject(err));
  });
  return promise;
};

export const setTimezoneList = (timezoneList) => ({
  type: actionTypes.SET_TIMEZONE_LIST,
  timezoneList,
});

export const getTimezoneList = (emailId) => {
  const result = {
    zoneList: null,
    errorCode: null,
    errorMsg: null,
  };

  return (dispatch) => {
    const promise = new Promise((resolve, reject) => {
      Axios.get(`users?email=${emailId}&action=list_timezones`)
        .then((resp) => {
          const respCode = resp.data.ResponseCode;
          const errCode = resp.data.ErrorCode;
          if (respCode !== 200 || (respCode === 200 && errCode !== 'err_0')) {
            result.errorCode = errCode;
            result.errorMsg = ErrorCodes[errCode];
          } else {
            result.zoneList = resp.data.ResponseData;
          }
          dispatch(setTimezoneList(result));
          resolve(result);
        })
        .catch(() => {
          reject(ErrorCodes.other);
          dispatch(setTimezoneList(result));
        });
    });
    return promise;
  };
};
