import React, { useEffect, useRef } from 'react';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';

const SensitivitySlider = props => {
  const { handleClicked, sliderVal, max } = props;
  const maxVol = max || 100;
  const updateSliderValue = val => {
    const inputElem = document.querySelector('#sound_slider input[type="hidden"]');
    if (inputElem) {
      inputElem.value = val;
    }
    const trackElem = document.querySelector('#sound_slider .MuiSlider-track');
    if (trackElem) {
      trackElem.style.width = val ? `${val * (100 / maxVol)}%` : 0;
    }
    const thumbElem = document.querySelector('#sound_slider .MuiSlider-thumb');
    if (thumbElem) {
      thumbElem.style.left = val ? `${val * (100 / maxVol)}%` : 0;
    }
  };

  const ToolTipForSoundSensitivity = sensitivityLevel => {
    const { children, open } = sensitivityLevel;
    const popperRef = useRef(null);
    useEffect(() => {
      if (popperRef.current) {
        popperRef.current.update();
      }
    });

    return (
      <Tooltip
        PopperProps={{ popperRef }}
        open={open}
        enterTouchDelay={0}
        placement="top"
        title={sliderVal}
      >
        {children}
      </Tooltip>
    );
  };

  useEffect(() => {
    updateSliderValue(sliderVal);
  });
  return (
    <Slider
      id="sound_slider"
      onChangeCommitted={handleClicked}
      ValueLabelComponent={ToolTipForSoundSensitivity}
      step={1}
      min={0}
      max={maxVol}
    />
  );
};

export default SensitivitySlider;
