import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import AWS from 'aws-sdk';
import { onUpdateMqttData } from '../../store/action-creator';
import sruImage from '../../../../assets/device/sru_display.png';
import * as iconEle from '../../../ui/Icons/Icons';
import SruActions from './SRU_actions';
import TypographyElem from '../../../ui/Typography/Typography';
import SubHeader from '../../../ui/SubHeader';
import classes from '../Device.module.css';
import { objectHasKey } from '../../../common/utils';
import { AuthContext } from '../../../../Providers/AuthProvider';

const SruDisplay = (props) => {
  const { awsConfig } = React.useContext(AuthContext);
  const { MQTTData, name, deviceInfo, thingShadowUpdate } = props;
  const [battery, setBattery] = useState(null);
  const [connected, setConnected] = useState(null);
  const [charging, setCharging] = useState(null);
  const [isLoaded, setLoaded] = useState(true);

  function onGetThingShadow(id) {
    const iotData = new AWS.IotData(awsConfig);
    const params = {
      thingName: id,
    };
    iotData.getThingShadow(params, (err, success) => {
      if (err) {
        console.log(err, err.stack);
      } else {
        const payload = JSON.parse(success.payload);
        thingShadowUpdate(payload.state);
      }
    });
  }

  useEffect(() => {
    if (deviceInfo && awsConfig) {
      onGetThingShadow(deviceInfo.DeviceId);
    }
  }, []);

  useEffect(() => {
    if (deviceInfo.DeviceType === 'SmartReadinessUnit') {
      if (MQTTData && isLoaded) {
        setLoaded(false);
        if (objectHasKey(MQTTData, 'reported')) {
          if (objectHasKey(MQTTData.reported, 'batRem')) {
            setBattery(MQTTData.reported.batRem);
          }
          if (objectHasKey(MQTTData.reported, 'cloudConnectedState')) {
            setConnected(
              MQTTData.reported.cloudConnectedState === 1 ? 'Connected' : 'Disconnected'
            );
          }
          if (objectHasKey(MQTTData.reported, 'batCharge')) {
            setCharging(MQTTData.reported.batCharge);
          }
        }
      }
    }
  }, [MQTTData]);

  const between = (x, min, max) => x >= min && x <= max;

  const getImage = (charge, bat) => {
    if (charge) {
      return iconEle.batteryChargingIcon;
      // eslint-disable-next-line
    } else if (bat === 100 && charge) {
      return iconEle.batteryPluggedIcon;
    } else if (between(bat, 0, 20)) {
      return iconEle.battery0020Icon;
    } else if (between(bat, 21, 50)) {
      return iconEle.battery2150Icon;
    } else if (between(bat, 51, 80)) {
      return iconEle.battery5180Icon;
    } else if (between(bat, 81, 100)) {
      return iconEle.battery8100Icon;
    }
  };

  let batteryLevel = null;
  if (battery !== null) {
    batteryLevel = (
      <div className={classes.wrapper}>
        <img src={getImage(charging, battery)} alt="Battery" />
        <p className={classes.batteryClass}>{`${battery}%`}</p>
      </div>
    );
  }

  let connectedState = null;
  if (connected) {
    connectedState = (
      <img
        src={connected === 'Connected' ? iconEle.connectedIcon : iconEle.disconnectedIcon}
        alt="wifi"
        role="presentation"
      />
    );
  }

  const sruContainer = { height: 'calc(100vh - 7.7em)' };

  return (
    <>
      <SubHeader {...props} />
      <TypographyElem content={name} smallText />
      <div className={classes.deviceStatus}>
        <div style={{ margin: '8px' }}>{connectedState}</div>
        <div>{batteryLevel}</div>
      </div>
      <Grid
        container
        direction="row"
        style={sruContainer}
        justify="space-around"
        alignItems="center"
      >
        <Grid item xs={6} align="center">
          <img src={sruImage} alt="SRU" className={classes.sruImage} />
        </Grid>
        <Grid item xs={6}>
          <SruActions />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  MQTTData: state.deviceReducer.MQTTInfo,
  deviceInfo: state.deviceReducer.deviceInfo,
});

const mapDispatchToProps = (dispatch) => ({
  thingShadowUpdate: (data) => dispatch(onUpdateMqttData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SruDisplay);
