// [#84226] WiFi status was not showing in home page
import React, { useEffect, useState } from 'react';
import { PubSub } from 'aws-amplify';
import classes from './DeviceList.module.css';
import LoadingSpinner from '../ui/LoadingSpinner/LoadingSpinner';
import { disconnectedIcon } from '../ui/Icons/Icons';

const ConnectionStatus = (props) => {
  const { deviceId, deviceStatus, plan } = props;
  const spinnerConfig = {
    showSpinner: true,
    color: '#0970C8',
  };
  const spinner = <LoadingSpinner spinnerConfig={spinnerConfig} size={20} />;
  const [status, setStatus] = useState(spinner);
  const [freePlan, setFreePlan] = useState(null);

  useEffect(() => {
    if (deviceStatus && deviceStatus.length) {
      const obj = deviceStatus.find((list) => list.deviceId === deviceId);
      if (obj) {
        if (obj.connectionState) {
          // setStatus(<img src={connectedIcon} alt="Connected" />);
          setStatus(null);
        } else {
          setStatus(<img src={disconnectedIcon} alt="Disconnected" />);
        }
      }
    }
  }, [deviceId, deviceStatus]);

  useEffect(() => {
    if (plan && plan.toUpperCase() === 'FREE') {
      setFreePlan(true);
    }
  }, [plan]);

  useEffect(() => {
    PubSub.subscribe(`evt/bgz/camera/connectedstate/${deviceId}`).subscribe({
      next: (data) => {
        if (data) {
          const connState = data.value.connectionState;
          if (connState) {
            setStatus(null);
          } else {
            setStatus(<img src={disconnectedIcon} alt="Disconnected" />);
          }
        }
      },
      error: (error) => console.error('Rejected', error),
      close: () => console.log('Done'),
    });
  }, []);

  return (
    <>
      <span className={classes.connectonStatus}>{status}</span>
      {freePlan && <span className={classes.currentPlan}>FREE</span>}
    </>
  );
};

export default ConnectionStatus;
