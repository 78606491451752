import React from 'react';
import { connect } from 'react-redux';
import classes from './Cropper.module.css';
import ButtonElem from '../ui/Button/Button';
import * as actionCreator from './store/action-creator';

const ActivityzoneActions = props => {
  const {
    zoneDisplayHandler, onSaveActivityZone, refreshCam, refreshCamView
  } = props;

  const onRefreshCamView = () => {
    refreshCamView(!refreshCam);
  };

  const saveButton = {
    width: 172,
    marginLeft: 176
  };
  const cancelButton = {
    width: 172,
    marginLeft: 20
  };

  return (
    <div className={classes.ActionBtns}>
      <div
        className={classes.refreshText}
        onClick={onRefreshCamView}
        role="presentation"
      >
        Refresh camera view &gt;
      </div>
      <ButtonElem
        btnColor="YellowButton"
        value="Save activity zones"
        clicked={onSaveActivityZone}
        style={saveButton}
      />
      <ButtonElem
        btnColor="GreyButton"
        value="Cancel"
        clicked={zoneDisplayHandler}
        style={cancelButton}
      />
    </div>
  );
};

const mapStateToProps = state => (
  {
    refreshCam: state.cropBoxReducer.refreshCam
  }
);

const mapDispatchToProps = dispatch => (
  {
    refreshCamView: data => dispatch(actionCreator.refreshCamView(data))
  }
);
export default connect(mapStateToProps, mapDispatchToProps)(ActivityzoneActions);
