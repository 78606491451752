import * as actionTypes from './action-types';

const intialState = {
  deviceToken: null,
  isTokenSent: false,
  notificationStatus: false,
  notificationPayload: null
};

const updateObject = (prevState, updatedValues) => (
  {
    ...prevState,
    ...updatedValues
  }
);

const reducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DEVICE_TOKEN:
      return updateObject(state, { deviceToken: action.deviceToken });
    case actionTypes.SET_TOKEN_STATUS:
      return updateObject(state, { isTokenSent: action.isTokenSent });
    case actionTypes.SET_NOTIFICATION_STATUS:
      return updateObject(state, { notificationStatus: action.notificationStatus });
    case actionTypes.SET_NOTIFICATION_PAYLOAD:
      return updateObject(state, { notificationPayload: action.notificationPayload });
    default: return state;
  }
};

export default reducer;
