import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoadingSpinner = (props) => {
  const [spinnerElem, setSpinnerElem] = useState(null);
  const { spinnerConfig, size } = props;
  const defualtSize = size || 40;
  const spinnerStyle = {
    position: 'absolute',
    left: spinnerConfig.left || '50%',
    right: spinnerConfig.right || 'auto',
    color: spinnerConfig.color || '#FFD200',
    zIndex: spinnerConfig.zIndex || '10000',
  };

  if (spinnerConfig.bottom) {
    spinnerStyle.bottom = spinnerConfig.bottom;
  } else {
    spinnerStyle.top = spinnerConfig.top || '50%';
  }

  useEffect(() => {
    setSpinnerElem(<CircularProgress style={spinnerStyle} thickness={6} size={defualtSize} />);
  }, []);

  return <>{spinnerConfig.showSpinner && spinnerElem}</>;
};

export default React.memo(LoadingSpinner);
