import React from 'react';
import logo from '../../assets/images/logo_black.png';
import CircularProgress from '@material-ui/core/CircularProgress';

const fixed = 250;

export default function LoadingScreen() {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        width: '100vw',
        alignItems: 'center',
      }}
    >
      <div style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress
            thickness={0.3}
            style={{
              color: '#444',
              height: fixed,
              width: fixed,
            }}
          />{' '}
        </div>
        <div
          style={{
            height: fixed,
            width: fixed,
            top: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={logo} style={{ height: fixed * 0.6 }} />
        </div>
      </div>
    </div>
  );
}
