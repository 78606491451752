import React, { useState, useEffect } from 'react';
import { PubSub } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TriggerEle from './TriggerEle';
import { displayActivityZone, setMQTTUpdated } from '../../../store/action-creator';
import * as iconElem from '../../../../ui/Icons/Icons';
import ButtonElem from '../../../../ui/Button/Button';
import CustomDialog from '../../../../ui/CustomDialog/CustomDialog';
import Notification from '../../../../ui/Notification/Notification';
import ActivityZoneDisplay from '../../../../Cropper/ActivityZoneDisplay';
import { objectHasKey, getBitPosition } from '../../../../common/utils';
import msgConstant from '../../../../common/textConstants';
import classes from '../DeviceSettings.module.css';

const ActivityTriggers = (props) => {
  const {
    MqttInfo,
    deviceInfo,
    dispActivityZone,
    isMQTTUpdated,
    onSetMQTTUpdated,
    trigger,
    selectedCamera,
  } = props;

  const [motionButton, setMotionButton] = useState(1);
  const [motionChecked, setMotionChecked] = useState(0);
  const [soundChecked, setSoundChecked] = useState(0);
  const [zoneChecked, setZoneChecked] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isLoaded, setLoaded] = useState(true);
  const [showSoundBlock, setSoundBlock] = useState(true);
  const [subscriptionList, setSubscriptionList] = useState(null);
  const [subExpModal, setSubExpModal] = useState(false);
  const [showSoundModal, setShowSoundModal] = useState(false);
  const [soundStatus, setSoundStatus] = useState(0);
  const [inCameraStatus, setInCameraStatus] = useState(false);
  const [open, setOpen] = useState(false);

  const motionTypes = (val) => {
    switch (val) {
      case 0:
        if (motionChecked !== 0) {
          setMotionChecked(0);
        }
        break;
      case 1:
        if (motionButton !== 1) {
          setMotionButton(1);
        }
        if (motionChecked !== 1) {
          setMotionChecked(1);
        }
        break;
      case 2:
        if (motionButton !== 2) {
          setMotionButton(2);
        }
        if (motionChecked !== 1) {
          setMotionChecked(1);
        }
        break;
      default:
        break;
    }
  };

  const inCameraActivityTriggers = () => {
    if (objectHasKey(MqttInfo.desired, 'motionDetectionInw')) {
      const motion = MqttInfo.desired.motionDetectionInw;
      motionTypes(motion);
    }
    if (objectHasKey(MqttInfo.desired, 'soundDetectionInw')) {
      if (soundChecked !== MqttInfo.desired.soundDetectionInw) {
        setSoundChecked(MqttInfo.desired.soundDetectionInw);
      }
    }
    if (objectHasKey(MqttInfo.desired, 'activityZoneStateInw')) {
      if (zoneChecked !== MqttInfo.desired.activityZoneStateInw) {
        setZoneChecked(MqttInfo.desired.activityZoneStateInw);
      }
    }
  };

  const normalCameraActivityTriggers = () => {
    if (objectHasKey(MqttInfo.desired, 'motionDetection')) {
      const motion = MqttInfo.desired.motionDetection;
      motionTypes(motion);
    }
    if (objectHasKey(MqttInfo.desired, 'soundDetection')) {
      if (soundChecked !== MqttInfo.desired.soundDetection) {
        setSoundChecked(MqttInfo.desired.soundDetection);
      }
    }
    if (objectHasKey(MqttInfo.desired, 'activityZoneState')) {
      if (zoneChecked !== MqttInfo.desired.activityZoneState) {
        setZoneChecked(MqttInfo.desired.activityZoneState);
      }
    }
  };

  const renderActivityTriggers = () => {
    setSoundBlock(true);
    if (objectHasKey(MqttInfo, 'desired')) {
      if (deviceInfo.DeviceType === 'OverDoorCamera') {
        if (selectedCamera && selectedCamera === 'inside') {
          inCameraActivityTriggers();
        } else {
          normalCameraActivityTriggers();
          setSoundBlock(false);
        }
      } else {
        normalCameraActivityTriggers();
      }
    }
    if (objectHasKey(MqttInfo, 'reported')) {
      if (objectHasKey(MqttInfo.reported, 'subscriptionValue')) {
        const subValue = MqttInfo.reported.subscriptionValue;
        const obj = {
          activityZone: getBitPosition(subValue, 1),
          twoWayTalk: getBitPosition(subValue, 2),
          personDetection: getBitPosition(subValue, 3),
          motionDetection: getBitPosition(subValue, 4),
          recording: getBitPosition(subValue, 5),
        };
        setSubscriptionList(obj);
      }
    }
  };

  useEffect(() => {
    if (MqttInfo && isLoaded) {
      setLoaded(false);
      renderActivityTriggers();
    }
  }, [MqttInfo]);

  useEffect(() => {
    if (isMQTTUpdated && trigger) {
      onSetMQTTUpdated();
      setTimeout(() => {
        renderActivityTriggers();
      });
    }
  }, [isMQTTUpdated]);

  useEffect(
    () => () => {
      if (trigger === true) {
        props.triggerHandler();
      }
    },
    []
  );

  const subExpModalConfig = {
    open: subExpModal,
    type: 'message',
    btnClicked: () => setSubExpModal(false),
  };

  const updateStatus = (newStatus) => {
    if (deviceInfo) {
      PubSub.publish(`$aws/things/${deviceInfo.DeviceId}/shadow/update`, {
        state: { desired: newStatus },
      });
    }
  };

  const toggleChecked = (type, status) => {
    let isInCamera = false;
    const isSubscriptionFree = deviceInfo?.Subscription?.toLowerCase() === 'free';
    const newStatus = {};
    if (deviceInfo.DeviceType === 'OverDoorCamera') {
      if (selectedCamera && selectedCamera === 'inside') {
        isInCamera = true;
      }
    }

    switch (type) {
      case 'motion': {
        if (status === 0 && isSubscriptionFree) {
          setOpen(true);
        }
        const motionStatus = status ? 0 : 1;
        setMotionChecked(motionStatus);
        if (isInCamera) {
          if (motionStatus === 1) {
            newStatus.motionDetectionInw = motionButton;
          } else {
            newStatus.motionDetectionInw = motionStatus;
          }
        } else if (motionStatus === 1) {
          newStatus.motionDetection = motionButton;
        } else {
          newStatus.motionDetection = motionStatus;
        }
        updateStatus(newStatus);
        break;
      }
      case 'sound': {
        if (status === 0 && isSubscriptionFree) {
          setOpen(true);
        }
        // Microphone and Sound detection handling.
        const getSoundStatus = status ? 0 : 1;
        if (
          (!status && isInCamera && !MqttInfo.desired.microphoneInw) || // Overdoor Inward camera
          (!status && !isInCamera && !MqttInfo.desired.microphone) // HTC
        ) {
          setSoundStatus(getSoundStatus);
          setInCameraStatus(isInCamera);
          setShowSoundModal(true);
        } else {
          if (isInCamera) {
            newStatus.soundDetectionInw = getSoundStatus;
          } else {
            newStatus.soundDetection = getSoundStatus;
          }
          updateStatus(newStatus);
        }
        break;
      }
      case 'zone': {
        if (status === 0 && subscriptionList.activityZone === 0) {
          setSubExpModal(true);
        } else {
          const zoneStatus = status ? 0 : 1;
          setZoneChecked(zoneStatus);
          if (isInCamera) {
            newStatus.activityZoneStateInw = zoneStatus;
          } else {
            newStatus.activityZoneState = zoneStatus;
          }
          updateStatus(newStatus);
        }
        break;
      }
      default:
        break;
    }
  };

  const btnHandler = (status) => {
    if (status) {
      const newStatus = {};
      setSoundChecked(soundStatus);
      if (inCameraStatus) {
        newStatus.soundDetectionInw = soundStatus;
        newStatus.microphoneInw = 1;
      } else {
        newStatus.soundDetection = soundStatus;
        newStatus.microphone = 1;
      }
      updateStatus(newStatus);
    }
    setShowSoundModal(false);
  };

  const dialogConfig = {
    open: showSoundModal,
    type: 'action',
    disabled: true,
    btnText: 'OK',
    btnClicked: (status) => btnHandler(status),
  };

  const headerClass = { paddingTop: '2em' };
  const peopleOnlyButton = { width: 120, marginLeft: 20 };
  const allMotionButton = { width: 120, marginLeft: 0 };

  const onCloseHandler = () => props.triggerHandler();

  const ActivityTriggerClose = () => (
    <img
      src={iconElem.closeWhiteIcon}
      alt="X"
      onClick={onCloseHandler}
      role="presentation"
      className={classes.closeIcon}
    />
  );

  const RenderSoundToggle = () => (
    <TriggerEle
      name="Sound"
      value={soundChecked}
      source={iconElem.soundIcon}
      type="sound"
      change={toggleChecked}
    />
  );

  const RenderMotionToggle = () => (
    <TriggerEle
      name="Motion"
      value={motionChecked}
      source={iconElem.motionIcon}
      type="motion"
      change={toggleChecked}
    />
  );

  const motionClicked = (e, val) => {
    if (val === 'People only' && subscriptionList.personDetection === 0) {
      setSubExpModal(true);
    } else {
      const status = val === 'People only' ? 2 : 1;
      setMotionButton(status);
      updateStatus({ motionDetection: status });
    }
  };

  const RenderActivityZoneToggle = () => (
    <div>
      <TriggerEle
        name="Use activity zones"
        value={zoneChecked}
        source={iconElem.selectionSquare}
        type="zone"
        change={toggleChecked}
      />
    </div>
  );

  const dialogHandler = () => {
    setShowModal(!showModal);
    props.activityZone(true);
  };

  const getThumbnailImgPath = (imageFile) => {
    let path = null;
    if (typeof imageFile === 'string') {
      path = imageFile;
    } else if (typeof imageFile === 'object' && imageFile.length) {
      // eslint-disable-next-line prefer-destructuring
      path = imageFile[0];
    }
    return path;
  };

  const notificationConfig = {
    open,
    type: 'error',
    position: 'topCenter',
    autoHide: 5000,
    onClose: () => setOpen(false),
  };

  return (
    <>
      <div className={dispActivityZone ? classes.blurOverlay : ''}>
        <ActivityTriggerClose />
        <div className={classes.header} style={headerClass}>
          {' '}
          Activity triggers
        </div>
        <p className={classes.triggerInfo}>
          Set what events trigger your camera to record and/or send notifications
        </p>
        <div className={classes.actTriggerHorLine} />
        {showSoundBlock && (
          <>
            <RenderSoundToggle />
            <p className={classes.soundInfo}>Detects events for sound levels in excess of 90dB</p>
            <div className={classes.actTriggerHorLine} />
          </>
        )}
        <RenderMotionToggle />
        {motionChecked ? (
          <>
            <div className={classes.motionButtons}>
              <ButtonElem
                btnColor={motionButton === 1 ? 'YellowButton' : 'GreyButton'}
                value="All"
                clicked={(evt) => motionClicked(evt, 'All motion')}
                style={allMotionButton}
              />
              <div>
                <ButtonElem
                  btnColor={motionButton === 2 ? 'YellowButton' : 'GreyButton'}
                  value="People Only"
                  clicked={(evt) => motionClicked(evt, 'People only')}
                  style={peopleOnlyButton}
                />
              </div>
            </div>
            <div className={classes.actTriggerHorLine} />
          </>
        ) : null}
        {motionButton === 2 && motionChecked ? (
          <>
            <RenderActivityZoneToggle />
            {zoneChecked ? (
              <Grid container direction="column">
                <Grid item className={classes.SmallText}>
                  Get notified when a person is detected inside a designated zone within the camera
                  view.
                </Grid>
                <Grid item onClick={dialogHandler}>
                  {deviceInfo && (
                    <ActivityZoneDisplay
                      thumbnailLInk={getThumbnailImgPath(deviceInfo.DeviceThumbnail)}
                    />
                  )}
                </Grid>
              </Grid>
            ) : null}
          </>
        ) : null}
      </div>
      {subExpModal && (
        <CustomDialog dialogConfig={subExpModalConfig}>
          <div>{msgConstant.SUBSCRIPTION_VALIDATION}</div>
        </CustomDialog>
      )}
      {showSoundModal && (
        <CustomDialog dialogConfig={dialogConfig}>
          <div>{msgConstant.SOUND_TRIGGER}</div>
        </CustomDialog>
      )}
      {open && (
        <Notification config={notificationConfig}>
          {msgConstant.STREAM_SUPPORT}
          <div style={{ marginTop: '1em' }}>*Activity Notifications are enabled.</div>
        </Notification>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  deviceInfo: state.deviceReducer.deviceInfo,
  cropBoxData: state.cropBoxReducer.cropBoxInfo,
  dispActivityZone: state.deviceReducer.dispActivityZone,
  isMQTTUpdated: state.deviceReducer.MQTTUpdated,
  selectedCamera: state.deviceReducer.selectedCamera,
});

const mapDispatchToProps = (dispatch) => ({
  activityZone: (val) => dispatch(displayActivityZone(val)),
  onSetMQTTUpdated: () => dispatch(setMQTTUpdated(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ActivityTriggers));
